import React from 'react';
import { Tabs } from 'antd';
import i18next from 'i18next';
// import UserTypesList from '../UserTypes/List';
import { useDispatch } from 'react-redux';
import ChannelsList from 'pages/Channels/List';
import ProductsList from 'pages/Products/List';
import { push } from 'connected-react-router';
import SalaryInfosList from 'containers/SalaryInfos/List';
import { useAccessSettingTabs } from 'hooks/usePermissionRoutes';
import MistakeTypesList from 'containers/MistakeTypes/List';
import PlatformsList from '../Platforms/List';
import ProductCatalogsList from '../productTypes/List';
import CustomerSegmentationsList from '../CustomerSegmentations/List';
import ServiceTypesList from '../ServiceTypes/List';
import BrandsList from '../Brands/List';
import NichesList from '../Niches/List';
import Dealpipelines from '../Dealpipelines/List';
import OrderRequirementsList from '../../containers/OrderRequirements/List/index';

const { TabPane } = Tabs;

const TABS = [
  {
    key: 'orderRequirements',
    text: 'tabs.orderRequirements',
    url: '/orderRequirements',
    component: OrderRequirementsList,
  },
  {
    key: 'mistakeTypes',
    text: 'tabs.mistakeTypes',
    url: '/mistakeTypes',
    component: MistakeTypesList,
  },
  {
    key: 'salaryInfos',
    text: 'tabs.salaryInfos',
    url: '/salaryInfos',
    component: SalaryInfosList,
  },
  {
    key: 'niches',
    text: 'tabs.niches',
    url: '/niches',
    component: NichesList,
  },
  {
    key: 'brands',
    text: 'tabs.brands',
    url: '/brands',
    component: BrandsList,
  },
  {
    key: 'platforms',
    text: 'tabs.platforms',
    url: '/platforms',
    component: PlatformsList,
  },
  {
    key: 'productTypes',
    text: 'tabs.productTypes',
    url: '/productTypes',
    component: ProductCatalogsList,
  },
  {
    key: 'customerSegmentations',
    text: 'tabs.customerSegmentations',
    url: '/customerSegmentations',
    component: CustomerSegmentationsList,
  },
  {
    key: 'serviceTypes',
    text: 'tabs.serviceTypes',
    url: '/serviceTypes',
    component: ServiceTypesList,
  },
  {
    key: 'dealpipelines',
    text: 'tabs.dealpipelines',
    url: '/dealpipelines',
    component: Dealpipelines,
  },
  {
    key: 'channels',
    text: 'tabs.channels',
    url: '/channels',
    component: ChannelsList,
  },
  {
    key: 'products',
    text: 'tabs.products',
    url: '/products',
    component: ProductsList,
  },
];

const Settings = (props) => {
  const dispatch = useDispatch();
  const tabs = useAccessSettingTabs(TABS);
  const onChangeTab = (e) => {
    dispatch(push(`/settings/${e}`));
  };
  return (
    <div>
      <Tabs
        onTabClick={onChangeTab}
        defaultActiveKey={props?.match?.params?.modal}
      >
        {tabs.map((tab) => (
          <TabPane tab={i18next.t(tab.text)} key={tab.key}>
            <tab.component
              rootPath="/settings"
              noCardWrapper
              layoutButtonCreate="no-inline"
              {...props}
            />
          </TabPane>
        ))}
      </Tabs>
    </div>
  );
};

Settings.propTypes = {};

export default Settings;
