import React from 'react';
import Create from '../../rest/Create';
import Form from '../components/Form';

const SalariesCreate = props => (
  <Create {...props} resource="salaries">
    <Form />
  </Create>
);

SalariesCreate.propTypes = {};

export default SalariesCreate;
