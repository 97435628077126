import React from 'react';
import List from '../../../containers/rest/List';
import RestFieldItem from '../../../components/RestField/RestFieldItem';
import ActionGroup from '../../../components/RestActions/ActionGroup';
import EditButton from '../../../components/RestActions/EditButton';
import DeleteButton from '../../../components/RestActions/DeleteButton';

const NichesList = props => (
  <List {...props} isScroll={false} hasAnalysis resource="niches">
    <RestFieldItem sorter source="name" header="niches.name" />
    <RestFieldItem source="description" header="niches.description" />
    <ActionGroup>
      <EditButton />
      <DeleteButton isSortDelete />
    </ActionGroup>
  </List>
);

NichesList.propTypes = {};

export default NichesList;
