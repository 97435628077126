const OneSignal = window.OneSignal || [];
const PUSHNOTIFICATION_COMMAND = {
  'notification.displayed': 'show',
  'notification.clicked': 'clicked',
};

const init = () => {
  try {
    OneSignal.init({
      appId: process.env.REACT_APP_ONESIGNAL_APP_ID,
      autoRegister: true,
      allowLocalhostAsSecureOrigin: true,
      notifyButton: {
        enable: true,
      },
      promptOptions: {
        actionMessage:
          "We'd like to show you notifications for the latest news and updates.",
        acceptButtonText: 'ALLOW',
        cancelButtonText: 'NO THANKS',
      },
    });
    if (Notification.permission === 'granted') {
      OneSignal.registerForPushNotifications();
    }
  } catch (error) {}
};

const getUserToken = (messageHandler) => {
  try {
    OneSignal.getUserId().then((oneSignalUserId) => {
      messageHandler({
        payload: { oneSignalUserId },
        actionType: 'getUserId',
      });
    });
  } catch (error) {
    console.log(error);
    OneSignal.registerForPushNotifications();
  }
  // OneSignal.isPushNotificationsEnabled().then(isEnabled => {
  //   console.log('getUserId',isEnabled);
  //   if (isEnabled) {
  //     OneSignal.getUserId().then(oneSignalUserId => {
  //       messageHandler({
  //         payload: { oneSignalUserId },
  //         actionType: 'getUserId',
  //       });
  //       // OneSignal.push(['setSubscription', true]);
  //     });
  //   } else {
  //     OneSignal.registerForPushNotifications();
  //   }
  // });
};

const onShowNotification = (messageHandler) => {
  try {
    OneSignal.push(() => {
      OneSignal.on('notificationDisplay', (event) => {
        messageHandler({
          ...event.data,
          actionType: PUSHNOTIFICATION_COMMAND[event.data.command],
        });
      });
    });
  } catch (error) {}
};

export const initOneSignal = (messageHandler) => {
  try {
    OneSignal.push(() => {
      init();
      OneSignal.on('notificationPermissionChange', (permissionChange) => {
        const currentPermission = permissionChange.to;
        if (currentPermission === 'granted') {
          getUserToken(messageHandler);
        }
      });
      getUserToken(messageHandler);
      onShowNotification(messageHandler);
    });
  } catch (error) {}
};

export const registerTag = (tag) => {
  OneSignal.sendTags({ channel: tag });
};
