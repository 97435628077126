import React, { useState, useLayoutEffect } from 'react';
// import PropTypes from 'prop-types';
import { Popover, Button, InputNumber } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import i18next from 'i18next';
import { formatMoney } from '../../../../utils/textUtils';
import DealPriceWrapper, { DealPriceContentWrapper } from './styles';
import { formatInputMoney } from '../../../../utils/tools';
import OrderAction from '../../../../redux/orders/actions';

const DealPrice = ({ order, style }) => {
  const [hovered, setHovered] = useState(false);
  const user = useSelector((state) => state.auth.data);
  const [dealPrice, setDealPrice] = useState(order.dealPrice);
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const updatePrice = () => {
    setLoading(true);
    dispatch(
      OrderAction.editOrders(
        {
          id: order.id,
          dealPrice: Math.round(dealPrice),
        },
        { isBack: false, unRefreshByRequest: true }
      )
    ).then((e) => {
      setLoading(false);
      if (e.id) {
        setHovered(false);
      }
    });
  };

  const onClose = () => {
    setDealPrice(order.dealPrice);
    setHovered(!hovered);
  };
  useLayoutEffect(() => {
    if (order.dealPrice && !dealPrice) {
      setDealPrice(order.dealPrice);
    }
    // eslint-disable-next-line
  }, [order.dealPrice]);

  const content = (
    <DealPriceContentWrapper style={style}>
      <InputNumber
        {...formatInputMoney}
        onChange={(value) => setDealPrice(value)}
        defaultValue={order.dealPrice}
        value={dealPrice}
      />
      <br />
      <div className="row">
        <Button loading={isLoading} onClick={updatePrice} type="primary">
          {i18next.t('button.ok')}
        </Button>
        <Button loading={isLoading} onClick={onClose} type="danger">
          {i18next.t('button.cancel')}
        </Button>
      </div>
    </DealPriceContentWrapper>
  );
  return user &&
    user.isOutsource === false &&
    (user.scope === 'manager' ||
      user.permission?.orders?.indexOf('dealPrice') > -1) ? (
    <DealPriceWrapper style={style}>
      <Popover
        placement="left"
        visible={hovered}
        content={content}
        title={i18next.t('orders.dealPrice')}
      >
        <div
          role="presentation"
          onClick={() => setHovered(!hovered)}
          className="dealPrice"
        >
          {`${formatMoney(order.dealPrice)}đ`}
        </div>
      </Popover>
    </DealPriceWrapper>
  ) : order.dealPrice ? (
    <DealPriceWrapper style={style}>
      <div role="presentation" className="dealPrice">
        {`${formatMoney(order.dealPrice)}đ`}
      </div>
    </DealPriceWrapper>
  ) : null;
};
DealPrice.propTypes = {};

export default DealPrice;
