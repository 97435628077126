import React, { useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import moment from 'moment';
import { Table, Collapse } from 'antd';
import { WORKING_TIME_OF_DAY } from 'configs/localData';
import { useSelector } from 'react-redux';
import crudSelectors from 'redux/crudSelectors';
import { formatDate } from 'utils/textUtils';
import { groupBy, values, sortBy } from 'lodash';

const WEEKS = [
  'date.t2',
  'date.t3',
  'date.t4',
  'date.t5',
  'date.t6',
  'date.t7',
  'date.cn',
];
const CurrentDate = ({ selectDay }) => {
  const workingRegisters = useSelector(
    crudSelectors.workingRegisters.getDataArr,
  );
  const [columns, setColumns] = useState([]);

  useLayoutEffect(() => {
    const datesOfWeek = [];
    for (let i = 0; i < 7; i += 1) {
      datesOfWeek.push({
        title: WEEKS[i],
        date: moment(selectDay)
          .startOf('week')
          .add(i, 'd')
          .format('YYYY-MM-DD'),
      });
    }
    const tempColumns = [
      {
        title: i18next.t('workingRegisters.name'),
        dataIndex: 'user.displayName',
        // key: 'displayName',
        fixed: 'left',
        width: 150,
      },
      {
        title: i18next.t('workingRegisters.total'),
        dataIndex: 'totalHours',
        // key: 'displayName',
        fixed: 'left',
        width: 80,
      },
      {
        title: i18next.t('OT'),
        dataIndex: 'totalHoursOT',
        // key: 'displayName',
        fixed: 'left',
        width: 80,
      },
      ...datesOfWeek.map((date, index) => ({
        ...date,
        title: i18next.t(date.title, { date: formatDate(date.date) }),
        dataIndex: date.title,
        // key: date.title + index,
        align: 'center',
        children: WORKING_TIME_OF_DAY.map((e) => ({
          title: i18next.t(e.title),
          align: 'center',
          key: `${date.date}.${e.title}.hours`,
          dataIndex: `${date.date}.hours`,
          children: e.hours.map((hour) => ({
            title: hour,
            align: 'center',
            // key: `${e.title}-${index}-${hour}`,
            dataIndex: `${date.date}.0.workingTimes.${hour}`,
          })),
        })),
      })),
    ];
    setColumns(tempColumns);
  }, [selectDay]);

  const currentWorkingRegisters = values(
    groupBy(
      sortBy(
        workingRegisters.filter(
          (e) =>
            moment(e.date).diff(moment(selectDay).startOf('week')) >= 0 &&
            moment(e.date).diff(moment(selectDay).endOf('week')) <= 0,
        ),
        'date',
      ),
      'userId',
    ),
  ).map((e) => {
    const temp = { totalHours: 0, totalHoursOT: 0 };
    e.forEach((day) => {
      temp.totalHours += day.totalHours;
      temp.totalHoursOT += day.totalHoursOT;
    });
    return {
      ...temp,
      ...groupBy(
        e.map((e) => ({ ...e, date: moment(e.date).format('YYYY-MM-DD') })),
        'date',
      ),
      id: e[0].userId,
      user: e[0]?.user,
    };
  });

  return (
    <Collapse defaultActiveKey={['1']}>
      <Collapse.Panel
        key="1"
        header={
          selectDay === moment().format('YYYY-MM-DD')
            ? i18next.t('workingRegisters.today')
            : selectDay
        }
      >
        <Table
          rowKey="id"
          columns={columns}
          bordered
          pagination={false}
          dataSource={currentWorkingRegisters}
          scroll={{ x: 6500 }}
        />
      </Collapse.Panel>
    </Collapse>
  );
};
CurrentDate.propTypes = {
  selectDay: PropTypes.string,
};

export default CurrentDate;
