import { makeConstantCreator, makeActionCreator } from 'utils/reduxUtils';
import {
  makeCRUDConstantCreator,
  makeCRUDActionsCreator,
} from '../crudCreator/actions';

export const PRIMARY_KEY = 'id';
export const MODEL = 'users';
export const IGNORE_ACTIONS = [];
export const UsersTypes = {
  ...makeCRUDConstantCreator(MODEL, IGNORE_ACTIONS),
  ...makeConstantCreator(
    'ADD_PERMISSION_FOR_USER',
    'ADD_PERMISSION_FOR_USER_SUCCESS',
    'ADD_PERMISSION_FOR_USER_FAILURE',
    'SET_DEFAULT_PERMISSION_FOR_USER'
  ),
};
const CRUDUsersActions = makeCRUDActionsCreator(MODEL, IGNORE_ACTIONS);

export const addPermissionForUser = (data) =>
  makeActionCreator(UsersTypes.ADD_PERMISSION_FOR_USER, { data });

export const addPermissionForUserSuccess = (data) =>
  makeActionCreator(UsersTypes.ADD_PERMISSION_FOR_USER_SUCCESS, { data });

export const addPermissionForUserFailure = (data) =>
  makeActionCreator(UsersTypes.ADD_PERMISSION_FOR_USER_FAILURE, { data });

export const setDefaultPermissionForUser = (data) =>
  makeActionCreator(UsersTypes.SET_DEFAULT_PERMISSION_FOR_USER, { data });

/**
 * getAllCaseTypes({pageSize, page })
 * getByIdCaseTypes(data)
 * createCaseTypes(data)
 * deleteCaseTypes()
 * editCaseTypes(data)
 */
export default { ...CRUDUsersActions };
