import React from 'react';
import RestInputItem from '../../../../components/RestInput/RestInputItem';

const ServiceTypesForm = props => (
  <div {...props}>
    <RestInputItem source="name" header="serviceTypes.name" />
    <RestInputItem source="description" header="serviceTypes.description" />
  </div>
);

ServiceTypesForm.propTypes = {};

export default ServiceTypesForm;
