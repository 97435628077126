import styled from 'styled-components';

export default styled.div`
  cursor: pointer;
  img {
    width: 100px;
    height: 100px;
  }
  .ql-editor {
    min-height: 150px;
  }
`;

export const PreviewTextEditorWrapper = styled.div`
  cursor: pointer;
  background: #f9f9f9;
  padding: 20px;
  border-radius: 20px;
  margin: 20px 0px;
  img {
    width: 100px;
    height: 100px;
  }
  & > * {
    overflow-wrap: break-word;
    word-wrap: break-word;
  }
`;
