import React from "react";
import SearchByImageButton from "./SearchByImageButton";
import { useDispatch } from "react-redux";
import { editOrders } from "redux/orders/actions";

export default function SearchInList({ record, onChange, ...props }) {
  const dispatch = useDispatch();
  const onLink = (selected) => {
    const note = record.customerRequirements;
    dispatch(
      editOrders({
        id: record.id,
        customerRequirements: `${note.substring(
          0,
          note.indexOf("<b>Link Order</b>:")
        )}<b>Link Order</b>: ${selected
          .map(
            (item) => `<div><a href="/orders/${item}/edit">#${item}</a></div>`
          )
          .join("")}`,
      })
    );
  };
  console.log(record)
  return <SearchByImageButton initImage={record?.thumbnail} onLink={onLink} />;
}
