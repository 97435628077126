import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'antd';
import { xor, cloneDeep } from 'lodash';
// import I18n from 'i18next';
// import Table from '../components/Table';
import { useDispatch, useSelector } from 'react-redux';
import { setPermission } from 'redux/tempPermissions/actions';

const PermissionCheckbox = ({
  roleIndex,
  config,
  data,
  record,
  permissionKey,
  rootPermission,
}) => {
  const dispatch = useDispatch();
  const tempPermissions = useSelector(
    (state) => state.tempPermissions[record.id],
  );
  const confirm = () => {
    const tempRecord = cloneDeep(record);
    if (!tempRecord) return <span />;
    if (tempPermissions && tempPermissions.permission) {
      tempRecord.permission = tempPermissions.permission;
    }
    if (!tempRecord.permission) {
      tempRecord.permission = {
        [rootPermission]: [permissionKey],
      };
      // eslint-disable-next-line
    } else if (tempRecord.permission[rootPermission]) {
      tempRecord.permission[rootPermission] = xor(
        tempRecord.permission[rootPermission],
        [permissionKey],
      );
      // eslint-disable-next-line
    } else {
      tempRecord.permission[rootPermission] = [permissionKey];
    }

    dispatch(
      setPermission(tempRecord.id, {
        id: tempRecord.id,
        permission: tempRecord.permission,
      }),
    );
  };
  const currentPermission = tempPermissions
    ? tempPermissions?.permission?.[rootPermission]?.indexOf(permissionKey) > -1
    : record?.permission?.[rootPermission]?.indexOf(permissionKey) > -1;

  return <Checkbox onChange={confirm} checked={!!currentPermission} />;
};

PermissionCheckbox.propTypes = {
  roleIndex: PropTypes.number,
  config: PropTypes.object,
  permissionKey: PropTypes.any,
  data: PropTypes.array,
  record: PropTypes.object,
};

PermissionCheckbox.defaultProps = {};

export default PermissionCheckbox;
