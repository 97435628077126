import React from 'react';
import RestShow from '../../../containers/rest/Show';
import RestFieldItem from '../../../components/RestField/RestFieldItem';

const ServiceTypesShow = props => (
  <RestShow {...props} hasEdit resource="serviceTypes">
    <RestFieldItem source="name" header="serviceTypes.name" />
    <RestFieldItem source="description" header="serviceTypes.description" />
  </RestShow>
);

export default ServiceTypesShow;
