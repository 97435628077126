import React from 'react';
import RestShow from '../../../containers/rest/Show';
import RestFieldItem from '../../../components/RestField/RestFieldItem';

const OrdersShow = (props) => (
  <RestShow {...props} hasEdit resource="orders">
    <RestFieldItem source="status" header="orders.status" />
    <RestFieldItem source="customerId" header="orders.customerId" />
    <RestFieldItem source="saleId" header="orders.saleId" />
    <RestFieldItem source="channelId" header="orders.channelId" />
    <RestFieldItem source="finishedDate" header="orders.finishedDate" />
    <RestFieldItem source="priceOrder" header="orders.priceOrder" />
    <RestFieldItem source="note" header="orders.note" />
    <RestFieldItem source="backupOrderLink" header="orders.backupOrderLink" />
    <RestFieldItem source="paymentStatus" header="orders.paymentStatus" />
    <RestFieldItem source="designerId" header="orders.designerId" />
    <RestFieldItem source="typeDesigner" header="orders.typeDesigner" />
    <RestFieldItem source="packageId" header="orders.packageId" />
    <RestFieldItem source="numberPackage" header="orders.numberPackage" />
    <RestFieldItem source="packageOrder" header="orders.packageOrder" />
  </RestShow>
);

export default OrdersShow;
