import React from 'react';
import { Input } from 'antd';
import { CHANNEL_TYPES } from 'configs/localData';
import RestInputItem from '../../../../components/RestInput/RestInputItem';
import RestSelect from '../../../../components/RestInput/RestSelect/index';
import ReferenceInput from '../../../../containers/rest/ReferenceInput';

const ChannelsForm = (props) => (
  <div {...props}>
    <RestInputItem source="trelloKey" header="Trello Key" />
    <RestInputItem source="trelloToken" header="Trello Token" />
    <RestInputItem source="name" header="channels.name" />
    <RestInputItem isReference source="brandId">
      <ReferenceInput source="brandId" resource="brands">
        <RestSelect
          source="brandId"
          valueProp="id"
          titleProp="name"
          header="channels.brand"
        />
      </ReferenceInput>
    </RestInputItem>
    <RestInputItem source="phone" header="channels.phone" />
    <RestInputItem source="email" header="channels.email" />
    <RestInputItem source="description" header="channels.description">
      <Input.TextArea />
    </RestInputItem>
    <RestInputItem isReference source="ownerId">
      <ReferenceInput source="ownerId" resource="users">
        <RestSelect
          source="ownerId"
          valueProp="id"
          titleProp="displayName"
          header="channels.owner"
        />
      </ReferenceInput>
    </RestInputItem>
    <RestSelect
      autoComplete="new-password"
      resourceData={CHANNEL_TYPES}
      titleProp="text"
      valueProp="value"
      source="d.type"
      header="channels.type"
      required
    />
    <RestInputItem
      autoComplete="new-password"
      source="d.username"
      header="channels.username"
    />
    <RestInputItem source="d.password" header="channels.password">
      <Input.Password autoComplete="new-password" />
    </RestInputItem>
  </div>
);

ChannelsForm.propTypes = {};

export default ChannelsForm;
