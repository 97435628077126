import React from 'react';
import Create from '../../../containers/rest/Create';
import Form from '../components/Form';

const DealersCreate = props => (
  <Create {...props} resource="dealers">
    <Form />
  </Create>
);

DealersCreate.propTypes = {};

export default DealersCreate;
