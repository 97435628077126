import React, { useLayoutEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { t } from 'i18next';
import { flatMap, map } from 'lodash';
import { withNamespaces } from 'react-i18next';
import Deleted from 'pages/Deleted';
import { usePermissionRoutes } from 'hooks/usePermissionRoutes';
import PrivateLayout from '../../layout/PrivateLayout';
import Payments from '../../pages/Payments';
import UserRequests from '../../pages/UserRequests';
import Logs from '../../pages/Logs';
import MistakeTypes from '../../pages/MistakeTypes';
import MistakeRecords from '../../pages/MistakeRecords';
import Permissions from '../../pages/Permissions';
import SalaryHistories from '../../pages/SalaryHistories';
import SalaryInfos from '../../pages/SalaryInfos';
import Salaries from '../../pages/Salaries';
import WorkingTimes from '../../pages/WorkingRegisters';
import OrderRequirements from '../../pages/OrderRequirements';
import Dealpipelines from '../../pages/Dealpipelines';
import Comments from '../../pages/Comments';
import Roles from '../../pages/Roles';
import Niches from '../../pages/Niches';
import Brands from '../../pages/Brands';
import Products from '../../pages/Products';
import Packages from '../../pages/Packages';
import ServiceTypes from '../../pages/ServiceTypes';
import OrderStatuses from '../../pages/OrderStatuses';
import CustomerSegmentations from '../../pages/CustomerSegmentations';
import productTypes from '../../pages/productTypes';
import Platforms from '../../pages/Platforms';
import Departments from '../../pages/Departments';
import Orders from '../../pages/Orders';
import ListOrdersDeleted from '../../pages/Orders/ListDeleted';
import Channels from '../../pages/Channels';
import Users from '../../pages/Users';
import Home from '../../pages/Dashboard';
import Settings from '../../pages/Settings';
import Customers from '../../pages/Customers';
import { getConfig as getConfigAction } from '../../redux/config/actions';
import { getCurentUser as getCurentUserAction } from '../../redux/auth/actions';
import Profile from '../../pages/Profile';
import ChangePassword from '../../pages/ChangePassword';
import Reports from '../../pages/Reports';

const routes = [

  {
    path: '/payments',
    routes: [
      {
        path: '/',
        component: Payments.List,
      },
      {
        path: '/create',
        component: Payments.Create,
      },
      {
        path: '/:id/edit',
        component: Payments.Edit,
      },
    ],
  },

  {
    path: '/userRequests',
    routes: [
      {
        path: '/',
        component: UserRequests.List,
      },
      {
        path: '/create',
        component: UserRequests.Create,
      },
      {
        path: '/:id/edit',
        component: UserRequests.Edit,
      },
    ],
  },

  {
    path: '/logs',
    routes: [
      {
        path: '/',
        component: Logs.List,
      },
      {
        path: '/create',
        component: Logs.Create,
      },
      {
        path: '/:id/edit',
        component: Logs.Edit,
      },
    ],
  },
  {
    path: '/mistakeTypes',
    routes: [
      {
        path: '/',
        component: MistakeTypes.List,
      },
      {
        path: '/create',
        component: MistakeTypes.Create,
      },
      {
        path: '/:id/edit',
        component: MistakeTypes.Edit,
      },
    ],
  },

  {
    path: '/mistakeRecords',
    routes: [
      {
        path: '/',
        component: MistakeRecords.List,
      },
      {
        path: '/create',
        component: MistakeRecords.Create,
      },
      {
        path: '/:id/edit',
        component: MistakeRecords.Edit,
      },
    ],
  },
  {
    path: '/permissions',
    routes: [
      {
        path: '/',
        component: Permissions.List,
      },
      {
        path: '/create',
        component: Permissions.Create,
      },
      {
        path: '/:id/edit',
        component: Permissions.Edit,
      },
    ],
  },
  {
    path: '/salaryHistories',
    routes: [
      {
        path: '/',
        component: SalaryHistories.List,
      },
      {
        path: '/create',
        component: SalaryHistories.Create,
      },
      {
        path: '/:id/edit',
        component: SalaryHistories.Edit,
      },
    ],
  },

  {
    path: '/salaryInfos',
    routes: [
      {
        path: '/',
        component: SalaryInfos.List,
      },
      {
        path: '/create',
        component: SalaryInfos.Create,
      },
      {
        path: '/:id/edit',
        component: SalaryInfos.Edit,
      },
    ],
  },
  {
    path: '/salaryHistories/:model',
    routes: [
      {
        path: '/',
        component: Salaries.List,
      },
      {
        path: '/create',
        component: Salaries.Create,
      },
      {
        path: '/:id/edit',
        component: Salaries.Edit,
      },
    ],
  },
  {
    path: '/workingRegisters',
    routes: [
      {
        path: '/',
        component: WorkingTimes.List,
      },
      {
        path: '/me',
        component: WorkingTimes.MySchedule,
      },
      // {
      //   path: '/create',
      //   component: WorkingTimes.Create,
      // },
      {
        path: '/:userId',
        component: WorkingTimes.Create,
      },
    ],
  },
  {
    path: '/working',
    routes: [
      {
        path: '/',
        component: OrderRequirements.List,
      },
      {
        path: '/create',
        component: OrderRequirements.Create,
      },
      {
        path: '/:id/edit',
        component: OrderRequirements.Edit,
      },
    ],
  },
  {
    path: '/orderRequirements',
    routes: [
      {
        path: '/',
        component: OrderRequirements.List,
      },
      {
        path: '/create',
        component: OrderRequirements.Create,
      },
      {
        path: '/:id/edit',
        component: OrderRequirements.Edit,
      },
    ],
  },
  {
    path: '/dealpipelines',
    routes: [
      {
        path: '/',
        component: Dealpipelines.List,
      },
      {
        path: '/create',
        component: Dealpipelines.Create,
      },
      {
        path: '/:id/edit',
        component: Dealpipelines.Edit,
      },
    ],
  },
  {
    path: '/comments',
    routes: [
      {
        path: '/',
        component: Comments.List,
      },
      {
        path: '/create',
        component: Comments.Create,
      },
      {
        path: '/:id/edit',
        component: Comments.Edit,
      },
    ],
  },
  {
    path: '/roles',
    routes: [
      {
        path: '/',
        component: Roles.List,
      },
      {
        path: '/create',
        component: Roles.Create,
      },
      {
        path: '/:id/edit',
        component: Roles.Edit,
      },
    ],
  },
  {
    path: '/niches',
    unShowOutsource: true,
    routes: [
      {
        path: '/',
        component: Niches.List,
      },
      {
        path: '/create',
        component: Niches.Create,
      },
      {
        path: '/:id/edit',
        component: Niches.Edit,
      },
    ],
  },

  {
    path: '/brands',
    unShowOutsource: true,
    routes: [
      {
        path: '/',
        component: Brands.List,
      },
      {
        path: '/create',
        component: Brands.Create,
      },
      {
        path: '/:id/edit',
        component: Brands.Edit,
      },
    ],
  },

  {
    path: '/products',
    unShowOutsource: true,
    routes: [
      {
        path: '/',
        component: Products.List,
      },
      {
        path: '/create',
        component: Products.Create,
      },
      {
        path: '/:id/edit',
        component: Products.Edit,
      },
    ],
  },

  {
    path: '/packages',
    unShowOutsource: true,
    routes: [
      {
        path: '/',
        component: Packages.List,
      },
      {
        path: '/create',
        component: Packages.Create,
      },
      {
        path: '/:id/edit',
        component: Packages.Edit,
      },
      {
        path: '/:id/show',
        component: Packages.Show,
      },
    ],
  },

  {
    path: '/serviceTypes',
    routes: [
      {
        path: '/',
        component: ServiceTypes.List,
      },
      {
        path: '/create',
        component: ServiceTypes.Create,
      },
      {
        path: '/:id/edit',
        component: ServiceTypes.Edit,
      },
    ],
  },

  {
    path: '/orderStatuses',
    routes: [
      {
        path: '/',
        component: OrderStatuses.List,
      },
      {
        path: '/create',
        component: OrderStatuses.Create,
      },
      {
        path: '/:id/edit',
        component: OrderStatuses.Edit,
      },
    ],
  },
  {
    path: '/customerSegmentations',
    routes: [
      {
        path: '/',
        component: CustomerSegmentations.List,
      },
      {
        path: '/create',
        component: CustomerSegmentations.Create,
      },
      {
        path: '/:id/edit',
        component: CustomerSegmentations.Edit,
      },
    ],
  },

  {
    path: '/productTypes',
    routes: [
      {
        path: '/',
        component: productTypes.List,
      },
      {
        path: '/create',
        component: productTypes.Create,
      },
      {
        path: '/:id/edit',
        component: productTypes.Edit,
      },
    ],
  },

  {
    path: '/platforms',
    routes: [
      {
        path: '/',
        component: Platforms.List,
      },
      {
        path: '/create',
        component: Platforms.Create,
      },
      {
        path: '/:id/edit',
        component: Platforms.Edit,
      },
    ],
  },

  {
    path: '/departments',
    routes: [
      {
        path: '/',
        component: Departments.List,
      },
      {
        path: '/create',
        component: Departments.Create,
      },
      {
        path: '/:id/edit',
        component: Departments.Edit,
      },
    ],
  },

  {
    path: '/orders',
    routes: [
      {
        path: '/',
        component: Orders.List,
      },
      {
        path: '/create',
        component: Orders.Create,
      },
      {
        path: '/:id/edit',
        component: Orders.Edit,
      },
      {
        path: '/deleted',
        component: ListOrdersDeleted,
      },
    ],
  },
  {
    path: '/channels',
    routes: [
      {
        path: '/',
        component: Channels.List,
      },
      {
        path: '/create',
        component: Channels.Create,
      },
      {
        path: '/:id/edit',
        component: Channels.Edit,
      },
    ],
  },
  {
    path: '/customers',
    routes: [
      {
        path: '/',
        component: Customers.List,
      },
      {
        path: '/create',
        component: Customers.Create,
      },
      {
        path: '/:id/edit',
        component: Customers.Edit,
      },
    ],
  },

  {
    path: '/users',
    routes: [
      {
        path: '/',
        component: Users.List,
      },
      {
        path: '/create',
        component: Users.Create,
      },
      {
        path: '/:id/edit',
        component: Users.Edit,
      },
    ],
  },
  {
    path: '/profile',
    component: Profile,
    exact: true,
  },
  {
    path: '/change-password',
    component: ChangePassword,
    exact: true,
  },
  {
    path: '/',
    component: Home,
    exact: true,
    title: t('dashboard.title'),
  },
  {
    path: '/settings/:modal',
    component: Settings,
    exact: true,
    title: t('settings.title'),
  },
  {
    path: '/deleted/:modal',
    component: Deleted,
    exact: true,
    title: t('deleted.title'),
  },
  {
    path: '/reports',
    component: Reports,
    exact: true,
    title: t('reports.title'),
  },
];

const wrappedRoutes = map(
  flatMap(routes, (route) => {
    if (route.routes) {
      return map(route.routes, (subRoute) => ({
        ...subRoute,
        path: route.path + subRoute.path,
        exact: subRoute.path === '/',
        // hasPrivateLayoutWrapper: route.hasPrivateLayoutWrapper,
        hasPrivateLayoutWrapper: true,
        component: withNamespaces()(subRoute.component || route.component),
      }));
    }
    return route;
  }),
  (route) => <PrivateRoute {...route} key={route.path} />,
);

function PrivateRoute({
  component: Component,
  title,
  hasPrivateLayoutWrapper,
  ...rest
}) {
  const isAccess = usePermissionRoutes(rest.path);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  return isAccess ? (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <PrivateLayout
            title={title}
            hasPrivateLayoutWrapper={hasPrivateLayoutWrapper}
          >
            <Component {...props} />
          </PrivateLayout>
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  ) : null;
}

const PrivateRoutes = ({ getCurentUser, isAuthenticated, getConfig }) => {
  useLayoutEffect(() => {
    isAuthenticated && getCurentUser();
    getConfig();
    // eslint-disable-next-line
  }, []);
  return wrappedRoutes;
};

// export default PrivateRoutes;

// const PrivateRoutes = ({ getCurentUser, isAuthenticated, getConfig, user }) => {
//   useLayoutEffect(() => {
//     isAuthenticated && getCurentUser();
//     getConfig();
//   }, []);

//   return (
//     <PrivateLayout>
//       <Switch>
//         {map(
//           flatMap(routes, route => {
//             if (route.routes) {
//               return map(route.routes, subRoute => ({
//                 ...subRoute,
//                 unShowOutsource: route.unShowOutsource,
//                 path: route.path + subRoute.path,
//                 exact: subRoute.path === '/',
//                 hasPrivateLayoutWrapper: true,
//               }));
//             }
//             return route;
//           }),
//           route =>
//             route.unShowOutsource && user.isOutsource ? null : (
//               <Route {...route} component={route.component} key={route.path} />
//             ),
//         )}
//         <Redirect to="/" />
//       </Switch>
//     </PrivateLayout>
//   );
// };
// PrivateRoutes.propTypes = {
//   getCurentUser: PropTypes.func,
//   isAuthenticated: PropTypes.bool,
//   getConfig: PropTypes.func,
// };

export default connect(
  (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
  }),
  (dispatch) => ({
    getConfig: () => dispatch(getConfigAction()),
    getCurentUser: () => dispatch(getCurentUserAction()),
  }),
)(PrivateRoutes);
