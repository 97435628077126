import React from 'react';
// import PropTypes from 'prop-types';
import { Card, Typography, Row, Col, Divider } from 'antd';
import { useSelector } from 'react-redux';
import i18next from 'i18next';
import crudSelectors from '../../../../redux/crudSelectors';
import PreviewImage from '../../../../components/common/PreviewImage';
import { PreviewTextEditor } from '../../../../components/RestInput/RestTextEditor';

const PlatformTemplate = props => {
  const record = useSelector(crudSelectors.orders.getCurrentData);
  const referencePlatforms = useSelector(state => state.reference.platforms);
  const platforms = useSelector(state => state.platforms.data);
  const productTypes = useSelector(state => state.productTypes.data);
  if (!record.platform) return null;
  const currentPlatform =
    referencePlatforms && referencePlatforms.data
      ? referencePlatforms.data[record.platform] || platforms[record.platform]
      : platforms[record.platform];
  return (
    <Card>
      <Divider>{`Platform - ${currentPlatform?.name}`}</Divider>
      {currentPlatform?.productDemoFile && (
        <>
          <PreviewImage image={currentPlatform?.productDemoFile || {}} />
          <br />
        </>
      )}
      {currentPlatform?.description && (
        <PreviewTextEditor html={currentPlatform?.description} />
      )}
      {record.productCatalogIds.map(key => {
        const productCatalog = productTypes[key];
        return productCatalog ? (
          <>
            <Divider>{`Catalog - ${productCatalog?.name}`}</Divider>
            <Row>
              {productCatalog?.mockupFile && (
                <Col span={12}>
                  <br />
                  <Typography.Title level={3}>
                    {i18next.t('productTypes.mockupFile')}
                  </Typography.Title>
                  <PreviewImage image={productCatalog.mockupFile || {}} />
                </Col>
              )}
              {productCatalog.templateFile && (
                <Col span={12}>
                  <br />
                  <Typography.Title level={3}>
                    {i18next.t('productTypes.templateFile')}
                  </Typography.Title>
                  <PreviewImage image={productCatalog.templateFile || {}} />
                </Col>
              )}
            </Row>
            <PreviewTextEditor html={productCatalog.description} />
          </>
        ) : null;
      })}
    </Card>
  );
};
PlatformTemplate.propTypes = {};

export default PlatformTemplate;
