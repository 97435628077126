import { makeReducerCreator } from '../../utils/reduxUtils';
import { makeCRUDReducerCreator, INITIAL_CRUD_STATE } from '../crudCreator/reducer';
import { MODEL, IGNORE_ACTIONS, CommentsTypes, PRIMARY_KEY } from './actions';

export const INITIAL_STATE = {
  ...INITIAL_CRUD_STATE,
  customerComment: {},
};

const getCommentByOrder = (state, { orderId }) => ({
  ...state,
  customerComment: orderId === state.customerComment.orderId ? state.customerComment : {},
});

const getCommentByOrderSuccess = (state, { data }) => ({
  ...state,
  customerComment: data,
});

const editCommentsSuccess = (state, { data }) => ({
  ...state,
  data: {
    ...state.data,
    [data[PRIMARY_KEY]]: { ...state.data[data[PRIMARY_KEY]], ...data },
  },
  itemLoadings: { ...state.itemLoadings, [data[PRIMARY_KEY]]: false },
  currentData: data,
  error: null,
  customerComment: data.id === state.customerComment.id ? data : state.customerComment,
});

const deleteCommentsSuccess = (state, { data }) => ({
  ...state,
  data: data[PRIMARY_KEY] ? { ...state.data, [data[PRIMARY_KEY]]: null } : {},
  itemLoadings: data[PRIMARY_KEY] ? { ...state.data, [data[PRIMARY_KEY]]: null } : {},
  ids: state.ids.filter(id => id !== data[PRIMARY_KEY]),
  error: null,
  currentId: null,
  total: state.total - 1,
  customerComment: data.id === state.customerComment.id ? {} : state.customerComment,
});

const reducer = makeReducerCreator(INITIAL_STATE, {
  ...makeCRUDReducerCreator(MODEL, IGNORE_ACTIONS),
  [CommentsTypes.GET_COMMENT_BY_ORDER]: getCommentByOrder,
  [CommentsTypes.GET_COMMENT_BY_ORDER_SUCCESS]: getCommentByOrderSuccess,
  [CommentsTypes.EDIT_COMMENTS_SUCCESS]: editCommentsSuccess,
  [CommentsTypes.DELETE_COMMENTS_SUCCESS]: deleteCommentsSuccess,
});

export default reducer;
