import React from 'react';
import RestShow from '../../rest/Show';
import RestFieldItem from '../../../components/RestField/RestFieldItem';

const SalaryInfosShow = props => (
  <RestShow {...props} hasEdit resource="salaryInfos">
    <RestFieldItem source="bonus" header="salaryInfos.bonus" />
    <RestFieldItem source="kpis" header="salaryInfos.kpis" />
    <RestFieldItem source="baseSalary" header="salaryInfos.baseSalary" />
    <RestFieldItem source="dateMonth" header="salaryInfos.dateMonth" />
  </RestShow>
);

export default SalaryInfosShow;
