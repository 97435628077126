import { makeReducerCreator } from '../../utils/reduxUtils';
import { TempPermissionTypes } from './actions';

export const INITIAL_STATE = {};

const setTempPermission = (state, { id, data }) => ({
  ...state,
  [id]: data,
});

const reducer = makeReducerCreator(INITIAL_STATE, {
  [TempPermissionTypes.SET_TEMP_PERMISSION]: setTempPermission,
});

export default reducer;
