import React from 'react';
import { Row, Col, Rate } from 'antd';
// import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Text from 'components/common/Text';
import i18next from 'i18next';
import SummaryCard from '../../../../components/common/SummaryCard';

const Summaries = ({ isProfile }) => {
  const currentUser = useSelector((state) => state.users.currentData);
  const user = useSelector((state) => state.auth.data);
  return (
    <Row gutter={16}>
      <Col md={24}>
        <SummaryCard
          value={currentUser.displayName}
          header={
            <div>
              <Rate value={currentUser.rate} />
              {`(${currentUser.totalRate || 0})`}
            </div>
          }
          color="#03a9f4"
          icon="user"
        >
          {(isProfile ||
            user.permission?.salaryInfos?.indexOf('update') > -1) && (
            <>
              <div>
                <Text type="h1SemiBold" className="value">
                  {currentUser.salaryScheme || i18next.t('waitingUpdate')}
                </Text>
                <Text type="h5" className="title">
                  {i18next.t('salaryHistories.salaryScheme')}
                </Text>
              </div>
              <div>
                <Text type="h1SemiBold" className="value">
                  {currentUser.coef || 0}
                </Text>
                <Text type="h5" className="title">
                  {i18next.t('salaryHistories.coef')}
                </Text>
              </div>
              <div>
                <Text type="h1SemiBold" className="value">
                  {currentUser.coefOT || 0}
                </Text>
                <Text type="h5" className="title">
                  {i18next.t('salaryHistories.coefOT')}
                </Text>
              </div>
            </>
          )}
        </SummaryCard>
      </Col>
      {/* <Col md={8}>
        <SummaryCard value={50} header="Happy" color="#03a9f4" icon="heart" />
      </Col> */}
      {/* <Col md={12}>
        <SummaryCard value={80} header="Order" color="#2ec0d5" icon="reconciliation" />
      </Col> */}
    </Row>
  );
};
Summaries.propTypes = {};

export default Summaries;
