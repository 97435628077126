import React from 'react';
import Create from '../../../containers/rest/Create';
import Form from '../components/Form';

const GuaranteesCreate = props => (
  <Create {...props} resource="guarantees">
    <Form />
  </Create>
);

GuaranteesCreate.propTypes = {};

export default GuaranteesCreate;
