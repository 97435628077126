import { call, put, cancel, fork, takeEvery } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import _ from 'lodash';
import {
  REST_ACTION_TYPES,
  retrieveReferenceSuccess,
  retrieveReferenceFailed,
} from './actions';
import { apiWrapper } from '../../utils/reduxUtils';
import { getAllApi } from '../../api/crud';
import { convertResponseData } from '../crudCreator/dataProvider';

const debouncedIds = {};
const mappeds = {};
const tasks = {};
const filters = {};
const addIds = (resource, ids) => {
  if (!debouncedIds[resource]) {
    debouncedIds[resource] = [];
  }
  debouncedIds[resource] = _.flatten(_.union(debouncedIds[resource], ids));
};

const addMappedBy = (resource, mappedBy) => {
  mappeds[resource] = mappedBy;
};

const addFilter = (resource, filter) => {
  if (!filters[resource]) {
    filters[resource] = {};
  }
  filters[resource] = { ...filters[resource], ...filter };
};

function* finalize(resource) {
  // combined with cancel(), this debounces the calls
  yield call(delay, 50);
  yield fork(retrieveReferenceList, resource);
  delete tasks[resource];
  delete debouncedIds[resource];
  delete mappeds[resource];
}

export function* retrieveReference({
  resource,
  ids,
  mappedBy,
  initialFilter = {},
}) {
  if (tasks[resource]) {
    yield cancel(tasks[resource]);
  }
  addIds(resource, ids);
  addMappedBy(resource, mappedBy);
  addFilter(resource, initialFilter);
  tasks[resource] = yield fork(finalize, resource);
}

export function* retrieveReferenceList(resource) {
  try {
    const mappedby = mappeds[resource];
    const params = {
      limit: 100,
      offset: 0,
      ...filters[resource],
      filter: JSON.stringify(
        _.merge({}, filters[resource].filter || {}, {
          [mappedby || 'id']: {
            $in: debouncedIds[resource],
          },
        }),
      ),
    };
    const response = yield call(
      apiWrapper,
      { isShowProgress: false },
      getAllApi,
      resource,
      params,
    );
    const result = convertResponseData('GET_ALL', response, {
      primaryKey: mappedby || 'id',
    });
    yield put(retrieveReferenceSuccess(resource, result));
  } catch (error) {
    yield put(retrieveReferenceFailed(resource, error));
  }
}

export default [
  takeEvery(REST_ACTION_TYPES.RETRIEVE_REFERENCE, retrieveReference),
];
