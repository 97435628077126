/* eslint-disable */
import React from 'react';
import { notification } from 'antd';
import { omit, reduce, isEmpty, keyBy, get, groupBy, pick } from 'lodash';
import moment from 'moment';
import CryptoJS from 'crypto-js';
import { getUrl, uploadFile, uploadMedia } from '../api/uploadMedia';
import I18n from 'i18next';

export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export const sortByProps = (list, props) => {
  if (!list) return [];
  const newList = list.sort((a, b) => {
    if (!a || !b) return -1;
    if (this.change_alias(a[props]) < this.change_alias(b[props])) {
      return -1;
    }
    if (this.change_alias(a[props]) > this.change_alias(b[props])) {
      return 1;
    }

    // names must be equal
    return 0;
  });
  return newList;
};

export const upperCaseFirstChart = (str) =>
  str[0].toUpperCase() + str.substring(1);

export const changeAlias = (alias) => {
  let str = alias;
  str = str.toLowerCase();
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ {2}|ặ|ẳ|ẵ/g, 'a');
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ {2}|ợ|ở|ỡ/g, 'o');
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
  str = str.replace(/đ/g, 'd');
  str = str.replace(
    /!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'| |\"|\&|\#|\[|\]|~|$|_/g,
    '-',
  );
  str = str.replace(/-+-/g, '-');
  str = str.replace(/^\-+|\-+$/g, '');
  return str;
};

export const validateName = (name) => {
  const re = /^[^0-9 *&^$#@!(){}\[\]\\//]+[^0-9*&^$#@!(){}\[\]\\//]+$/;
  return re.test(name);
};

export const getResourceTitle = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const formatFormData = (originalData, data) => {
  const newData = {};
  Object.keys(data).forEach((key) => {
    newData[key] = formatData(data[key], typeof originalData[key]);
  });
  return newData;
};

export const formatData = (data, type) => {
  switch (type) {
    case 'number':
      return Number(data);
    default:
      return data;
  }
};

export const getMatchFromPath = (string) => {
  const re = '(\\/)((?:[a-z][a-z0-9_]*))(\\/)((?:[a-z][a-z0-9_]*))';
  const p = new RegExp(re, ['i']);
  const m = p.exec(string);
  return m && m.length > 0 ? m[0] : string;
};

export const getSearch = (filter) => {
  const params = {
    limit: filter.limit,
    page: filter.page,
    q: filter.q,
    orderBy: filter.orderBy,
    ...getValidData(filter.filter),
  };

  return convertObjToSearchStr(params);
};

export const convertObjToSearchStr = (params) =>
  Object.keys(params)
    .map((key) =>
      params[key]
        ? `${encodeURIComponent(key)}=${encodeURIComponent(
            JSON.stringify(params[key]),
          )}`
        : '',
    )
    .filter((data) => data !== '')
    .join('&');

const getValidDataOfObj = (obj, isFilter) => {
  const validData = reduce(
    obj,
    (result, value, key) => {
      if (Array.isArray(value)) {
        return value.length > 0 ? { ...result, [key]: value } : result;
      }
      if (typeof value === 'object' && !isEmpty(value)) {
        const formatChildValue = getValidDataOfObj(value);
        return !isEmpty(formatChildValue)
          ? { ...result, [key]: formatChildValue }
          : result;
      }

      if (value || value === false || value === 0) {
        // eslint-disable-next-line
        result[key] = value;
        return { ...result, [key]: value };
      }

      if (value === '' && !isFilter) {
        // eslint-disable-next-line
        result[key] = ' ';
      }
      return result;
    },
    {},
  );
  return validData;
};

export const getValidData = (filter, isFilter) =>
  getValidDataOfObj(filter, isFilter);

export const getFilterFromUrl = (searchStr, ignoreFilters = []) => {
  const searchParams = new URLSearchParams(searchStr);
  console.log('entries', searchParams.entries());
  const parsed = {};
  if (!searchStr || searchStr.trim() === '') return {};
  decodeURIComponent(searchStr)
    .trim()
    .substring(1)
    .split('&')
    .forEach((text) => {
      const keyValue = text.split('=');
      if (ignoreFilters.indexOf(keyValue[0]) > -1) return;
      parsed[keyValue[0]] = keyValue[1];
      try {
        parsed[keyValue[0]] = JSON.parse(parsed[keyValue[0]]);
      } catch (error) {
        parsed[keyValue[0]] = parsed[keyValue[0]];
      }
    });
  const filter = {
    q: parsed.q,
    orderBy: parsed.orderBy,
    limit: parsed.limit,
    page: parsed.page,
  };
  delete parsed.limit;
  delete parsed.page;
  delete parsed.orderBy;
  delete parsed.q;
  filter.filter = parsed;
  return filter;
};

export const getRecordData = (record, source) => {
  const arrKeys = source
    ? replaceAll(replaceAll(source, '\\[', '.'), '\\]', '').split('.')
    : [];
  let data = record;
  arrKeys.forEach((key) => {
    data = data ? data[key] : data;
  });
  return data;
};

export const convertDataToObj = (formatOnSubmit, record) => {
  const newRecord = {};
  Object.keys(record).forEach((key) => {
    newRecord[key] = formatOnSubmit[key]
      ? { ...record[key], ...formatOnSubmit[key](record[key]) }
      : record[key];
  });
  // const arrKeys = source.split('.');
  // let data = record;
  // arrKeys.forEach((key, index) => {
  //   if (index === arrKeys.index - 1) {
  //     data[key] = value;
  //   } else {
  //     data = data[key];
  //   }
  // });
  return newRecord;
};

export const replaceAll = function (str, search, replacement) {
  return str.replace(new RegExp(search, 'g'), replacement);
};

export const formattedRESTData = (data) => ({
  data: keyBy(data),
  ids: data.map((item) => item.id),
});

export const getIdByUrl = (props) => {
  const idFromPath =
    props.location.pathname.match(`${props.resource}/(.*)/edit`) ||
    props.location.pathname.match(`${props.resource}/(.*)/show`);
  const idFromHash =
    props.location.hash.match(`#${props.resource}/(.*)/edit`) ||
    props.location.hash.match(`#${props.resource}/(.*)`);
  return (idFromPath && idFromPath[1]) || (idFromHash && idFromHash[1]);
};

export const getPrefixPath = (props, action) =>
  `${
    props.redirects[action] === 'modal'
      ? `${props.location.pathname}${props.location.search}#`
      : props.rootPath
  }/${props.resource}`;

export const onSearch = (data, keySearch) =>
  data &&
  keySearch &&
  data.toLowerCase().search(keySearch.toLowerCase()) !== -1;

export const formattedData = (list) => ({
  data: keyBy(list, 'id'),
  ids: list.map((data) => data.id),
});

export const makeBreadCrumbFromPath = (location) => {
  const BREADCRUMB_LIST = [];
  const paths = location.pathname.split('/');
  paths.forEach((data) => {
    if (data === '') return;
    BREADCRUMB_LIST.push({
      title: data,
      path: `${
        BREADCRUMB_LIST.length
          ? BREADCRUMB_LIST[BREADCRUMB_LIST.length - 1].path
          : ''
      }/${data}`,
    });
  });
  return BREADCRUMB_LIST;
};

export const reorderOffset = (
  boards,
  prevOrder,
  { sourceId, destinationId, sourceIndex, destinationIndex },
) => {
  const newBoards = { ...boards };
  if (sourceId === destinationId) {
    newBoards[sourceId] = reorder(
      boards[sourceId],
      sourceIndex,
      destinationIndex,
    );
  } else {
    const moveResults = move(
      boards,
      sourceId,
      destinationId,
      sourceIndex,
      destinationIndex,
    );
    newBoards[sourceId] = moveResults[sourceId];
    newBoards[destinationId] = moveResults[destinationId];
  }
  return { boards: newBoards, prevOrder: {} };
};

const reorder = (list, startIndex, endIndex) => {
  const result = [...list];
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const move = (
  boards,
  sourceId,
  destinationId,
  sourceIndex,
  destinationIndex,
) => {
  const sourceClone = Array.from(boards[sourceId]);
  const destClone = Array.from(boards[destinationId]);
  const [removed] = sourceClone.splice(sourceIndex, 1);

  destClone.splice(destinationIndex, 0, removed);

  const result = {};
  result[sourceId] = sourceClone;
  result[destinationId] = destClone;

  return result;
};

export const parserPrice = (price) => {
  const value = price ? price.replace('VND', '').trim().replace(',', '') : '0';
  return !value ? '0' : value;
};

export const formatDuration = (start, end) => {
  const duration = moment(end).diff(moment(start), 'm');

  return (duration / 60).toFixed(0) + 'h ' + (duration % 60) + 'm';
};

export const getCloneOrderData = (obj) => ({
  ...pick(obj, [
    'priceOrder',
    'customerRequirements',
    'customerNote',
    'infoOrderLink',
    // 'niche',
    // 'platform',
    'orderFeature',
    'productCatalogIds',
    'deadline',
    'orderRequirementId',
    'name',
    'thumbnail',
    'customerId',
    'saleId',
    'dealerId',
    'channelId',
  ]),
  deadline: obj?.deadline || undefined,
  saleId: obj.saleId || undefined,
  dealerId: obj.dealerId || undefined,
  channelId: obj.channelId || undefined,
  customerNote: obj.customerNote || ' ',
  paymentStatus: 'CTT',
  status: 'new_deal',
});

export const getImageUrl = (image) =>
  image && image.indexOf('http') > -1
    ? image
    : process.env.REACT_APP_PHOTO_HOST + '/' + image;

export const formatInputMoney = {
  formatter: (value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
  parser: (value) => value.replace(/\\s?|(,*)/g, ''),
};

export const imageUploadHandler = async (blobInfo, success, failure) => {
  var xhr, formData;

  xhr = new XMLHttpRequest();
  xhr.withCredentials = false;
  // xhr.open('POST', 'postAcceptor.php');

  formData = new FormData();
  formData.append('file', blobInfo.blob(), blobInfo.filename());

  const response = await uploadMedia(blobInfo.blob());
  if (!response || response?.error) {
    notification.error({
      message: I18n.t('error.title'),
      description: I18n.t('error.uploadSize'),
    });
    failure();
  } else {
    success(response);
  }
  // xhr.onload = function() {
  //   var json;

  //   if (xhr.status != 200) {
  //     failure('HTTP Error: ' + xhr.status);
  //     return;
  //   }

  //   json = JSON.parse(xhr.responseText);

  //   if (!json || typeof json.location != 'string') {
  //     failure('Invalid JSON: ' + xhr.responseText);
  //     return;
  //   }

  // };

  // formData = new FormData();
  // formData.append('file', blobInfo.blob(), blobInfo.filename());

  // xhr.send(formData);
};

const imageHandler = (ref) => () => {
  const input = document.createElement('input');
  input.setAttribute('type', 'file');
  input.setAttribute('accept', 'image/*');
  input.click();
  input.onchange = async function () {
    if (ref.current && ref.current.editor) {
      const file = input.files[0];
      const { editor } = ref.current;
      let range = editor.getSelection();
      let index = null;

      if (range) {
        index = range.index;
        editor.insertText(index, '\n');
        editor.insertEmbed(
          index,
          'image',
          'https://media.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.gif',
        );
      }
      const res = await getUrl(file.name, file.type);
      const response = await uploadFile(res.uploadUrl, file);
      if (!res.url) {
        notification.error({
          message: I18n.t('error.title'),
          description: I18n.t('error.uploadSize'),
        });
      }
      const { url: link } = res;
      // this part the image is inserted
      // by 'image' option below, you just have to put src(link) of img here.
      range && index !== null && editor.deleteText(index, 1);
      console.log(link);
      editor.insertText(index, '\n');
      range &&
        editor.pasteHTML(
          index,
          `<a href='${link}' target='_blank'><img src='${link}' /></a>`,
        );
      range && editor.pasteHTML(editor.getLength(), '<br />');
    }
  }.bind(this); // react thing
};

export const modules = (ref) => ({
  toolbar: {
    container: [
      [{ header: '1' }, { header: '2' }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      ['link', 'image'],
      ['clean'],
    ],
    handlers: {
      image: imageHandler(ref),
    },
  },
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
});

export const randomColor = (arr) => {
  const groupValue = groupBy(arr, 'value');
  Object.keys(groupValue).forEach((key) => {
    groupValue[key] = getRandomColor();
  });
  return arr.map((e, index) => ({
    ...e,
    color: groupValue[e.value],
  }));
};
function getRandomColor() {
  var letters = '0123456789ABCDEF'.split('');
  var color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export const formatChannelData = (values) => {
  return {
    ...omit(values, ['d']),
    account: CryptoJS.AES.encrypt(
      JSON.stringify(values.d || {}),
      process.env.REACT_APP_ENCRYPT_KEY,
    ).toString(),
  };
};

export const flat = (obj, concatenator = '.') =>
  Object.keys(obj).reduce((acc, key) => {
    if (typeof obj[key] !== 'object') {
      return {
        ...acc,
        [key]: obj[key],
      };
    }

    const flattenedChild = flat(obj[key], concatenator);

    return {
      ...acc,
      ...Object.keys(flattenedChild).reduce(
        (childAcc, childKey) => ({
          ...childAcc,
          [`${key}${concatenator}${childKey}`]: flattenedChild[childKey],
        }),
        {},
      ),
    };
  }, {});

export const getTotal = (data = {}, key, selectedRowKeys) => {
  let total = 0;
  values(data).forEach((e) => {
    if (!selectedRowKeys || !!selectedRowKeys?.includes(e?.id)) {
      total += e?.[key] || 0;
    }
  });
  return total;
};
