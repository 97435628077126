import React from 'react';
import RestFormDateTimePicker from 'components/RestInput/RestDateTimePicker';
import { InputNumber, Input, Tabs, Collapse } from 'antd';
import i18next from 'i18next';
import { formatInputMoney } from 'utils/tools';
import RestInputItem from '../../../../components/RestInput/RestInputItem';
import { BONUS_LEVELS, BONUS_SUB_LEVELS } from '../../../../configs/localData';

const { Panel } = Collapse;

const SalaryInfosForm = (props) => (
  <div {...props}>
    <Tabs>
      <Tabs.TabPane tab={i18next.t('salaryInfos.general')} key="1">
        <RestFormDateTimePicker
          pickerType="month"
          source="dateMonth"
          ruleType="object"
          header="salaryInfos.dateMonth"
        />
        <RestFormDateTimePicker
          source="startDate"
          showTime={false}
          formatDate="DD/MM/YYYY"
          header="salaryInfos.startDate"
        />
        <RestFormDateTimePicker
          showTime={false}
          source="endDate"
          formatDate="DD/MM/YYYY"
          header="salaryInfos.endDate"
        />
        <RestInputItem
          ruleType="number"
          source="baseSalary"
          header="salaryInfos.baseSalary"
        >
          <InputNumber {...formatInputMoney} />
        </RestInputItem>
        <RestInputItem source="note" header="salaryInfos.note">
          <Input.TextArea />
        </RestInputItem>
      </Tabs.TabPane>
      <Tabs.TabPane forceRender tab={i18next.t('salaryInfos.bonusKPI')} key="2">
        <Collapse defaultActiveKey={BONUS_LEVELS}>
          {BONUS_LEVELS.map((e) => (
            <Panel
              header={i18next.t('salaryInfos.salaryLevel', { level: e })}
              key={e}
            >
              <RestInputItem
                source={`bonus.${e}`}
                header={i18next.t('salaryInfos.bonusAmount', {
                  level: e,
                })}
                ruleType="number"
              >
                <InputNumber {...formatInputMoney} />
              </RestInputItem>
              {BONUS_SUB_LEVELS.map((subLv) => (
                <RestInputItem
                  ruleType="number"
                  source={`kpis.${e}.${subLv}`}
                  header={i18next.t('salaryInfos.bonusKPIAmount', {
                    level: subLv,
                    mainLevel: e,
                  })}
                  defaultValue={0}
                >
                  <InputNumber {...formatInputMoney} />
                </RestInputItem>
              ))}
            </Panel>
          ))}
        </Collapse>
      </Tabs.TabPane>
    </Tabs>
  </div>
);

SalaryInfosForm.propTypes = {};

export default SalaryInfosForm;
