import React from 'react';
import i18next from 'i18next';
import RestInputItem from '../../../../components/RestInput/RestInputItem';
import RestTextEditor from '../../../../components/RestInput/RestTextEditor';
import ReferenceInput from '../../../../containers/rest/ReferenceInput';
import RestSelect from '../../../../components/RestInput/RestSelect';
import RestAvatarInput from '../../../../components/RestInput/RestAvatarInput';

const PlatformsForm = (props) => (
  <div {...props}>
    <RestInputItem source="name" header="platforms.name" />
    <RestInputItem isReference>
      <ReferenceInput
        searchKey="name"
        source="productCatalogId"
        resource="productTypes"
      >
        <RestSelect
          valueProp="id"
          titleProp="name"
          source="productCatalogId"
          header="platforms.productCatalogId"
        />
      </ReferenceInput>
    </RestInputItem>
    <RestTextEditor
      isCreate
      source="description"
      header="platforms.description"
    />
    <RestAvatarInput
      style={{ marginBottom: 30, width: 250, height: 150, borderRadius: 0 }}
      defaultIcon="picture"
      defaultText="picture"
      hasCrop={false}
      source="productDemoFile"
      type="platform"
      label={i18next.t('platforms.productDemoFile')}
    />
  </div>
);

PlatformsForm.propTypes = {};

export default PlatformsForm;
