import {
  makeCRUDConstantCreator,
  makeCRUDActionsCreator,
} from '../crudCreator/actions';

export const PRIMARY_KEY = 'id';
export const MODEL = 'payments';
export const IGNORE_ACTIONS = [];
export const PaymentsTypes = {
  ...makeCRUDConstantCreator(MODEL, IGNORE_ACTIONS),
};
export const CRUDPaymentsActions = makeCRUDActionsCreator(
  MODEL,
  IGNORE_ACTIONS,
);
/**
 * getAllCaseTypes({pageSize, page })
 * getByIdCaseTypes(data)
 * createCaseTypes(data)
 * deleteCaseTypes()
 * editCaseTypes(data)
 */
export default { ...CRUDPaymentsActions };
