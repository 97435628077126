import React from 'react';
import RestShow from '../../../containers/rest/Show';
import RestFieldItem from '../../../components/RestField/RestFieldItem';

const DesignersShow = props => (
  <RestShow {...props} hasEdit resource="designers">
    <RestFieldItem source="displayName" header="designers.displayName" />
  </RestShow>
);

export default DesignersShow;
