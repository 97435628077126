import { takeEvery, put, call } from 'redux-saga/effects';
import { sortBy } from 'lodash';
import NichesActions, {
  MODEL,
  IGNORE_ACTIONS,
  PRIMARY_KEY,
  NichesTypes,
  getNichesReportSuccess,
  getNichesReportFailure,
} from './actions';
import rootCRUDSaga from '../crudCreator/saga';
import { getNichesReport } from '../../api/report';
import { randomColor } from '../../utils/tools';
import { convertRequestParams } from '../crudCreator/dataProvider';
// use IGNORE_SAGAS to replace "saga" or ignore "saga"
// IGNORE_SAGAS = ['GET_ALL', 'GET_BY_ID', 'DELETE', 'EDIT', 'CREATE'];

const IGNORE_SAGAS = IGNORE_ACTIONS;

function* getNichesReportSaga({ data }) {
  try {
    const requestParams = convertRequestParams('GET_ALL', data);
    const response = yield call(getNichesReport, requestParams);
    yield put(
      getNichesReportSuccess({
        numOrderPieChar: randomColor(sortBy(response.numOrderPieChar, e => -e.value)),
        revenuePieChar: randomColor(sortBy(response.revenuePieChar, e => -e.value)),
      }),
    );
  } catch (error) {
    yield put(getNichesReportFailure(error));
  }
}

export default [
  ...rootCRUDSaga(MODEL, IGNORE_SAGAS, NichesActions, PRIMARY_KEY),
  takeEvery(NichesTypes.GET_NICHES_REPORT, getNichesReportSaga),
];
