import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Upload,
  Avatar,
  Progress,
  Form,
  Input,
  Icon,
  notification,
  Modal,
} from 'antd';
import AvatarCropperModal from './AvatarCropperModal';
import UploadImageWrapper from './style';
import { getRecordData, getImageUrl } from '../../../utils/tools';
import { del } from '../../../api/utils';
import user from '../../../assets/images/user.png';
import { uploadImages } from '../../../api/uploadMedia';

const uploadUrl = `${process.env.REACT_APP_SERVER_URL}/uploadFile`;
const FormItem = Form.Item;

// const confirm = Modal.confirm;

class UploadImage extends Component {
  static getDerivedStateFromProps = (nextProps, prevState) => {
    if (
      getRecordData(nextProps.record, nextProps.source) !==
      prevState.prevRecordImgSource
    ) {
      return {
        prevRecordImgSource:
          getRecordData(nextProps.record, nextProps.source) &&
          getImageUrl(
            getRecordData(nextProps.record, nextProps.source).previewImage ||
              getRecordData(nextProps.record, nextProps.source),
          ),
        imgDisplay:
          getRecordData(nextProps.record, nextProps.source) &&
          getImageUrl(
            getRecordData(nextProps.record, nextProps.source).previewImage ||
              getRecordData(nextProps.record, nextProps.source),
          ),
      };
    }
    return {};
  };

  state = {
    file: null,
    previewImage: false,
    prevRecordImgSource:
      getRecordData(this.props.record, this.props.source) || undefined,
    imgDisplay:
      getRecordData(this.props.record, this.props.source) || undefined,
    loading: false,
    loadingProgress: 0,
    isShowCropperModal: false,
    hasErr: false,
  };

  componentDidMount() {
    // window.addEventListener('beforeunload', this.onUnload);
    // this.onLoad();
  }

  componentWillUnmount() {
    // window.removeEventListener('beforeunload', this.onUnload);
  }

  // onLoad = () => {
  //   const getUrl = localStorage.getItem('url');
  //   if (getUrl) {
  //     this.onRemove(getUrl);
  //   }
  // };

  onUnload = (e) => {
    if (this.state.imgDisplay && this.props.form) {
      localStorage.setItem('url', this.state.imgDisplay);
      e.returnValue = '';
    }
  };

  onHideCropperModal = () => {
    this.setState({
      isShowCropperModal: false,
    });
  };

  onChangePreview = async ({ croppedFile }) => {
    const { type, source, form, onChange } = this.props;
    try {
      if (this.state.imgDisplay) {
        this.onRemove(this.state.imgDisplay);
      }
      this.setState({
        isShowCropperModal: false,
        loading: true,
      });

      const formData = new FormData();
      formData.append('type', type || 'product');
      formData.append('images', croppedFile);
      // const responseS3 = await getUrl(croppedFile.name, croppedFile.type);
      console.log('croppedFile', croppedFile);
      const response = await uploadImages(formData);

      this.setState({
        imgDisplay: getImageUrl(response[0].image),
        loading: false,
        hasErr: false,
      });
      form.setFieldsValue({
        [source]: response[0],
      });
      setTimeout(() => {
        onChange(response);
      }, 0);
    } catch (error) {
      notification.error({
        title: 'Error',
        message:
          error && error.message
            ? error.message
            : 'Server Internall Error. Please try later !!!!',
        position: 'tr',
        autoDismiss: 15,
      });
      this.setState({
        file: null,
        imgDisplay: null,
        loading: false,
        hasErr: true,
        loadingProgress: 0,
      });
    }
  };

  onRemove = (url) => {
    del('/deleteFile', { url });
    this.setState({
      file: null,
      imgDisplay: null,
      loading: false,
      loadingProgress: 0,
    });
    localStorage.removeItem('url');
  };

  renderImage() {
    const { style, defaultText, defaultIcon, defaultValue } = this.props;
    const { loading, imgDisplay, hasErr } = this.state;
    if (loading) {
      return (
        <Avatar style={style}>
          <Progress percent={this.state.loadingProgress} showInfo={false} />
          <div className="ant-upload-text">Uploading....</div>
        </Avatar>
      );
    }
    if (!imgDisplay) {
      return (
        <Avatar
          icon={defaultIcon}
          src={(!defaultText && user) || defaultValue}
          style={style}
        >
          <span className="default-image">{defaultText}</span>
        </Avatar>
      );
    }

    if (!hasErr) {
      return <Avatar src={imgDisplay} style={style} />;
    }

    return (
      <Avatar src={imgDisplay} style={style}>
        <Progress
          percent={this.state.loadingProgress}
          showInfo={false}
          status="exception"
        />
        <div className="ant-upload-text">Upload Failed</div>
      </Avatar>
    );
  }

  showImage = (e) => {
    e.preventDefault();
    this.setState({ previewImage: true });
  };

  render() {
    const {
      hasCrop,
      form,
      source,
      style,
      className,
      label,
      defaultValue,
      cropDimension,
      disabled,
    } = this.props;
    const { imgDisplay } = this.state;
    const props = {
      disabled,
      showUploadList: false,
      action: uploadUrl,
      beforeUpload: (file) => {
        this.setState(() => ({
          file,
          isShowCropperModal: hasCrop,
        }));
        if (!hasCrop) {
          this.onChangePreview({ croppedFile: file });
        }
        return false;
      },
    };

    return (
      <UploadImageWrapper className={className}>
        <FormItem label={label}>
          {form &&
            form.getFieldDecorator(`${source}.image`, {
              initialValue: imgDisplay || (defaultValue && defaultValue.image),
            })(<Input style={{ display: 'none' }} />)}
          {form &&
            form.getFieldDecorator(`${source}.previewImage`, {
              initialValue:
                imgDisplay || (defaultValue && defaultValue.previewImage),
            })(<Input style={{ display: 'none' }} />)}
          <Upload {...props} accept="image/*">
            <div className="image-uploader">
              {this.renderImage()}
              <div className="image-hover" style={style}>
                <Icon
                  type="eye"
                  className="image-hover-icon"
                  style={{ marginRight: 10 }}
                  onClick={this.showImage}
                />
                <Icon type="camera" className="image-hover-icon" />
              </div>
            </div>
          </Upload>
          <AvatarCropperModal
            cropDimension={cropDimension}
            isShowModal={this.state.isShowCropperModal}
            onHideModal={this.onHideCropperModal}
            onChangePreview={this.onChangePreview}
            image={this.state.file}
          />
        </FormItem>
        <Modal
          visible={this.state.previewImage}
          onCancel={() => this.setState({ previewImage: false })}
        >
          <img alt="" src={this.state.prevRecordImgSource} />
        </Modal>
      </UploadImageWrapper>
    );
  }
}

UploadImage.propTypes = {
  showErrorMsg: PropTypes.func,
  form: PropTypes.object,
  source: PropTypes.string,
  record: PropTypes.object,
  style: PropTypes.object,
  defaultText: PropTypes.string,
  defaultIcon: PropTypes.string,
  onUploadImage: PropTypes.func,
  className: PropTypes.string,
  cropDimension: PropTypes.object,
  hasCrop: PropTypes.bool,
  label: PropTypes.string,
  defaultValue: PropTypes.any,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

UploadImage.defaultProps = {
  hasCrop: true,
};

export default UploadImage;
