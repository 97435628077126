import React from 'react';
import RestInputItem from '../../../../components/RestInput/RestInputItem';

const CommentsForm = props => (
  <div {...props}>
    <RestInputItem source="userId" header="comments.userId" />
    <RestInputItem source="content" header="comments.content" />
    <RestInputItem source="orderId" header="comments.orderId" />
  </div>
);

CommentsForm.propTypes = {};

export default CommentsForm;
