import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, notification } from 'antd';
import i18next from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import mistakeRecordsAct from 'redux/mistakeRecords/actions';

const AcceptButton = ({ record }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.data);
  const accept = () => {
    Modal.confirm({
      title: i18next.t('mistakeRecords.acceptTitle'),
      content: (
        <div
          dangerouslySetInnerHTML={{
            __html: i18next.t('mistakeRecords.acceptDescription', {
              mistakeType: record?.mistakeType?.name,
              id: record?.orderId,
            }),
          }}
        />
      ),
      onOk: () => {
        dispatch(
          mistakeRecordsAct.editMistakeRecords(record, {
            customId: `${record?.id}/accept`,
          })
        ).then((e) => {
          notification.success({
            message: e?.error?.messenger,
          });
          dispatch(
            mistakeRecordsAct.getAllMistakeRecords({}, { isRefresh: true })
          );
        });
      },
    });
  };
  return user?.id === record.victimId ? (
    <div>
      {record?.isAccept ? (
        i18next.t('mistakeRecords.accepted')
      ) : (
        <Button type="danger" onClick={accept}>
          {i18next.t('button.accept')}
        </Button>
      )}
    </div>
  ) : (
    i18next.t('button.noAccept')
  );
};

AcceptButton.propTypes = {
  record: PropTypes.object,
};

export default AcceptButton;
