import React from 'react';
import PropTypes from 'prop-types';
import { Timeline, Icon } from 'antd';
import i18next from 'i18next';
import Text from '../../../../components/common/Text';
import { ORDER_STATUS } from '../../../../configs/localData';
import ReceiveNewDeal from './ReceiveNewDeal';
import AssignDesigner from './AssignDesigner';
import VerifyResults from './VerifyResults';
import DesignInprogress from './DesignInprogress';
import Deliveried from './Deliveried';
import WaitingApproved from './WaitingApproved';
// import Feedback from './Feedback';
import Canceled from './Canceled';
import Done from './Done';
import Feedback from './Feedback';
import DefaultStatus from './Default';

const OrderStatus = ({ status, item }) => {
  let content = '';
  switch (status) {
    case 'new_deal':
      content = <ReceiveNewDeal item={item} />;
      break;
    case 'designer_confirm':
      content = <AssignDesigner item={item} />;
      break;
    case 'design_in_progress':
      content = <DesignInprogress item={item} />;
      break;
    case 'verified':
      content = <VerifyResults item={item} />;
      break;
    case 'delivery':
      content = <Deliveried item={item} />;
      break;
    case 'waiting_approved':
      content = <WaitingApproved item={item} />;
      break;
    case 'feedback':
      content = <Feedback item={item} />;
      break;
    case 'canceled':
      content = <Canceled item={item} />;
      break;
    case 'done':
      content = <Done item={item} />;
      break;
    default:
      content = <DefaultStatus item={item} />;
  }
  const dot = (
    <div
      className="dot"
      style={{ background: TIMELINE_ICON[item && item.accept].color }}
    >
      <Icon type={TIMELINE_ICON[item && item.accept].icon} className="icDot" />
    </div>
  );
  const orderStatus = ORDER_STATUS.find((e) => e.value === status) || {};
  return (
    <Timeline.Item dot={dot}>
      <Text type="h4">{i18next.t(`orderStatuses.${orderStatus?.value}`)}</Text>
      {content}
    </Timeline.Item>
  );
};
OrderStatus.propTypes = {};

const TIMELINE_ICON = {
  null: { icon: 'loading', color: 'green' },
  undefined: { icon: 'loading', color: 'green' },
  true: { icon: 'check', color: 'blue' },
  false: { icon: 'close', color: 'red' },
};

OrderStatus.propTypes = {
  status: PropTypes.string,
  item: PropTypes.object,
};

export default OrderStatus;
