import React from 'react';
import List from '../../../containers/rest/List';
import RestFieldItem from '../../../components/RestField/RestFieldItem';
import ActionGroup from '../../../components/RestActions/ActionGroup';
import EditButton from '../../../components/RestActions/EditButton';
import DeleteButton from '../../../components/RestActions/DeleteButton';
import Reference from '../../../containers/rest/Reference';

const ChannelsList = props => (
  <List {...props} isScroll={false} resource="channels">
    <RestFieldItem sorter width={150} source="name" header="channels.name" />
    <Reference width={100} source="brandId" resource="brands" header="channels.brand">
      <RestFieldItem source="name" />
    </Reference>
    <Reference width={150} source="ownerId" resource="users" header="channels.owner">
      <RestFieldItem source="displayName" />
    </Reference>
    <RestFieldItem
      width={150}
      format={(data, record) => (
        <div>
          Phone: {record.phone}
          <br />
          Email: {record.email}
        </div>
      )}
      source="phone"
      header="channels.contact"
    />

    <RestFieldItem source="description" header="channels.description" />
    <ActionGroup>
      <EditButton />
      <DeleteButton isSortDelete />
    </ActionGroup>
  </List>
);

ChannelsList.propTypes = {};

export default ChannelsList;
