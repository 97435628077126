import React from 'react';
import RestInputItem from '../../../../components/RestInput/RestInputItem';

const DealersForm = props => (
  <div {...props}>
    <RestInputItem source="displayName" header="dealers.displayName" />
  </div>
);

DealersForm.propTypes = {};

export default DealersForm;
