import React from 'react';
import Create from '../../../containers/rest/Create';
import Form from '../components/Form';

const LeadersCreate = props => (
  <Create {...props} resource="leaders">
    <Form />
  </Create>
);

LeadersCreate.propTypes = {};

export default LeadersCreate;
