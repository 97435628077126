import React from 'react';
import Edit from '../../../containers/rest/Edit';
import Form from '../components/Form';

const ServiceTypesEdit = props => (
  <Edit {...props} resource="serviceTypes">
    <Form />
  </Edit>
);

ServiceTypesEdit.propTypes = {};

export default ServiceTypesEdit;
