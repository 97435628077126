import React from 'react';
import PropTypes from 'prop-types';
import { Button, Tooltip } from 'antd';
import { setDefaultPermissionForUser } from 'redux/users/actions';
import i18next from 'i18next';
import { useDispatch } from 'react-redux';

const Actions = ({ record }) => {
  const dispatch = useDispatch();
  const setDefaultPermission = (data) => {
    dispatch(setDefaultPermissionForUser(data));
  };

  return (
    <Tooltip title={i18next.t('permissions.setDefaultByRole')}>
      <Button
        style={{ marginLeft: 10 }}
        icon="cluster"
        onClick={() => setDefaultPermission({ userId: record.id })}
      />
    </Tooltip>
  );
};
Actions.propTypes = {
  record: PropTypes.object,
};

export default Actions;
