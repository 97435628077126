import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Modal, Tag } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import i18next from 'i18next';
import { FormatOrderFeature } from 'utils/renderUtils';
import PreviewImage from 'components/common/PreviewImage';
import ReferenceManyToMany from 'containers/rest/ReferenceManyToMany';
import GridPhotos from 'components/common/GridPhotos';
import List from '../../../containers/rest/List';
import RestFieldItem from '../../../components/RestField/RestFieldItem';
import { formatDateTime, formatMoney } from '../../../utils/textUtils';
import { cleanIsCurrentRequest } from '../../../redux/orderHistories/actions';
import { ORDER_STATUS } from '../../../configs/localData';

const OrderRequestedsList = (props) => {
  const dispatch = useDispatch();
  const orderFeatures = useSelector((state) => state.config?.orderFeatures);
  const user = useSelector((state) => state.auth.data);
  return (
    <List
      {...props}
      hasCreate={false}
      defaultOptions={{ customApi: 'orderHistories/on-pd' }}
      resource="orderRequesteds"
      isScroll={false}
      isUpdateRoute={false}
      initialFilter={{
        filter: {
          isCurrent: true,
        },
      }}
    >
      <RestFieldItem
        sorter
        format={(id, record) => (
          <>
            <Link to={`/orders/${id}/edit`}>
              {`#${id}${
                record?.order?.codeCustomer
                  ? `\n(${record?.order?.codeCustomer})`
                  : ''
              }`}
            </Link>
            <br />
            <PreviewImage src={record?.order?.thumbnail} />
          </>
        )}
        source="orderId"
        header="orderRequesteds.orderId"
      />
      <RestFieldItem
        source="order.name"
        header="orders.name"
        format={(data, record) => (
          <>
            {data}
            <ReferenceManyToMany
              header="orders.product"
              source="productsId"
              resource="products"
              record={record.order}
            >
              <RestFieldItem
                format={(data, record) =>
                  record && record.productImages ? (
                    <GridPhotos images={record.productImages} />
                  ) : (
                    <GridPhotos images={data} />
                  )
                }
                source="images"
              />
            </ReferenceManyToMany>
          </>
        )}
      />
      <RestFieldItem
        filters={orderFeatures}
        format={(data, record) => (
          <FormatOrderFeature data={data} record={record.order} />
        )}
        source="order.orderFeature"
        header="orders.orderFeature"
      />
      <RestFieldItem
        filters={ORDER_STATUS.filter(
          (e) =>
            e.outsourceLimitFilter &&
            e.outsourceLimitFilter.indexOf(user.scope) > -1,
        )}
        format={(data) => {
          const status = ORDER_STATUS.find((item) => item.value === data) || {};
          return (
            <Tag
              style={{ color: status.color || '#000' }}
              color={status.background}
            >
              {status && i18next.t(`orderStatuses.${status.value}`)}
            </Tag>
          );
        }}
        source="status"
        header="orders.status"
      />
      <RestFieldItem
        sorter
        format={(data) => `${formatDateTime(data)}`}
        source="createdAt"
        header="orderRequesteds.createdAt"
      />
      <RestFieldItem
        sorter
        format={(data) => `${formatMoney(data)} VND`}
        source="order.dealPrice"
        header="orders.dealPrice"
      />
      <RestFieldItem
        format={(data, record) => (
          <Button
            type="danger"
            onClick={() => {
              Modal.confirm({
                title: i18next.t('orderHistories.modal.cleanRequest'),
                onOk() {
                  dispatch(cleanIsCurrentRequest(data, record));
                },
              });
            }}
            icon="delete"
          />
        )}
        source="id"
        header=""
      />
    </List>
  );
};

OrderRequestedsList.propTypes = {};

export default OrderRequestedsList;
