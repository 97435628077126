import React from 'react';
import Edit from '../../../containers/rest/Edit';
import Form from '../components/Form';

const CommentsEdit = props => (
  <Edit {...props} resource="comments">
    <Form />
  </Edit>
);

CommentsEdit.propTypes = {};

export default CommentsEdit;
