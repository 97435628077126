import React from 'react';
import RestInputItem from '../../../../components/RestInput/RestInputItem';

const PermissionsForm = props => (
  <div {...props}>
    <RestInputItem source="name" header="permissions.name" />
    <RestInputItem source="dashboard" header="permissions.dashboard" />
    <RestInputItem source="student" header="permissions.student" />
    <RestInputItem source="teacher" header="permissions.teacher" />
    <RestInputItem source="subject" header="permissions.subject" />
    <RestInputItem source="attendance" header="permissions.attendance" />
    <RestInputItem source="accounting" header="permissions.accounting" />
    <RestInputItem source="notification" header="permissions.notification" />
  </div>
);

PermissionsForm.propTypes = {};

export default PermissionsForm;
