import React from 'react';
import { InputNumber, Input, Row, Col } from 'antd';
import RestInputItem from '../../../../components/RestInput/RestInputItem';
import RestSelect from '../../../../components/RestInput/RestSelect';
import ReferenceInput from '../../../../containers/rest/ReferenceInput';
import { PACKAGE_STATUS, PAYMENT_STATUS } from '../../../../configs/localData';
import RestFormDateTimePicker from '../../../../components/RestInput/RestDateTimePicker';
import { formatInputMoney } from '../../../../utils/tools';
import RestTextEditor from 'components/RestInput/RestTextEditor';

const PackagesForm = props => (
  <div {...props}>
    <Row gutter={16}>
      <Col span={12}>
        <RestInputItem source="name" header="packages.name" />
        <RestInputItem isReference source="channelId">
          <ReferenceInput source="channelId" resource="channels">
            <RestSelect
              source="channelId"
              valueProp="id"
              titleProp="name"
              header="packages.channelId"
            />
          </ReferenceInput>
        </RestInputItem>
        <RestInputItem ruleType="number" source="orderCount" header="packages.orderCount">
          <InputNumber min={0} {...formatInputMoney} />
        </RestInputItem>
        <RestInputItem ruleType="number" source="price" header="packages.price">
          <Input type="number" addonAfter="VND" />
        </RestInputItem>
        <RestInputItem isReference source="dealerId">
          <ReferenceInput source="dealerId" resource="dealers">
            <RestSelect
              source="dealerId"
              valueProp="id"
              titleProp="displayName"
              header="packages.dealerId"
            />
          </ReferenceInput>
        </RestInputItem>
      </Col>

      <Col span={12}>
        <RestSelect
          resourceData={PACKAGE_STATUS}
          valueProp="value"
          titleProp="text"
          source="status"
          header="packages.status"
        />
        <RestInputItem isReference source="customerId">
          <ReferenceInput source="customerId" resource="customers">
            <RestSelect
              source="customerId"
              valueProp="id"
              titleProp="displayName"
              header="packages.customerId"
            />
          </ReferenceInput>
        </RestInputItem>
        <RestInputItem ruleType="number" source="orderUsed" header="packages.orderUsed">
          <InputNumber min={0} {...formatInputMoney} disabled />
        </RestInputItem>
        <RestFormDateTimePicker
          ruleType="object"
          source="finishDate"
          header="packages.finishDate"
        />
        <RestSelect
          resourceData={PAYMENT_STATUS}
          valueProp="value"
          titleProp="text"
          source="paymentStatus"
          header="packages.paymentStatus"
        />
      </Col>
    </Row>
    <RestTextEditor source="note" header="packages.note" />
  </div>
);

PackagesForm.propTypes = {};

export default PackagesForm;
