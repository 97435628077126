import React from 'react';
import { PreviewTextEditor } from 'components/RestInput/RestTextEditor';
import List from '../../../containers/rest/List';
import RestFieldItem from '../../../components/RestField/RestFieldItem';
import ActionGroup from '../../../components/RestActions/ActionGroup';
import EditButton from '../../../components/RestActions/EditButton';
import DeleteButton from '../../../components/RestActions/DeleteButton';
import PreviewImage from '../../../components/common/PreviewImage';

const ProductCatalogsList = (props) => (
  <List {...props} isScroll={false} resource="productTypes">
    <RestFieldItem source="name" header="productTypes.name" />
    <RestFieldItem
      format={(data) => <PreviewTextEditor html={data} />}
      source="description"
      header="productTypes.description"
    />
    <RestFieldItem
      format={(data) => <PreviewImage src={data && data.previewImage} />}
      source="templateFile"
      header="productTypes.mockupFile"
    />
    <ActionGroup>
      <EditButton />
      <DeleteButton isSortDelete />
    </ActionGroup>
  </List>
);

ProductCatalogsList.propTypes = {};

export default ProductCatalogsList;
