import { makeReducerCreator } from '../../utils/reduxUtils';
import { makeCRUDReducerCreator, INITIAL_CRUD_STATE } from '../crudCreator/reducer';
import { MODEL, IGNORE_ACTIONS, NichesTypes } from './actions';

export const INITIAL_STATE = {
  ...INITIAL_CRUD_STATE,
  revenuePieChar: [],
  numOrderPieChar: [],
};

const getNichesReportSuccess = (state, { data }) => ({
  ...state,
  ...data,
});

const reducer = makeReducerCreator(INITIAL_STATE, {
  ...makeCRUDReducerCreator(MODEL, IGNORE_ACTIONS),
  [NichesTypes.GET_NICHES_REPORT_SUCCESS]: getNichesReportSuccess,
});

export default reducer;
