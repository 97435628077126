import React from 'react';
import { formatDate, formatMoney } from 'utils/textUtils';
import i18next from 'i18next';
import { useSelector } from 'react-redux';
import List from '../../rest/List';
import EditButton from '../../../components/RestActions/EditButton';
import DeleteButton from '../../../components/RestActions/DeleteButton';
import ActionGroup from '../../../components/RestActions/ActionGroup';
import RestFieldItem from '../../../components/RestField/RestFieldItem';

const SalaryHistoriesList = (props) => {
  const user = useSelector((state) => state.auth.data);
  console.log(user);
  return (
    <List {...props} resource="salaryHistories">
      <RestFieldItem
        format={(data) => formatDate(data, 'DD/MM/YYYY')}
        source="dateMonth"
        header="salaryHistories.dateMonth"
      />
      <RestFieldItem
        format={(data, record) =>
          `${formatDate(data, 'DD/MM')} => ${formatDate(
            record.endDate,
            'DD/MM'
          )}`
        }
        source="startDate"
        header="salaryHistories.startDate"
      />
      <RestFieldItem
        source="salaryScheme"
        header="salaryHistories.salaryScheme"
      />
      <RestFieldItem source="coef" header="salaryHistories.coef" />
      <RestFieldItem source="coefOT" header="salaryHistories.coefOT" />
      <RestFieldItem source="kpi" header="salaryHistories.kpi" />
      <RestFieldItem
        format={(data, record) => (
          <div>
            {`${i18next.t('salaryHistories.time')}: `}
            {`${record?.workingHours || 0} hours`}
            <br />
            {`${i18next.t('salaryHistories.bonus')}: `}
            <b>{`${formatMoney(record?.salaryInBonus || 0)}`}</b>
            <br />
            {`${i18next.t('salaryHistories.bonusForKPI')}: `}
            <b>{`${formatMoney(record?.salaryInKpi || 0)}`}</b>
            <br />
            {`${i18next.t('salaryHistories.baseSalary')}: `}
            <b>{`${formatMoney(record?.salaryInBasic || 0)}`}</b>
            <br />
            {`${i18next.t('salaryHistories.salary')}: `}
            <b>{`${formatMoney(record?.salary || 0)}`}</b>
          </div>
        )}
        header="salaryHistories.normalTime"
        source="salary"
      />
      <RestFieldItem
        header="salaryHistories.overtime"
        format={(data, record) => (
          <div>
            {`${i18next.t('salaryHistories.time')} OT: `}
            {`${record?.workingHoursOT || 0} hours`}
            <br />
            {`${i18next.t('salaryHistories.salary')} OT: `}
            <b>{`${formatMoney(record?.salaryInOT || 0)}`}</b>
          </div>
        )}
        source="workingHours"
      />
      {user.permission?.salaryInfos?.indexOf('update') > -1 && (
        <ActionGroup>
          <EditButton />
          <DeleteButton />
        </ActionGroup>
      )}
    </List>
  );
};

SalaryHistoriesList.propTypes = {};

export default SalaryHistoriesList;
