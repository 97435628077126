import React from 'react';
import RestShow from '../../../containers/rest/Show';
import RestFieldItem from '../../../components/RestField/RestFieldItem';

const CustomerSegmentationsShow = props => (
  <RestShow {...props} hasEdit resource="customerSegmentations">
    <RestFieldItem source="name" header="customerSegmentations.name" />
    <RestFieldItem source="description" header="customerSegmentations.description" />
    <RestFieldItem source="isDelete" header="customerSegmentations.isDelete" />
  </RestShow>
);

export default CustomerSegmentationsShow;
