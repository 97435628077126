import { makeCRUDConstantCreator, makeCRUDActionsCreator } from '../crudCreator/actions';
import { makeConstantCreator, makeActionCreator } from '../../utils/reduxUtils';

export const PRIMARY_KEY = 'id';
export const MODEL = 'niches';
export const IGNORE_ACTIONS = [];
export const NichesTypes = {
  ...makeCRUDConstantCreator(MODEL, IGNORE_ACTIONS),
  ...makeConstantCreator(
    'GET_NICHES_REPORT',
    'GET_NICHES_REPORT_SUCCESS',
    'GET_NICHES_REPORT_FAILURE',
  ),
};
const CRUDNichesActions = makeCRUDActionsCreator(MODEL, IGNORE_ACTIONS);
export const getNichesReport = data => makeActionCreator(NichesTypes.GET_NICHES_REPORT, { data });
export const getNichesReportSuccess = data =>
  makeActionCreator(NichesTypes.GET_NICHES_REPORT_SUCCESS, { data });
export const getNichesReportFailure = data =>
  makeActionCreator(NichesTypes.GET_NICHES_REPORT_FAILURE, { data });
/**
 * getAllCaseTypes({pageSize, page })
 * getByIdCaseTypes(data)
 * createCaseTypes(data)
 * deleteCaseTypes()
 * editCaseTypes(data)
 */
export default { ...CRUDNichesActions };
