import { takeEvery, put, call } from 'redux-saga/effects';
import {
  SummariesTypes,
  getSummariesDashboardSuccess,
  getSummariesDashboardFailure,
} from './actions';
// use IGNORE_SAGAS to replace "saga" or ignore "saga"
// IGNORE_SAGAS = ['GET_ALL', 'GET_BY_ID', 'DELETE', 'EDIT', 'CREATE'];
import { getDashboardSummaries } from '../../api/summaries';
import { convertRequestParams } from '../crudCreator/dataProvider';

function* summariesDashboardSaga({ data }) {
  try {
    const requestParams = convertRequestParams('GET_ALL', data);
    const response = yield call(getDashboardSummaries, requestParams);
    if (response) {
      yield put(getSummariesDashboardSuccess(response));
    } else {
      yield put(getSummariesDashboardFailure(response));
    }
  } catch (error) {
    yield put(getSummariesDashboardFailure(error));
  }
}

export default [
  takeEvery(SummariesTypes.SUMMARIES_DASHBOARD, summariesDashboardSaga),
];
