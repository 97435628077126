import React from 'react';
import { Switch, Tag, Rate } from 'antd';
import moment from 'moment';
import { get } from 'lodash';
import { Link } from 'react-router-dom';
import i18next from 'i18next';
import UndoButton from 'components/RestActions/UndoButton';
import List from 'containers/rest/List';
import RestFieldItem from 'components/RestField/RestFieldItem';
import ActionGroup from 'components/RestActions/ActionGroup';
import EditButton from 'components/RestActions/EditButton';
import DeleteButton from 'components/RestActions/DeleteButton';
import ReferenceInput from 'containers/rest/ReferenceInput';
import FilterDropdown from 'components/RestLayout/FilterDropdown';
import { FORMAT_DATE, STATUS } from 'configs/localData';
import { dateFilterDropdown } from 'components/RestInput/RestDateTimePicker';
import { formatDateTime } from 'utils/textUtils';
import PropTypes from 'prop-types';

const CustomersList = ({ isDeleted, isSortDelete, ...props }) => {
  const filterDropdownChannels = (filterKey, resourceFilter) => (props) => (
    <ReferenceInput searchKey="name" resource="channels">
      <FilterDropdown
        {...props}
        defaultChecked={get(resourceFilter.filter, filterKey)}
        valueProp="id"
        titleProp="name"
      />
    </ReferenceInput>
  );

  const filterDropdownOwners = (filterKey, resourceFilter) => (props) => (
    <ReferenceInput searchKey="displayName" resource="users">
      <FilterDropdown
        {...props}
        defaultChecked={get(resourceFilter.filter, filterKey)}
        valueProp="id"
        titleProp="displayName"
      />
    </ReferenceInput>
  );
  const filterDropdownDealPipelines = (filterKey, resourceFilter) => (
    props,
  ) => (
    <ReferenceInput searchKey="name" resource="dealpipelines">
      <FilterDropdown
        {...props}
        defaultChecked={get(resourceFilter.filter, filterKey)}
        valueProp="id"
        titleProp="name"
      />
    </ReferenceInput>
  );
  const filterDropdownServiceTypes = (filterKey, resourceFilter) => (props) => (
    <ReferenceInput searchKey="name" resource="serviceTypes">
      <FilterDropdown
        {...props}
        defaultChecked={get(resourceFilter.filter, filterKey)}
        valueProp="id"
        titleProp="name"
      />
    </ReferenceInput>
  );
  return (
    <List
      {...props}
      onEditHeaderSuccess={onEditHeaderSuccess}
      redirects={{ edit: 'page', create: 'modal' }}
      resource="customers"
      header="customers.title"
      // isScroll={false}
      hasAnalysis
      initialFilter={{
        includes: 'owner,channel,dealPipeline,serviceType',
      }}
    >
      <RestFieldItem
        filterDropdown={dateFilterDropdown}
        source="createdAt"
        filterKey="createdAt"
        sorter
        format={(data) => moment(data).format(FORMAT_DATE)}
        header="customers.createdAt"
      />
      <RestFieldItem
        sorter
        width={150}
        format={(data, record) => (
          <div style={{ width: 150 }}>
            <Link
              to={`/customers/${record.id}/edit`}
              href={`/customers/${record.id}/edit`}
            >
              {record.displayName}
            </Link>
            <div>
              <Rate value={record.rate || 0} />({record.totalRate || 0})
            </div>
          </div>
        )}
        source="displayName"
        header="customers.displayName"
      />
      <RestFieldItem
        filterDropdown={filterDropdownOwners}
        source="owner.displayName"
        filterKey="contactOwner.$in"
        header="customers.contactOwner"
      />
      <RestFieldItem
        filterKey="channelId.$in"
        header="customers.channel"
        filterDropdown={filterDropdownChannels}
        source="channel.name"
      />
      <RestFieldItem
        format={(data) => formatDateTime(data)}
        sorter
        filterKey="createdAt"
        filterDropdown={dateFilterDropdown}
        source="lastTimeOrder"
        header="customers.lastTimeOrder"
      />
      <RestFieldItem
        filterKey="dealPipelineId.$in"
        filterDropdown={filterDropdownDealPipelines}
        source="dealPipeline.name"
        header="customers.dealPipeline"
        format={(data, record) => (
          <Tag color={record && record.color}>{data}</Tag>
        )}
      />
      <RestFieldItem
        filterDropdown={filterDropdownServiceTypes}
        format={(data, record) =>
          data?.map((e) => <Tag color={record && record.color}>{e.name}</Tag>)
        }
        source="serviceType"
        header="customers.serviceType"
      />
      <RestFieldItem
        hasDebounce={false}
        filters={STATUS.map((e) => ({ ...e, text: i18next.t(e.text) }))}
        component={<Switch />}
        type="switch"
        valueProp="checked"
        source="isDelete"
        header="users.isDelete"
      />
      <ActionGroup>
        {!isDeleted && <EditButton />}
        {isDeleted ? <UndoButton /> : null}
        <DeleteButton isSortDelete={isSortDelete} />
      </ActionGroup>
    </List>
  );
};

const onEditHeaderSuccess = () => {
  // console.log('e', e);
};

CustomersList.propTypes = {
  isDeleted: PropTypes.bool,
  isSortDelete: PropTypes.bool,
};

CustomersList.propTypes = {
  isSortDelete: true,
};

export default CustomersList;
