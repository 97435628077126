import React from 'react';
import { Input } from 'antd';
import RestInputItem from '../../../../components/RestInput/RestInputItem';

const CustomerSegmentationsForm = props => (
  <div {...props}>
    <RestInputItem source="name" header="customerSegmentations.name" />
    <RestInputItem source="description" header="customerSegmentations.description">
      <Input.TextArea />
    </RestInputItem>
  </div>
);

CustomerSegmentationsForm.propTypes = {};

export default CustomerSegmentationsForm;
