import React from 'react';
import { Switch } from 'antd';
import List from '../../../containers/rest/List';
import RestFieldItem from '../../../components/RestField/RestFieldItem';
import ActionGroup from '../../../components/RestActions/ActionGroup';
import EditButton from '../../../components/RestActions/EditButton';
import DeleteButton from '../../../components/RestActions/DeleteButton';

const DepartmentsList = props => (
  <List {...props} resource="departments">
    <RestFieldItem source="name" header="departments.name" />
    <RestFieldItem source="description" header="departments.description" />
    <RestFieldItem
      component={<Switch />}
      type="switch"
      valueProp="checked"
      source="isDelete"
      header="departments.isDelete"
    />
    <ActionGroup>
      <EditButton />
      <DeleteButton isSortDelete />
    </ActionGroup>
  </List>
);

DepartmentsList.propTypes = {};

export default DepartmentsList;
