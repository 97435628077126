import React, { useState } from 'react';
// import PropTypes from 'prop-types';
import { Popover, Button, TimePicker, DatePicker } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import i18next from 'i18next';
import moment from 'moment';
import { formatDateTime } from '../../../../utils/textUtils';
import DeadlineWrapper, { DeadlineContentWrapper } from './styles';
import OrderAction from '../../../../redux/orders/actions';
import { ORDER_STATUS } from '../../../../configs/localData';

const Deadline = ({ order = {} }) => {
  const [hovered, setHovered] = useState(false);
  const user = useSelector((state) => state.auth.data);
  const [deadline, setDeadline] = useState(
    order.deadline ? moment(order.deadline) : moment().add(12, 'hours')
  );
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const updatePrice = () => {
    setLoading(true);
    dispatch(
      OrderAction.editOrders(
        {
          id: order.id,
          deadline: deadline.toISOString(),
        },
        { isBack: false, unRefreshByRequest: true }
      )
    ).then((e) => {
      setLoading(false);
      if (e.id) {
        setHovered(false);
      }
    });
  };

  const onClose = () => {
    setDeadline(
      order.deadline ? moment(order.deadline) : moment().add(12, 'hours')
    );
    setHovered(!hovered);
  };

  const content = (
    <DeadlineContentWrapper>
      <DatePicker
        onChange={(date) => setDeadline(date)}
        value={deadline}
        className="selectDeadline"
      />
      <TimePicker
        onChange={(date) => setDeadline(date)}
        value={deadline}
        className="selectDeadline"
      />
      <br />
      <div className="row">
        <Button loading={isLoading} onClick={updatePrice} type="primary">
          {i18next.t('button.ok')}
        </Button>
        <Button loading={isLoading} onClick={onClose} type="danger">
          {i18next.t('button.cancel')}
        </Button>
      </div>
    </DeadlineContentWrapper>
  );
  const editPermission =
    user &&
    user.isOutsource === false &&
    user.permission?.orders?.indexOf('deadLine') > -1;
  return editPermission ? (
    <DeadlineWrapper className={getStatusClassName(order.deadline)}>
      <Popover
        placement="left"
        visible={hovered}
        content={content}
        title={i18next.t('orders.deadline')}
      >
        <div
          role="presentation"
          onClick={() => setHovered(!hovered)}
          className="deadline"
        >
          {`${
            order.deadline
              ? formatDateTime(order.deadline)
              : i18next.t(`orderHistories.selectDeadLine`)
          }`}
        </div>
      </Popover>
    </DeadlineWrapper>
  ) : order.deadline ? (
    <DeadlineWrapper className={getStatusClassName(order.deadline)}>
      <div role="presentation" className="deadline">
        {`${formatDateTime(order.deadline)}`}
      </div>
    </DeadlineWrapper>
  ) : null;
};

const getStatusClassName = (deadline, status) => {
  if (status === ORDER_STATUS[8].value || status === ORDER_STATUS[9].value)
    return '';
  const timeout = moment(deadline).diff(moment(), 'hour');
  if (timeout < 2) {
    return 'error';
  }
  if (timeout < 4) {
    return 'warning';
  }
  return '';
};

Deadline.propTypes = {};

export default Deadline;
