import React from 'react';
import { Tag, Switch } from 'antd';
import List from '../../../containers/rest/List';
import RestFieldItem from '../../../components/RestField/RestFieldItem';
import ActionGroup from '../../../components/RestActions/ActionGroup';
import EditButton from '../../../components/RestActions/EditButton';
import DeleteButton from '../../../components/RestActions/DeleteButton';

const OrderStatusesList = props => (
  <List {...props} resource="orderStatuses">
    <RestFieldItem source="name" header="orderStatuses.name" />
    <RestFieldItem
      format={data => <Tag color={data}>{data}</Tag>}
      source="color"
      header="orderStatuses.color"
    />
    <RestFieldItem source="description" header="orderStatuses.description" />
    <RestFieldItem
      component={<Switch />}
      type="switch"
      valueProp="checked"
      source="isDelete"
      header="orderStatuses.isDelete"
    />
    <ActionGroup>
      <EditButton />
      <DeleteButton />
    </ActionGroup>
  </List>
);

OrderStatusesList.propTypes = {};

export default OrderStatusesList;
