import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import i18next from 'i18next';
import { notification, Progress, Modal } from 'antd';
import TextEditor from 'components/common/TextEditor';
import { push } from 'connected-react-router';
import Text from '../../../../components/common/Text';
import { ReceiveNewDealAction } from '../OrderStatus/ReceiveNewDeal';
import { ActionForNextStepWrapper } from './styles';
import ExtraAction from '../OrderStatus/ExtraAction';
import { DesignInprogressAction } from '../OrderStatus/DesignInprogress';
import { VerifyResultsAction } from '../OrderStatus/VerifyResults';
import { FeedbackAction } from '../OrderStatus/Feedback';
import SelectUser from '../../../../components/common/SelectUser';
import ReferenceInput from '../../../../containers/rest/ReferenceInput';
import OrderHistoriesAction from '../../../../redux/orderHistories/actions';
import OrdersAction from '../../../../redux/orders/actions';
import { ORDER_STATUS as _ORDER_STATUS } from '../../../../configs/localData';
import { CancelAction } from '../OrderStatus/Canceled';
import { DoneAction } from '../OrderStatus/Done';
import { uploadImages } from '../../../../api/uploadMedia';
import crudSelectors from '../../../../redux/crudSelectors';
import { PreviewTextEditor } from '../../../../components/RestInput/RestTextEditor/index';
import { formatDateTime } from '../../../../utils/textUtils';
import DealPrice from '../DealPrice';
import Deadline from '../Deadline';
import GetSuggestionDesigner from '../GetSuggestionDesigner';

const ActiveForNextStep = ({
  orderHistories,
  user,
  editOrderHistories,
  createOrderHistories,
  order,
  getOrder,
  productTypes,
}) => {
  const dispatch = useDispatch();
  const [note] = useState({ text: '' });
  const [processing, setProcessing] = useState();
  const [deadLine, setDeadline] = useState(null);
  const [isUpload, setUpload] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [products, onChangeProducts] = useState([]);
  let content = '';
  let action = '';
  if (orderHistories && orderHistories.length === 0) return null;
  const item = orderHistories[0];
  const ORDER_STATUS = _ORDER_STATUS.map((e) => ({
    ...e,
    isAccess:
      user.scope === 'manager' ||
      user.permission?.orderFlow?.indexOf(e.nextActionPermissionKey) > -1,
  }));
  const orderStatus = ORDER_STATUS.find(
    (e) => item && e.value === order.status,
  );

  const setNote = (e) => {
    note.text = e;
  };
  const onRenew = (report) => {
    const doneStatus = ORDER_STATUS.find((e) => e.value === 'done');
    const requestPayload = {
      assignedUser: [],
      status: doneStatus?.rejectStatus,
      orderId: order.id,
      note: note.text,
      products,
      report,
      deadLine: deadLine && deadLine.toISOString(),
    };
    createOrderHistories(requestPayload).then(() => {
      setSelectedUser(null);
      setNote('');
      getOrder(order);
      setDeadline();
      dispatch(
        OrderHistoriesAction.getAllOrderHistories(
          {
            includes: 'assignedUsers,assigner',
            limit: 10,
            filter: { orderId: order.id },
          },
          { isRefresh: true },
        ),
      );
    });
    // createOrder(getCloneOrderData({ ...order, id: undefined }));
  };

  const onUploadProgress = (value) => {
    setProcessing(value);
  };

  const onReopen = (e) => {
    const requestPayload = {
      assignedUser: orderHistories?.[1].assignedUser,
      status: orderHistories?.[1].status,
      orderId: order.id,
      note: e,
      products,
      deadLine: deadLine && deadLine.toISOString(),
    };
    createOrderHistories(requestPayload).then(() => {
      setSelectedUser(null);
      setNote('');
      getOrder(order);
      setDeadline();
      dispatch(
        OrderHistoriesAction.getAllOrderHistories(
          {
            includes: 'assignedUsers,assigner',
            limit: 10,
            filter: { orderId: order.id },
          },
          { isRefresh: true },
        ),
      );
    });
  };

  const goCreateMistakeRecord = (requestPayload) => {
    if (requestPayload.status === 'feedback') {
      Modal.confirm({
        title: i18next.t('orders.makeToMistake'),
        onOk: () => {
          dispatch(
            push(
              `#mistakeRecords/create?orderId=${order?.id}&note=${
                requestPayload.feedback || requestPayload.note
              }`,
            ),
          );
        },
      });
    }
  };

  const accept = async () => {
    const requestPayload = {
      assignedUser: selectedUser || [],
      status: !orderStatus ? ORDER_STATUS[0].value : orderStatus?.acceptStatus,
      orderId: order.id,
      note:
        orderStatus?.acceptStatus === 'waiting_approved'
          ? item.note
          : note.text,
      products: [],
      deadLine: deadLine && deadLine.toISOString(),
    };

    const fileLink = '';

    const images = products.filter(
      (e) =>
        e.originFileObj.type.indexOf('image') > -1 &&
        e.originFileObj.name.indexOf('.psd') === -1,
    );
    if (images.length > 0) {
      const formData = new FormData();
      images.forEach((e) => {
        formData.append('images', e.originFileObj);
      });
      formData.append('orderId', order.id);
      setUpload(true);
      formData.append('type', 'product');
      formData.append('channelId', order.channelId);
      try {
        const responseImages = await uploadImages(formData, onUploadProgress);
        setUpload(false);
        requestPayload.products = responseImages;
      } catch (err) {
        setUpload(false);
      }
    }
    // // upload file
    // if (products.length > 0) {
    //   const formFiles = new FormData();
    //   products.forEach((e) => {
    //     formFiles.append('files', e.originFileObj);
    //   });
    //   formFiles.append('orderId', order.id);
    //   setUpload(true);
    //   formFiles.append('type', 'product');
    //   formFiles.append('channelId', order.channelId);
    //   formFiles.append('customerId', order.customerId);
    //   try {
    //     const responseFiles = await uploadFiles(formFiles, onUploadProgress);
    //     fileLink = responseFiles;
    //     // requestPayload.note = `${requestPayload.note}<br/>${
    //     //   fileLink.url
    //     //     ? `<a href="${fileLink.url}" target="_blank">${fileLink.url}</a>`
    //     //     : ''
    //     // }`;
    //     setUpload(false);
    //   } catch (err) {
    //     setUpload(false);
    //   }
    // }
    if (orderStatus && validateCreateNextStep(requestPayload)) {
      // update accept for current status
      editOrderHistories({
        id: item.id,
        accept: true,
        note: `${user?.displayName} xác nhận.<br/><br/>${item?.note}<br/><a target="_blank" href="${fileLink.url}">${fileLink.url}</a>`,
      }).then((res) => {
        if (res.error) {
          setSelectedUser(null);
          setNote('');
          getOrder(order);
          setDeadline();
          setProcessing(0);
          onChangeProducts([]);
        } else {
          if (
            orderStatus &&
            orderStatus.extraAcceptStatus &&
            selectedUser &&
            selectedUser.length > 0
          ) {
            requestPayload.status = orderStatus.extraAcceptStatus;
          }
          orderStatus.acceptStatus &&
            createOrderHistories(requestPayload).then(() => {
              setSelectedUser(null);
              setNote('');
              getOrder(order);
              setDeadline();
              onChangeProducts([]);
              goCreateMistakeRecord(requestPayload);
              dispatch(
                OrderHistoriesAction.getAllOrderHistories(
                  {
                    includes: 'assignedUsers,assigner',
                    limit: 10,
                    filter: { orderId: order.id },
                  },
                  { isRefresh: true },
                ),
              );
            });
        }
      });
      // update accept for current status
      // this is customer for feedback select Guarantee
    } else if (!orderStatus) {
      createOrderHistories(requestPayload).then(() => {
        setSelectedUser(null);
        setNote('');
        getOrder(order);
        setDeadline();
        setProcessing(0);
        onChangeProducts([]);
        goCreateMistakeRecord(requestPayload);
        dispatch(
          OrderHistoriesAction.getAllOrderHistories(
            {
              includes: 'assignedUsers,assigner',
              limit: 10,
              filter: { orderId: order.id },
            },
            { isRefresh: true },
          ),
        );
      });
    }
  };

  const reject = (report) => {
    editOrderHistories({
      id: item.id,
      accept: false,
      report,
    }).then((res) => {
      if (res.error) {
        //
      } else if (
        orderStatus &&
        orderStatus.rejectStatus &&
        orderStatus.rejectStatus !== 'selectAnotherDesigner'
      ) {
        createOrderHistories({
          assignedUser: item.assignedUser,
          status: orderStatus.rejectStatus,
          orderId: order.id,
          note: report,
          report,
          deadLine: deadLine && deadLine.toISOString(),
        }).then(() => {
          getOrder(order);
          goCreateMistakeRecord({
            assignedUser: item.assignedUser,
            status: orderStatus.rejectStatus,
            orderId: order.id,
            note: report,
            report,
            deadLine: deadLine && deadLine.toISOString(),
          });
          dispatch(
            OrderHistoriesAction.getAllOrderHistories(
              {
                includes: 'assignedUsers,assigner',
                limit: 10,
                filter: { orderId: order.id },
              },
              { isRefresh: true },
            ),
          );
        });
      } else if (!orderStatus) {
        createOrderHistories({
          status: ORDER_STATUS[8].value,
          orderId: order.id,
          report,
          note: report,
          deadLine: deadLine && deadLine.toISOString(),
        }).then(() => {
          getOrder(order);
          goCreateMistakeRecord({
            status: ORDER_STATUS[8].value,
            orderId: order.id,
            report,
            note: report,
            deadLine: deadLine && deadLine.toISOString(),
          });
          dispatch(
            OrderHistoriesAction.getAllOrderHistories(
              {
                includes: 'assignedUsers,assigner',
                limit: 10,
                filter: { orderId: order.id },
              },
              { isRefresh: true },
            ),
          );
          setDeadline();
          onChangeProducts([]);
        });
      }
    });
  };

  if (!item)
    return (
      <ActionForNextStepWrapper>
        <Text align="center" type="h4">
          {i18next.t(`orderHistories.status.titleActions.default`)}
        </Text>
        <div className="contentAction">
          <ReceiveNewDealAction
            setNote={setNote}
            setSelectedUser={setSelectedUser}
          />
        </div>
        <ExtraAction isUpload={isUpload} accept={accept} reject={reject} />
      </ActionForNextStepWrapper>
    );

  let acceptEdit = orderStatus?.isAccess;
  action = acceptEdit ? (
    <ExtraAction isUpload={isUpload} accept={accept} reject={reject} />
  ) : (
    <Text align="center" color="red" type="h4">
      {i18next.t('orderHistories.error.unAuth')}
    </Text>
  );
  switch (order.status) {
    case 'new_deal':
      content = (
        <ActionForNextStepAction
          acceptEdit={acceptEdit}
          setNote={setNote}
          setSelectedUser={setSelectedUser}
          assignResource={orderStatus.assignResource}
          item={item}
          order={order}
          user={user}
        />
      );
      break;
    case 'gua_pd':
      acceptEdit =
        item.assignedUser?.indexOf(user.id) > -1 || orderStatus?.isAccess;
      content = acceptEdit ? (
        <DesignInprogressAction
          acceptEdit={acceptEdit}
          onChangeProducts={onChangeProducts}
          setNote={setNote}
          setSelectedUser={setSelectedUser}
          item={item}
          note={note}
        />
      ) : (
        <span />
      );
      action = acceptEdit ? (
        <ExtraAction isUpload={isUpload} accept={accept} reject={reject} />
      ) : (
        <Text align="center" color="red" type="h4">
          {i18next.t('orderHistories.error.unAuth')}
        </Text>
      );
      break;
    case 'design_in_progress':
      acceptEdit = order.designerId === user.id || orderStatus?.isAccess;
      content = acceptEdit ? (
        <DesignInprogressAction
          acceptEdit={acceptEdit}
          onChangeProducts={onChangeProducts}
          setNote={setNote}
          setSelectedUser={setSelectedUser}
          item={item}
          note={note}
        />
      ) : (
        <span />
      );
      action = acceptEdit ? (
        <ExtraAction isUpload={isUpload} accept={accept} reject={reject} />
      ) : (
        <Text align="center" color="red" type="h4">
          {i18next.t('orderHistories.error.unAuth')}
        </Text>
      );
      break;
    case 'verified':
      content = (
        <VerifyResultsAction
          disabled={!acceptEdit}
          onChangeProducts={onChangeProducts}
          setNote={setNote}
          setSelectedUser={setSelectedUser}
          item={item}
          note={note}
        />
      );
      break;

    case 'feedback':
      content = (
        <FeedbackAction
          onChangeProducts={onChangeProducts}
          setNote={setNote}
          setSelectedUser={setSelectedUser}
          item={item}
        />
      );
      break;
    case 'canceled':
      content = (
        <CancelAction
          onReopen={onReopen}
          setNote={setNote}
          setSelectedUser={setSelectedUser}
          item={item}
        />
      );
      action = null;
      break;
    case 'payment_progress':
      content = (
        <ActionForNextStepAction
          setNote={setNote}
          setSelectedUser={setSelectedUser}
          item={item}
          user={user}
        />
      );
      break;
    case 'pre_order':
      content = (
        <ActionForNextStepAction
          setNote={setNote}
          setSelectedUser={setSelectedUser}
          item={item}
          user={user}
        />
      );
      break;
    case 'done':
      content = (
        <DoneAction
          onReopen={onRenew}
          order={order}
          setNote={setNote}
          setSelectedUser={setSelectedUser}
          item={item}
        />
      );
      action = null;
      break;
    case 'designer_confirm':
      acceptEdit =
        orderStatus.isAccess || item.assignedUser?.indexOf(user.id) > -1;
      action = acceptEdit ? (
        <ExtraAction isUpload={isUpload} accept={accept} reject={reject} />
      ) : (
        <Text align="center" color="red" type="h4">
          {i18next.t('orderHistories.error.unAuth')}
        </Text>
      );
      content = '';
      break;
    case 'delivery':
      content = '';
      break;
    case 'waiting_approved':
    default:
      content = '';
  }

  return !acceptEdit ? (
    <ActionForNextStepWrapper>
      <div className="header">
        <Text align="center" color="red" type="h4">
          {i18next.t('orderHistories.error.unAuth')}
        </Text>
        <div className="header">
          <Deadline order={order} />
          <DealPrice order={order} style={{ marginLeft: 10 }} />
        </div>
      </div>
    </ActionForNextStepWrapper>
  ) : (
    <ActionForNextStepWrapper>
      <div className="header">
        <Text align="left" type="h4">
          {i18next.t(
            `orderHistories.status.titleActions.${
              orderStatus && orderStatus?.value
            }`,
          )}
        </Text>
        <div className="header">
          <Deadline order={order} />
          <DealPrice order={order} style={{ marginLeft: 10 }} />
        </div>
      </div>
      <br />
      {item.deadLine && (
        <h1 style={{ color: 'red' }}>
          {`Dead Line: ${formatDateTime(item.deadLine)}`}
        </h1>
      )}
      {(item.note || item.report) && (
        <PreviewTextEditor html={item.note || item.report} />
      )}
      {acceptEdit && <div className="contentAction">{content}</div>}
      {action}
      {isUpload && <Progress percent={processing} />}
    </ActionForNextStepWrapper>
  );
};

export const ActionForNextStepAction = ({
  acceptEdit,
  setSelectedUser,
  setNote,
  assignResource = 'designers',
  order,
  user,
}) => (
  <div>
    <ReferenceInput searchKey="displayName" resource={assignResource}>
      <SelectUser disabled={!acceptEdit} onChange={setSelectedUser} />
    </ReferenceInput>
    {order?.status === _ORDER_STATUS[0].value &&
      user?.permission?.orders?.indexOf('recommendDesigner') > -1 && (
        <GetSuggestionDesigner order={order} />
      )}
    <TextEditor
      disabled={!acceptEdit}
      style={{ marginTop: 20 }}
      placeholder={i18next.t('note')}
      onEditorChange={(e) => {
        setNote(e);
      }}
    />
  </div>
);

const validateCreateNextStep = (request) => {
  let isValid = true;
  switch (request.status) {
    case 'designer_confirm':
      isValid = request.assignedUser;
      !isValid &&
        notification.error({
          message: i18next.t('orderHistories.error.selectDesigner'),
        });
      break;
    // case 'verified':
    //   isValid = request.products.length > 0 && request.assignedUser;
    //   !isValid &&
    //     notification.error({
    //       message: i18next.t('orderHistories.error.addProducts'),
    //     });
    //   break;
    case 'canceled':
      break;
    case 'done':
      return true;
    default:
      return true;
  }
  return isValid;
};

ActionForNextStepAction.propTypes = {
  acceptEdit: PropTypes.bool,
  setSelectedUser: PropTypes.func,
  setNote: PropTypes.func,
  assignResource: PropTypes.array,
};

ActiveForNextStep.propTypes = {
  orderHistories: PropTypes.array,
  user: PropTypes.object,
  editOrderHistories: PropTypes.func,
  createOrderHistories: PropTypes.func,
  order: PropTypes.object,
  getOrder: PropTypes.func,
  productTypes: PropTypes.array,
};

ActiveForNextStep.defaultProps = {
  orderHistories: [],
  user: {},
};

const mapStateToProps = (state, props) => ({
  orderHistories: crudSelectors.orderHistories.getDataArr(state),
  error: state.orderHistories.error,
  user: state.auth.data,
  productTypes: state.config.productTypes,
});

const mapDispatchToProps = (dispatch, props) => ({
  createOrderHistories: (data) =>
    dispatch(
      OrderHistoriesAction.createOrderHistories(data, { isBack: false }),
    ),
  editOrderHistories: (data) =>
    dispatch(OrderHistoriesAction.editOrderHistories(data, { isBack: false })),
  getOrder: (data) => dispatch(OrdersAction.getByIdOrders(data)),
  createOrder: (data) => dispatch(OrdersAction.createOrders(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ActiveForNextStep);
