import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import { SketchPicker } from 'react-color';
import FormItemUI from '../FormItem';

class RestColorPicker extends React.Component {
  handleChangeComplete = color => {
    this.props.form.setFieldsValue({ [this.props.source]: color.hex });
  };

  render() {
    const { form, source, defaultValue } = this.props;
    const colorStr = form.getFieldValue(source);
    return (
      <Row>
        <Col span={16}>
          <FormItemUI {...this.props} defaultValue={defaultValue}>
            {/* <Popover
          content={}
          placement="bottom"
          trigger="click"
        >
          <Button block style={{ textAlign: 'left', color: colorStr || '#ddd' }}>
            <div>{colorStr || '#ddd'}</div>
          </Button>
        </Popover> */}
            <SketchPicker
              color={colorStr}
              onChangeComplete={this.handleChangeComplete}
            />
          </FormItemUI>
        </Col>
        <Col span={8}>
          <div style={{ width: 100, height: 100, backgroundColor: colorStr }} />
        </Col>
      </Row>
    );
  }
}

RestColorPicker.propTypes = {
  form: PropTypes.object,
  record: PropTypes.object,
  source: PropTypes.string
};

export default RestColorPicker;
