import React, { useState } from 'react';
import { Modal, Button, Tabs, DatePicker } from 'antd';
import { useSelector } from 'react-redux';
import i18next from 'i18next';
import PieChar from '../../PieChar';

const AnalysisButton = ({ resource, getAnalysis }) => {
  const [isShow, setIsShow] = useState(false);
  const analysis = useSelector(state => state[resource].analysis);
  const getData = () => {
    setIsShow(true);
    getAnalysis();
  };
  const onClickCell = item => {
    // console.log(item);
  };

  const onCalendarChange = data => {
    data[0] &&
      data[1] &&
      getAnalysis({
        filter: {
          createdAt: {
            $gt: data[0].toISOString(),
            $lt: data[1].toISOString(),
          },
        },
      });
  };

  return (
    <div>
      <Button type="primary" icon="pie-chart" onClick={getData} style={{ marginRight: 10 }} />
      <Modal
        title={<DatePicker.RangePicker onCalendarChange={onCalendarChange} />}
        width={700}
        footer={null}
        visible={isShow}
        onCancel={() => setIsShow(false)}
      >
        <Tabs>
          {Object.keys(analysis).map(key => (
            <Tabs.TabPane tab={i18next.t(`report.${key}`)} key={key}>
              <PieChar onClickCell={onClickCell} resource={analysis[key]} />
            </Tabs.TabPane>
          ))}
        </Tabs>
      </Modal>
    </div>
  );
};

AnalysisButton.defaultProps = {
  source: 'analysis',
};

export default AnalysisButton;
