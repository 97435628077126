import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';
import { debounce } from 'lodash';
import FormItem from '../../form/FormItem';
import { getRecordData } from '../../../utils/tools';
import { RestInputContext } from '../RestInputContext';

const RestInputItem = ({ customHandleSubmit, ...props }) => (
  <RestInputContext.Consumer>
    {({ record, form, handleSubmit }) =>
      props.isReference ? (
        React.cloneElement(props.children, {
          record,
        })
      ) : (
        <FormItem
          {...props}
          form={form}
          defaultValue={
            getRecordData(record, props.source) || props.defaultValue
          }
        >
          {React.cloneElement(props.children, {
            record,
            handleSubmit,
            onBlur:
              props.isAutoUpdate && (handleSubmit || customHandleSubmit)
                ? debounce(
                    customHandleSubmit
                      ? () => customHandleSubmit(form.getFieldsValue())
                      : handleSubmit,
                    1000
                  )
                : () => {},
          })}
        </FormItem>
      )
    }
  </RestInputContext.Consumer>
);

RestInputItem.propTypes = {
  source: PropTypes.string,
  record: PropTypes.object,
  defaultValue: PropTypes.string,
  children: PropTypes.any,
  isAutoUpdate: PropTypes.bool,
  isReference: PropTypes.bool,
  customHandleSubmit: PropTypes.func,
};

RestInputItem.defaultProps = {
  children: <Input />,
};

export default RestInputItem;
