import React from 'react';
import RestShow from '../../../containers/rest/Show';
import RestFieldItem from '../../../components/RestField/RestFieldItem';

const OrderHistoriesShow = props => (
  <RestShow {...props} hasEdit resource="orderHistories">
    <RestFieldItem source="assigner" header="orderHistories.assigner" />
    <RestFieldItem source="assignedUser" header="orderHistories.assignedUser" />
    <RestFieldItem source="status" header="orderHistories.status" />
    <RestFieldItem source="orderId" header="orderHistories.orderId" />
  </RestShow>
);

export default OrderHistoriesShow;
