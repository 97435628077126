import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { DatePicker } from 'antd';
import FormItem from '../FormItem';
import { DateTimePickerWrapper } from './styles';

const { MonthPicker } = DatePicker;

const FormDatePicker = props => {
  const {
    required,
    showTime,
    formatDate,
    defaultValue,
    initialValue,
    form,
    pickerType,
  } = props;
  if (pickerType === 'month') {
    return (
      <DateTimePickerWrapper>
        <FormItem
          {...props}
          form={form}
          // formOptions={{
          //   getValueFromEvent: value => value.toISOString(),
          //   normalize: value => (value ? moment(value) : moment()),
          // }}
          ruleType="object"
          defaultValue={moment(defaultValue || initialValue)}
          className="title"
          required={required}
        >
          <MonthPicker format="MMM/YYYY" className="viewDatePicker" />
        </FormItem>
      </DateTimePickerWrapper>
    );
  }

  return (
    <DateTimePickerWrapper>
      <FormItem
        {...props}
        form={form}
        formOptions={{
          getValueFromEvent: value => value.toISOString(),
          normalize: value => (value ? moment(value) : moment()),
        }}
        ruleType="object"
        defaultValue={moment(defaultValue || initialValue)}
        className="title"
        required={required}
      >
        <DatePicker
          showTime={showTime}
          format={formatDate || 'MMM/DD/YYYY HH:mm'}
          className="viewDatePicker"
        />
      </FormItem>
    </DateTimePickerWrapper>
  );
};

FormDatePicker.propTypes = {
  source: PropTypes.string,
  header: PropTypes.any,
  required: PropTypes.bool,
  requiredMessage: PropTypes.node,
  icon: PropTypes.string,
  form: PropTypes.object,
  defaultValue: PropTypes.any,
  initialValue: PropTypes.object,
  formOptions: PropTypes.object,
  pickerType: PropTypes.string,
};

FormDatePicker.defaultProps = {
  formOptions: {},
  initialValue: moment(),
  pickerType: 'date',
};

export default FormDatePicker;
