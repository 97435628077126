import React from 'react';
import { Row, Col } from 'antd';
import { connect } from 'react-redux';
import RestInputItem from '../../../../components/RestInput/RestInputItem';
import RestDateTimePicker from '../../../../components/RestInput/RestDateTimePicker';

const UserOutsourceInfo = (props) => (
  <div {...props}>
    <RestInputItem
      disabled
      autoComplete="off"
      ruleType="email"
      source="email"
      header="email"
    />
    <RestInputItem disabled source="displayName" header="users.displayName" />
    <RestDateTimePicker
      disabled
      autoComplete="off"
      source="birthday"
      header="users.birthday"
    />
    <Row gutter={16}>
      <Col span={12}>
        <RestInputItem source="trelloKey" header="Trello Key" />
      </Col>
      <Col span={12}>
        <RestInputItem source="trelloToken" header="Trello Token" />
      </Col>
      <Col span={12}>
        <RestInputItem
          disabled
          autoComplete="off"
          source="linkFB"
          header="users.linkFB"
        />
      </Col>
    </Row>
  </div>
);

UserOutsourceInfo.propTypes = {};

export default connect((state) => ({
  roles: state.config.roles,
}))(UserOutsourceInfo);
