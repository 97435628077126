import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Comment, Button, Modal, Rate } from 'antd';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import i18next from 'i18next';
import CommentActions from '../../../../redux/comments/actions';
import { PreviewTextEditor } from '../../../../components/RestInput/RestTextEditor/index';
import Editor from '../CommentEditor';
import { CommentItemWrapper } from './styles';

const CommentItem = (props) => {
  const [isEdit, setIsEdit] = useState(false);
  const user = useSelector((state) => state.auth.data);
  const dispatch = useDispatch();
  const onSubmit = (content, rate) => {
    setIsEdit(false);
    console.log(props);
    return new Promise((resolve, reject) => {
      dispatch(
        CommentActions.editComments(
          {
            id: props.id,
            content,
            rate: props.rate ? rate : undefined,
          },
          {
            isBack: false,
            ...props.initialOptions,
          },
        ),
      )
        .then((e) => {
          resolve(e);
          props.editCallback && props.editCallback();
        })
        .catch((e) => {
          reject(e);
        });
    });
  };

  const onDelete = () => {
    Modal.confirm({
      title: i18next.t('comments.deleteTitle'),
      onCancel: () => {},
      onOk: () => {
        dispatch(
          CommentActions.deleteComments(
            {
              id: props.id,
            },
            {
              isBack: false,
              ...props.initialOptions,
            },
          ),
        ).then((e) => {
          props.editCallback && props.editCallback();
        });
      },
    });
  };

  return !isEdit ? (
    <CommentItemWrapper className={props.index === 0 ? 'firstItem' : ''}>
      <Comment
        {...props}
        author={
          user.isOutsource === false && props.user && props.user.displayName
        }
        avatar={
          (props.user && props.user.avatar) ||
          'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png'
        }
        content={
          <Fragment>
            {props.rate && <Rate value={props.rate} disabled />}
            <PreviewTextEditor html={props.content} />
          </Fragment>
        }
        datetime={moment(props.createdAt).fromNow()}
      />
      {user && user.id === props[props.reviewerIdKey] && (
        <Fragment>
          <Button
            type="primary"
            style={{ position: 'absolute', bottom: 20, right: 50 }}
            shape="circle"
            icon="edit"
            onClick={() => setIsEdit(!isEdit)}
          />
          <Button
            type="danger"
            style={{ position: 'absolute', bottom: 20, right: 10 }}
            shape="circle"
            icon="delete"
            onClick={() => onDelete()}
          />
        </Fragment>
      )}
    </CommentItemWrapper>
  ) : (
    <Editor
      hasRate={Number.isInteger(props.rate)}
      value={props.content}
      initRate={props.rate}
      onSubmit={onSubmit}
    />
  );
};
CommentItem.propTypes = {
  reviewerIdKey: PropTypes.string,
};

CommentItem.defaultProps = {
  reviewerIdKey: 'userId',
};

export default CommentItem;
