import React, { useLayoutEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import { groupBy, sortBy } from 'lodash';
import { MIN_PRICE } from 'configs/localData';
import LineChart from '../../LineChart';
import crudActions from '../../../redux/crudActions';

const AnalysisLineChart = ({
  initialFilter = {},
  defaultOptions = {},
  resource,
  chartName,
  filter,
  hasRequestData,
  redirectWhenClick,
}) => {
  const analysis = useSelector(state => state[resource].analysis);
  const dispatch = useDispatch();
  const getAnalysis = e =>
    dispatch(
      crudActions.channels.analysisChannels(
        {
          ...initialFilter,
          ...e,
        },
        { ...defaultOptions },
      ),
    );
  const onClickCell = item => {
    // console.log(item);
    dispatch(
      push(
        redirectWhenClick === 'screen'
          ? `/${resource}/${item.id}/edit`
          : `#${resource}/${item.id}/edit`,
      ),
    );
  };

  useLayoutEffect(() => {
    hasRequestData && getAnalysis();
    return () => {};
    // eslint-disable-next-line
  }, []);

  useLayoutEffect(() => {
    hasRequestData && getAnalysis({ filter });
    return () => {};
    // eslint-disable-next-line
  }, [filter]);
  return (
    <LineChart onClickCell={onClickCell} resource={formatAnalysis(analysis)} />
  );
};

const formatAnalysis = data => {
  const numCustomerPieChar = groupBy(data.numCustomerPieChar, 'name');
  const numOrderPieChar = groupBy(data.numOrderPieChar, 'name');
  const revenuePieChar = groupBy(data.revenuePieChar, 'name');
  return sortBy(
    Object.keys(revenuePieChar).map(key => ({
      ...revenuePieChar?.[key]?.[0],
      totalCustomer: numCustomerPieChar?.[key]?.[0]?.value,
      totalOrder: numOrderPieChar?.[key]?.[0]?.value,
      totalRevenue: revenuePieChar?.[key]?.[0]?.value / MIN_PRICE,
    })),
    'totalRevenue',
  );
};

AnalysisLineChart.propTypes = {
  initialFilter: PropTypes.object,
  defaultOptions: PropTypes.object,
  resource: PropTypes.string,
  chartName: PropTypes.string,
  filter: PropTypes.object,
};

AnalysisLineChart.defaultProps = {
  resource: 'channels',
};

export default AnalysisLineChart;
