import React from 'react';
// import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import DefaultBarChart from '../../../components/BarChart/DefaultBarChart';

const DesignerRevenue = () => {
  const dispatch = useDispatch();
  const revenuePieChar = useSelector(
    state => state.designers.analysis.revenuePieChar
  );

  const onClick = e => {
    dispatch(push(`/users/${e.id}/edit`));
  };

  return <DefaultBarChart onClick={onClick} resource={revenuePieChar || []} />;
};
DesignerRevenue.propTypes = {};

export default DesignerRevenue;
