import React from 'react';
import Create from '../../../containers/rest/Create';
import Form from '../components/Form';

const PackagesCreate = props => (
  <Create
    {...props}
    formatOnSubmit={data => ({
      ...data,
      finishDate: data.finishDate && data.finishDate.toISOString(),
    })}
    resource="packages"
  >
    <Form />
  </Create>
);

PackagesCreate.propTypes = {};

export default PackagesCreate;
