import React, { useLayoutEffect } from 'react';
import { Row, Col } from 'antd';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import SummaryCard from '../../components/common/SummaryCard';
import { formatMoney, formatShortNumber } from '../../utils/textUtils';
import { getSummariesDashboard as getSummariesDashboardAction } from '../../redux/summaries/actions';

const Summaries = ({ filter }) => {
  const summaries = useSelector(state => state.summaries.boards);
  const user = useSelector(state => state.auth.data);
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    user.isOutsource === false && dispatch(getSummariesDashboardAction());
  }, [user,dispatch]);

  useLayoutEffect(() => {
    dispatch(getSummariesDashboardAction({ filter }));
  }, [filter,dispatch]);

  return (
    <Row gutter={16}>
      <Col md={6}>
        <SummaryCard
          value={formatShortNumber(summaries && summaries.totalPriceDoneOrder)}
          header="summaries.revenue"
          color="#9b28b0"
          icon="dollar"
        />
      </Col>
      <Col md={6}>
        <SummaryCard
          value={summaries && summaries.totalDoneOrder}
          header="summaries.ordersDone"
          color="#2ec0d5"
          icon="reconciliation"
        />
      </Col>
      <Col md={6}>
        <SummaryCard
          value={formatMoney(summaries && summaries.ratioPayment * 100)}
          header="summaries.ratioPayment"
          color="#03a9f4"
          icon="percentage"
        />
      </Col>
      <Col md={6}>
        <SummaryCard
          value={formatMoney(summaries && summaries.coefOut)}
          header="summaries.coefOut"
          color="#7ed321"
          icon="export"
        />
      </Col>
    </Row>
  );
};
Summaries.propTypes = {
  filter:PropTypes.object
};

export default Summaries;
