import React from 'react';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import { getRecordData } from '../../../utils/tools';

const RestFieldItem = ({
  record,
  source,
  format,
  formatSubmitData,
  valueProp,
  component,
  onChangeRecord,
  loading,
  hasDebounce,
  canUpdate,
}) => {
  const onChange = value => {
    onChangeRecord(formatSubmitData(value));
  };
  const debounceOnChange = debounce(onChange, 400);
  const element = React.cloneElement(component, {
    record,
    [valueProp]: format(getRecordData(record, source), record, onChange),
    // eslint-disable-next-line
    onChange: canUpdate ? (hasDebounce ? debounceOnChange : onChange) : undefined,
    loading,
  });
  // element.setAttributeNode('update', e => {
  //   console.log('e', e);
  // });
  return element;
};
RestFieldItem.propTypes = {
  record: PropTypes.any,
  source: PropTypes.string,
  format: PropTypes.func,
  formatSubmitData: PropTypes.func,
  onChangeRecord: PropTypes.func,
  canUpdate: PropTypes.bool,
};

RestFieldItem.defaultProps = {
  format: data => data,
  formatSubmitData: data => data,
  onChangeRecord: () => {},
  component: <span />,
  valueProp: 'children',
  hasDebounce: true,
  canUpdate: true,
};

export default RestFieldItem;
