import React, { useLayoutEffect } from 'react';
import { Row, Col } from 'antd';
import i18next from 'i18next';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import Edit from '../../../containers/rest/Edit';
import OrderDetail from '../components/OrderDetail';
import OrderActivity from '../components/OrderActivity';
import OrderBreadcrumb from '../components/OrderBreadcrumb';
import EmployeeInOrder from '../components/EmployeeInOrder';
import OrderActions from '../../../redux/orders/actions';

const OrdersEdit = (props) => {
  const dispatch = useDispatch();

  useLayoutEffect(
    () => () => {
      dispatch(OrderActions.cleanCurrentOrders());
    },
    [dispatch],
  );
  return (
    <Edit
      {...props}
      initOptions={{
        successDescription: i18next.t('orders.updateSuccess', {
          id: props.match.params.id,
        }),
        isBack: false,
        isShowSuccessNoti: true,
        params: {
          includes: 'designer,sale,dealer,deliver,qualityAssurance,customer',
        },
      }}
      noCardWrapper
      customEditButton={null}
      resource="orders"
      formatOnSubmit={(values) => ({
        ...values,
        finishedDate:
          values.finishedDate && moment(values.finishedDate).toISOString(),
        thumbnail: values.thumbnail.previewImage || values.thumbnail,
      })}
    >
      <OrderBreadcrumb match={props.match} />
      <EmployeeInOrder />
      <br />
      <Row gutter={16}>
        <Col sm={12} md={8}>
          <OrderDetail />
        </Col>
        <Col sm={12} md={16}>
          <OrderActivity {...props} resource="orderHistories" />
        </Col>
      </Row>
    </Edit>
  );
};

OrdersEdit.propTypes = {};

export default OrdersEdit;
