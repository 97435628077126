import styled from 'styled-components';

export default styled.div`
  ul {
    padding: 0px;
  }
  li {
    list-style: none;
  }
`;
