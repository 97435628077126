import { takeLatest, put, call, fork, select } from 'redux-saga/effects';
import _ from 'lodash';
import { goBack } from 'connected-react-router';
import { apiWrapper } from '../../utils/reduxUtils';
import {
  getAllApi,
  getDataByIdApi,
  postApi,
  putApi,
  delApi,
  getAnalysis,
} from '../../api/crud';
import { makeActionName } from '../../utils/textUtils';
import { PRIMARY_KEY, CRUD_ACTIONS } from './actions';
import { convertResponseData, convertRequestParams } from './dataProvider';
import { closeModal } from '../modal/actions';
import { DEFERRED } from '../ExposedPromiseMiddleware';
import { randomColor } from '../../utils/tools';

function* getAllSaga(
  data,
  options = {},
  resource,
  successAction,
  failureAction,
  primaryKey
) {
  try {
    const { limit, page, filter, includes } = yield select(
      (state) => state[resource]
    );
    const convertRequest = convertRequestParams(
      'GET_ALL',
      {
        limit,
        offset: limit * (page - 1),
        filter,
        includes,
        ...data,
      },
      resource,
      { primaryKey }
    );
    const response = yield call(
      apiWrapper,
      { isShowProgress: options.isShowProgress },
      getAllApi,
      options.customApi || resource,
      convertRequest
    );
    const result = convertResponseData('GET_ALL', response, { primaryKey });
    if (result.data) {
      yield put(
        successAction(
          {
            numberOfPages:
              result.total % limit !== 0
                ? Number((result.total / limit).toFixed(0)) + 1
                : result.total / limit,
            ...result,
          },
          options
        )
      );
    } else {
      yield put(failureAction(response));
    }
  } catch (error) {
    yield put(failureAction(error));
  }
}

function* getDataByIdSaga(
  data,
  options = { isRequestApi: true },
  resource,
  successAction,
  failureAction,
  primaryKey = PRIMARY_KEY
) {
  try {
    if (!options.isRequestApi) {
      yield put(successAction(data));
      return;
    }
    const convertRequest = convertResponseData('GET_BY_ID', data, {
      primaryKey,
    });
    const response = yield call(
      apiWrapper,
      { isShowProgress: options.isShowProgress },
      getDataByIdApi,
      options.customApi || resource,
      convertRequest[primaryKey],
      options?.params
    );
    const result = convertResponseData('GET_BY_ID', response, { primaryKey });
    if (result) {
      yield put(successAction(result, options));
    } else {
      yield put(failureAction(result));
    }
  } catch (error) {
    yield put(failureAction(error));
  }
}
// function* editSaga(data, resource, successAction, failureAction, getOne)
function* editSaga(
  data,
  options = { isBack: true },
  resource,
  successAction,
  failureAction,
  primaryKey = PRIMARY_KEY,
  deferred,
  getAll
) {
  // delete data.c
  try {
    const { limit, page, filter, includes, orderBy } = yield select(
      (state) => state[resource]
    );
    const currentModal = yield select((state) => state.router.location.hash);
    const requestParams = convertRequestParams('EDIT', data);
    const response = yield call(
      apiWrapper,
      { isShowProgress: options.isShowProgress, ...options },
      putApi,
      options.customApi || resource,
      options.customId || data[PRIMARY_KEY],
      requestParams
    );
    const result = convertResponseData('EDIT', response, { primaryKey });
    if (result) {
      deferred.resolve(result);
      yield put(successAction({ ...data, ...result }));
      // yield put(successAction({ ...data, ...result }));
      if (options.isBack) {
        yield put(currentModal ? closeModal() : goBack());
      }
      if (!options.unRefreshByRequest) {
        yield put(
          getAll(
            { limit, page, filter, includes, orderBy },
            { isRefresh: true }
          )
        );
      }
    } else {
      deferred.resolve({ error: response });
      yield put(failureAction({ ...data, ...response }));
    }
  } catch (error) {
    deferred.resolve({ error });
    yield put(failureAction(error));
    //
  }
}

function* createSaga(
  data,
  options = { isBack: true },
  resource,
  successAction,
  failureAction,
  primaryKey,
  deferred,
  getAll
) {
  try {
    const currentModal = yield select((state) => state.router.location.hash);
    const response = yield call(
      apiWrapper,
      {
        isShowProgress: options.isShowProgress,
        isShowSuccessNoti: options.isShowSuccessNoti,
        successDescription: options.successDescription,
      },
      postApi,
      options.customApi || resource,
      data
    );
    const result = convertResponseData('CREATE', response, { primaryKey });
    if (result) {
      yield put(successAction(result));
      deferred.resolve(result);
      if (options.isBack) {
        yield put(currentModal ? closeModal() : goBack());
      }

      if (!options.unRefetch) {
        const { limit, page, filter, includes } = yield select(
          (state) => state[resource]
        );
        yield put(
          getAll({ limit, page, filter, includes }, { isRefresh: true })
        );
      }
    } else {
      deferred.resolve({ error: response });
      yield put(failureAction(data, response));
    }
  } catch (error) {
    deferred.resolve({ error });
    yield put(failureAction(data, error));
  }
}

function* delSaga(
  data,
  options = {},
  resource,
  successAction,
  failureAction,
  primaryKey = PRIMARY_KEY,
  deferred
) {
  try {
    const response = yield call(
      apiWrapper,
      { isShowProgress: true },
      delApi,
      options.customApi || resource,
      data.path || data[PRIMARY_KEY],
      options.isSortDelete || false
    );
    const result = convertResponseData('DELETE', data, { primaryKey });
    if (result) {
      deferred.resolve(result);
      yield put(successAction(result || {}));
    } else {
      deferred.resolve({ error: response });
      yield put(failureAction(response));
    }
  } catch (error) {
    deferred.resolve({ error });
    yield put(failureAction(error));
  }
}

function* redoSaga(
  data,
  options = { isBack: false },
  resource,
  successAction,
  failureAction,
  primaryKey = PRIMARY_KEY,
  deferred
) {
  // delete data.c
  try {
    const response = yield call(
      apiWrapper,
      { isShowProgress: options.isShowProgress, isShowSuccessNoti: true },
      putApi,
      options.customApi || resource,
      data.path || data[PRIMARY_KEY],
      { isDelete: false }
    );
    const result = convertResponseData('DELETE', response, { primaryKey });
    if (result) {
      deferred.resolve(result);
      yield put(successAction({ ...data, ...result }));
      // yield put(successAction({ ...data, ...result }));
    } else {
      deferred.resolve({ error: response });
      yield put(failureAction({ ...data, ...response }));
    }
  } catch (error) {
    deferred.resolve({ error });
    yield put(failureAction(error));
    //
  }
}

function* analysisSaga(
  data,
  options = {},
  resource,
  successAction,
  failureAction,
  primaryKey,
  deferred
) {
  try {
    const requestParams = convertRequestParams('GET_ALL', data);
    const response = yield yield call(
      apiWrapper,
      { isShowProgress: options.isShowProgress, isShowSuccessNoti: false },
      getAnalysis,
      options.customApi || resource,
      requestParams
    );

    const formattedData = {};
    Object.keys(response).forEach((key) => {
      formattedData[key] = randomColor(
        _.sortBy(response[key], (e) => -e.value)
      );
    });
    yield put(successAction(formattedData));
    deferred.resolve(formattedData);
  } catch (error) {
    yield put(failureAction(error));
  }
}

const makeCRUDSagaCreator = (resource, actions, primaryKey) => {
  function* getAllSagaCreator({ data, options }) {
    yield fork(
      getAllSaga,
      data,
      options,
      resource,
      actions[
        makeActionName(`GET_ALL_${_.snakeCase(resource).toUpperCase()}_SUCCESS`)
      ],
      actions[
        makeActionName(`GET_ALL_${_.snakeCase(resource).toUpperCase()}_FAILURE`)
      ],
      primaryKey
    );
  }
  function* getDataByIdSagaCreator({ data, options }) {
    yield fork(
      getDataByIdSaga,
      data,
      options,
      resource,
      actions[
        makeActionName(
          `GET_BY_ID_${_.snakeCase(resource).toUpperCase()}_SUCCESS`
        )
      ],
      actions[
        makeActionName(
          `GET_BY_ID_${_.snakeCase(resource).toUpperCase()}_FAILURE`
        )
      ],
      primaryKey
    );
  }
  function* editSagaCreator({ data, options, [DEFERRED]: deferred }) {
    yield fork(
      editSaga,
      data,
      options,
      resource,
      actions[
        makeActionName(`EDIT_${_.snakeCase(resource).toUpperCase()}_SUCCESS`)
      ],
      actions[
        makeActionName(`EDIT_${_.snakeCase(resource).toUpperCase()}_FAILURE`)
      ],
      primaryKey,
      deferred,
      actions[makeActionName(`GET_ALL_${_.snakeCase(resource).toUpperCase()}`)]
    );
  }
  function* deleteSagaCreator({ data, options, [DEFERRED]: deferred }) {
    yield fork(
      delSaga,
      data,
      options,
      resource,
      actions[
        makeActionName(`DELETE_${_.snakeCase(resource).toUpperCase()}_SUCCESS`)
      ],
      actions[
        makeActionName(`DELETE_${_.snakeCase(resource).toUpperCase()}_FAILURE`)
      ],
      primaryKey,
      deferred
    );
  }
  function* createSagaCreator({ data, options, [DEFERRED]: deferred }) {
    yield fork(
      createSaga,
      data,
      options,
      resource,
      actions[
        makeActionName(`CREATE_${_.snakeCase(resource).toUpperCase()}_SUCCESS`)
      ],
      actions[
        makeActionName(`CREATE_${_.snakeCase(resource).toUpperCase()}_FAILURE`)
      ],
      primaryKey,
      deferred,
      actions[makeActionName(`GET_ALL_${_.snakeCase(resource).toUpperCase()}`)]
    );
  }
  function* redoSagaCreator({ data, options, [DEFERRED]: deferred }) {
    yield fork(
      redoSaga,
      data,
      options,
      resource,
      actions[
        makeActionName(`REDO_${_.snakeCase(resource).toUpperCase()}_SUCCESS`)
      ],
      actions[
        makeActionName(`REDO_${_.snakeCase(resource).toUpperCase()}_FAILURE`)
      ],
      primaryKey,
      deferred
    );
  }
  function* analysisSagaCreator({ data, options, [DEFERRED]: deferred }) {
    yield fork(
      analysisSaga,
      data,
      options,
      resource,
      actions[
        makeActionName(
          `ANALYSIS_${_.snakeCase(resource).toUpperCase()}_SUCCESS`
        )
      ],
      actions[
        makeActionName(
          `ANALYSIS_${_.snakeCase(resource).toUpperCase()}_FAILURE`
        )
      ],
      primaryKey,
      deferred
    );
  }
  const sagas = {
    GET_ALL: getAllSagaCreator,
    GET_BY_ID: getDataByIdSagaCreator,
    EDIT: editSagaCreator,
    DELETE: deleteSagaCreator,
    CREATE: createSagaCreator,
    REDO: redoSagaCreator,
    ANALYSIS: analysisSagaCreator,
  };
  return sagas;
};

const rootCRUDSaga = (
  resource,
  ignoreActions = ['CLEAR'],
  actions,
  primaryKey
) => {
  const sagaCreators = makeCRUDSagaCreator(resource, actions, primaryKey);
  const acceptActions = _.xor(CRUD_ACTIONS, [
    ...ignoreActions,
    'CLEAR',
    'CLEAN_CURRENT',
  ]);
  return acceptActions.map((data) =>
    takeLatest(
      `${data}_${_.snakeCase(resource).toUpperCase()}`,
      sagaCreators[data]
    )
  );
};

export default rootCRUDSaga;
