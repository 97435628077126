import React from 'react';
import { Row, Col, DatePicker, Input } from 'antd';
// import i18next from 'i18next';
import ReferenceInput from '../../../../containers/rest/ReferenceInput';
import RestInputItem from '../../../../components/RestInput/RestInputItem';
import RestSelect from '../../../../components/RestInput/RestSelect';

const customersForm = (props) => (
  <Row {...props} gutter={12}>
    <Col span={24}>
      <RestInputItem source="displayName" header="customers.displayName" />
      <RestInputItem source="email" header="customers.email" />
      <RestInputItem source="address" header="customers.address" />
      <RestInputItem source="phoneNumber" header="customers.phoneNumber" />
      <RestInputItem source="code" header="Order Code" />
    </Col>
    <Col span={12}>
      <RestInputItem
        ruleType="object"
        source="birthday"
        header="customers.birthday"
      >
        <DatePicker />
      </RestInputItem>
      <RestInputItem isReference source="contactOwner">
        <ReferenceInput
          source="contactOwner"
          valueProp="id"
          titleProp="name"
          resource="users"
          searchKey="q"
        >
          <RestSelect
            source="contactOwner"
            valueProp="id"
            titleProp="displayName"
            header="customers.contactOwner"
          />
        </ReferenceInput>
      </RestInputItem>
      <RestInputItem isReference source="channelId">
        <ReferenceInput
          source="channelId"
          valueProp="id"
          titleProp="name"
          resource="channels"
        >
          <RestSelect
            source="channelId"
            valueProp="id"
            titleProp="name"
            header="customers.channel"
          />
        </ReferenceInput>
      </RestInputItem>
      <RestInputItem isReference source="dealPipelineId">
        <ReferenceInput source="dealPipelineId" resource="dealpipelines">
          <RestSelect
            ruleType="number"
            source="dealPipelineId"
            valueProp="id"
            titleProp="name"
            header="customers.dealPipeline"
          />
        </ReferenceInput>
      </RestInputItem>
    </Col>
    <Col span={12}>
      <RestInputItem source="national" header="customers.national" />
      <RestInputItem source="facebookLink" header="customers.facebookLink" />
      <RestInputItem isReference source="customerSegmentationId">
        <ReferenceInput
          source="customerSegmentationId"
          valueProp="id"
          titleProp="name"
          resource="customerSegmentations"
        >
          <RestSelect
            source="customerSegmentationId"
            valueProp="id"
            titleProp="name"
            header="customers.customerSegmentation"
          />
        </ReferenceInput>
      </RestInputItem>
    </Col>
    <Col span={24}>
      <RestInputItem source="note" header="customers.note">
        <Input.TextArea />
      </RestInputItem>
    </Col>
  </Row>
);

customersForm.propTypes = {};

export default customersForm;
