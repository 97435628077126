import React from 'react';
import { Link } from 'react-router-dom';
import { Tag, Button, Modal } from 'antd';
import i18next from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FormatOrderFeature } from 'utils/renderUtils';
import PreviewImage from 'components/common/PreviewImage';
import ReferenceManyToMany from 'containers/rest/ReferenceManyToMany';
import GridPhotos from 'components/common/GridPhotos';
import List from '../../../containers/rest/List';
import RestFieldItem from '../../../components/RestField/RestFieldItem';
import { ORDER_STATUS } from '../../../configs/localData/index';
import { cleanIsCurrentRequest } from '../../../redux/orderHistories/actions';
import Reference from '../../../containers/rest/Reference';
import Deadline from '../../Orders/components/Deadline';
import DealPrice from '../../Orders/components/DealPrice';

const OrderHistoriesList = (props) => {
  const orderFeatures = useSelector((state) => state.config?.orderFeatures);
  const dispatch = useDispatch();
  return (
    <List
      {...props}
      isScroll={false}
      hasCreate={false}
      resource="orderHistories"
    >
      <RestFieldItem
        sorter
        hasSearch
        format={(id, record) => (
          <>
            <Link to={`/orders/${id}/edit`}>
              {`#${id}${
                record?.order?.codeCustomer
                  ? `\n(${record?.order?.codeCustomer})`
                  : ''
              }`}
            </Link>
            <br />
            <PreviewImage src={record?.order?.thumbnail} />
          </>
        )}
        source="orderId"
        filterKey="orderId"
        header="Order Id"
      />
      <RestFieldItem
        source="order.name"
        header="orders.name"
        format={(data, record) => (
          <>
            {data}
            <ReferenceManyToMany
              header="orders.product"
              source="productsId"
              resource="products"
              record={record.order}
            >
              <RestFieldItem
                format={(data, record) =>
                  record && record.productImages ? (
                    <GridPhotos images={record.productImages} />
                  ) : (
                    <GridPhotos images={data} />
                  )
                }
                source="images"
              />
            </ReferenceManyToMany>
          </>
        )}
      />
      <RestFieldItem
        filters={orderFeatures}
        format={(data, record) => (
          <FormatOrderFeature data={record} record={record.order} />
        )}
        source="order.orderFeature"
        header="orders.orderFeature"
      />
      <Reference
        filterKey="deadline"
        source="orderId"
        header="orders.deadLine"
        resource="orders"
      >
        <RestFieldItem
          format={(data, record) => <Deadline order={record} />}
          source="deadline"
        />
      </Reference>
      <RestFieldItem
        filters={
          props.isOutsource
            ? ORDER_STATUS.filter((e) => e.isShowOutsource)
            : ORDER_STATUS
        }
        format={(data) => {
          const status = ORDER_STATUS.find((item) => item.value === data) || {};
          return (
            <Tag
              style={{ color: status.color || '#000' }}
              color={status.background}
            >
              {status && i18next.t(`orderStatuses.${status.value}`)}
            </Tag>
          );
        }}
        source="status"
        header="orders.status"
      />
      <Reference
        filterKey="dealPrice"
        source="orderId"
        header="orders.dealPrice"
        resource="orders"
      >
        <RestFieldItem
          format={(data, record) => <DealPrice order={record || {}} />}
          source="dealPrice"
        />
      </Reference>
      <RestFieldItem
        format={(data, record) => (
          <Button
            type="danger"
            onClick={() => {
              Modal.confirm({
                title: i18next.t('orderHistories.modal.cleanRequest'),
                onOk() {
                  dispatch(cleanIsCurrentRequest(data, record));
                },
              });
            }}
            icon="delete"
          />
        )}
        source="id"
        header=""
      />
    </List>
  );
};

OrderHistoriesList.propTypes = {};

export default OrderHistoriesList;
