import React from 'react';
import PropTypes from 'prop-types';
// import I18n from 'i18next';
// import { Tooltip } from 'antd';
import { usePermissionAction } from 'hooks/usePermissionActions';
import { ButtonWrapper } from './styles';

const UndoButton = ({ modelResource, redoItem, record, source }) => {
  const isAccess = usePermissionAction(modelResource, 'update');
  return isAccess ? (
    // <Tooltip header={<IntlMessages id="tooltip.edit" />}>
    <ButtonWrapper
      source={source}
      icon="undo"
      onClick={() => redoItem(record ? record.id : '', { isDelete: false })}
    >
      {/* {I18n.t('button.edit')} */}
    </ButtonWrapper>
  ) : // </Tooltip>
  null;
};

UndoButton.propTypes = {
  redoItem: PropTypes.func,
  record: PropTypes.object,
  source: PropTypes.string,
  modelResource: PropTypes.string,
};

UndoButton.defaultProps = {
  source: 'edit',
};

export default UndoButton;
