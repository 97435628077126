import React from "react";
import PropTypes from "prop-types";
import { connect, useDispatch, useSelector } from "react-redux";

import { Card, Row, Col, Button, Input, Divider } from "antd";
import i18next from "i18next";
import RestAvatarInput from "components/RestInput/RestAvatarInput";
import RestInputItem from "components/RestInput/RestInputItem";
import { RestInputContext } from "../../../../components/RestInput/RestInputContext";
import { EmployeeWrapper } from "./styles";
import UserTag from "../../../../components/common/UserTag";
import ActiveForNextStep from "../ActionForNextStep";
import RestSelect from "../../../../components/RestInput/RestSelect";
import Text from "../../../../components/common/Text";
import OrderAction, { editOrders } from "../../../../redux/orders/actions";
import SearchByImageButton from "containers/SearchByImage/SearchByImageButton";

const EmployeeInOrder = ({ editOrder, user }) => {
  const dispatch = useDispatch();
  const orderFeatures = useSelector((state) => state.config?.orderFeatures);
  return (
    <RestInputContext.Consumer>
      {({ record, handleSubmit }) => (
        <EmployeeWrapper>
          <Row gutter={16} type="flex">
            <Col md={8} sm={24} xs={24} className="employeeView">
              <Card className="employeeInfo">
                <RestAvatarInput
                  style={{ width: 250, height: 150, borderRadius: 0 }}
                  defaultIcon="picture"
                  defaultText="picture"
                  hasCrop={false}
                  disabled={!user || user.isOutsource}
                  source="thumbnail"
                  onChange={() => {
                    handleSubmit();
                  }}
                />
                <div className="d-flex w-100 flex-center">
                  <SearchByImageButton
                    initImage={record?.thumbnail}
                    onLink={(selected) => {
                      editOrder({
                        id: record.id,
                        customerRequirements: `${record?.customerRequirements?.substring(
                          0,
                          record?.customerRequirements?.indexOf(
                            "<b>Link Order</b>:"
                          )
                        )}<b>Link Order</b>: ${selected
                          .map(
                            (item) =>
                              `<div><a href="/orders/${item}/edit">#${item}</a></div>`
                          )
                          .join("")}`,
                      });
                    }}
                  />
                </div>
                <Row>
                  <RestInputItem
                    disabled={!user || user.isOutsource}
                    source="name"
                    header="orders.name"
                  >
                    <Input
                      disabled={!user || user.isOutsource}
                      addonAfter={
                        !user.isOutsource ? (
                          <div
                            className="copy"
                            role="presentation"
                            onClick={handleSubmit}
                          >
                            {i18next.t("button.save")}
                          </div>
                        ) : null
                      }
                    />
                  </RestInputItem>
                </Row>
                <RestSelect
                  resourceData={orderFeatures}
                  ruleType="array"
                  selectProps={{ mode: "multiple" }}
                  source="orderFeature"
                  header="orders.orderFeature"
                  onChange={() => (data, value) => {
                    editOrder({ id: record.id, orderFeature: value });
                  }}
                />
                {!user.isOutsource && (
                  <>
                    <Text type="bodyTitle">{i18next.t("orders.employee")}</Text>
                    <div className="employeeRow">
                      <UserTag
                        className="itemRow"
                        prefix={i18next.t("orderHistories.employee.dealer")}
                        iconName="SaleB"
                        record={record?.dealer}
                      />
                      <UserTag
                        className="itemRow"
                        prefix={i18next.t("orderHistories.employee.sale")}
                        iconName="SaleB"
                        record={record?.sale}
                      />
                      <UserTag
                        className="itemRow"
                        prefix={i18next.t("orderHistories.employee.designer")}
                        iconName="PD"
                        record={record?.designer}
                      />
                      <UserTag
                        className="itemRow"
                        prefix={i18next.t(
                          "orderHistories.employee.qualityAssurance"
                        )}
                        iconName="LS"
                        record={record?.qualityAssurance}
                      />
                      <UserTag
                        className="itemRow"
                        prefix={i18next.t("orderHistories.employee.deliver")}
                        iconName="Delivery"
                        record={record?.deliver}
                      />
                      <UserTag
                        className="itemRow"
                        prefix={i18next.t("orderHistories.employee.gua")}
                        iconName="GUA"
                        record={record?.gua}
                      />
                    </div>
                  </>
                )}
                <Divider />
                {record?.linkDesigner?.map((e, index) => (
                  <div key={e}>
                    <a href={e} target="__blank">
                      <Button type="primary">
                        {`Original Upload Link ${index + 1}`}
                      </Button>
                    </a>
                    <br />
                    <br />
                  </div>
                ))}
              </Card>
            </Col>
            <Col md={16} sm={24} xs={24} className="employeeView">
              <Card className="employeeInfo">
                <ActiveForNextStep order={record} resource="orderHistories" />
              </Card>
            </Col>
          </Row>
        </EmployeeWrapper>
      )}
    </RestInputContext.Consumer>
  );
};

EmployeeInOrder.propTypes = {
  editOrder: PropTypes.func,
  user: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => ({
  editOrder: (data) =>
    dispatch(OrderAction.editOrders(data, { isBack: false })),
});

export default connect(
  (state) => ({
    user: state.auth.data,
  }),
  mapDispatchToProps
)(EmployeeInOrder);
