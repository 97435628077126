import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import I18n from 'i18next';
import { usePermissionAction } from 'hooks/usePermissionActions';
import { ButtonWrapper } from './styles';

const { confirm } = Modal;

const EditButton = ({
  source,
  deleteItem,
  modelResource,
  record,
  isSortDelete,
}) => {
  const isAccess = usePermissionAction(modelResource, 'delete');
  const onOk = () => {
    deleteItem(record.id, { isSortDelete });
  };

  const handleDelete = () => {
    confirm({
      title: I18n.t('popup.alertDelete'),
      content: I18n.t('popup.alertDeleteDes', {
        customMessage: `(#${record.id})`,
      }),
      okText: I18n.t('button.ok'),
      cancelText: I18n.t('button.cancel'),
      onOk: () => {
        onOk(source);
      },
      onCancel: () => {},
    });
  };

  return isAccess ? (
    <div>
      {/* <Tooltip header={<IntlMessages id="tooltip.delete" />}> */}
      <ButtonWrapper icon="delete" onClick={handleDelete}>
        {/* {I18n.t('button.delete')} */}
      </ButtonWrapper>
      {/* </Tooltip> */}
    </div>
  ) : null;
};

EditButton.propTypes = {
  deleteItem: PropTypes.func,
  record: PropTypes.object,
  source: PropTypes.string,
  isSortDelete: PropTypes.bool,
  modelResource: PropTypes.string,
};

EditButton.defaultProps = {
  source: 'delete',
};

export default EditButton;
