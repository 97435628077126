import React from 'react';
import Edit from '../../rest/Edit';
import Form from '../components/Form';

const SalariesEdit = props => (
  <Edit {...props} resource="salaries">
    <Form />
  </Edit>
);

SalariesEdit.propTypes = {};

export default SalariesEdit;
