import React, { useState, useEffect } from 'react';
import { Tabs } from 'antd';
import i18next from 'i18next';
// import UserTypesList from '../UserTypes/List';
import { useSelector, useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import Orders from 'pages/Orders/List';
import Customers from 'pages/Customers/List';
import PageTitle from 'components/common/PageTitle';

const { TabPane } = Tabs;

const TABS = [
  {
    key: 'orders',
    text: 'tabs.orders',
    url: '/orders',
    component: Orders,
    defaultOptions: { customApi: 'orders/getDelete', isRefresh: true },
  },
  {
    key: 'customers',
    text: 'tabs.customers',
    url: '/customers',
    component: Customers,
    defaultOptions: { customApi: 'customers/getDelete', isRefresh: true },
  },
];

const Deleted = (props) => {
  const user = useSelector((state) => state.auth.data);
  const [tabs, setTabs] = useState([]);
  const dispatch = useDispatch();
  const onChangeTab = (e) => {
    dispatch(push(`/deleted/${e}`));
  };

  useEffect(() => {
    setTabs(
      TABS.filter(
        (e) => user?.permission?.[e.key]?.indexOf('getDelete') > -1
      ) || []
    );
    // eslint-disable-next-line
  }, [user.permission]);

  return (
    <div>
      <PageTitle>{i18next.t('deleted.title')}</PageTitle>
      <Tabs
        onTabClick={onChangeTab}
        defaultActiveKey={props?.match?.params?.modal}
      >
        {tabs?.map((tab) => (
          <TabPane tab={i18next.t(tab.text)} key={tab.key}>
            <tab.component
              rootPath="/deleted"
              noCardWrapper
              layoutButtonCreate="no-inline"
              {...props}
              isDeleted
              defaultOptions={tab.defaultOptions}
              isSortDelete={false}
            />
          </TabPane>
        ))}
      </Tabs>
    </div>
  );
};

Deleted.propTypes = {};

export default Deleted;
