import React from 'react';
import Create from '../../../containers/rest/Create';
import Form from '../components/Form';

const BrandsCreate = props => (
  <Create {...props} resource="brands">
    <Form />
  </Create>
);

BrandsCreate.propTypes = {};

export default BrandsCreate;
