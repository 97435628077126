import React from 'react';
import RestShow from '../../../containers/rest/Show';
import RestFieldItem from '../../../components/RestField/RestFieldItem';
import { formatMoney } from '../../../utils/textUtils';

const PackagesShow = props => (
  <RestShow {...props} hasEdit resource="packages">
    <RestFieldItem source="name" header="packages.name" />
    <RestFieldItem source="customerId" header="packages.customerId" />
    <RestFieldItem source="channelId" header="packages.channelId" />
    <RestFieldItem source="orderCount" header="packages.orderCount" />
    <RestFieldItem source="orderUsed" header="packages.orderUsed" />
    <RestFieldItem format={data => formatMoney(data)} source="price" header="packages.price" />
    <RestFieldItem source="finishDate" header="packages.finishDate" />
    <RestFieldItem source="note" header="packages.note" />
    <RestFieldItem source="status" header="packages.status" />
  </RestShow>
);

export default PackagesShow;
