import React from 'react';
import RestShow from '../../../containers/rest/Show';
import RestFieldItem from '../../../components/RestField/RestFieldItem';

const ChannelsShow = props => (
  <RestShow {...props} hasEdit resource="channels">
    <RestFieldItem source="name" header="channels.name" />
  </RestShow>
);

export default ChannelsShow;
