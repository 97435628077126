import axios from 'axios';
import { post } from './utils';

const CLIENT_ID = '50b73e2dc3f6fb5';

export async function getUrl(key, type) {
  return post('/upload/signedUrlS3', { key, type });
}

export async function uploadFile(url, data) {
  return fetch(url, {
    method: 'PUT',
    body: data,
  }).then(() => url);
}

export async function uploadMedia(data) {
  console.log('data', data);
  const res = await getUrl(data.name, data.type);
  await uploadFile(res.uploadUrl, data);
  return res.url;
}

export async function uploadMediaWithImgBB(data) {
  const form = new FormData();
  form.append('image', data);
  return fetch(`https://api.imgbb.com/1/upload?expiration=600&key=baa746f0e3729e38babc66b31a2372c3`, {
    method: 'POST',
    headers: {
      // Authorization: `Client-ID ${CLIENT_ID}`,
    },
    body: form,
  }).then((res) => res.json());
}

export async function findProductWithImage(link) {
  return fetch(`https://find-order-by-url.toothlessai-tech.workers.dev/`, {
    method: 'POST',
    headers: {
      // Authorization: `Client-ID ${CLIENT_ID}`,
    },
    body: JSON.stringify({
      link,
    }),
  }).then((res) => {
    try {
      return res.json()
    } catch (error) {
      const text= res.text();
      return text;
    }
  });
}
export async function uploadFiles(data, onUploadProgress) {
  try {
    const res = await axios.request({
      method: 'post',
      url: `${process.env.REACT_APP_SERVER_URL}/file/uploadFiles`,
      headers: {
        'Content-Type': 'multipart/form-data',
        authorization: `Bearer ${localStorage.getItem('sessionToken')}`,
      },
      data,
      onUploadProgress: (p) => {
        onUploadProgress(Number((p.loaded / p.total) * 100).toFixed(2));
      },
    });
    return res.data;
  } catch (error) {
    return error;
  }
}

export async function uploadImages(data, onUploadProgress) {
  try {
    const images = data.get('images');
    // const resS3 = await uploadMedia(data);
    let res = null;
    console.log('resS3', images);
    if (Array.isArray(images)) {
      res = await Promise.all(images.map((e) => uploadMedia(e)));
    } else {
      const res1 = await uploadMedia(images);
      res = [res1];
    }
    // const res = await axios.request({
    //   method: 'post',
    //   url: `${process.env.REACT_APP_SERVER_URL}/file/uploadImages`,
    //   headers: {
    //     'Content-Type': 'multipart/form-data',
    //     authorization: `Bearer ${localStorage.getItem('sessionToken')}`,
    //   },
    //   data,
    //   onUploadProgress: (p) => {
    //     onUploadProgress &&
    //       onUploadProgress(Number((p.loaded / p.total) * 100).toFixed(2));
    //   },
    // });
    return res.map((e) => ({
      // ...e,
      image: e,
      previewImage: e,
    }));
  } catch (error) {
    return error;
  }
}
