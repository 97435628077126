import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'antd';
import TextEditor from 'components/common/TextEditor';
import i18next from 'i18next';
import Text from '../../../../components/common/Text';
import PreviewImage from '../../../../components/common/PreviewImage';
import SelectUser from '../../../../components/common/SelectUser';
import ReferenceInput from '../../../../containers/rest/ReferenceInput';
import OrderStatusTitle from './OrderStatusTitle';
import ReferenceManyToMany from '../../../../containers/rest/ReferenceManyToMany';
import RestFieldItem from '../../../../components/RestField/RestFieldItem';
import { PreviewTextEditor } from '../../../../components/RestInput/RestTextEditor/index';
import { RenderUploadLinkForVerify } from '../RederUploadLink';

const VerifyResults = ({ item }) => (
  <Card title={<OrderStatusTitle item={item} />} className="timelineItem">
    <ReferenceManyToMany record={item} source="products" resource="products">
      <RestFieldItem
        format={(data, record) =>
          record && record.productImages
            ? record.productImages.map((e, index) => <PreviewImage image={e} />)
            : data && data.map((e, index) => <PreviewImage src={e} />)
        }
        source="images"
      />
    </ReferenceManyToMany>
    <div>
      <br />
      <Text type="body">
        {i18next.t(
          `orderHistories.status.description.${item.status}.${item.accept}`,
        )}
      </Text>
      <br />
      <Text type="body">
        {item.note && <PreviewTextEditor html={item.note} />}
        {item.report && (
          <Text color="red" type="bodyTitle">
            {i18next.t('orderHistories.feedback')}
            :
            <br />
            <PreviewTextEditor html={item.report} />
          </Text>
        )}
      </Text>
    </div>
  </Card>
);

export const VerifyResultsAction = ({
  disabled,
  item,
  setSelectedUser,
  setNote,
  note,
}) => {
  const editorRef = useRef(null);
  return (
    <div>
      <div style={{ marginBottom: 20 }}>
        <ReferenceManyToMany
          record={item}
          source="products"
          resource="products"
        >
          <RestFieldItem
            format={(data) =>
              data && data.map((e) => <PreviewImage key={e} image={e} />)
            }
            source="images"
          />
        </ReferenceManyToMany>
      </div>
      <div>
        <RenderUploadLinkForVerify
          orderId={item.orderId}
          onAfterRender={(link) => {
            setNote(
              `${
                note.text || ''
              } <br/>Product Link: <a target="__blank" href=${link}>${link}</a>`,
            );
            editorRef.current && editorRef.current.setValue(note.text);
          }}
        />
        <Text align="center" className="itemRow" type="h5">
          {i18next.t('orderHistories.placeholder.selectDeliver')}
        </Text>
        <ReferenceInput className="itemRow" resource="delivers">
          <SelectUser
            disabled={disabled}
            placeholder={i18next.t('orderHistories.placeholder.selectDeliver')}
            onChange={setSelectedUser}
          />
        </ReferenceInput>
        <TextEditor
          ref={editorRef}
          disabled={disabled}
          className="itemRow"
          style={{ marginTop: 20 }}
          placeholder={i18next.t('note')}
          onEditorChange={(e) => {
            setNote(e);
          }}
        />
      </div>
    </div>
  );
};

VerifyResultsAction.propTypes = {
  item: PropTypes.object,
  setSelectedUser: PropTypes.func,
  setNote: PropTypes.func,
  disabled: PropTypes.bool,
  note: PropTypes.object,
};

VerifyResults.propTypes = {
  item: PropTypes.object,
};

VerifyResults.defaultProps = {
  item: {},
};

export default VerifyResults;
