import {
  makeCRUDConstantCreator,
  makeCRUDActionsCreator,
} from '../crudCreator/actions';
import { makeConstantCreator, makeActionCreator } from '../../utils/reduxUtils';

export const PRIMARY_KEY = 'id';
export const MODEL = 'notifications';
export const IGNORE_ACTIONS = [];
export const NotificationsTypes = {
  ...makeCRUDConstantCreator(MODEL, IGNORE_ACTIONS),
  ...makeConstantCreator(
    'CREATE_INSTALLATIONS',
    'CREATE_INSTALLATIONS_SUCCESS',
    'CREATE_INSTALLATIONS_FAILURE',
    'WATCH_ONE_SIGNAL_NOTIFICATION',
    'CLOSE_ONE_SIGNAL_NOTIFICATION',
    'UPDATE_ONE_SIGNAL_NOTIFICATION',
  ),
};
const CRUDNotificationsActions = makeCRUDActionsCreator(MODEL, IGNORE_ACTIONS);
export const createInstallations = data =>
  makeActionCreator(NotificationsTypes.CREATE_INSTALLATIONS, { data });
export const createInstallationsSuccess = data =>
  makeActionCreator(NotificationsTypes.CREATE_INSTALLATIONS_SUCCESS, { data });
export const createInstallationsFailure = data =>
  makeActionCreator(NotificationsTypes.CREATE_INSTALLATIONS_FAILURE, { data });

export const watchNotification = data =>
  makeActionCreator(NotificationsTypes.WATCH_ONE_SIGNAL_NOTIFICATION, { data });
export const closeNotification = data =>
  makeActionCreator(NotificationsTypes.CLOSE_ONE_SIGNAL_NOTIFICATION, { data });
export const updateNotification = data =>
  makeActionCreator(NotificationsTypes.UPDATE_ONE_SIGNAL_NOTIFICATION, {
    data,
  });

/**
 * getAllCaseTypes({pageSize, page })
 * getByIdCaseTypes(data)
 * createCaseTypes(data)
 * deleteCaseTypes()
 * editCaseTypes(data)
 */
export default { ...CRUDNotificationsActions };
