import React from 'react';
import { Typography } from 'antd';
import i18next from 'i18next';
import { useSelector } from 'react-redux';
import Edit from '../../../containers/rest/Edit';
import Form from '../components/Form';
import BarChart from '../../../components/BarChart';

const NichesEdit = props => {
  const niche = useSelector(state => state.niches.currentData);
  return (
    <Edit {...props} resource="niches">
      <Form />
      <div>
        <Typography.Title>{i18next.t('niches.analysis')}</Typography.Title>
        {niche.analysicChart && <BarChart resource={niche.analysicChart} />}
      </div>
    </Edit>
  );
};

NichesEdit.propTypes = {};

export default NichesEdit;
