import React from 'react';
import PropTypes from 'prop-types';
import { Avatar } from 'antd';
import i18next from 'i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import RestFieldItem from '../../../../components/RestField/RestFieldItem';
import Text from '../../../../components/common/Text';
import { formatDateTime } from '../../../../utils/textUtils';

const OrderStatusTitle = ({ item, user }) =>
  user && !user.isOutsource ? (
    <div className="headerItem">
      <RestFieldItem
        record={item.assigner}
        format={(data) => <Avatar src={data} />}
        source="avatar"
      />
      <div className="headerContent">
        <Text type="bodyTitle">
          {item.assigner && (
            <RestFieldItem record={item.assigner} source="displayName" />
          )}
          <Text type="bodyGray" inline>
            {i18next.t(`orderHistories.status.title.${item.status}`)}
          </Text>
          <ListUserName resourceData={item.assignedusers} />
        </Text>
        <Text type="smallText">{formatDateTime(item.createdAt)}</Text>
      </div>
    </div>
  ) : null;

const ListUserName = ({ resourceData }) =>
  resourceData ? (
    resourceData.map((data, index) => (
      <Link key={String(index)} to={`/users/${data && data.id}/edit`}>
        <Text type="bodyTitle" inline>
          {`${data && data.displayName}${
            index === resourceData.length - 1 ? '' : ', '
          }`}
        </Text>
      </Link>
    ))
  ) : (
    <span />
  );

ListUserName.propTypes = {
  resourceData: PropTypes.object,
};

OrderStatusTitle.propTypes = {
  item: PropTypes.object,
  user: PropTypes.string,
};

export default connect((state) => ({
  user: state.auth.data,
}))(OrderStatusTitle);
