import React from 'react';
import RestInputItem from '../../../../components/RestInput/RestInputItem';

const OrderRequestedsForm = props => (
  <div {...props}>
    <RestInputItem source="a" header="orderRequesteds.a" />
  </div>
);

OrderRequestedsForm.propTypes = {};

export default OrderRequestedsForm;
