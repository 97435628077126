import React from 'react';
import RestInputItem from '../../../../components/RestInput/RestInputItem';

const OrderRequirementsForm = props => (
  <div {...props}>
    <RestInputItem source="name" header="orderRequirements.name" />
    <RestInputItem source="description" header="orderRequirements.description" />
  </div>
);

OrderRequirementsForm.propTypes = {};

export default OrderRequirementsForm;
