import React from 'react';
import PropTypes from 'prop-types';
import { Tabs } from 'antd';
import i18next from 'i18next';
import { useSelector, useDispatch } from 'react-redux';
import Box from 'components/common/Box';
import SalaryHistories from 'containers/SalaryHistories/List';
import MistakeRecordsList from 'containers/MistakeRecords/List';
import PaymentsList from 'containers/Payments/List';
import { ClientActivityWrapper } from './styles';
import ListOrders from '../../../Orders/List';
import ListProducts from '../../../Products/List';
import { RestInputContext } from '../../../../components/RestInput/RestInputContext';
// import EmailLogs from '../EmailLogs';
// import CallLogs from '../CallLogs';
import OrderHistoriesList from '../../../OrderHistories/List/index';
import OrderRequesteds from '../../../OrderRequesteds/List/index';
import UserCommentsList from '../../../Comments/List';
import { EmployeeKeys } from '../../../../configs/localData';
import CommentActions from '../../../../redux/comments/actions';
import UsersActions from '../../../../redux/users/actions';
import WorkingRegisters from '../../../../containers/WorkingRegisters/Create';
import UserRequestsList from '../../../../containers/UserRequests/List/index';

const { TabPane } = Tabs;

const ClientActivity = ({ match, isProfile, customId, location }) => {
  const user = useSelector((state) => state.auth.data);
  const currentUser = useSelector((state) => state.users.currentData);
  const dispatch = useDispatch();

  const ACTIVITY_FORMS = [
    {
      text: 'customers.detail.activityLog.orderRequested',
      key: 'orderRequested',
      component: user.isOutsource ? OrderRequesteds : OrderHistoriesList,
      filterKey: () => 'assignedUser',
      defaultFilter: {
        isCurrent: true,
      },
      includes: 'order',
      profileDefaultOptions: { customApi: 'orderHistories/me' },
    },
    {
      text: 'customers.detail.activityLog.orders',
      key: 'activityLog',
      component: ListOrders,
      filterKey: (e) => EmployeeKeys[e],
      profileDefaultOptions: { customApi: 'orders/me' },
    },
    {
      text: 'customers.detail.activityLog.products',
      key: 'product',
      component: ListProducts,
      filterKey: () => 'creatorId',
      profileDefaultOptions: { customApi: 'products/me' },
    },
    {
      text: 'salaryHistories.salary',
      key: 'salaryHistories',
      component: SalaryHistories,
      filterKey: () => 'userId',
      profileDefaultOptions: { customApi: 'salaryHistories/me' },
    },
    {
      text: 'userRequests.header',
      key: 'userRequests',
      component: UserRequestsList,
      filterKey: () => 'userId',
      profileDefaultOptions: { customApi: 'user-requests' },
    },
    // {
    //   text: 'customers.detail.activityLog.summary',
    //   key: 'productAttended',
    //   component: null,
    // },
    // {
    //   text: 'customers.detail.activityLog.email',
    //   key: 'emailLog',
    //   component: EmailLogs,
    // },
    // {
    //   text: 'customers.detail.activityLog.call',
    //   key: 'callLog',
    //   component: CallLogs,
    // },
  ];

  const getCurrentUser = () => {
    dispatch(UsersActions.getByIdUsers({ ...currentUser }));
  };

  const createComments = (content, rate) =>
    new Promise((resolve, reject) => {
      dispatch(
        CommentActions.createComments(
          {
            content,
            rate,
            userId: customId === 'me' ? user.id : match.params.id,
          },
          { isBack: false, customApi: 'userComments' },
        ),
      )
        .then((e) => {
          resolve(e);
          getCurrentUser();
        })
        .catch((e) => {
          reject(e);
        });
    });

  return (
    <RestInputContext.Consumer>
      {({ record = {} }) => (
        <ClientActivityWrapper>
          <Tabs>
            {ACTIVITY_FORMS.map((tab) => (
              <TabPane tab={<span>{i18next.t(tab.text)}</span>} key={tab.key}>
                {(record.roleId && record.id) || customId ? (
                  <tab.component
                    location={location}
                    isUserDetail
                    noCardWrapper
                    isUpdateRoute={false}
                    initialFilter={{
                      includes: tab.includes,
                      filter:
                        tab.filterKey && !customId
                          ? {
                              ...tab.defaultFilter,
                              [tab.filterKey(
                                isProfile ? user.roleId : record.roleId,
                              )]: {
                                $in: [record.id || user.id],
                              },
                            }
                          : tab.defaultFilter,
                    }}
                    defaultOptions={
                      isProfile ? tab.profileDefaultOptions : undefined
                    }
                  />
                ) : (
                  ''
                )}
              </TabPane>
            ))}
            {user.isOutsource && (
              <TabPane key="payments" tab="Payments">
                <PaymentsList
                  match={match}
                  createComments={createComments}
                  hiddenInput={customId}
                  initialQuery={{
                    filter: {
                      clientId: customId === 'me' ? user.id : match.params.id,
                    },
                  }}
                />
              </TabPane>
            )}
            <TabPane
              key="comments"
              tab={i18next.t('customers.detail.activityLog.comments')}
            >
              <UserCommentsList
                match={match}
                createComments={createComments}
                hasRate
                hiddenInput={customId}
                initialQuery={{
                  filter: {
                    userId: customId === 'me' ? user.id : match.params.id,
                  },
                  includes: 'reviewer',
                }}
                initialOptions={
                  isProfile
                    ? { customApi: 'userComments/me' }
                    : { customApi: 'userComments' }
                }
                reviewerIdKey="reviewerId"
                editCallback={getCurrentUser}
              />
            </TabPane>
            <TabPane
              key="workingTimes"
              tab={i18next.t('workingRegisters.title')}
            >
              <Box>
                <WorkingRegisters
                  location={location}
                  match={match}
                  noCardWrapper
                  defaultOptions={{
                    customApi: 'workingRegisters/user',
                    isShowSuccessNoti: true,
                  }}
                  userId={customId === 'me' ? user.id : match.params.id}
                />
              </Box>
            </TabPane>

            <TabPane
              key="mistakeRecords"
              tab={i18next.t('mistakeRecords.title')}
            >
              <MistakeRecordsList
                match={match}
                initialFilter={{
                  filter: {
                    victimId: customId === 'me' ? user.id : match.params.id,
                  },
                  includes: 'mistakeType,creator',
                }}
                isDesigner
                isUpdateRoute={false}
                initialOptions={
                  isProfile
                    ? { customApi: 'mistakeRecords/me' }
                    : { customApi: 'mistakeRecords' }
                }
              />
            </TabPane>
          </Tabs>
        </ClientActivityWrapper>
      )}
    </RestInputContext.Consumer>
  );
};
ClientActivity.propTypes = {
  match: PropTypes.object,
};

export default ClientActivity;
