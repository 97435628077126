import styled from 'styled-components';

export default styled.div`
  td {
    height: 164px;
  }
  th {
    height: 70px;
  }
`;
