import React from 'react';
import Create from '../../rest/Create';
import Form from '../components/Form';

const UserRequestsCreate = (props) => (
  <Create
    {...props}
    defaultOptions={{ unRefetch: true, customApi: 'user-requests' }}
    resource="userRequests"
  >
    <Form />
  </Create>
);

UserRequestsCreate.propTypes = {};

export default UserRequestsCreate;
