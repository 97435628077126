import React, { useState } from 'react';
// import PropTypes from 'prop-types';
import { Tabs, Typography } from 'antd';
import i18next from 'i18next';
import { useSelector } from 'react-redux';
import NumOrder from './NumOrder';
import Revenue from './Revenue';
import Summaries from '../Dashboard/Summaries';
import FilterReports from './Filter';
import ReportWrapper from './styles';

const Reports = () => {
  const [filter, setFilter] = useState(null);
  const user = useSelector((state) => state.auth.data);

  return (
    <ReportWrapper>
      <Typography.Title>{i18next.t('report.title')}</Typography.Title>
      <FilterReports setFilter={setFilter} filter={filter} />
      {user.scope === 'manager' && <Summaries filter={filter} />}
      <Tabs>
        <Tabs.TabPane key="numOrder" tab={i18next.t('report.numOrderPieChar')}>
          <NumOrder user={user} filter={filter} />
        </Tabs.TabPane>
        <Tabs.TabPane key="revenue" tab={i18next.t('report.revenuePieChar')}>
          <Revenue />
        </Tabs.TabPane>
      </Tabs>
    </ReportWrapper>
  );
};
Reports.propTypes = {};

export default Reports;
