import React, { useLayoutEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import PieChar from '../../PieChar';
import crudActions from '../../../redux/crudActions';
import { upperCaseFirstChart } from '../../../utils/tools';

const AnalysisPieChart = ({
  initialFilter = {},
  defaultOptions = {},
  resource,
  chartName,
  filter,
  hasRequestData,
  redirectWhenClick,
}) => {
  const analysis = useSelector(state => state[resource].analysis[chartName]);
  const dispatch = useDispatch();
  const getAnalysis = e =>
    dispatch(
      crudActions[resource][`analysis${upperCaseFirstChart(resource)}`](
        {
          ...initialFilter,
          ...e,
        },
        { ...defaultOptions },
      ),
    );
  const onClickCell = item => {
    // console.log(item);
    dispatch(
      push(
        redirectWhenClick === 'screen'
          ? `/${resource}/${item.id}/edit`
          : `#${resource}/${item.id}/edit`,
      ),
    );
  };

  useLayoutEffect(() => {
    hasRequestData && getAnalysis();
    return () => {};
    // eslint-disable-next-line
  }, []);

  useLayoutEffect(() => {
    hasRequestData && getAnalysis({ filter });
    return () => {};
    // eslint-disable-next-line
  }, [filter]);
  return <PieChar onClickCell={onClickCell} resource={analysis || []} />;
};

AnalysisPieChart.propTypes = {
  initialFilter: PropTypes.object,
  defaultOptions: PropTypes.object,
  resource: PropTypes.string,
  chartName: PropTypes.string,
  filter: PropTypes.object,
};

AnalysisPieChart.defaultProps = {};

export default AnalysisPieChart;
