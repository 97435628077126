import React from 'react';
import RestShow from '../../../containers/rest/Show';
import RestFieldItem from '../../../components/RestField/RestFieldItem';

const DealersShow = props => (
  <RestShow {...props} hasEdit resource="dealers">
    <RestFieldItem source="displayName" header="dealers.displayName" />
  </RestShow>
);

export default DealersShow;
