import React, { useLayoutEffect } from 'react';
import { Comment, Avatar, List, Button } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import CRUDSelectors from '../../../redux/crudSelectors';
import CommentActions from '../../../redux/comments/actions';
import Editor from '../components/CommentEditor';
import CommentItem from '../components/CommentItem';

const CommentList = ({
  editCallback,
  match,
  reviewerIdKey,
  orderId,
  initialOptions = {},
  initialQuery = {},
}) => {
  const comments = useSelector(CRUDSelectors.comments.getDataArr);
  const dispatch = useDispatch();
  const enabledLoadMore = useSelector(CRUDSelectors.comments.enabledLoadMore);

  const loadMore = () => {
    enabledLoadMore &&
      dispatch(
        CommentActions.getAllComments(
          {
            filter: {
              orderId,
            },
            includes: 'user',
            orderBy: '-createdAt',
            ...initialQuery,
          },
          { ...initialOptions },
        ),
      );
  };

  useLayoutEffect(() => {
    dispatch(
      CommentActions.getAllComments(
        {
          filter: {
            orderId: match && match.params && Number(match.params.id),
          },
          includes: 'user',
          orderBy: '-createdAt',
          ...initialQuery,
        },
        { isRefresh: true, ...initialOptions },
      ),
    );
    // eslint-disable-next-line
  }, []);
  return comments.length > 0 ? (
    <List
      dataSource={comments}
      header={`${comments.length} ${comments.length > 1 ? 'replies' : 'reply'}`}
      itemLayout="horizontal"
      renderItem={(props, index) => (
        <CommentItem
          reviewerIdKey={reviewerIdKey}
          initialOptions={initialOptions}
          index={index}
          editCallback={editCallback}
          {...props}
        />
      )}
      footer={
        enabledLoadMore && (
          <Button type="link" onClick={loadMore}>
            {i18next.t('button.loadMore')}
          </Button>
        )
      }
    />
  ) : null;
};

const Comments = ({
  hasRate,
  createComments,
  match,
  orderId,
  initialOptions = {},
  initialQuery = {},
  hiddenInput,
  reviewerIdKey,
  editCallback,
}) => {
  const submitting = useSelector(CRUDSelectors.comments.getLoading);

  return (
    <div>
      {!hiddenInput && (
        <Comment
          avatar={
            <Avatar
              src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
              alt="Han Solo"
            />
          }
          content={
            <Editor
              hasRate={hasRate}
              onSubmit={createComments}
              submitting={submitting}
            />
          }
        />
      )}
      <CommentList
        hasRate={hasRate}
        orderId={orderId}
        initialOptions={initialOptions}
        initialQuery={initialQuery}
        match={match}
        reviewerIdKey={reviewerIdKey}
        editCallback={editCallback}
      />
    </div>
  );
};

Comments.propTypes = {
  match: PropTypes.object,
  hasRate: PropTypes.bool,
  createComments: PropTypes.func,
  orderId: PropTypes.number,
  initialOptions: PropTypes.object,
  initialQuery: PropTypes.object,
  hiddenInput: PropTypes.bool,
};

export default Comments;
