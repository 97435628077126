import React from 'react';
import Edit from '../../../containers/rest/Edit';
import Form from '../components/Form';

const RolesEdit = (props) => (
  <Edit {...props} resource="roles">
    <Form isEdit />
  </Edit>
);

RolesEdit.propTypes = {};

export default RolesEdit;
