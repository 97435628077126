import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { PieChart, Pie, Sector, Cell } from 'recharts';
import { formatShortNumber } from '../../utils/textUtils';
import { PieCharWrapper } from './styles';

const renderActiveShape = (suffix = '') => props => {
  const RADIAN = Math.PI / 180;
  // eslint-disable-next-line
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill={payload.color}
      >
        {payload.name}
      </text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill="#333"
      >
        {`${formatShortNumber(value)} ${suffix}`}
      </text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={36}
        textAnchor={textAnchor}
        fill="#999"
      >
        {`(Rate ${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
};

const PieChar = ({ resource, suffix, onClickCell }) => {
  const [activeIndex, setActiveKey] = useState(0);
  const onPieEnter = (data, index) => {
    setActiveKey(index);
  };

  const onClick = data => {
    onClickCell(data);
  };

  return (
    <PieCharWrapper>
      <div>
        <PieChart width={500} height={400}>
          <Pie
            data={resource}
            cx={200}
            cy={200}
            activeIndex={activeIndex}
            activeShape={renderActiveShape(suffix)}
            outerRadius={100}
            fill="#8884d8"
            dataKey="value"
            onMouseEnter={onPieEnter}
            onClick={onClick}
          >
            {resource.map((entry, index) => (
              <Cell key={`cell-${String(index)}`} fill={entry.color} />
            ))}
          </Pie>
        </PieChart>
      </div>
      <div className="listResource">
        {resource.map((e, index) => (
          <div
            key={String(index)}
            onMouseEnter={() => setActiveKey(index)}
            className="item"
          >
            <div
              style={{
                width: 10,
                height: 10,
                backgroundColor: e.color,
                marginRight: 5
              }}
            />
            {e.name}
            <i className="value">{`(${formatShortNumber(e.value)})`}</i>
          </div>
        ))}
      </div>
    </PieCharWrapper>
  );
};

PieChar.propTypes = {
  resource: PropTypes.array,
  suffix: PropTypes.string,
  onClickCell: PropTypes.func
};

export default PieChar;
