import { take, call, takeEvery } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import { createInstallationApi } from 'api/user';
import NotificationsActions, {
  MODEL,
  IGNORE_ACTIONS,
  PRIMARY_KEY,
  NotificationsTypes,
} from './actions';
import rootCRUDSaga from '../crudCreator/saga';
import { initOneSignal } from '../../api/oneSignal';
// use IGNORE_SAGAS to replace "saga" or ignore "saga"
// IGNORE_SAGAS = ['GET_ALL', 'GET_BY_ID', 'DELETE', 'EDIT', 'CREATE'];

const IGNORE_SAGAS = IGNORE_ACTIONS;

let notificationChannel = null;

function createNotificationChannel() {
  return eventChannel((emit) => {
    const notificationHandler = (data) => {
      emit(data);
    };
    const unsubscribe = initOneSignal(notificationHandler);
    return () => {
      unsubscribe && unsubscribe();
    };
  });
}
// reply with a `pong` message by invoking `socket.emit('pong')`

export function* watchNotification() {
  notificationChannel = yield call(createNotificationChannel);
  while (true) {
    try {
      const response = yield take(notificationChannel);
      switch (response.actionType) {
        case 'getUserId':
          // yield put(
          //   NotificationsActions.editInstallationsSuccess(response.payload),
          // );
          if (!localStorage.getItem('installationId')) {
            localStorage.setItem(
              'oneSignalUserId',
              response?.payload?.oneSignalUserId,
            );
            yield call(createInstallationApi, {
              oneSignalUserId: response?.payload?.oneSignalUserId,
            });
            localStorage.setItem(
              'installationId',
              response?.payload?.oneSignalUserId,
            );
          }
          break;
        case 'show':
          break;
        default:
      }
    } catch (error) {
      notificationChannel.close();
    }
  }
}

export function* closeNotification() {
  try {
    yield call(notificationChannel.close);
  } catch (error) {
    // tesst
  }
}

export default [
  ...rootCRUDSaga(MODEL, IGNORE_SAGAS, NotificationsActions, PRIMARY_KEY),
  takeEvery(
    NotificationsTypes.WATCH_ONE_SIGNAL_NOTIFICATION,
    watchNotification,
  ),
  takeEvery(
    NotificationsTypes.CLOSE_ONE_SIGNAL_NOTIFICATION,
    closeNotification,
  ),
];
