import React, { useState, useContext } from 'react';
import {
  Icon,
  Input,
  Row,
  Col,
  Modal,
  Switch,
  InputNumber,
  Button,
} from 'antd';
import { connect, useSelector } from 'react-redux';
import { FULL_ROLES, BONUS_LEVELS } from 'configs/localData';
import i18next from 'i18next';
import ReferenceInput from 'containers/rest/ReferenceInput';
import { getReferenceResource } from 'redux/referenceData/selectors';
import RestInputItem from '../../../../components/RestInput/RestInputItem';
import RestDateTimePicker from '../../../../components/RestInput/RestDateTimePicker';
import RestSelect from '../../../../components/RestInput/RestSelect';
import RestTextEditor from '../../../../components/RestInput/RestTextEditor';

import { RestInputContext } from '../../../../components/RestInput/RestInputContext/index';

const UsersForm = (props) => {
  const user = useSelector((state) => state.auth.data);
  const customers = useSelector((state) =>
    getReferenceResource(state, { resource: 'customers' }),
  );
  const [isShow, setIsShow] = useState(false);
  const { handleSubmit, form } = useContext(RestInputContext);
  const hasUpdate =
    user.scope === 'manager' || user?.permission?.users?.indexOf('update') > -1;
  const hasCreate =
    user.scope === 'manager' || user?.permission?.users?.indexOf('create') > -1;
  const isCustomer =
    props.roles?.find((e) => e.id === form.getFieldValue('roleId'))?.name ===
    'customer';

  const onChangeCustomer = (value) => {
    const selected = customers.find((e) => e.id === value);
    form.setFieldsValue({
      displayName: selected?.displayName,
      email: selected?.email,
      phone: selected?.phoneNumber,
      facebookLink: selected?.facebookLink,
    });
    return value;
  };

  return (
    <div {...props}>
      <RestSelect
        isAutoUpdate
        resourceData={props.roles}
        ruleType="number"
        source="roleId"
        valueProp="id"
        titleProp="description"
        header="users.role"
        disabled={props.isEdit ? !hasUpdate : !hasCreate}
      />
      {isCustomer ? (
        <RestInputItem isReference source="customerId">
          <ReferenceInput
            source="customerId"
            valueProp="id"
            titleProp="name"
            resource="customers"
            searchKey="q"
          >
            <RestSelect
              valueProp="id"
              titleProp="displayName"
              source="customerId"
              formOptions={{
                getValueFromEvent: onChangeCustomer,
              }}
              disabled={
                !(
                  props.roles.find((e) => e.id === form.getFieldValue('roleId'))
                    ?.name === 'customer'
                )
              }
            />
          </ReferenceInput>
        </RestInputItem>
      ) : null}
      {props.isEdit && (
        <Modal
          onCancel={() => setIsShow(false)}
          onOk={() => {
            handleSubmit();
            setIsShow(false);
          }}
          visible={isShow}
        >
          <RestInputItem source="password" header="password">
            <Input.Password
              autoComplete="new-password"
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
            />
          </RestInputItem>
        </Modal>
      )}
      <div style={{ display: 'none' }}>
        <RestInputItem source="password" header="password">
          <Input.Password
            autoComplete="new-password"
            prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
            type="password"
          />
        </RestInputItem>
      </div>
      {props.isEdit ? (
        <Button onClick={() => setIsShow(true)}>
          {i18next.t('button.changePassword')}
        </Button>
      ) : null}
      {user.permission?.salaryInfos?.indexOf('update') > -1 && !isCustomer ? (
        <RestSelect
          isAutoUpdate
          resourceData={BONUS_LEVELS.map((e) => ({
            id: e,
            description: e,
          }))}
          source="salaryScheme"
          valueProp="id"
          titleProp="description"
          header="salaryHistories.salaryScheme"
        />
      ) : null}
      {user.permission?.salaryInfos?.indexOf('update') > -1 && !isCustomer ? (
        <RestInputItem
          isAutoUpdate
          source="coef"
          header="coef"
          ruleType="number"
        >
          <InputNumber />
        </RestInputItem>
      ) : null}
      {user.permission?.salaryInfos?.indexOf('update') > -1 && !isCustomer ? (
        <RestInputItem
          isAutoUpdate
          source="coefOT"
          header="coef OT"
          ruleType="number"
        >
          <InputNumber />
        </RestInputItem>
      ) : null}
      <RestInputItem
        disabled={props.isEdit}
        autoComplete="off"
        ruleType="email"
        source="email"
        header="email"
      />
      {!props.isEdit || FULL_ROLES.indexOf(user?.scope) > -1 ? (
        <RestInputItem source="password" header="password">
          <Input.Password
            autoComplete="new-password"
            prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
            type="password"
          />
        </RestInputItem>
      ) : null}
      <RestInputItem source="displayName" header="users.displayName" />
      <RestDateTimePicker
        autoComplete="off"
        source="birthday"
        header="users.birthday"
      />
      <Row gutter={16}>
        <Col span={12}>
          <RestInputItem source="trelloKey" header="Trello Key" />
        </Col>
        <Col span={12}>
          <RestInputItem source="trelloToken" header="Trello Token" />
        </Col>
        <Col span={12}>
          <RestInputItem
            autoComplete="off"
            source="phone"
            header="users.phone"
          />
        </Col>
        <Col span={12}>
          <RestInputItem
            autoComplete="off"
            source="linkFB"
            header="users.linkFB"
          />
          {isCustomer ? null : (
            <RestInputItem
              isAutoUpdate
              ruleType="boolean"
              autoComplete="off"
              source="isOutsource"
              valuePropName="checked"
              header="users.isOutsource"
              disabled={props.isEdit ? !hasUpdate : !hasCreate}
            >
              <Switch disabled={props.isEdit ? !hasUpdate : !hasCreate} />
            </RestInputItem>
          )}
        </Col>
        <Col span={24}>
          <RestTextEditor isAutoUpdate source="note" header="users.note" />
        </Col>
      </Row>
    </div>
  );
};

UsersForm.propTypes = {};

export default connect((state) => ({
  roles: state.config.roles,
}))(UsersForm);
