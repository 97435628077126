import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Button, Form } from 'antd';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import CRUDActions from '../../../redux/crudActions';
import { retrieveReference } from '../../../redux/referenceData/actions';
import { getRecordData, upperCaseFirstChart } from '../../../utils/tools';
import {
  getReferenceResource,
  getTotalReference,
  getEnabledLoadMoreReference,
} from '../../../redux/referenceData/selectors';
import crudSelectors from '../../../redux/crudSelectors';

class ReferenceInput extends Component {
  checkPermission = () => {
    // TO DO: will remove
    const SPECIAL_USER_RESOURCES = {
      designers: 'getDesigners',
      guarantees: 'getGuarantees',
      delivers: 'getDelivers',
      dealers: 'getDealer',
      salers: 'getSalerB',
      leaders: 'getLeaders',
    };

    const { user, resource } = this.props;
    if (user.scope === 'manager') return true;
    if (SPECIAL_USER_RESOURCES[resource]) {
      return (
        user?.permission?.users?.indexOf(SPECIAL_USER_RESOURCES[resource]) > -1
      );
    }
    return (
      user?.permission &&
      user?.permission[SPECIAL_USER_RESOURCES[resource] || resource]?.indexOf(
        'get',
      ) > -1
    );
  };

  componentDidMount() {
    const { record, source, initialFilter } = this.props;
    if (!this.checkPermission()) return;
    if (getRecordData(record, source)) {
      this.props.retrieveReference(getRecordData(record, source));
    }
    this.props.retrieveList(initialFilter || { page: 1, limit: 50 }, true);
    this.debouceSearch = _.debounce(this.onSearch, 300);
  }

  componentDidUpdate(prevProps) {
    const { record, source, initialFilter } = this.props;
    if (!this.checkPermission()) return;
    if (
      prevProps.record &&
      record &&
      prevProps.record.id !== record.id &&
      getRecordData(record, source)
    ) {
      this.props.retrieveReference(getRecordData(record, source));
    }
    if (prevProps.initialFilter !== initialFilter) {
      this.props.retrieveList(initialFilter || { page: 1, limit: 50 }, true);
    }
  }

  getInit = () => {
    const { initialFilter } = this.props;
    this.props.retrieveList(initialFilter || { page: 1, limit: 50 }, true);
  };

  onSearch = (value) => {
    const { searchKey, retrieveList } = this.props;
    if (searchKey) {
      retrieveList(
        searchKey === 'q'
          ? { q: value }
          : { filter: { [searchKey]: { $like: value } } },
        true,
      );
    }
  };

  retrieveListWaypoint = () => {
    const { enabledLoadMore, retrieveList } = this.props;
    if (enabledLoadMore) {
      retrieveList();
    }
  };

  render() {
    const {
      resourceData,
      record,
      children,
      source,
      getFieldDecorator,
      setFieldsValue,
      form,
      searchKey,
      loadingData,
      resource,
      hasAdd,
      goCreate,
    } = this.props;
    if (!this.checkPermission()) return null;
    const newChildren = React.cloneElement(children, {
      onSearch: (value) => {
        this.debouceSearch(value);
      },
      onEnter: () => this.retrieveListWaypoint(),
      getInit: this.getInit,
      searchKey,
      record,
      loading: loadingData,
      form,
      source,
      getFieldDecorator,
      setFieldsValue,
      resourceData,
      resource,
      subfix: hasAdd ? (
        <Button
          size="small"
          style={{ marginLeft: 10 }}
          type="primary"
          onClick={goCreate}
          icon="plus"
        />
      ) : null,
    });
    return newChildren;
  }
}
ReferenceInput.propTypes = {
  resource: PropTypes.string.isRequired,
  resourceData: PropTypes.array,
  record: PropTypes.object,
  retrieveList: PropTypes.func,
  children: PropTypes.node,
  source: PropTypes.string,
  retrieveReference: PropTypes.func,
  getFieldDecorator: PropTypes.func,
  setFieldsValue: PropTypes.func,
  form: PropTypes.object,
  searchKey: PropTypes.string,
  enabledLoadMore: PropTypes.bool,
  loadingData: PropTypes.bool,
  initialFilter: PropTypes.object,
  hasAdd: PropTypes.bool,
};

const mapStateToProps = (state, props) => ({
  resourceData: getReferenceResource(state, props),
  loadingData: crudSelectors[props.resource].getLoading(state, props),
  count: getTotalReference(state, props),
  enabledLoadMore: getEnabledLoadMoreReference(state, props),
  user: state.auth.data,
});

const mapDispatchToProps = (dispatch, props) => ({
  retrieveReference: (data) =>
    dispatch(
      retrieveReference(
        props.resource,
        Array.isArray(data) ? data : [data],
        props.mappedBy,
        props.initialFilter,
      ),
    ),
  retrieveList: (filter, isRefresh) =>
    dispatch(
      CRUDActions[props.resource][
        `getAll${upperCaseFirstChart(props.resource)}`
      ](
        {
          ...props.initialFilter,
          ...filter,
        },
        { isRefresh },
      ),
    ),
  goCreate: () => dispatch(push(`#${props.resource}/create`)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Form.create({})(ReferenceInput));
