import React from 'react';
import RestShow from '../../rest/Show';
import RestFieldItem from '../../../components/RestField/RestFieldItem';

const OrderRequirementsShow = props => (
  <RestShow {...props} hasEdit resource="orderRequirements">
    <RestFieldItem source="name" header="orderRequirements.name" />
    <RestFieldItem source="description" header="orderRequirements.description" />
  </RestShow>
);

export default OrderRequirementsShow;
