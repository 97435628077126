import React from 'react';
import { formatDateTime } from 'utils/textUtils';
import List from '../../rest/List';
import RestFieldItem from '../../../components/RestField/RestFieldItem';
import ActionGroup from '../../../components/RestActions/ActionGroup';
import EditButton from '../../../components/RestActions/EditButton';
import DeleteButton from '../../../components/RestActions/DeleteButton';

const UserRequestsList = (props) => (
  <List {...props} resource="userRequests">
    <RestFieldItem source="title" header="userRequests.title" />
    <RestFieldItem source="content" header="userRequests.content" />
    <RestFieldItem source="typeRequest" header="userRequests.typeRequest" />
    <RestFieldItem source="status" header="userRequests.status" />
    <RestFieldItem
      format={(data) => formatDateTime(data)}
      source="createdAt"
      header="userRequests.createdAt"
    />
    <ActionGroup>
      <EditButton />
      <DeleteButton />
    </ActionGroup>
  </List>
);

UserRequestsList.propTypes = {};

export default UserRequestsList;
