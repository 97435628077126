import { makeConstantCreator, makeActionCreator } from '../../utils/reduxUtils';

export const ModalTypes = makeConstantCreator(
  'SHOW_MODAL',
  'CLOSE_MODAL',
  'SHOW_PREVIEW',
  'CANCEL_PREVIEW'
);

export const showModal = data => makeActionCreator(ModalTypes.SHOW_MODAL, { data });
export const closeModal = data => makeActionCreator(ModalTypes.CLOSE_MODAL, { data });
export const showPreviewImage = data => makeActionCreator(ModalTypes.SHOW_PREVIEW, { data });
export const cancelPreviewImage = data => makeActionCreator(ModalTypes.CANCEL_PREVIEW, { data });
