import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Avatar, Tooltip } from 'antd';
import Text from '../Text';
import { UserTagWrapper } from './styles';
import SVGIcon from '../SVGIcon';

const UserTag = ({ record, prefix, iconName }) =>
  record ? (
    <Tooltip title={prefix}>
      <Link to={`/users/${record.id}/edit`}>
        <UserTagWrapper>
          <div className="icon">
            {record.avatar ? <Avatar src={record.avatar} /> : <SVGIcon name={iconName} />}
          </div>
          &nbsp;
          <Text type="bodyTitle">{`${record.displayName}`}</Text>
        </UserTagWrapper>
      </Link>
    </Tooltip>
  ) : null;

UserTag.propTypes = {
  record: PropTypes.object,
  prefix: PropTypes.string,
  iconName: PropTypes.string,
};

export default UserTag;
