import React from 'react';
import RestInputItem from '../../../../components/RestInput/RestInputItem';

const BrandsForm = props => (
  <div {...props}>
    <RestInputItem source="name" header="brands.name" />
  </div>
);

BrandsForm.propTypes = {};

export default BrandsForm;
