import React from 'react';
import PropTypes from 'prop-types';
// import I18n from 'i18next';
// import { Tooltip } from 'antd';
// import { usePermissionAction } from 'hooks/usePermissionActions';
import { usePermissionAction } from 'hooks/usePermissionActions';
import { ButtonWrapper } from './styles';

const EditButton = ({ source, record, gotoEditPage, modelResource }) => {
  const isAccess = usePermissionAction(modelResource, 'update');
  return isAccess ? (
    // <Tooltip header={<IntlMessages id="tooltip.edit" />}>
    <ButtonWrapper
      source={source}
      icon="edit"
      onClick={() => gotoEditPage(record ? record.id : '')}
    >
      {/* {I18n.t('button.edit')} */}
    </ButtonWrapper>
  ) : null;
};
EditButton.propTypes = {
  gotoEditPage: PropTypes.func,
  record: PropTypes.object,
  source: PropTypes.string,
  modelResource: PropTypes.string,
};

EditButton.defaultProps = {
  source: 'edit',
};

export default EditButton;
