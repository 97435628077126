import React from "react";
import { connect } from "react-redux";
import Edit from "../../../containers/rest/Edit";
import Form from "../components/Form";
import { uploadImages } from "../../../api/uploadMedia";

const ProductsEdit = ({ productTypes, ...props }) => (
  <Edit
    {...props}
    formatOnSubmit={async values => {
      const formData = new FormData();
      const prevImages = values.productImages.filter(e => e.previewImage);
      const newImages = values.productImages.filter(e => !e.previewImage);
      newImages.forEach(e => {
        formData.append("images", e.originFileObj);
      });
      const tags = values.productCatalogIds
        ? productTypes
            .filter(e => values.productCatalogIds.indexOf(e.id) > -1)
            .map(e => e.name)
        : "";
      formData.append("tags", tags);
      formData.append("type", "product");
      if (newImages.length > 0) {
        const images = await uploadImages(formData);
        values.productImages = [...prevImages, ...images];
      }

      // newImages.forEach(e => {
      //   if (e.url) {
      //     images.push(e.name);
      //   }
      // });

      return values;
    }}
    resource="products"
  >
    <Form />
  </Edit>
);

ProductsEdit.propTypes = {};

const mapStateToProps = (state, props) => ({
  productTypes: state.config.productTypes,
});

const mapDispatchToProps = (dispatch, props) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(ProductsEdit);
