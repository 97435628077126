import React from 'react';
import { Switch } from 'antd';
import RestInputItem from '../../../../components/RestInput/RestInputItem';

const DepartmentsForm = props => (
  <div {...props}>
    <RestInputItem source="name" header="departments.name" />
    <RestInputItem source="description" header="departments.description" />
    <RestInputItem ruleType="boolean" source="isDelete" header="departments.isDelete">
      <Switch />
    </RestInputItem>
  </div>
);

DepartmentsForm.propTypes = {};

export default DepartmentsForm;
