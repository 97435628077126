import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import TextEditor from 'components/common/TextEditor';
import { Card } from 'antd';
import i18next from 'i18next';
import Text from '../../../../components/common/Text';
import SelectUser from '../../../../components/common/SelectUser';
import ReferenceInput from '../../../../containers/rest/ReferenceInput';
import OrderStatusTitle from './OrderStatusTitle';
import { PreviewTextEditor } from '../../../../components/RestInput/RestTextEditor/index';

const Feedback = ({ item }) => (
  <Card title={<OrderStatusTitle item={item} />} className="timelineItem">
    <Text type="body" color="red">
      Report:
    </Text>
    <PreviewTextEditor
      html={
        item.note +
        (item.note !== item.report && item.report ? item.report : '')
      }
    />
  </Card>
);

export const FeedbackAction = ({ setNote, item, setSelectedUser }) => (
  <Fragment>
    <Text type="h5" color="#000">
      {i18next.t('orderHistories.status.description.feedback.selectGuarantee')}
    </Text>
    <ReferenceInput searchKey="displayName" resource="guarantees">
      <SelectUser onChange={setSelectedUser} />
    </ReferenceInput>
    <div style={{ marginTop: 20 }}>
      <Text type="h5" color="#000">
        {i18next.t('orderHistories.status.description.feedback.note')}
      </Text>
      <TextEditor
        className="itemRow"
        style={{ marginTop: 20 }}
        placeholder={i18next.t('note')}
        onEditorChange={(e) => {
          setNote(e);
        }}
      />
    </div>
  </Fragment>
);

Feedback.propTypes = {
  item: PropTypes.object,
};

export default Feedback;
