import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import I18n from 'i18next';
import { Layout, Menu, Icon } from 'antd';
import { useAccessSidebar } from 'hooks/usePermissionRoutes';
import { history } from '../../redux/store';

const getCurrentTab = (str) => {
  const paths = str && str.split('/');
  return paths && paths[1];
};
const sidebarMenu = [
  {
    key: 'dashboard',
    text: 'tabs.dashboard',
    icon: 'tcrm-dashboard',
    url: '/',
  },
  {
    key: 'workingRegisters',
    text: 'tabs.workingRegisters',
    icon: 'schedule',
    url: '/workingRegisters',
    unShowOutsource: true,
  },
  {
    key: 'customers',
    text: 'tabs.customers',
    icon: 'tcrm-contact',
    url: '/customers',
  },
  {
    key: 'users',
    text: 'tabs.users',
    icon: 'tcrm-employee',
    url: '/users',
  },
  {
    key: 'packages',
    text: 'tabs.package',
    icon: 'build',
    url: '/packages',
    unShowOutsource: true,
  },
  {
    key: 'orders',
    text: 'tabs.orders',
    icon: 'shopping-cart',
    url: '/orders',
  },
  {
    key: 'payments',
    text: 'tabs.payments',
    icon: 'dollar',
    url: '/payments',
  },
  {
    key: 'deleted',
    text: 'tabs.orderDeleted',
    icon: 'delete',
    url: '/deleted/orders',
    unShowOutsource: true,
  },
  {
    key: 'mistakeRecords',
    text: 'tabs.mistakeRecords',
    icon: 'bug',
    url: '/mistakeRecords',
  },
  {
    key: 'reports',
    text: 'tabs.reports',
    icon: 'bar-chart',
    url: '/reports',
  },

  {
    key: 'salaryHistories',
    text: 'tabs.salaries',
    icon: 'dollar',
    url: '/salaryHistories/users',
  },
  {
    key: 'permissions',
    text: 'tabs.permissions',
    icon: 'apartment',
    url: '/permissions',
  },
  {
    key: 'roles',
    text: 'tabs.roles',
    icon: 'user',
    url: '/roles',
  },
  {
    key: 'logs',
    text: 'tabs.logs',
    icon: 'exception',
    url: '/logs',
  },
  {
    key: 'settings',
    text: 'tabs.setting',
    icon: 'tcrm-setting',
    url: '/settings/niches',
  },
];

const SideBar = ({ location, collapsed, user }) => {
  const url = getCurrentTab(location.pathname);
  const accessSidebar = useAccessSidebar(sidebarMenu);
  return (
    <Layout.Sider
      trigger={null}
      collapsible
      collapsed={collapsed}
      className="sidebar"
    >
      <div className="logo">
        {typeof user.isOutsource !== 'undefined' && !user.isOutsource && (
          <img src="https://i.imgur.com/ecNKMr6.png" alt="lucas" />
        )}
      </div>
      <Menu
        mode="inline"
        selectedKeys={[url || 'dashboard']}
        defaultSelectedKeys={[url || 'dashboard']}
      >
        {accessSidebar
          .filter(
            (e) =>
              !user.isOutsource || (user.isOutsource && !e.unShowOutsource),
          )
          .map((menu) => (
            <Menu.Item key={menu.key} onClick={() => history.push(menu.url)}>
              <Icon type={menu.icon} />
              <span>{I18n.t(menu.text)}</span>
            </Menu.Item>
          ))}
      </Menu>
    </Layout.Sider>
  );
};

SideBar.propTypes = {
  location: PropTypes.object,
  collapsed: PropTypes.bool,
};

export default connect((state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  location: state.router.location,
  user: state.auth.data,
}))(SideBar);
