import styled from 'styled-components';

export default styled.div`
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    padding: 6px;
  }
  .row {
    display: flex;
    justify-content: space-between;
    .ant-tag {
      font-size: 30px;
      line-height: 1.35;
    }
  }
  .ant-table-thead > tr {
    &:first-child {
      & > th:nth-child(2n + 3) {
        background: #d1d1d1;
      }
    }
  }
`;
