import React from 'react';
import PropTypes from 'prop-types';
import { DatePicker, Select } from 'antd';
import moment from 'moment';
import i18next from 'i18next';
import { ReportFilterWrapper } from './styles';
import { ORDER_STATUS } from '../../configs/localData/index';

const FilterReports = ({ filter, setFilter }) => {
  const onCalendarChange = data => {
    if (data[0] && data[1]) {
      setFilter({
        ...filter,
        createdAt: {
          $gt: data[0].startOf('day').toISOString(),
          $lt: data[1].endOf('day').toISOString(),
        },
      });
    } else {
      setFilter({ ...filter, createdAt: {} });
    }
  };

  const onChangeStatus = e => {
    setFilter({ ...filter, status: e });
  };

  return (
    <ReportFilterWrapper>
      <DatePicker.RangePicker
        size="large"
        onChange={onCalendarChange}
        ranges={{
          Today: [moment(), moment()],
          'This Week': [moment().startOf('week'), moment().endOf('week')],
          'This Month': [moment().startOf('month'), moment().endOf('month')],
          'This Year': [moment().startOf('year'), moment().endOf('year')],
        }}

        // onCalendarChange={onCalendarChange}
      />
      <Select
        size="large"
        placeholder={i18next.t('report.selectStatus')}
        style={{ marginLeft: 10, width: 200 }}
        onChange={onChangeStatus}
        allowClear
      >
        {ORDER_STATUS.map(e => (
          <Select.Option key={e.value} value={e.value}>
            {i18next.t(e.text)}
          </Select.Option>
        ))}
      </Select>
    </ReportFilterWrapper>
  );
};
FilterReports.propTypes = {
  setFilter: PropTypes.func,
};

export default FilterReports;
