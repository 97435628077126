import React from 'react';
import { Switch } from 'antd';
import RestInputItem from '../../../../components/RestInput/RestInputItem';
import RestColorPicker from '../../../../components/RestInput/RestColorPicker';

const OrderStatusesForm = props => (
  <div {...props}>
    <RestInputItem source="name" header="orderStatuses.name" />
    <RestInputItem source="description" header="orderStatuses.description" />
    <RestColorPicker source="color" header="orderStatuses.color" />
    <RestInputItem ruleType="boolean" source="isDelete" header="orderStatuses.isDelete">
      <Switch />
    </RestInputItem>
  </div>
);

OrderStatusesForm.propTypes = {};

export default OrderStatusesForm;
