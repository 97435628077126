import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import ReferenceInput from '../../../../containers/rest/ReferenceInput';
import { debounce } from 'lodash';
import SelectField from '../../../../components/RestField/SelectField';
import { ORDER_STATUS } from '../../../../configs/localData/index';
import crudSelectors from '../../../../redux/crudSelectors';
import UsersAction from '../../../../redux/users/actions';

const SelectDesigner = (props) => {
  const onSearch = (e) => {
    props.getAllUsers(
      {
        offset: 0,
        filter: {
          displayName: { $like: `%${e}%` },
          roleId: { $in:[ 7, 24 ]}
        },
      },
      { isRefresh: false }
    );
  };
  const debounceSearch = debounce(onSearch, 500);
  return props.record.status !== 'new_deal' &&
    props.record.status !== 'designer_confirm' ? (
    <span>{props.record?.designer?.displayName}</span>
  ) : (
    <SelectField
      defaultValue={props.record.designerId}
      resourceData={props.users}
      onSearch={debounceSearch}
      onChange={(designer, e) =>
        props.assignerDesigner({
          assignedUser: [e],
          status: ORDER_STATUS[1].value,
          orderId: props.record.id,
        })
      }
      titleProp="displayName"
      valueProp="id"
      placeholder="Designer"
      selectProps={{ allowClear: false }}
    />
  );
};
SelectDesigner.propTypes = {
  getAllUsers: PropTypes.func,
};

export default connect(
  (state, props) => ({
    users: crudSelectors.users.getDataArr(state, props),
    loading: crudSelectors.users.getLoading(state, props),
  }),
  (dispatch) => ({
    getAllUsers: (data, options) =>
      dispatch(UsersAction.getAllUsers(data, options)),
  })
)(SelectDesigner);
