import React from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Card, Row, Col, Form, Input, Button } from 'antd';
import { changePassword } from '../../redux/auth/actions';

const ChangePassword = ({ form }) => {
  const { getFieldDecorator } = form;
  const dispatch = useDispatch();

  const handleSubmit = e => {
    e.preventDefault();
    form.validateFieldsAndScroll((err, { password }) => {
      if (!err) {
        dispatch(changePassword(password));
      }
    });
  };

  const compareToFirstPassword = (rule, value, callback) => {
    if (value && value !== form.getFieldValue('password')) {
      callback(i18next.t('users.validate.passwordInconsistent'));
    } else {
      callback();
    }
  };
  return (
    <Card>
      <Form>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label={i18next.t('users.newPassword')} hasFeedback>
              {getFieldDecorator('password', {
                rules: [
                  {
                    required: true,
                    message: i18next.t('users.validate.password'),
                  },
                ],
              })(<Input.Password />)}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={i18next.t('users.confirmPass')} hasFeedback>
              {getFieldDecorator('confirm', {
                rules: [
                  {
                    required: true,
                    message: i18next.t('users.validate.confirmPass'),
                  },
                  {
                    validator: compareToFirstPassword,
                  },
                ],
              })(<Input.Password />)}
            </Form.Item>
          </Col>
          <Col span={24}>
            <Button onClick={handleSubmit} type="primary">
              {i18next.t('button.update')}
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

ChangePassword.propTypes = {
  form: PropTypes.object,
};

export default Form.create()(ChangePassword);
