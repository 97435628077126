import React from 'react';
import { PreviewTextEditor } from 'components/RestInput/RestTextEditor';
import List from '../../rest/List';
import RestFieldItem from '../../../components/RestField/RestFieldItem';
import ActionGroup from '../../../components/RestActions/ActionGroup';
import EditButton from '../../../components/RestActions/EditButton';
import DeleteButton from '../../../components/RestActions/DeleteButton';

const MistakeTypesList = (props) => (
  <List {...props} resource="mistakeTypes">
    <RestFieldItem source="name" header="mistakeTypes.name" />
    <RestFieldItem source="level" header="mistakeTypes.level" />
    <RestFieldItem
      format={(data) => <PreviewTextEditor html={data} />}
      source="description"
      header="mistakeTypes.description"
    />
    <ActionGroup>
      <EditButton />
      <DeleteButton />
    </ActionGroup>
  </List>
);

MistakeTypesList.propTypes = {};

export default MistakeTypesList;
