import React from 'react';
import { Row, Col, Card } from 'antd';
import i18next from 'i18next';
import Edit from '../../../containers/rest/Edit';
import Form from '../components/Form';
import Permission from '../components/Permission';
import Text from '../../../components/common/Text';

const UserTypesEdit = props => (
  <Edit {...props} noCardWrapper resource="userTypes">
    <Row gutter={16}>
      <Col span={8}>
        <Card>
          <Text type="h1">{i18next.t('userTypes.info')}</Text>
          <Form />
        </Card>
      </Col>
      <Col span={16}>
        <Card>
          <Permission />
        </Card>
      </Col>
    </Row>
  </Edit>
);

UserTypesEdit.propTypes = {};

export default UserTypesEdit;
