import React from 'react';
import Create from '../../rest/Create';
import Form from '../components/Form';

const OrderRequirementsCreate = props => (
  <Create {...props} resource="orderRequirements">
    <Form />
  </Create>
);

OrderRequirementsCreate.propTypes = {};

export default OrderRequirementsCreate;
