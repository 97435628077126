import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import I18n from "i18next";
import { push } from "connected-react-router";
import { Layout, Menu, Icon, Dropdown, Avatar, Button } from "antd";
import { Redirect, Link } from "react-router-dom";
import SideBar from "containers/SideBar";
import PrivateLayoutWrapper from "./styles";
import { logout as logoutAction } from "../../redux/auth/actions";
import SearchByImageButton from "containers/SearchByImage/SearchByImageButton";
import { getSSOTokenApi } from "api/user";

const { Header, Content, Footer } = Layout;

const PROFILE_MENU = [
  {
    key: "profile",
    text: "Profile",
    url: "/profile",
  },
];

const mobileTabs = [
  {
    key: "home",
    text: "Profile",
    url: "/",
    icon: "home",
  },
  {
    key: "change-password",
    text: "button.changePassword",
    url: "/change-password",
    icon: "home",
  },
  {
    key: "user",
    text: "Profile",
    url: "#",
    icon: "user",
  },
];

class PrivateLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: true,
      locale: localStorage.getItem("locale") || "en",
    };
  }

  componentDidMount() {
    I18n.changeLanguage(localStorage.getItem("locale") || "en");
    this.props.isAuthenticated && push("/login");
  }

  toggle = () => {
    this.setState((prevState) => ({
      collapsed: !prevState.collapsed,
    }));
  };

  changeLocale = (e) => () => {
    this.setState({ locale: e });
    I18n.changeLanguage(e);
    localStorage.setItem("locale", e);
  };

  goFinanceSite = () => {
    getSSOTokenApi().then((data) => {
      window.open(
        `${process.env.REACT_APP_FINANCE_SITE}/login?token=${data}`,
        "_blank"
      );
    });
  };

  render() {
    const { children, logout, isAuthenticated, user } = this.props;
    const { locale } = this.state;
    if (!isAuthenticated) return <Redirect to="/login" />;
    const profileMenu =
      user && user.scope === "manager"
        ? [
            ...PROFILE_MENU,
            {
              key: "home",
              text: "button.changePassword",
              url: "/change-password",
              icon: "home",
            },
          ]
        : PROFILE_MENU;
    return (
      <PrivateLayoutWrapper>
        <Layout className="windowView">
          <input
            onChange={() => {}}
            id="collapsedTracker"
            type="checkbox"
            checked={!this.state.collapsed}
          />
          <label
            htmlFor="collapsedTracker"
            className="overlay"
            onClick={this.toggle}
          />
          <SideBar user={user} collapsed={this.state.collapsed} />
          <Layout className="mainView">
            <Header className="header">
              <div className="leftHeader">
                <Icon
                  className="trigger"
                  type={this.state.collapsed ? "menu-unfold" : "menu-fold"}
                  onClick={this.toggle}
                />
                <div
                  className={`localeSelect${locale === "vi" ? " active" : ""}`}
                  role="presentation"
                  onClick={this.changeLocale("vi")}
                >
                  VI
                </div>
                <div
                  className={`localeSelect${locale === "en" ? " active" : ""}`}
                  role="presentation"
                  onClick={this.changeLocale("en")}
                >
                  EN
                </div>
                <div className="title">{I18n.t("appInfo.name")}</div>
              </div>
              <div className="rightHeader">
                {!user.isOutsource && (
                  <>
                    <Button
                      onClick={this.goFinanceSite}
                      type="primary"
                      icon="schedule"
                      size="large"
                    >
                      {I18n.t("button.goFinanceSite")}
                    </Button>
                    &nbsp;
                    <Link to="/workingRegisters/me">
                      <Button type="primary" icon="schedule" size="large">
                        {I18n.t("workingRegisters.mySchedule")}
                      </Button>
                    </Link>
                    &nbsp;
                    <Link to="/workingRegisters/create">
                      <Button type="primary" size="large">
                        {I18n.t("button.registerWorkingTime")}
                      </Button>
                    </Link>
                  </>
                )}
                <div className="username">
                  <div style={{ fontWeight: "bold" }}>{user.displayName}</div>
                  <div>{user.scope}</div>
                </div>
                <Dropdown
                  overlay={
                    <Menu style={{ minWidth: "120px" }}>
                      {profileMenu.map((menu) => (
                        <Menu.Item key={menu.key}>
                          <Link to={menu.url}>{I18n.t(menu.text)}</Link>
                        </Menu.Item>
                      ))}
                      <Menu.Divider />
                      <Menu.Item onClick={logout} key="logout">
                        Logout
                      </Menu.Item>
                    </Menu>
                  }
                  trigger={["click"]}
                >
                  <Avatar size="large" icon="user" src={user.avatar} />
                </Dropdown>
              </div>
            </Header>
            <Content id="container" className="container">
              <div id="content" className="content">
                {children}
              </div>
              <Footer className="footer">{I18n.t("appInfo.footer")}</Footer>
              <Footer className="footerMobile">
                {mobileTabs.map((tab) => (
                  <a href={tab.url} key={tab.key}>
                    <Icon type={tab.icon} className="tabIcon" />
                  </a>
                ))}
              </Footer>
            </Content>
          </Layout>
        </Layout>
        <div className="btnFloat">
          <SearchByImageButton />
        </div>
      </PrivateLayoutWrapper>
    );
  }
}

PrivateLayout.propTypes = {
  children: PropTypes.any,
  isAuthenticated: PropTypes.bool,
  logout: PropTypes.func,
};

export default connect(
  (state) => ({
    user: state.auth.data,
    isAuthenticated: state.auth.isAuthenticated,
  }),
  {
    logout: logoutAction,
  }
)(PrivateLayout);
