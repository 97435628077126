import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import I18n from 'i18next';
import { Popconfirm } from 'antd';
import { usePermissionAction } from 'hooks/usePermissionActions';
import { ButtonWrapper } from './styles';
import OrderAction from '../../../../redux/orders/actions';
import { getCloneOrderData } from '../../../../utils/tools';

const ButtonClone = (props) => {
  const isAccess = usePermissionAction('orders', 'clone');
  return isAccess ? (
    <Popconfirm
      placement="topLeft"
      title={I18n.t('orders.cloneConfirm')}
      onConfirm={() => props.createOrder(getCloneOrderData(props.record))}
      okText="Yes"
      cancelText="No"
    >
      <ButtonWrapper source={props.source} icon="diff" />
    </Popconfirm>
  ) : null;
};

ButtonClone.propTypes = {
  createOrder: PropTypes.func,
  record: PropTypes.object,
  source: PropTypes.string,
};

ButtonClone.defaultProps = {
  source: 'clone',
};

export default connect(null, (dispatch) => ({
  createOrder: (data) =>
    dispatch(
      OrderAction.createOrders(data, {
        isBack: false,
        isShowSuccessNoti: true,
      }),
    ),
}))(ButtonClone);
