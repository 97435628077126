import styled from 'styled-components';

const EditClientsWrapper = styled.div`
  .box {
    background-color: ${({ theme }) => theme.background.content};
    margin-bottom: 20px;
    .txtHeader {
      font-size: 16px;
      font-weight: ${({ theme }) => theme.fontWeight.semibold};
      color: white;
    }
    .icEdit {
      color: ${({ theme }) => theme.text.primary};
      font-size: 17px;
      cursor: pointer;
      &:hover {
        transform: scale(1.1, 1.1);
        color: ${({ theme }) => theme.palette.primary};
      }
    }
    .ant-card-head {
      background: ${({ theme }) => theme.card.header};
    }
  }
`;

export { EditClientsWrapper };
