import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';
import FormItem from '../../form/FormItem';
import { getRecordData } from '../../../utils/tools';
import { RestInputContext } from '../RestInputContext';
import { formatDateTime } from '../../../utils/textUtils';
import MaterialInput from '../../common/MaterialInput';

const RestInputItem = props => (
  <RestInputContext.Consumer>
    {({ record, form }) =>
      props.isReference ? (
        React.cloneElement(props.children, {
          record,
        })
      ) : (
        <FormItem
          {...props}
          form={form}
          defaultValue={formatDateTime(props.defaultValue || getRecordData(record, props.source))}
        >
          <MaterialInput placeholder={props.placeholder} />
        </FormItem>
      )
    }
  </RestInputContext.Consumer>
);

RestInputItem.propTypes = {
  source: PropTypes.string,
  record: PropTypes.object,
  defaultValue: PropTypes.string,
  children: PropTypes.any,
};

RestInputItem.defaultProps = {
  children: <Input />,
};

export default RestInputItem;
