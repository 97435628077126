import React from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18next';
import { usePermissionAction } from 'hooks/usePermissionActions';
import { ButtonWrapper } from './styles';

const CreateButton = ({ header, resource, gotoCreatePage, source }) => {
  const isAccess = usePermissionAction(resource, 'create');
  return isAccess ? (
    <ButtonWrapper source={source} type="primary" onClick={gotoCreatePage}>
      {I18n.t(header)}
    </ButtonWrapper>
  ) : null;
};

CreateButton.propTypes = {
  gotoCreatePage: PropTypes.func,
  header: PropTypes.string,
  source: PropTypes.string,
  resource: PropTypes.string,
};

CreateButton.defaultProps = {
  source: 'create',
  header: 'button.create',
};

export default CreateButton;
