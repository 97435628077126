import React from 'react';
import RestShow from '../../rest/Show';
import RestFieldItem from '../../../components/RestField/RestFieldItem';

const SalaryHistoriesShow = props => (
  <RestShow {...props} hasEdit resource="salaryHistories">
    <RestFieldItem source="userId" header="salaryHistories.userId" />
    <RestFieldItem source="dateMonth" header="salaryHistories.dateMonth" />
    <RestFieldItem source="startDate" header="salaryHistories.startDate" />
    <RestFieldItem source="endDate" header="salaryHistories.endDate" />
    <RestFieldItem source="kpi" header="salaryHistories.kpi" />
    <RestFieldItem source="coef" header="salaryHistories.coef" />
    <RestFieldItem source="salaryScheme" header="salaryHistories.salaryScheme" />
  </RestShow>
);

export default SalaryHistoriesShow;
