import React from 'react';
import RestInputItem from '../../../../components/RestInput/RestInputItem';

const LogsForm = props => (
  <div {...props}>
    <RestInputItem source="createdAt" header="logs.createdAt" />
    <RestInputItem source="name" header="logs.name" />
  </div>
);

LogsForm.propTypes = {};

export default LogsForm;
