import React from 'react';
import RestInputItem from '../../../../components/RestInput/RestInputItem';

const PaymentsForm = props => (
  <div {...props}>
    <RestInputItem source="createdAt" header="payments.createdAt" />
  </div>
);

PaymentsForm.propTypes = {};

export default PaymentsForm;
