import React from 'react';
import {
  LineChart as ReLineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';
import PropTypes from 'prop-types';
import { MIN_PRICE } from 'configs/localData';
import { formatShortNumber } from '../../utils/textUtils';
import { BarChartWrapper } from './styles';

const CustomTooltip = ({ active, payload, label, resource }) => {
  if (active) {
    const currentData = resource?.find(e => e?.name === label);
    return (
      <div className="custom-tooltip">
        <p>
          <b className="label">{label}</b>
        </p>
        <div className="revenue">
          <div style={{ color: 'red' }}>
            <b>Customer:</b>
            {`${formatShortNumber(currentData?.totalCustomer)}`}
          </div>
          <div style={{ color: 'green' }}>
            <b>Order:</b>
            {`${formatShortNumber(currentData?.totalOrder)}`}
          </div>
          <div style={{ color: 'blue' }}>
            <b>Revenue:</b>
            {`${formatShortNumber(currentData?.totalRevenue * MIN_PRICE)} VND`}
          </div>
        </div>
      </div>
    );
  }

  return null;
};

const LineChart = ({ onClick, resource = [] }) => (
  <BarChartWrapper>
    <ReLineChart
      width={1200}
      height={400}
      data={[...resource]}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray="1 1" />
      <XAxis height={100} dataKey="name" angle={-50} dy={20} interval={0} />
      <YAxis yAxisId="left" tickFormatter={e => e} />
      <YAxis
        yAxisId="right"
        orientation="right"
        tickFormatter={e => formatShortNumber(e * MIN_PRICE)}
      />
      <Tooltip content={<CustomTooltip resource={resource} />} />
      <Legend />
      <Line
        yAxisId="left"
        onClick={onClick}
        type="monotone"
        dataKey="totalCustomer"
        stroke="#ff0000"
      />
      <Line
        yAxisId="left"
        onClick={onClick}
        type="monotone"
        dataKey="totalOrder"
        stroke="#00FF00"
      />
      <Line
        yAxisId="right"
        onClick={onClick}
        dataKey="totalRevenue"
        stroke="#0000FF"
      />
    </ReLineChart>
  </BarChartWrapper>
);

LineChart.propTypes = {
  onClick: PropTypes.func,
  resource: PropTypes.array,
};

LineChart.defaultProps = {
  resource: [],
};

export default LineChart;
