import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';

export const usePermissionRoutes = (routeName) => {
  const user = useSelector((state) => state.auth.data);
  const [isActive, setActive] = useState(false);
  const IGNORE_ROUTES = ['/', 'setting', '/profile'];

  const breakLinks = routeName.split('/');
  useEffect(() => {
    if (
      breakLinks[1] &&
      user.permission &&
      user.permission[breakLinks[1]]?.indexOf('get') > -1
    ) {
      breakLinks[2] !== 'create'
        ? setActive(true)
        : setActive(user.permission[breakLinks[1]].indexOf('create') > -1);
    }

    if (
      breakLinks[1] &&
      user.permission &&
      (breakLinks[3] === 'edit' || breakLinks[3] === 'show') &&
      user.permission[breakLinks[1]]?.indexOf('getOne') > -1
    ) {
      setActive(true);
    }

    if (breakLinks[1] === 'reports' && user.permission) {
      const hasReportPath = Object.keys(user.permission).find(
        (key) => user.permission[key].indexOf('analysis') > -1
      );
      setActive(!!hasReportPath);
    }

    if (breakLinks[1] === 'deleted' && user.permission) {
      const hasDeletedPath = Object.keys(user.permission).find(
        (key) => user.permission[key].indexOf('getDelete') > -1
      );
      setActive(!!hasDeletedPath);
    }
    // eslint-disable-next-line
  }, [user.permission]);
  if (user.scope === 'manager') return true;
  if (
    IGNORE_ROUTES.indexOf(routeName) > -1 ||
    routeName.indexOf('settings') > -1
  )
    return true;
  return isActive;
};

export const useAccessSidebar = (menus) => {
  const user = useSelector((state) => state.auth.data);
  const [accessMenu, setAccessMenu] = useState([]);
  useEffect(() => {
    const IGNORE_ROUTES = ['/'];

    setAccessMenu(
      // eslint-disable-next-line
      menus.filter((menu) => {
        if (
          menu.url.indexOf('settings') > -1 ||
          IGNORE_ROUTES.indexOf(menu.url) > -1
        )
          return true;
        const breakLinks = menu.url.split('/');
        if (
          breakLinks[1] &&
          user.permission &&
          user.permission[breakLinks[1]]?.indexOf('get') > -1
        ) {
          return breakLinks[2] !== 'create'
            ? true
            : user.permission[breakLinks[1]].indexOf('create') > -1;
        }
        if (breakLinks[1] === 'reports' && user.permission) {
          const hasReportPath = Object.keys(user.permission).find(
            (key) => user.permission[key].indexOf('analysis') > -1
          );
          return hasReportPath;
        }
        if (breakLinks[1] === 'deleted' && user.permission) {
          const hasDeletedPath = Object.keys(user.permission).find(
            (key) => user.permission[key].indexOf('getDelete') > -1
          );
          return hasDeletedPath;
        }
      })
    );
    // eslint-disable-next-line
  }, [user.permission]);
  if (user.scope === 'manager') return menus;
  return accessMenu;
};

export const useAccessSettingTabs = (tabs) => {
  const user = useSelector((state) => state.auth.data);
  const [accessMenu, setAccessMenu] = useState([]);
  useEffect(() => {
    const IGNORE_ROUTES = ['/'];

    setAccessMenu(
      // eslint-disable-next-line
      tabs.filter((menu) => {
        if (
          menu.url.indexOf('settings') > -1 ||
          IGNORE_ROUTES.indexOf(menu.url) > -1
        )
          return true;
        const breakLinks = menu.url.split('/');
        if (
          breakLinks[1] &&
          user.permission &&
          user.permission[breakLinks[1]]?.indexOf('get') > -1
        ) {
          return breakLinks[1] !== 'create'
            ? true
            : user.permission[breakLinks[1]].indexOf('create') > -1;
        }
      })
    );
    // eslint-disable-next-line
  }, [user.permission]);
  if (user.scope === 'manager') return tabs;
  return accessMenu;
};
