import React, { useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import RestSelect from '../../../../components/RestInput/RestSelect/index';
import ReferenceInput from '../../../../containers/rest/ReferenceInput';

const CustomerPackage = ({ record, form }) => {
  const [customerId, setCustomerId] = useState(record && record.customerId);

  useLayoutEffect(() => {
    const newCustomerId = form.getFieldValue('customerId');
    if (customerId !== newCustomerId) {
      setCustomerId(newCustomerId);
      form.setFieldsValue({ packageId: undefined });
    }
  }, [form,customerId]);

  return customerId ? (
    <ReferenceInput
      record={record}
      initialFilter={{
        filter: {
          customerId,
        },
      }}
      source="packageId"
      resource="packages"
    >
      <RestSelect source="packageId" valueProp="id" titleProp="name" header="orders.packageId" />
    </ReferenceInput>
  ) : (
    <span />
  );
};

CustomerPackage.propTypes = {
  record: PropTypes.object,
  form: PropTypes.object,
};

export default CustomerPackage;
