import { DEFERRED } from 'redux/ExposedPromiseMiddleware';
import {
  makeCRUDConstantCreator,
  makeCRUDActionsCreator,
} from '../crudCreator/actions';
import { makeConstantCreator, makeActionCreator } from '../../utils/reduxUtils';

export const PRIMARY_KEY = 'id';
export const MODEL = 'orderHistories';
export const IGNORE_ACTIONS = [];
export const OrderHistoriesTypes = {
  ...makeCRUDConstantCreator(MODEL, IGNORE_ACTIONS),
  ...makeConstantCreator(
    'CLEAN_IS_CURRENT_REQUEST',
    'CLEAN_IS_CURRENT_REQUEST_SUCCESS',
    'FORCE_UPDATE',
    'FORCE_UPDATE_SUCCESS',
    'FORCE_UPDATE_FAILURE',
  ),
};
const CRUDOrderHistoriesActions = makeCRUDActionsCreator(MODEL, IGNORE_ACTIONS);
export const cleanIsCurrentRequest = (id, data) =>
  makeActionCreator(OrderHistoriesTypes.CLEAN_IS_CURRENT_REQUEST, { id, data });
export const cleanIsCurrentRequestSuccess = (id) =>
  makeActionCreator(OrderHistoriesTypes.CLEAN_IS_CURRENT_REQUEST_SUCCESS, {
    id,
  });
const forceUpdate = (data) =>
  makeActionCreator(OrderHistoriesTypes.FORCE_UPDATE, {
    data,
    [DEFERRED]: true,
  });
export const forceUpdateSuccess = (id, data) =>
  makeActionCreator(OrderHistoriesTypes.FORCE_UPDATE_SUCCESS, data);
export const forceUpdateFailure = (id, data) =>
  makeActionCreator(OrderHistoriesTypes.FORCE_UPDATE_FAILURE, data);
/**
 * getAllCaseTypes({pageSize, page })
 * getByIdCaseTypes(data)
 * createCaseTypes(data)
 * deleteCaseTypes()
 * editCaseTypes(data)
 */
export default {
  ...CRUDOrderHistoriesActions,
  forceUpdate,
  forceUpdateSuccess,
  forceUpdateFailure,
};
