import { takeEvery, put, call } from 'redux-saga/effects';
import CommentsActions, {
  MODEL,
  IGNORE_ACTIONS,
  PRIMARY_KEY,
  CommentsTypes,
  getCommentByOrderSuccess,
  getCommentByOrderFailure,
} from './actions';
import rootCRUDSaga from '../crudCreator/saga';
import { apiWrapper } from '../../utils/reduxUtils';
import { getCommentByOrder } from '../../api/order';
// use IGNORE_SAGAS to replace "saga" or ignore "saga"
// IGNORE_SAGAS = ['GET_ALL', 'GET_BY_ID', 'DELETE', 'EDIT', 'CREATE'];

const IGNORE_SAGAS = IGNORE_ACTIONS;

export function* getCommentByOrderSaga({ orderId }) {
  try {
    const response = yield call(apiWrapper, { isShowProgress: true }, getCommentByOrder, orderId);
    // TODO: Set token in cookies
    if (!response) {
      throw response;
    }
    yield put(getCommentByOrderSuccess(response));
  } catch (err) {
    yield put(getCommentByOrderFailure(err));
  }
}

export default [
  ...rootCRUDSaga(MODEL, IGNORE_SAGAS, CommentsActions, PRIMARY_KEY),
  takeEvery(CommentsTypes.GET_COMMENT_BY_ORDER, getCommentByOrderSaga),
];
