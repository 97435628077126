import styled from 'styled-components';
import { Form } from 'antd';

const FormItem = Form.Item;

export const FormItemWrapper = styled(FormItem)`
  .ant-input-number {
    width: 100%;
  }
  .ant-form-item-label {
    label {
      color: ${({ theme }) => theme.palette.primary};
      font-size: 14px;
      font-weight: 500;
      &:after {
        content: '';
      }
    }
  }
  &.disabled {
    .ant-input-disabled,
    .ant-select-selection__rendered,
    .ant-input-number-input,
    .ant-input-number-input-wrap,
    .ant-input-number-disabled {
      background: transparent;
      border: none;
      margin-left: 0px;
      padding-left: 0px;
      color: #000;
    }
    .ant-form-explain-holder {
      display: none;
    }
  }
`;
