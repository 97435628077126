import React from 'react';
import i18next from 'i18next';
import RestInputItem from '../../../../components/RestInput/RestInputItem';
import RestAvatarInput from '../../../../components/RestInput/RestAvatarInput';
import RestTextEditor from '../../../../components/RestInput/RestTextEditor';

const ProductCatalogsForm = (props) => (
  <div {...props}>
    <RestInputItem source="name" header="productTypes.name" />
    <RestTextEditor
      isCreate
      source="description"
      header="productTypes.description"
    />
    {/* <RestAvatarInput
      style={{ marginBottom: 30, width: 250, height: 150, borderRadius: 0 }}
      defaultIcon="picture"
      defaultText="picture"
      hasCrop={false}
      source="mockupFile"
      type="platform"
      label={i18next.t('productTypes.mockupFile')}
    /> */}
    <RestAvatarInput
      style={{ marginBottom: 30, width: 250, height: 150, borderRadius: 0 }}
      defaultIcon="picture"
      defaultText="picture"
      hasCrop={false}
      source="templateFile"
      type="productCatalog"
      label={i18next.t('productTypes.templateFile')}
    />
  </div>
);

ProductCatalogsForm.propTypes = {};

export default ProductCatalogsForm;
