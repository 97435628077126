import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'antd';
import i18next from 'i18next';
import TextEditor from 'components/common/TextEditor';

const ExtraAction = ({ isUpload, accept, reject }) => {
  const [visible, setVisible] = useState(false);
  const [report] = useState({ text: '' });
  const onPressReject = () => {
    setVisible(true);
  };
  const onOk = () => {
    reject(report.text);
    setVisible(false);
  };
  return (
    <div>
      <Button loading={isUpload} onClick={accept} icon="check" type="primary">
        {i18next.t('button.accept')}
      </Button>
      &nbsp;
      <Button
        loading={isUpload}
        onClick={onPressReject}
        ghost
        icon="close"
        type="danger"
      >
        {i18next.t('button.reject')}
      </Button>
      <Modal
        title={i18next.t('orderHistories.modal.reject')}
        visible={visible}
        onCancel={() => setVisible(false)}
        onOk={onOk}
      >
        <TextEditor
          onEditorChange={e => {
            report.text = e;
          }}
          placeholder={i18next.t('orderHistories.modal.rejectReason')}
        />
      </Modal>
    </div>
  );
};

ExtraAction.propTypes = {
  accept: PropTypes.func,
  reject: PropTypes.func,
  isUpload: PropTypes.bool,
};

ExtraAction.defaultProps = {
  accept: () => {},
  reject: () => {},
};

export default ExtraAction;
