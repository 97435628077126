import React, { useState } from 'react';
import { Tabs, DatePicker } from 'antd';
import PageTitle from 'components/common/PageTitle';
import i18next from 'i18next';
import SalaryInfosList from 'containers/SalaryInfos/List';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import moment from 'moment';
import EditableTable from './UserSalaries';
import { getSearch, getFilterFromUrl } from '../../../utils/tools';

const Filter = ({ filter, setFilter }) => (
  <div>
    <DatePicker.MonthPicker
      defaultValue={moment(filter.dateMonth)}
      onChange={(value) =>
        setFilter({
          ...filter,
          initialFilter: {
            filter: {
              dateMonth: moment(value?.format())
                ?.startOf('month')
                ?.format('YYYY-MM-DD'),
            },
          },
          dateMonth: moment(value?.format())
            ?.startOf('month')
            ?.format('YYYY-MM-DD'),
        })
      }
      placeholder={i18next.t('salaryInfos.dateMonth')}
    />
    &nbsp;
    <DatePicker
      defaultValue={moment(filter.startDate)}
      onChange={(value) =>
        setFilter({
          ...filter,
          startDate: value?.format('YYYY-MM-DD'),
        })
      }
      placeholder={i18next.t('salaryInfos.startDate')}
    />
    &nbsp;
    <DatePicker
      defaultValue={moment(filter.endDate)}
      onChange={(value) =>
        setFilter({
          ...filter,
          endDate: value?.format('YYYY-MM-DD'),
        })
      }
      placeholder={i18next.t('salaryInfos.endDate')}
    />
  </div>
);

function NestedTable(props) {
  const filterFromUrl = getFilterFromUrl(props.location.search)?.filter;
  const [filter, setFilter] = useState({
    initialFilter: {
      filter: {
        dateMonth:
          filterFromUrl?.dateMonth ||
          moment().startOf('month').format('YYYY-MM-DD'),
      },
    },
    dateMonth:
      filterFromUrl?.dateMonth ||
      moment().startOf('month').format('YYYY-MM-DD'),
    startDate: moment().date(20).subtract(1, 'month').format('YYYY-MM-DD'),
    endDate: moment().date(20).format('YYYY-MM-DD'),
  });
  const dispatch = useDispatch();

  const onChangeFilter = (filter) => {
    dispatch(
      push(
        `${props.location.pathname}?${getSearch({
          filter: {
            dateMonth: moment(filter.dateMonth)
              .startOf('month')
              .format('YYYY-MM-DD'),
          },
        })}`,
      ),
    );
    setFilter(filter);
    // this.props.retrieveList(filter, true);
  };

  const onChangeTab = (e) => {
    dispatch(push(`/salaryHistories/${e}${props.location.search}`));
  };

  return (
    <>
      <PageTitle
        extraAction={<Filter filter={filter} setFilter={onChangeFilter} />}
      >
        {i18next.t('salaries.title', {
          dateMonth: moment(filter.dateMonth).format('MM'),
          startDate: moment(filter.startDate).format('DD/MM'),
          endDate: moment(filter.endDate).format('DD/MM'),
        })}
      </PageTitle>
      <Tabs
        onTabClick={onChangeTab}
        defaultActiveKey={props?.match?.params?.model}
      >
        <Tabs.TabPane tab={i18next.t('salaryHistories.title')} key="users">
          <EditableTable
            location={props.location}
            setFilter={onChangeFilter}
            filterData={filter}
            rootPath="/salaryHistories"
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab={i18next.t('salaryInfos.title')} key="salaryInfos">
          <SalaryInfosList
            {...props}
            isUpdateRoute={false}
            initialFilter={
              {
                // filter: {
                //   dateMonth: moment(filter.dateMonth)
                //     .startOf('month')
                //     .format(),
                // },
              }
            }
            rootPath="/salaryHistories"
            noCardWrapper
          />
        </Tabs.TabPane>
      </Tabs>
    </>
  );
}

export default NestedTable;
