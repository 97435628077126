import React from "react";
import moment from "moment";
import { Input, Row, Col, InputNumber } from "antd";
import { useSelector } from "react-redux";
import RestInputItem from "../../../../components/RestInput/RestInputItem";
import RestAvatarInput from "../../../../components/RestInput/RestAvatarInput";
import ReferenceInput from "../../../../containers/rest/ReferenceInput";
import RestSelect from "../../../../components/RestInput/RestSelect";
import { PAYMENT_STATUS } from "../../../../configs/localData";
import RestFormDateTimePicker from "../../../../components/RestInput/RestDateTimePicker";
import { RestInputContext } from "../../../../components/RestInput/RestInputContext/index";
import CustomerPackage from "../CustomerPackage/index";
import RestTextEditor from "../../../../components/RestInput/RestTextEditor/index";
import SearchByImageButton from "containers/SearchByImage/SearchByImageButton";

const OrdersForm = (props) => {
  const orderFeatures = useSelector((state) => state.config?.orderFeatures);
  console.log(props);
  return (
    <div>
      <RestAvatarInput
        style={{ width: 250, height: 150, borderRadius: 0 }}
        defaultIcon="picture"
        defaultText="picture"
        hasCrop={false}
        source="thumbnail"
        required
      />
      <div className="d-flex flex-center">
        <RestInputContext.Consumer>
          {({ record, form, handleSubmit }) => (
            <SearchByImageButton
              initImage={form.getFieldValue("thumbnail")}
              onLink={(selected) => {
                const note = form.getFieldValue("customerRequirements");
                form.setFieldsValue({
                  customerRequirements: `${note.substring(
                    0,
                    note.indexOf("<b>Link Order</b>:")
                  )}<b>Link Order</b>: ${selected
                    .map(
                      (item) =>
                        `<div><a href="/orders/${item}/edit">#${item}</a></div>`
                    )
                    .join("")}`,
                });
              }}
            />
          )}
        </RestInputContext.Consumer>
      </div>
      <RestInputItem style={{ display: "none" }} source="channelId">
        <input style={{ display: "none" }} />
      </RestInputItem>
      <RestInputItem required source="name" header="orders.name" />
      <RestInputItem ruleType="array" isReference source="talogIds">
        <ReferenceInput
          source="productCatalogIds"
          valueProp="id"
          ruleType="array"
          titleProp="displayName"
          resource="productTypes"
        >
          <RestSelect
            ruleType="array"
            selectProps={{ mode: "multiple" }}
            source="productCatalogIds"
            valueProp="id"
            titleProp="name"
            header="orders.productCatalogId"
          />
        </ReferenceInput>
      </RestInputItem>
      <Row gutter={16} {...props}>
        <Col md={12}>
          <RestInputItem isReference source="customerId">
            <ReferenceInput
              source="customerId"
              resource="customers"
              searchKey="displayName"
              initialFilter={{ includes: "channel" }}
            >
              <RestSelect
                source="customerId"
                valueProp="id"
                onChange={(form) => (e) => {
                  form.setFieldsValue({ channelId: e && e.channelId });
                }}
                titleProp="displayName"
                header="orders.customer"
                formatText={(data, record) =>
                  `${record.displayName} - channel ${
                    record.channel && record.channel.name
                  }`
                }
              />
            </ReferenceInput>
          </RestInputItem>
          <RestInputItem isReference source="saleId">
            <ReferenceInput source="saleId" resource="salers">
              <RestSelect
                source="saleId"
                valueProp="id"
                titleProp="displayName"
                header="orders.saleId"
              />
            </ReferenceInput>
          </RestInputItem>
          <RestSelect
            resourceData={orderFeatures}
            ruleType="array"
            selectProps={{ mode: "multiple" }}
            source="orderFeature"
            header="orders.orderFeature"
          />
          <RestInputItem isReference source="orderRequirementId">
            <ReferenceInput
              source="orderRequirementId"
              resource="orderRequirements"
            >
              <RestSelect
                ruleType="number"
                source="orderRequirementId"
                valueProp="id"
                titleProp="name"
                header="orders.orderRequirementId"
              />
            </ReferenceInput>
          </RestInputItem>
        </Col>
        <Col md={12}>
          <RestFormDateTimePicker
            defaultValue={moment().add(24, "hour").toISOString()}
            source="finishedDate"
            header="orders.finishedDate"
            required
          />
          <RestInputItem isReference source="saleId">
            <ReferenceInput source="dealerId" resource="dealers">
              <RestSelect
                source="dealerId"
                valueProp="id"
                titleProp="displayName"
                header="orders.dealerId"
              />
            </ReferenceInput>
          </RestInputItem>
          <RestInputItem
            ruleType="number"
            source="priceOrder"
            header="orders.priceOrder"
          >
            <InputNumber
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value) => value.replace(/\\s?|(,*)/g, "")}
            />
          </RestInputItem>
          <RestSelect
            ruleType="string"
            resourceData={PAYMENT_STATUS}
            defaultValue={PAYMENT_STATUS[1].value}
            source="paymentStatus"
            header="orders.paymentStatus"
          />
          <RestInputContext.Consumer>
            {({ record, form }) => (
              <CustomerPackage record={record} form={form} />
            )}
          </RestInputContext.Consumer>
        </Col>
        <Col span={24}>
          <RestInputItem source="customerNote" header="orders.customerNote">
            <Input.TextArea />
          </RestInputItem>
          <RestTextEditor
            isCreate
            source="customerRequirements"
            header="orders.customerRequirement"
            defaultValue={`
<b>Template/ size:</b><br/><br/>
<b>Yêu cầu</b>:<br/><br/>
<b>Note</b>:<br/><br/>
`}
          />
        </Col>
      </Row>
    </div>
  );
};

OrdersForm.propTypes = {};

export default OrdersForm;
