import React from 'react';
import { formatDateTime } from 'utils/textUtils';
import List from '../../rest/List';
import RestFieldItem from '../../../components/RestField/RestFieldItem';

const LogsList = (props) => (
  <List
    {...props}
    hasCreate={false}
    hasSearch={false}
    initialFilter={{ includes: 'customer', orderBy: '-createdAt' }}
    resource="logs"
  >
    <RestFieldItem filterKey="id" source="id" sorter hasSearch header="ID" />
    <RestFieldItem
      format={(data) => formatDateTime(data)}
      source="createdAt"
      sorter
      header="logs.createdAt"
    />
    <RestFieldItem source="customer.displayName" header="logs.customer" />
    <RestFieldItem
      format={(data) => (
        <a href={data} target="_blank" rel="noopener noreferrer">
          link
        </a>
      )}
      source="linkFile"
      header="Link File"
    />
    <RestFieldItem
      format={(data) => (
        <ul>
          {data?.map((e) => (
            <li>
              <b>{e.status}</b>
              {` - ${e.name || 'N/A'}`}
            </li>
          ))}
        </ul>
      )}
      source="result"
      header="logs.result"
    />
    <RestFieldItem source="status" header="logs.status" />
  </List>
);

LogsList.propTypes = {};

export default LogsList;
