import { makeCRUDConstantCreator, makeCRUDActionsCreator } from '../crudCreator/actions';
import { makeConstantCreator, makeActionCreator } from '../../utils/reduxUtils';

export const PRIMARY_KEY = 'id';
export const MODEL = 'comments';
export const IGNORE_ACTIONS = [];
export const CommentsTypes = {
  ...makeCRUDConstantCreator(MODEL, IGNORE_ACTIONS),
  ...makeConstantCreator(
    'GET_COMMENT_BY_ORDER',
    'GET_COMMENT_BY_ORDER_SUCCESS',
    'GET_COMMENT_BY_ORDER_FAILURE'
  ),
};
const CRUDCommentsActions = makeCRUDActionsCreator(MODEL, IGNORE_ACTIONS);
export const getCommentByOrder = orderId =>
  makeActionCreator(CommentsTypes.GET_COMMENT_BY_ORDER, { orderId });
export const getCommentByOrderSuccess = data =>
  makeActionCreator(CommentsTypes.GET_COMMENT_BY_ORDER_SUCCESS, { data });
export const getCommentByOrderFailure = error =>
  makeActionCreator(CommentsTypes.GET_COMMENT_BY_ORDER_FAILURE, { error });
/**
 * getAllCaseTypes({pageSize, page })
 * getByIdCaseTypes(data)
 * createCaseTypes(data)
 * deleteCaseTypes()
 * editCaseTypes(data)
 */
export default { ...CRUDCommentsActions };
