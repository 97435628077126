import React, { useLayoutEffect } from 'react';
// import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import CommentItem from '../../../Comments/components/CommentItem';
import CommentActions, {
  getCommentByOrder,
} from '../../../../redux/comments/actions';
import CommentEditor from '../../../Comments/components/CommentEditor';

const ReviewCustomer = ({ order }) => {
  const dispatch = useDispatch();
  const customerComment = useSelector(
    (state) => state.comments.customerComment
  );

  const createComments = (content, rate) =>
    new Promise((resolve, reject) => {
      dispatch(
        CommentActions.createComments(
          {
            content,
            rate,
            orderId: order.id,
            customerId: order.customerId,
          },
          { isBack: false, customApi: 'customerComments' }
        )
      )
        .then((e) => {
          order.id && dispatch(getCommentByOrder(order.id));
          resolve(e);
        })
        .catch((e) => {
          reject(e);
        });
    });
  useLayoutEffect(() => {
    order.id && dispatch(getCommentByOrder(order.id));
  }, [order.id, dispatch]);

  return (
    <div style={{ textAlign: 'left' }}>
      <CommentItem
        initialOptions={{ customApi: 'customerComments' }}
        {...customerComment}
      />
      {(!customerComment || !customerComment.id) && (
        <CommentEditor onSubmit={createComments} hasRate />
      )}
    </div>
  );
};
ReviewCustomer.propTypes = {};

export default ReviewCustomer;
