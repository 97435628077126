import { takeEvery, put, call } from 'redux-saga/effects';
import { sortBy } from 'lodash';
import DesignersActions, {
  DesignersTypes,
  MODEL,
  IGNORE_ACTIONS,
  PRIMARY_KEY,
  getDesignersAnalysisSuccessAction,
  getDesignersAnalysisFailureAction,
  getDesignersAnalysisBaseOnStatusSuccessAction,
  getDesignersAnalysisBaseOnStatusFailureAction
} from './actions';
import rootCRUDSaga from '../crudCreator/saga';
import { apiWrapper } from '../../utils/reduxUtils';
import {
  getDesignersAnalysis,
  getDesignersAnalysisBaseOnStatus
} from '../../api/user';
import { convertRequestParams } from '../crudCreator/dataProvider';
// use IGNORE_SAGAS to replace "saga" or ignore "saga"
// IGNORE_SAGAS = ['GET_ALL', 'GET_BY_ID', 'DELETE', 'EDIT', 'CREATE'];

const IGNORE_SAGAS = IGNORE_ACTIONS;

function* getDesignerAnalysisSaga({ data }) {
  try {
    const response = yield call(
      apiWrapper,
      {
        isShowLoading: false,
        isShowSuccessNoti: false
      },
      getDesignersAnalysis,
      convertRequestParams('GET_ALL', data)
    );
    if (!response.error) {
      yield put(
        getDesignersAnalysisSuccessAction({
          numOrderPieChar: sortBy(response.numOrderPieChar, 'value'),
          revenuePieChar: sortBy(response.revenuePieChar, 'value')
        })
      );
    } else {
      yield put(getDesignersAnalysisFailureAction(response));
    }
  } catch (error) {
    yield put(getDesignersAnalysisFailureAction(error));
  }
}

function* getDesignerAnalysisBaseOnStatusSaga({ status = 'done', data }) {
  try {
    const response = yield call(
      apiWrapper,
      {
        isShowLoading: false,
        isShowSuccessNoti: false
      },
      getDesignersAnalysisBaseOnStatus,
      status,
      convertRequestParams('GET_ALL', data)
    );
    if (!response.error) {
      yield put(
        getDesignersAnalysisBaseOnStatusSuccessAction({
          numOrderPieChar: sortBy(response.numOrderPieChar, 'value'),
          revenuePieChar: sortBy(response.revenuePieChar, 'value')
        })
      );
    } else {
      yield put(getDesignersAnalysisBaseOnStatusFailureAction(response));
    }
  } catch (error) {
    yield put(getDesignersAnalysisBaseOnStatusFailureAction(error));
  }
}

export default [
  ...rootCRUDSaga(MODEL, IGNORE_SAGAS, DesignersActions, PRIMARY_KEY),
  takeEvery(DesignersTypes.GET_DESIGNER_ANALYSIS, getDesignerAnalysisSaga),
  takeEvery(
    DesignersTypes.GET_DESIGNER_ANALYSIS_BASE_ON_ORDER_STATUS,
    getDesignerAnalysisBaseOnStatusSaga
  )
];
