import styled from 'styled-components';

export const PieCharWrapper = styled.div`
  display: flex;
  .listResource {
    flex: 1;
    max-height: 400px;
    width: 200px;
    overflow: auto;
    .item {
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 5px 0px;
      .value {
        margin-left: 5px;
        font-size: 12px;
        color: #515151;
      }
    }
  }
`;
