import styled from 'styled-components';

export const ReportFilterWrapper = styled.div`
  padding: 20px;
  text-align: center;
`;

export default styled.div`
  .ant-tabs-nav-scroll {
    text-align: center;
  }
  .ant-col {
    margin-top: 15px;
  }
`;
