import React from 'react';
import {
  BarChart as ReBarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from 'recharts';
import PropTypes from 'prop-types';
import { formatShortNumber } from '../../utils/textUtils';
import { BarChartWrapper } from './styles';

const CustomTooltip = ({ active, payload, label }) => {
  if (active) {
    return (
      <div className="custom-tooltip">
        <p>
          <b className="label">{label}</b>
        </p>
        <div className="revenue">
          {`${formatShortNumber(payload && payload[0].value)}`}
        </div>
      </div>
    );
  }

  return null;
};

const DefaultBarChart = ({ onClick, resource = [], fill = '#8884d8' }) => (
  <BarChartWrapper>
    <ReBarChart
      width={1200}
      height={400}
      data={[...resource]}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray="1 1" />
      <XAxis height={100} dataKey="name" angle={-50} dy={20} interval={0} />
      <YAxis tickFormatter={e => formatShortNumber(e)} />
      <Tooltip content={<CustomTooltip />} />
      <Bar onClick={onClick} dataKey="value" fill={fill} />
    </ReBarChart>
  </BarChartWrapper>
);

DefaultBarChart.propTypes = {
  onClick: PropTypes.func,
  redirectWhenClick: PropTypes.string,
  resource: PropTypes.array,
  fill: PropTypes.string,
};

DefaultBarChart.defaultProps = {
  resource: [],
  fill: '#8884d8',
};

export default DefaultBarChart;
