import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import Edit from '../../../containers/rest/Edit';
import UserBreadcrumb from '../components/UserBreadcrumb';
import Summaries from '../components/Summaries';
import UserDetail from '../components/UserDetail';
import UserActivity from '../components/UserActivity';

const UsersEdit = (props) => (
  <Edit
    {...props}
    noCardWrapper
    customEditButton={null}
    initOptions={{ isBack: false }}
    formatOnSubmit={({
      password,
      note,
      displayName,
      linkFB,
      birthday,
      phone,
      roleId,
      isOutsource,
      salaryScheme,
      coef,
      coefOT,
      customerId,
      trelloKey,
      trelloToken,
    }) => ({
      displayName,
      password,
      linkFB,
      birthday: birthday?.toISOString(),
      phone,
      roleId,
      isOutsource,
      note,
      salaryScheme,
      coef,
      coefOT,
      customerId,
      trelloKey,
      trelloToken,
    })}
    resource="users"
  >
    {props.showBreadcrumb && <UserBreadcrumb />}
    <Summaries isProfile={props.isProfile} />
    <br />
    <Row gutter={16}>
      <Col md={8}>
        <UserDetail />
      </Col>
      <Col md={16}>
        <UserActivity {...props} />
      </Col>
    </Row>
  </Edit>
);

UsersEdit.propTypes = {
  isProfile: PropTypes.bool,
};
UserDetail.defaultProps = {
  showBreadcrumb: true,
};
export default UsersEdit;
