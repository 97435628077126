import React from 'react';
import Edit from '../../rest/Edit';
import ConfirmOrderModal from './ConfirmPendingRewardModal';

const PaymentsEdit = (props) => (
  <Edit
    {...props}
    header="Review Request"
    customEditButton={<span />}
    resource="payments"
    initOptions={{ params: { includes: 'orders' } }}
  >
    <ConfirmOrderModal resource="designers" />
  </Edit>
);

PaymentsEdit.propTypes = {};

export default PaymentsEdit;
