import React from 'react';
import Create from '../../../containers/rest/Create';
import Form from '../components/Form';

const NichesCreate = props => (
  <Create {...props} resource="niches">
    <Form />
  </Create>
);

NichesCreate.propTypes = {};

export default NichesCreate;
