import React from 'react';
import { Tag } from 'antd';
import { useSelector } from 'react-redux';

export const FormatOrderFeature = ({ data, record }) => {
  const orderFeatures = useSelector((state) => state.config.orderFeatures);
  const features =
    orderFeatures.filter(
      (e) => record?.orderFeature?.indexOf?.(e.value) > -1,
    ) || [];
  return (
    <div style={{ width: 150 }}>
      {features.map((feature) => (
        <Tag color={feature.color} key={feature.value}>
          {feature.text}
        </Tag>
      ))}
    </div>
  );
};
