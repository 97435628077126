import React from 'react';
import { Row, Col, Rate } from 'antd';
// import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import SummaryCard from '../../../../components/common/SummaryCard';

const Summaries = () => {
  const currentCustomer = useSelector(state => state.customers.currentData);
  return (
    <Row gutter={16}>
      <Col md={24}>
        <SummaryCard
          value={currentCustomer.displayName}
          header={(
<div>
              <Rate value={currentCustomer.rate} />
              {`(${currentCustomer.totalRate || 0})`}
            </div>
)}
          color="#03a9f4"
          icon="user"
        />
        {/* <SummaryCard value={100} header="Revenue" color="#9b28b0" icon="dollar" /> */}
      </Col>
      {/* <Col md={8}>
      <SummaryCard value={50} header="Happy" color="#03a9f4" icon="heart" />
    </Col>
    <Col md={8}>
      <SummaryCard value={80} header="Order" color="#2ec0d5" icon="reconciliation" />
    </Col> */}
    </Row>
  );
};
Summaries.propTypes = {};

export default Summaries;
