import React from 'react';
import RestShow from '../../rest/Show';
import RestFieldItem from '../../../components/RestField/RestFieldItem';

const UserRequestsShow = props => (
  <RestShow {...props} hasEdit resource="userRequests">
    <RestFieldItem source="title" header="userRequests.title" />
    <RestFieldItem source="content" header="userRequests.content" />
    <RestFieldItem source="typeRequest" header="userRequests.typeRequest" />
  </RestShow>
);

export default UserRequestsShow;
