import React from 'react';
import RestShow from '../../rest/Show';
import RestFieldItem from '../../../components/RestField/RestFieldItem';

const WorkingTimesShow = props => (
  <RestShow {...props} hasEdit resource="workingRegisters">
    <RestFieldItem source="roleName" header="workingRegisters.roleName" />
    <RestFieldItem source="startTime" header="workingRegisters.startTime" />
    <RestFieldItem source="endTime" header="workingRegisters.endTime" />
    <RestFieldItem source="userName" header="workingRegisters.userName" />
    <RestFieldItem source="timeTables" header="workingRegisters.timeTables" />
  </RestShow>
);

export default WorkingTimesShow;
