import { makeReducerCreator } from '../../utils/reduxUtils';
import { makeCRUDReducerCreator, INITIAL_CRUD_STATE } from '../crudCreator/reducer';
import { MODEL, IGNORE_ACTIONS } from './actions';

export const INITIAL_STATE = {
  ...INITIAL_CRUD_STATE,
};

const cleanCurrentOrders = state => ({
  ...state,
  isAuthenticated: true,
  loginError: false,
  loginSuccess: true,
  currentData: {},
  currentId: null,
});

const reducer = makeReducerCreator(INITIAL_STATE, {
  ...makeCRUDReducerCreator(MODEL, IGNORE_ACTIONS),
  CLEAN_CURRENT_ORDERS: cleanCurrentOrders,
});

export default reducer;
