import {
  makeCRUDConstantCreator,
  makeCRUDActionsCreator,
} from "../crudCreator/actions";

export const PRIMARY_KEY = "id";
export const MODEL = "orders";
export const IGNORE_ACTIONS = [];
export const OrdersTypes = {
  ...makeCRUDConstantCreator(MODEL, IGNORE_ACTIONS),
};
const CRUDOrdersActions = makeCRUDActionsCreator(MODEL, IGNORE_ACTIONS);
/**
 * getAllCaseTypes({pageSize, page })
 * getByIdCaseTypes(data)
 * createCaseTypes(data)
 * deleteCaseTypes()
 * editCaseTypes(data)
 */

export const editOrders = CRUDOrdersActions.editOrders;

export default { ...CRUDOrdersActions };
