import React from 'react';
import Create from '../../../containers/rest/Create';
import Form from '../components/Form';

const OrderHistoriesCreate = props => (
  <Create {...props} resource="orderHistories">
    <Form />
  </Create>
);

OrderHistoriesCreate.propTypes = {};

export default OrderHistoriesCreate;
