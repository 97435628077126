import React from 'react';
import PropTypes from 'prop-types';
import RestInputItem from 'components/RestInput/RestInputItem';
import RestSelect from 'components/RestInput/RestSelect';
import { ORDER_STATUS, PAYMENT_STATUS } from 'configs/localData';
import { Row, Col } from 'antd';
import ReferenceInput from 'containers/rest/ReferenceInput';
import { useSelector } from 'react-redux';

const Filter = ({ isUserDetail, user, isCustomerDetail }) => {
  const orderFeatures = useSelector((state) => state.config?.orderFeatures);
  return (
    <Row gutter={16}>
      <Col md={6}>
        <RestSelect
          ruleType="array"
          selectProps={{ mode: 'multiple' }}
          source="status.$in"
          placeholder="orders.status"
          isAutoUpdate
          resourceData={ORDER_STATUS}
        />
      </Col>
      <Col md={6}>
        <RestSelect
          ruleType="array"
          selectProps={{ mode: 'multiple' }}
          source="orderFeature.$containAll"
          placeholder="orders.orderFeature"
          isAutoUpdate
          resourceData={orderFeatures}
        />
      </Col>
      {!isUserDetail && !isCustomerDetail && (
        <Col md={6}>
          <RestInputItem source="designerId.$in" isReference>
            <ReferenceInput
              searchKey="displayName"
              resource="designers"
              source="designerId.$in"
            >
              <RestSelect
                source="designerId.$in"
                ruleType="array"
                selectProps={{ mode: 'multiple' }}
                placeholder="orders.designerId"
                isAutoUpdate
                valueProp="id"
                titleProp="displayName"
              />
            </ReferenceInput>
          </RestInputItem>
        </Col>
      )}
      {!isUserDetail && !isCustomerDetail && (
        <Col md={6}>
          <RestInputItem source="customerId.$in" isReference>
            <ReferenceInput
              searchKey="displayName"
              resource="customers"
              source="customerId.$in"
            >
              <RestSelect
                source="customerId.$in"
                ruleType="array"
                selectProps={{ mode: 'multiple' }}
                placeholder="orders.customer"
                isAutoUpdate
                valueProp="id"
                titleProp="displayName"
              />
            </ReferenceInput>
          </RestInputItem>
        </Col>
      )}
      {user?.permission?.orders?.indexOf('paymentStatus') > -1 && (
        <Col md={6}>
          <RestSelect
            filterKey="paymentStatus"
            source="paymentStatus"
            placeholder="orders.paymentStatus"
            isAutoUpdate
            resourceData={PAYMENT_STATUS}
          />
        </Col>
      )}
    </Row>
  );
};

Filter.propTypes = {
  isUserDetail: PropTypes.bool,
  user: PropTypes.object,
  isCustomerDetail: PropTypes.bool,
};

export default Filter;
