import React from 'react';
import Create from '../../../containers/rest/Create';
import Form from '../components/Form';

const SalerBSCreate = props => (
  <Create {...props} resource="salers">
    <Form />
  </Create>
);

SalerBSCreate.propTypes = {};

export default SalerBSCreate;
