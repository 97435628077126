import React from 'react';
import { Tag } from 'antd';
import { connect } from 'react-redux';
import GridPhotos from 'components/common/GridPhotos';
import List from '../../../containers/rest/List';
import ReferenceManyToMany from '../../../containers/rest/ReferenceManyToMany';
import RestFieldItem from '../../../components/RestField/RestFieldItem';
import ActionGroup from '../../../components/RestActions/ActionGroup';
import EditButton from '../../../components/RestActions/EditButton';
import DeleteButton from '../../../components/RestActions/DeleteButton';
import { formatMoney } from '../../../utils/textUtils';

const ProductsList = ({ isUserDetail, productTypes, ...props }) => (
  <div>
    <List
      {...props}
      initialFilter={{
        filter: props.user.isOutsource ? { creatorId: props.user.id } : {},
        ...props.initialFilter,
      }}
      hasSearch={false}
      isScroll={false}
      resource="products"
    >
      <RestFieldItem hasSearch source="name" header="products.name" />
      <ReferenceManyToMany
        filters={productTypes.map(e => ({ value: e.id, text: e.name }))}
        source="productCatalogIds"
        header="products.productCatalogId"
        resource="productTypes"
        filterKey="productCatalogIds.$containAll"
      >
        <RestFieldItem component={<Tag />} source="name" />
      </ReferenceManyToMany>
      <RestFieldItem
        format={(data, record) =>
          record && record.productImages ? (
            <GridPhotos images={record.productImages} />
          ) : (
            <GridPhotos images={data || []} />
          )
        }
        source="images"
        header="products.images"
      />
      {!props.user.isOutsource && (
        <RestFieldItem
          format={data => formatMoney(data)}
          sorter
          source="price"
          header="products.price"
        />
      )}
      {!isUserDetail && (
        <ActionGroup>
          <EditButton />
          <DeleteButton isSortDelete />
        </ActionGroup>
      )}
    </List>
  </div>
);

ProductsList.defaultProps = {
  initialFilter: {},
};

export default connect(state => ({
  user: state.auth.data,
  productTypes: state.config.productTypes,
}))(ProductsList);
