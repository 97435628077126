import React from 'react';
import Edit from '../../../containers/rest/Edit';
import Form from '../components/Form';

const BrandsEdit = props => (
  <Edit {...props} resource="brands">
    <Form />
  </Edit>
);

BrandsEdit.propTypes = {};

export default BrandsEdit;
