import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { getDesignersAnalysis } from '../../../redux/designers/actions';
import DefaultBarChart from '../../../components/BarChart/DefaultBarChart';

const DesignerNumOrder = ({ filter }) => {
  const dispatch = useDispatch();
  const numOrderPieChar = useSelector(
    state => state.designers.analysis.numOrderPieChar
  );

  const onClick = e => {
    dispatch(push(`/users/${e.id}/edit`));
  };

  useLayoutEffect(() => {
    dispatch(getDesignersAnalysis({ filter }));
  }, [filter,dispatch]);

  return <DefaultBarChart onClick={onClick} resource={numOrderPieChar || []} />;
};
DesignerNumOrder.propTypes = {
  filter: PropTypes.object,
};

export default DesignerNumOrder;
