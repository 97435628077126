import React from 'react';
import { Input } from 'antd';
import RestInputItem from '../../../../components/RestInput/RestInputItem';
import ReferenceInput from '../../../../containers/rest/ReferenceInput';
import RestSelect from '../../../../components/RestInput/RestSelect';
import RestUpload from '../../../../components/RestInput/RestUpload';

const ProductsForm = props => (
  <div {...props}>
    <RestInputItem source="name" header="products.name" />
    <RestInputItem isReference source="productCatalogId">
      <ReferenceInput
        source="productCatalogIds"
        valueProp="id"
        titleProp="displayName"
        resource="productTypes"
      >
        <RestSelect
          ruleType="array"
          selectProps={{ mode: 'multiple' }}
          source="productCatalogIds"
          valueProp="id"
          titleProp="name"
          header="products.productCatalogId"
        />
      </ReferenceInput>
    </RestInputItem>
    <RestUpload useFormData source="productImages" header="products.images" />
    <RestInputItem ruleType="number" source="price" header="products.price">
      <Input type="number" addonAfter="VND" />
    </RestInputItem>
  </div>
);

ProductsForm.propTypes = {};

export default ProductsForm;
