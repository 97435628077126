import React from 'react';
import { Link } from 'react-router-dom';
import { PreviewTextEditor } from 'components/RestInput/RestTextEditor';
import { Icon } from 'antd';
import List from '../../rest/List';
import RestFieldItem from '../../../components/RestField/RestFieldItem';
import AcceptButton from '../components/AcceptButton';

const MistakeRecordsList = (props) => (
  <List
    initialFilter={{ includes: 'mistakeType, victim,creator' }}
    {...props}
    hasCreate={false}
    resource="mistakeRecords"
    hasExport={false}
  >
    <AcceptButton header="mistakeRecords.status" />
    {!props.isOrder && (
      <RestFieldItem
        hasSearch
        format={(data) => <Link to={`/orders/${data}/edit`}>{`#${data}`}</Link>}
        source="orderId"
        sorter
        header="mistakeRecords.orderId"
      />
    )}
    <RestFieldItem
      format={(data) => (
        <Link to={`/users/${data?.id}/edit`}>{`${data?.displayName}`}</Link>
      )}
      source="creator"
      header="Creator"
    />
    {!props.isDesigner && (
      <RestFieldItem
        format={(data) => (
          <Link to={`/users/${data?.id}/edit`}>{`${data?.displayName}`}</Link>
        )}
        source="victim"
        header="Victim"
      />
    )}
    <RestFieldItem
      format={(data) => (
        <a rel="noopener noreferrer" target="_blank" href={data}>
          <Icon type="link" />
        </a>
      )}
      source="link"
      header="mistakeRecords.link"
    />
    <RestFieldItem
      source="mistakeType.name"
      header="mistakeRecords.mistakeTypeId"
    />
    <RestFieldItem
      sorter
      source="monetaryFine"
      header="mistakeRecords.monetaryFine"
    />
    <RestFieldItem
      format={(data) => <PreviewTextEditor html={data} />}
      source="note"
      header="mistakeRecords.note"
    />
  </List>
);

MistakeRecordsList.propTypes = {};

export default MistakeRecordsList;
