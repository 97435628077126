import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'antd';
import { EditUserAvatarViewWrapper } from './styles';
import RestInputItem from '../RestInput/RestInputItem';
import RestSelect from '../RestInput/RestSelect';
import SaveButton from '../RestActions/SaveButton';
import ReferenceInput from '../../containers/rest/ReferenceInput';

const EditUserAvatarView = ({ isShowSubTitle, avatarSource, titleSource }) => (
  <EditUserAvatarViewWrapper>
    <Card className="box">
      <div className="contentUserAvatar">
        {/* <RestAvatarInput
          style={{ width: 60, height: 60 }}
          source={avatarSource}
          header="customers.avatar"
        /> */}
        <div className="vInfo">
          <RestInputItem className="txtName" source={titleSource} />
          {isShowSubTitle && (
            <RestInputItem isReference source="userTypeId">
              <ReferenceInput
                source="userTypeId"
                valueProp="id"
                titleProp="name"
                resource="userTypes"
              >
                <RestSelect valueProp="id" titleProp="name" ruleType="number" source="userTypeId" />
              </ReferenceInput>
            </RestInputItem>
          )}
          <SaveButton className="icEdit" />
        </div>
      </div>
    </Card>
  </EditUserAvatarViewWrapper>
);
EditUserAvatarView.propTypes = {
  avatarSource: PropTypes.string,
  titleSource: PropTypes.string,
  isShowSubTitle: PropTypes.bool,
};

EditUserAvatarView.defaultProps = {
  avatarSource: 'avatar',
  titleSource: 'displayName',
  isShowSubTitle: true,
};
export default EditUserAvatarView;
