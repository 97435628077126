import styled from 'styled-components';

const ActivityLogWrapper = styled.div`
  padding-left: 20px;
  .timelineItem {
    margin-top: 10px;
    border-radius: 2px;
    background: ${({ theme }) => theme.background.content};
  }
  .headerItem {
    display: flex;
    align-items: center;
    .headerContent {
      margin-left: 10px;
    }
    .txtEdit {
      color: ${({ theme }) => theme.color.blue};
    }
  }
  .ant-timeline-item-head-custom {
    background: transparent;
    padding-top: 90px;
  }
  .ant-timeline-item-content {
    margin-left: 40px;
  }
  .dot {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: ${({ theme }) => theme.palette.primary};
  }
  .icDot {
    color: white;
  }
`;

export { ActivityLogWrapper };
