import React from 'react';
import Create from '../../../containers/rest/Create';
import Form from '../components/Form';

const CommentsCreate = props => (
  <Create {...props} resource="comments">
    <Form />
  </Create>
);

CommentsCreate.propTypes = {};

export default CommentsCreate;
