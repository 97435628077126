import React from 'react';
import Edit from '../../rest/Edit';
import Form from '../components/Form';

const OrderRequirementsEdit = props => (
  <Edit {...props} resource="orderRequirements">
    <Form />
  </Edit>
);

OrderRequirementsEdit.propTypes = {};

export default OrderRequirementsEdit;
