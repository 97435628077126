import React, { useLayoutEffect } from 'react';
import { Tag, Select } from 'antd';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import i18next from 'i18next';
import GridPhotos from 'components/common/GridPhotos';
import List from '../../../containers/rest/List';
import RestFieldItem from '../../../components/RestField/RestFieldItem';
import ActionGroup from '../../../components/RestActions/ActionGroup';
import EditButton from '../../../components/RestActions/EditButton';
import DeleteButton from '../../../components/RestActions/DeleteButton';
import ReferenceManyToMany from '../../../containers/rest/ReferenceManyToMany';
import { formatDateTime, formatMoney } from '../../../utils/textUtils';
import {
  ORDER_STATUS as _ORDER_STATUS,
  PAYMENT_STATUS,
  EmployeeKeys,
} from '../../../configs/localData';
import ButtonClone from '../components/ButtonClone';
// import ReferenceInput from '../../../containers/rest/ReferenceInput';
// import SelectField from '../../../components/RestField/SelectField';
import OrderHistories from '../../../redux/orderHistories/actions';
import OrdersActions from '../../../redux/orders/actions';
import SelectDesigner from '../components/SelectDesigner';
import UsersAction from '../../../redux/users/actions';
import PreviewImage from '../../../components/common/PreviewImage';
import UndoButton from '../../../components/RestActions/UndoButton';
import OrderListWrapper from './styles';
import Deadline from '../components/Deadline';
import DealPrice from '../components/DealPrice';
import Filter from '../components/Filter';
import GetSuggestionDesigner from '../components/GetSuggestionDesigner';
import CustomActions from '../components/CustomActions';
import SearchInList from 'containers/SearchByImage/SearchInList';

const OrdersList = ({
  isUserDetail,
  isSortDelete,
  isDeleted,
  clearOrders,
  isCustomerDetail,
  location,
  ...props
}) => {
  const orderFeatures = useSelector((state) => state.config?.orderFeatures);
  const user = useSelector((state) => state.auth.data);
  const ORDER_STATUS = _ORDER_STATUS.map((e) => ({
    ...e,
    isAccess:
      user.permission?.orderFlow?.indexOf(e.nextActionPermissionKey) > -1,
  }));
  useLayoutEffect(() => {
    !isUserDetail &&
      (user.permission?.orderFlow?.indexOf('nextNewDeal') > -1 ||
        user.permission?.orderFlow?.indexOf('nextDesignerConfirm') > -1) &&
      props.getAllUsers(
        {
          filter: {
            roleId: { $in:[ 7, 24 ]}
          },
        },
        { isRefresh: true },
      );
    return () => {
      clearOrders();
    };
    // eslint-disable-next-line
  }, []);
  if (typeof props.user.isOutsource === 'undefined') return null;
  return props.user.isOutsource ? (
    <OutsourceOrderList {...props} location={location} />
  ) : (
    <OrderListWrapper>
      <List
        {...props}
        redirects={{ edit: 'screen', create: 'screen' }}
        resource="orders"
        location={location}
        initialFilter={{
          ...props.initialFilter,
          includes:
            'package,customer,customer.channel,designer,orderRequirement,currentHistoryOrder,nicheData,platformData,products',
        }}
        filter={
          <Filter
            user={props.user}
            isUserDetail={isUserDetail}
            isCustomerDetail={isCustomerDetail}
          />
        }
        customActions={<CustomActions location={location} />}
      >
        <RestFieldItem
          format={(data, record) => (
            <div style={{ width: 150 }}>
              <div style={{ marginBottom: 10 }}>
                <Link to={`/orders/${data}/edit`}>
                  {`#${data}`}
                  <b style={{ color: '#000' }}>
                    {record?.codeCustomer
                      ? `\n(${record?.codeCustomer || ''})`
                      : ''}
                  </b>
                </Link>
              </div>
              {record && record?.products?.[0]?.productImages ? (
                <GridPhotos images={record?.products?.[0]?.productImages} />
              ) : (
                <GridPhotos images={record?.products?.[0]?.productImages} />
              )}
            </div>
          )}
          source="id"
          header="ID"
        />
        <RestFieldItem
          format={(data, record) => (
            <div style={{ width: 150 }}>
              {data}
              <br />
              <br />
              {record?.thumbnail && <PreviewImage src={record?.thumbnail} />}
            </div>
          )}
          source="name"
          header="orders.name"
        />
        <RestFieldItem
          format={(data, record) => {
            const status =
              _ORDER_STATUS.find((item) => item.value === data) || {};
            const features =
              orderFeatures.filter(
                (e) => record?.orderFeature?.indexOf?.(e.value) > -1,
              ) || [];
            return (
              <div style={{ width: 150 }}>
                <Tag
                  style={{ color: status.color || '#000' }}
                  color={status.background}
                >
                  {status && i18next.t(`orderStatuses.${status.value}`)}
                </Tag>
                <br />
                <br />
                {features.map((feature) => (
                  <Tag color={feature.color} key={feature.value}>
                    {feature?.text}
                  </Tag>
                ))}
              </div>
            );
          }}
          source="status"
          header="orders.status"
        />
        <RestFieldItem
          sorter
          format={(data) => <div>{`${formatMoney(data)} VND`}</div>}
          source="priceOrder"
          header="orders.priceOrder"
        />
        <RestFieldItem
          sorter
          format={(data, record, onChange) => (
            <div>
              <b>{`${i18next.t('orders.createdAt')}:`}</b>
              <br />
              {`${formatDateTime(record?.createdAt)}`}
              <br />
              {!isUserDetail &&
              props.user?.permission?.orders?.indexOf('paymentStatus') > -1 ? (
                <RestFieldItem
                  record={record}
                  format={(data) => (
                    <Select onChange={onChange} defaultValue={data}>
                      {PAYMENT_STATUS.map((e) => (
                        <Select.Option key={e.value}>{e.text}</Select.Option>
                      ))}
                    </Select>
                  )}
                  source="paymentStatus"
                  header="orders.paymentStatus"
                />
              ) : null}
            </div>
          )}
          source="paymentStatus"
          header="orders.fidTeam"
        />
        <ReferenceManyToMany
          header="orders.productCatalogId"
          source="productCatalogIds"
          resource="productTypes"
        >
          <RestFieldItem format={(data) => <Tag>{data}</Tag>} source="name" />
        </ReferenceManyToMany>
        <RestFieldItem
          sorter
          format={(data, record) => (
            <div>
              {!isCustomerDetail && (
                <Link
                  href={`/customers/${record && record.customerId}/edit`}
                  to={`/customers/${record && record.customerId}/edit`}
                >
                  {record
                    ? `${record?.customer?.displayName} - ${record?.customer?.channel?.name}`
                    : data}
                </Link>
              )}
              <Deadline order={record} />
            </div>
          )}
          source="deadline"
          header="orders.customer&deadline"
        />
        <RestFieldItem
          sorter
          canUpdate={false}
          format={(data, record) => (
            <div>
              {!isUserDetail && ORDER_STATUS[0].isAccess ? (
                <SelectDesigner
                  assignerDesigner={props.assignerDesigner}
                  source="designerId"
                  header="orders.designerId"
                  resource="users"
                  record={record}
                />
              ) : (
                <RestFieldItem
                  source="designer.displayName"
                  header="orders.designerId"
                  record={record}
                />
              )}
              <br />
              <br />
              <b>Deal Price</b>
              <br />
              <DealPrice order={record} />
              {record.status === ORDER_STATUS[0].value &&
                props.user?.permission?.orders?.indexOf('recommendDesigner') >
                  -1 && <GetSuggestionDesigner order={record} />}
            </div>
          )}
          source="dealPrice"
          header="orders.designer&dealPrice"
        />
        {!isUserDetail && (
          <RestFieldItem
            sorter
            format={(data, record) =>
              record?.package ? `${record?.package?.name} - No.${data}` : ''
            }
            source="packageOrder"
            header="orders.packageId"
          />
        )}
        <ActionGroup>
          {!isDeleted ? <ButtonClone /> : null}
          {!isDeleted && <EditButton />}
          {isDeleted ? <UndoButton /> : null}
          <DeleteButton isSortDelete={isSortDelete} />
          <SearchInList source='customerRequirements' />
        </ActionGroup>
      </List>
    </OrderListWrapper>
  );
};

const OutsourceOrderList = (props) => (
  <List
    {...props}
    hasCreate={false}
    redirects={{ edit: 'screen', create: 'screen' }}
    resource="orders"
    isScroll={false}
    initialFilter={
      props.user.roleId
        ? {
            filter: {
              [EmployeeKeys[props.user.roleId]]: props.user.id,
            },
          }
        : {}
    }
    defaultOptions={{ customApi: 'orders/on-pd' }}
  >
    <RestFieldItem
      format={(data) => <Link to={`/orders/${data}/edit`}>{`#${data}`}</Link>}
      hasSearch
      source="id"
      header="ID"
    />

    <RestFieldItem
      sorter
      format={(data, record) => <Deadline order={record} />}
      source="deadline"
      header="deadline"
    />
    <RestFieldItem
      format={(data, record) =>
        record && record.productImages
          ? record.productImages.map((e, index) => <PreviewImage image={e} />)
          : data && data.map((e, index) => <PreviewImage src={e} />)
      }
      source="products[0].images"
    />
    <RestFieldItem
      filters={_ORDER_STATUS.filter(
        (e) =>
          e.outsourceLimitFilter &&
          e.outsourceLimitFilter.indexOf(props.user.scope) > -1,
      )}
      format={(data) => {
        const status =
          _ORDER_STATUS.find(
            (item) => item.isShowOutsource && item.value === data,
          ) || {};
        return (
          <Tag
            style={{ color: status.color || '#000' }}
            color={status.background}
          >
            {status && i18next.t(`orderStatuses.${status.value}`)}
          </Tag>
        );
      }}
      source="status"
      header="orders.status"
    />
    <RestFieldItem
      sorter
      format={(data) => formatDateTime(data)}
      source="finishedDate"
      header="orders.finishedDate"
    />
    <RestFieldItem
      sorter
      format={(data, record) => <DealPrice order={record || {}} />}
      source="dealPrice"
      header="orders.dealPrice"
    />
    {/* <RestFieldItem source="customerRequirement" header="orders.customerRequirement" /> */}
  </List>
);

OrdersList.propTypes = {
  isUserDetail: PropTypes.bool,
  isSortDelete: PropTypes.bool,
};

OrdersList.defaultProps = {
  isSortDelete: true,
};

export default connect(
  (state) => ({
    user: state.auth.data,
  }),
  (dispatch) => ({
    assignerDesigner: (data) =>
      dispatch(
        OrderHistories.createOrderHistories(data, { isShowSuccessNoti: true }),
      ),
    getAllUsers: (data, options) =>
      dispatch(UsersAction.getAllUsers(data, options)),
    clearOrders: () => dispatch(OrdersActions.clearOrders()),
  }),
)(OrdersList);
