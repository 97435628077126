import React from 'react';
import RestShow from '../../rest/Show';
import RestFieldItem from '../../../components/RestField/RestFieldItem';

const MistakeRecordsShow = props => (
  <RestShow {...props} hasEdit resource="mistakeRecords">
    <RestFieldItem source="orderId" header="mistakeRecords.orderId" />
    <RestFieldItem source="link" header="mistakeRecords.link" />
    <RestFieldItem source="mistakeTypeId" header="mistakeRecords.mistakeTypeId" />
    <RestFieldItem source="monetaryFine" header="mistakeRecords.monetaryFine" />
    <RestFieldItem source="note" header="mistakeRecords.note" />
  </RestShow>
);

export default MistakeRecordsShow;
