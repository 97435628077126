import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'antd';
// import i18next from 'i18next';
import OrderStatusTitle from './OrderStatusTitle';
import ReferenceInput from '../../../../containers/rest/ReferenceInput';
import SelectUser from '../../../../components/common/SelectUser';
import { PreviewTextEditor } from '../../../../components/RestInput/RestTextEditor/index';

const ReceiveNewDeal = ({ item }) => (
  <Card title={<OrderStatusTitle item={item} />} className="timelineItem">
    {item.note && <PreviewTextEditor html={item.note} />}
    {item.report && <PreviewTextEditor html={item.report} />}
  </Card>
);

export const ReceiveNewDealAction = ({ setSelectedUser }) => {
  useLayoutEffect(() => {});

  return (
    <ReferenceInput resource="leaders">
      <SelectUser onChange={setSelectedUser} />
    </ReferenceInput>
  );
};

ReceiveNewDealAction.propTypes = {
  setSelectedUser: PropTypes.func,
};

ReceiveNewDeal.propTypes = {
  item: PropTypes.object,
};

export default ReceiveNewDeal;
