import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Icon, Row, Col, Modal, Button } from 'antd';
import i18next from 'i18next';
import TextEditor from 'components/common/TextEditor';
import Text from '../../../../components/common/Text';
import PreviewImage from '../../../../components/common/PreviewImage';
import { formatDuration } from '../../../../utils/tools';
import OrderStatusTitle from './OrderStatusTitle';
import ReferenceManyToMany from '../../../../containers/rest/ReferenceManyToMany';
import RestFieldItem from '../../../../components/RestField/RestFieldItem/index';
import ReviewCustomer from '../ReviewCustomer';

const Done = ({ item }) => (
  <Card title={<OrderStatusTitle item={item} />} className="timelineItem">
    <Text type="body">
      {i18next.t(
        `orderHistories.status.description.${item.status}.${item.accept}`,
      )}
    </Text>
  </Card>
);

export const DoneAction = ({ order = {}, onReopen }) => {
  const [visible, setVisible] = useState(false);
  const [report] = useState({ text: '' });

  const onOk = () => {
    onReopen(report.text);
    setVisible(false);
  };

  return (
    <div className="center">
      <Row>
        <Col span={12}>
          <Text align="center" color="#000">
            {i18next.t('orderHistories.summary.duration')}
            {formatDuration(order.createdAt, order.updatedAt)}
          </Text>
          <Text align="center">
            {order.happy ? (
              <Icon
                style={{ fontSize: 60, marginTop: 10, color: 'blue' }}
                type="smile"
              />
            ) : (
              <Icon
                style={{ fontSize: 60, marginTop: 10, color: 'red' }}
                type="frown"
              />
            )}
          </Text>
        </Col>
        <Col span={12}>
          <ReferenceManyToMany
            record={order}
            source="productsId"
            header="products.productsId"
            resource="products"
          >
            <RestFieldItem
              format={data =>
                data && data.map(e => <PreviewImage key={e} image={e} />)
              }
              source="images"
            />
          </ReferenceManyToMany>
        </Col>
      </Row>
      <Modal
        title={i18next.t('orderHistories.modal.reopen')}
        visible={visible}
        onCancel={() => setVisible(false)}
        onOk={onOk}
      >
        <TextEditor
          onEditorChange={e => {
            report.text = e;
          }}
          placeholder={i18next.t('orderHistories.modal.reopenReason')}
        />
      </Modal>
      <Button onClick={() => setVisible(true)} type="primary">
        {i18next.t('orderHistories.button.reopen')}
      </Button>
      <br />
      <br />
      <Text type="h3" align="left" color="#000">
        {i18next.t('orders.addReview')}
      </Text>
      <br />
      <ReviewCustomer order={order} />
    </div>
  );
};

DoneAction.propTypes = {
  order: PropTypes.object,
  onReopen: PropTypes.func,
};

Done.propTypes = {
  item: PropTypes.object,
};

Done.defaultProps = {
  item: {},
};

export default Done;
