import React from 'react';
import moment from 'moment';
import Edit from '../../rest/Edit';
import Form from '../components/Form';

const SalaryInfosEdit = (props) => (
  <Edit
    {...props}
    formatOnSubmit={(values) => ({
      ...values,
      dateMonth: moment(values.dateMonth).startOf('month').format('YYYY-MM-DD'),
      startDate: moment(values.startDate).format('YYYY-MM-DD'),
      endDate: moment(values.endDate).format('YYYY-MM-DD'),
    })}
    resource="salaryInfos"
  >
    <Form />
  </Edit>
);

SalaryInfosEdit.propTypes = {};

export default SalaryInfosEdit;
