import React, { useLayoutEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector, useDispatch } from 'react-redux';
import { Timeline, Card, Tabs, Button } from 'antd';
import i18next from 'i18next';
import ProductsList from 'pages/Products/List';
import orderActions from 'redux/orders/actions';
import MistakeRecordsList from 'containers/MistakeRecords/List';
import { ActivityLogWrapper } from './styles';
import OrderStatus from '../OrderStatus';
import orderHistoriesActions from '../../../../redux/orderHistories/actions';
import crudSelectors from '../../../../redux/crudSelectors';
import { RestInputContext } from '../../../../components/RestInput/RestInputContext';
import RestTextEditor, {
  PreviewTextEditor,
} from '../../../../components/RestInput/RestTextEditor/index';
import { ORDER_STATUS } from '../../../../configs/localData/index';
import CommentList from '../../../Comments/List/index';
import CommentActions from '../../../../redux/comments/actions';
import PlatformTemplate from '../PlatformTemplate';

const ActivityLogs = ({ match, location, user, getAllOrderHistories }) => {
  const editorRef = useRef();
  const order = useSelector((state) => state.orders.currentData);
  const dispatch = useDispatch();
  const enabledLoadMore = useSelector(
    crudSelectors.orderHistories.enabledLoadMore,
  );
  const commentCount = useSelector(crudSelectors.comments.getTotal);
  const orderHistories = useSelector(crudSelectors.orderHistories.getDataArr);
  const listStatusOfOutsource = ORDER_STATUS.filter(
    (e) => e.isShowOutsource,
  ).map((e) => e.value);
  const formattedHistories =
    user && user.isOutsource
      ? orderHistories.filter(
          (e) => listStatusOfOutsource.indexOf(e.status) > -1,
        )
      : orderHistories;

  const loadMoreHistories = () => {
    getAllOrderHistories({ includes: 'assignedUsers,assigner' });
  };

  const createComments = (content) =>
    new Promise((resolve, reject) => {
      dispatch(
        CommentActions.createComments(
          {
            orderId: Number(match.params.id),
            content,
          },
          { isBack: false, unRefetch: true },
        ),
      )
        .then((e) => {
          resolve(e);
        })
        .catch((e) => {
          reject(e);
        });
    });

  const handleChangeRequirement = (values) => {
    dispatch(
      orderActions.editOrders(
        {
          id: order?.id,
          customerRequirements: values.customerRequirements,
        },
        {
          customApi: 'orders/customerRequirements',
        },
      ),
    );
  };
  useLayoutEffect(() => {
    getAllOrderHistories(
      {
        includes: 'assignedUsers,assigner',
        limit: 5,
        filter: { orderId: match.params.id },
      },
      { isRefresh: true },
    );
    // eslint-disable-next-line
  }, []);
  // if (!user || user.isOutsource)
  //   return (
  //     <Card>
  //       <RestInputContext.Consumer>
  //         {({ record }) => (
  //           <PreviewTextEditor html={(record && record.customerRequirements) || ''} />
  //         )}
  //       </RestInputContext.Consumer>
  //     </Card>
  //   );
  return (
    <Tabs defaultActiveKey="requirement">
      <Tabs.TabPane
        forceRender
        tab={i18next.t('orders.customerRequirement')}
        key="requirement"
      >
        <Card>
          {user?.permission?.orders?.indexOf('customerRequirements') > -1 &&
          user &&
          !user.isOutsource ? (
            <RestTextEditor
              isAutoUpdate
              ref={editorRef}
              customHandleSubmit={handleChangeRequirement}
              source="customerRequirements"
              header="orders.customerRequirement"
            />
          ) : (
            <RestInputContext.Consumer>
              {({ record }) => (
                <PreviewTextEditor
                  html={(record && record.customerRequirements) || ''}
                />
              )}
            </RestInputContext.Consumer>
          )}
        </Card>
        <PlatformTemplate />
      </Tabs.TabPane>
      <Tabs.TabPane tab={i18next.t('orderHistories.header')} key="orderHistory">
        <ActivityLogWrapper>
          <Timeline>
            {formattedHistories.map((item, index) => (
              <OrderStatus
                user={user}
                key={String(index)}
                item={item}
                status={item.status}
              />
            ))}
            {enabledLoadMore ? (
              <Button onClick={loadMoreHistories} type="link">
                {i18next.t('button.loadMore')}
              </Button>
            ) : null}
          </Timeline>
        </ActivityLogWrapper>
      </Tabs.TabPane>
      <Tabs.TabPane
        forceRender
        tab={
          !commentCount ? (
            i18next.t('comments.header')
          ) : (
            <b style={{ color: 'red' }}>
              {i18next.t('comments.header')}
              <span>{commentCount ? ` (${commentCount})` : ''}</span>
            </b>
          )
        }
        key="comments"
      >
        <ActivityLogWrapper>
          <CommentList
            match={match}
            createComments={createComments}
            orderId={Number(match.params.id)}
          />
        </ActivityLogWrapper>
      </Tabs.TabPane>
      {order?.productCatalogIds && (
        <Tabs.TabPane tab={i18next.t('orders.oldProduct')} key="oldProduct">
          <ActivityLogWrapper>
            <ProductsList
              isUserDetail
              hasCreate={false}
              hasExport={false}
              rootPath={`/orders/${match.params.id}/edit`}
              initialFilter={{
                orderBy: '-createdAt',
                filter: {
                  productCatalogIds: { $containAll: order?.productCatalogIds },
                },
              }}
            />
          </ActivityLogWrapper>
        </Tabs.TabPane>
      )}
      <Tabs.TabPane
        tab={i18next.t('mistakeRecords.title')}
        key="mistakeRecords"
      >
        <MistakeRecordsList
          noCardWrapper
          isOrder
          match={match}
          isUpdateRoute={false}
          location={location}
          initialFilter={{
            includes: 'mistakeType,victim,creator',
            filter: {
              orderId: match.params.id,
            },
          }}
        />
      </Tabs.TabPane>
    </Tabs>
  );
};

ActivityLogs.propTypes = {
  match: PropTypes.object,
  getAllOrderHistories: PropTypes.func,
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.auth.data,
});

const mapDispatchToProps = (dispatch) => ({
  getAllOrderHistories: (data, options) =>
    dispatch(orderHistoriesActions.getAllOrderHistories(data, options)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ActivityLogs);
