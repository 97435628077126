import React from 'react';
import { Table } from 'antd';
import { formatDate, formatMoney } from 'utils/textUtils';
import i18next from 'i18next';
import { BONUS_LEVELS } from 'configs/localData';
import List from '../../rest/List';
import RestFieldItem from '../../../components/RestField/RestFieldItem';
import ActionGroup from '../../../components/RestActions/ActionGroup';
import EditButton from '../../../components/RestActions/EditButton';
import DeleteButton from '../../../components/RestActions/DeleteButton';

const SalaryInfosList = props => {
  const expandedRowRender = record => {
    const data = BONUS_LEVELS.map(kpi => ({
      name: kpi,
      bonus: formatMoney(record.bonus[kpi] || 0),
      A1: formatMoney(record.kpis[kpi]?.A1 || 0),
      A2: formatMoney(record.kpis[kpi]?.A2 || 0),
      A3: formatMoney(record.kpis[kpi]?.A3 || 0),
    }));
    const columns = [
      { title: i18next.t('salaryInfos.level'), dataIndex: 'name', key: 'name' },
      {
        title: i18next.t('salaryInfos.bonus'),
        dataIndex: 'bonus',
        key: 'bonus',
      },
      { title: 'A1', dataIndex: 'A1', key: 'A1' },
      { title: 'A2', dataIndex: 'A2', key: 'A2' },
      { title: 'A3', dataIndex: 'A3', key: 'A3' },
    ];

    return <Table columns={columns} dataSource={data} pagination={false} />;
  };

  return (
    <List
      {...props}
      hasSearch={false}
      expandedRowRender={expandedRowRender}
      resource="salaryInfos"
    >
      <RestFieldItem
        format={data => formatDate(data, 'MM/YYYY')}
        source="dateMonth"
        header="salaryInfos.dateMonth"
      />
      {/* <RestFieldItem source="bonus" header="salaryInfos.bonus" />
    <RestFieldItem source="kpis" header="salaryInfos.kpis" /> */}
      <RestFieldItem
        format={data => formatMoney(data)}
        source="baseSalary"
        header="salaryInfos.baseSalary"
      />
      <ActionGroup fixed={false}>
        <EditButton />
        <DeleteButton />
      </ActionGroup>
    </List>
  );
};

SalaryInfosList.propTypes = {};

export default SalaryInfosList;
