import React from 'react';
import { omit, values } from 'lodash';
import PropTypes from 'prop-types';
import Create from '../../rest/Create';
import Form from '../components/Form';

const WorkingTimesCreate = ({ userId, ...props }) => (
  <Create
    defaultOptions={{
      customApi: 'workingRegisters/createMany',
      isShowSuccessNoti: true,
    }}
    {...props}
    formatOnSubmit={(e) => ({
      userId: userId !== 'create' ? userId : undefined,
      values: values(omit(e.values, ['total', 'normal', 'overtime'])).map(
        (e) => ({
          ...e,
          workingTimes: { ...e.workingTimes },
        }),
      ),
    })}
    resource="workingRegisters"
  >
    <Form userId={userId !== 'create' ? userId : undefined} />
  </Create>
);

WorkingTimesCreate.propTypes = {
  userId: PropTypes.string,
};

export default WorkingTimesCreate;
