import {
  Button,
  Col,
  Divider,
  Form,
  Modal,
  notification,
  Row,
  Table,
  Typography,
} from 'antd';
import { getAllApi, putApi } from 'api/crud';
import { goBack as goBackAction } from 'connected-react-router';
import i18next from 'i18next';
import { sortBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useRef } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import paymentActions from 'redux/payments/actions';
import { formatMoney } from 'utils/textUtils';

export default function ConfirmOrderModal({ resource, ...props }) {
  const currentPayment = useSelector((state) => state.payments.currentData);
  const orderDetailRef = useRef();
  const dispatch = useDispatch();

  const queryClient = useQueryClient();
  // const user = useSelector((state) => state.auth.data);

  const { data, isLoading } = useQuery(
    [`payments`, currentPayment?.typeClient, currentPayment.clientId],
    async () => {
      const res = await getAllApi(
        `payments/${
          currentPayment?.typeClient === 'DESIGNER' ? 'designers' : 'customers'
        }/${currentPayment.clientId}`,
      );

      return res;
    },
    {
      enabled: !!currentPayment.clientId,
      onSuccess: (res) => {},
    },
  );

  const { isLoading: loadingCreatePayment } = useMutation(
    async () => putApi('payments', `${currentPayment.id}/confirm`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(`payments/${resource}`);
        notification.success({
          message: 'Confirmed successfully!',
        });
        dispatch(goBackAction());
      },
      onError: (error) => {
        notification.error({
          message: error?.message,
        });
      },
      onSettled: () => {},
    },
  );

  const searchData = useMemo(
    () => sortBy(currentPayment?.orders || [], 'id'),
    [currentPayment],
  );

  const onConfirm = () => {
    Modal.confirm({
      title: i18next.t('confirmPendingToPayment', {
        total: currentPayment?.orders?.length,
      }),
      onOk: () => {
        putApi('payments', `${currentPayment?.id}/confirm`).then((e) => {
          notification.success({ message: 'Confirm successfully!' });
          dispatch(paymentActions.getByIdPayments(currentPayment));
        });
      },
    });
  };

  const onClickId = useCallback(
    (record) => {
      orderDetailRef.current.setOrderId(record.id);
    },
    [orderDetailRef],
  );

  return (
    <>
      <Form>
        <Table dataSource={searchData} loading={isLoading} rowKey="id">
          <Table.Column
            render={(data, record) => (
              <div
                className="cursor-pointer"
                role="presentation"
                onClick={() => onClickId(record)}
              >
                {`#${data}`}
              </div>
            )}
            dataIndex="id"
            title="ID"
          />

          <Table.Column
            dataIndex="dealPrice"
            render={(data) => <div className="text-right">{data}</div>}
            title={
              <div className="text-right w-100">
                {i18next.t('orders.priceOrder')}
              </div>
            }
          />
        </Table>

        <br />
        <Row>
          <Col md={12} />
          <Col md={12}>
            <Row>
              <Col md={12}>
                <Typography.Text className="text-red">Bonus</Typography.Text>
              </Col>
              <Col md={12}>
                <Typography.Text className="text-red">
                  {formatMoney(currentPayment?.bonusAmount)}
                </Typography.Text>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Typography.Text className="text-red">Discount</Typography.Text>
              </Col>
              <Col md={12}>
                <Typography.Text className="text-red">
                  {formatMoney(currentPayment?.discountAmount)}
                </Typography.Text>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Typography.Text className="text-red">
                  Total Payment
                </Typography.Text>
              </Col>
              <Col md={12}>
                <Typography.Text className="text-red">
                  <b>{formatMoney(currentPayment?.totalAmount)}</b>
                </Typography.Text>
              </Col>
            </Row>
          </Col>
        </Row>
        <Divider />
        <div className="bg-gray">
          <Form.Item name="note">{currentPayment?.note}</Form.Item>
        </div>
      </Form>
      <div className="mt-16 text-center">
        {!currentPayment?.isConfirm && (
          <Button
            loading={loadingCreatePayment}
            onClick={onConfirm}
            type="primary"
          >
            {i18next.t('reviewConfirm')}
          </Button>
        )}
      </div>
    </>
  );
}

ConfirmOrderModal.propTypes = {
  resource: PropTypes.string,
  id: PropTypes.string,
  onClose: PropTypes.func,
};
