import React from 'react';
import Edit from '../../rest/Edit';
import Form from '../components/Form';

const UserRequestsEdit = (props) => (
  <Edit
    {...props}
    initOptions={{ unRefetch: true, customApi: 'user-requests' }}
    resource="userRequests"
  >
    <Form />
  </Edit>
);

UserRequestsEdit.propTypes = {};

export default UserRequestsEdit;
