import React from 'react';
import RestShow from '../../rest/Show';
import RestFieldItem from '../../../components/RestField/RestFieldItem';

const PermissionsShow = props => (
  <RestShow {...props} hasEdit resource="permissions">
    <RestFieldItem source="name" header="permissions.name" />
    <RestFieldItem source="dashboard" header="permissions.dashboard" />
    <RestFieldItem source="student" header="permissions.student" />
    <RestFieldItem source="teacher" header="permissions.teacher" />
    <RestFieldItem source="subject" header="permissions.subject" />
    <RestFieldItem source="attendance" header="permissions.attendance" />
    <RestFieldItem source="accounting" header="permissions.accounting" />
    <RestFieldItem source="notification" header="permissions.notification" />
  </RestShow>
);

export default PermissionsShow;
