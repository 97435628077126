import React from 'react';
import { Input } from 'antd';
import ReferenceInput from '../../../../containers/rest/ReferenceInput';
import RestInputItem from '../../../../components/RestInput/RestInputItem';
import RestSelect from '../../../../components/RestInput/RestSelect';

const UserTypesForm = props => (
  <div {...props}>
    <RestInputItem source="name" header="userTypes.name" />
    <RestInputItem source="description" header="userTypes.description">
      <Input.TextArea />
    </RestInputItem>
    <RestInputItem isReference source="departmentId">
      <ReferenceInput source="departmentId" resource="departments">
        <RestSelect
          ruleType="number"
          source="departmentId"
          valueProp="id"
          titleProp="name"
          header="userTypes.department"
        />
      </ReferenceInput>
    </RestInputItem>
  </div>
);

UserTypesForm.propTypes = {};

export default UserTypesForm;
