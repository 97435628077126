import React, { useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Card, Row, Col, Tag, InputNumber } from 'antd';

import i18next from 'i18next';
import orderActions from 'redux/orders/actions';
import ReferenceInput from '../../../../containers/rest/ReferenceInput';
import RestInputItem from '../../../../components/RestInput/RestInputItem';
import RestSelect from '../../../../components/RestInput/RestSelect';
import RestFormDateTimePicker from '../../../../components/RestInput/RestDateTimePicker';
import { PAYMENT_STATUS, ORDER_STATUS } from '../../../../configs/localData';
import { RestInputContext } from '../../../../components/RestInput/RestInputContext';
import RestTextEditor from '../../../../components/RestInput/RestTextEditor';
import { OrderDetailWrapper } from './styles';

const OrderDetail = ({ user }) => {
  const editorRef = useRef();
  const dispatch = useDispatch();
  const currentId = useSelector((state) => state.orders.currentId);
  const filterCustomers = useMemo(
    () => ({
      includes: 'channel',
    }),
    [],
  );
  const handleChangePrice = (values) => {
    dispatch(
      orderActions.editOrders(
        {
          id: currentId,
          priceOrder: values.priceOrder,
        },
        {
          customApi: 'orders/priceOrder',
        },
      ),
    );
  };

  const handleChangePaymentStatus = (values) => {
    dispatch(
      orderActions.editOrders(
        {
          id: currentId,
          paymentStatus: values.paymentStatus,
        },
        {
          customApi: 'orders/paymentStatus',
        },
      ),
    );
  };

  const orderStatus = (
    <RestInputContext.Consumer>
      {({ record }) => (
        <Tag color="#6c63ff">
          {ORDER_STATUS.find((e) => e.value === record.status) &&
            ORDER_STATUS.find((e) => e.value === record.status).text}
        </Tag>
      )}
    </RestInputContext.Consumer>
  );

  return user.isOutsource ? (
    <OutsourceOrderDetail />
  ) : (
    <Card
      className="box orderDetail"
      title={
        <span className="txtHeader">{i18next.t('customers.detail.about')}</span>
      }
      extra={orderStatus}
    >
      <OrderDetailWrapper>
        <Row gutter={16}>
          <Col span={24}>
            <RestInputItem isReference source="customerId">
              <ReferenceInput
                source="customerId"
                valueProp="id"
                titleProp="displayName"
                resource="customers"
                initialFilter={filterCustomers}
              >
                <RestSelect
                  disabled={user?.permission?.orders?.indexOf('update') === -1}
                  source="customerId"
                  valueProp="id"
                  titleProp="displayName"
                  header="orders.customer"
                  formatText={(data, record) =>
                    `${record.displayName} - channel ${
                      record.channel && record.channel.name
                    }`
                  }
                />
              </ReferenceInput>
            </RestInputItem>
            <RestInputItem isReference source="orderRequirementId">
              <ReferenceInput
                source="orderRequirementId"
                resource="orderRequirements"
              >
                <RestSelect
                  disabled={
                    user?.permission?.orders?.indexOf(
                      'customerRequirements',
                    ) === -1
                  }
                  source="orderRequirementId"
                  valueProp="id"
                  ruleType="number"
                  titleProp="name"
                  header="orders.orderRequirementId"
                  isAutoUpdate
                />
              </ReferenceInput>
            </RestInputItem>
            <RestInputItem isReference source="niche">
              <ReferenceInput source="niche" resource="niches">
                <RestSelect
                  source="niche"
                  valueProp="id"
                  titleProp="name"
                  header="orders.niche"
                  isAutoUpdate
                />
              </ReferenceInput>
            </RestInputItem>
            <RestInputItem isReference source="dealerId">
              <ReferenceInput
                source="dealerId"
                valueProp="id"
                titleProp="displayName"
                resource="dealers"
              >
                <RestSelect
                  disabled={user?.permission?.orders?.indexOf('update') === -1}
                  source="dealerId"
                  valueProp="id"
                  titleProp="displayName"
                  header="orders.dealerId"
                  isAutoUpdate
                />
              </ReferenceInput>
            </RestInputItem>
            <RestInputItem isReference source="saleId">
              <ReferenceInput
                source="saleId"
                valueProp="id"
                titleProp="displayName"
                resource="salers"
              >
                <RestSelect
                  disabled={user?.permission?.orders?.indexOf('update') === -1}
                  source="saleId"
                  valueProp="id"
                  titleProp="displayName"
                  header="orders.saleId"
                  isAutoUpdate
                />
              </ReferenceInput>
            </RestInputItem>
            <RestSelect
              disabled={
                user?.permission?.orders?.indexOf('paymentStatus') === -1
              }
              ruleType="string"
              resourceData={PAYMENT_STATUS}
              source="paymentStatus"
              header="orders.paymentStatus"
              isAutoUpdate
              customHandleSubmit={handleChangePaymentStatus}
            />
          </Col>
          <Col span={12}>
            <RestInputItem isReference source="productCatalogIds">
              <ReferenceInput
                source="productCatalogIds"
                valueProp="id"
                titleProp="displayName"
                resource="productTypes"
              >
                <RestSelect
                  disabled={user?.permission?.orders?.indexOf('update') === -1}
                  selectProps={{ mode: 'multiple' }}
                  source="productCatalogIds"
                  valueProp="id"
                  titleProp="name"
                  ruleType="array"
                  header="orders.productCatalogId"
                  isAutoUpdate
                />
              </ReferenceInput>
            </RestInputItem>
          </Col>
          <Col span={12}>
            <RestInputItem isReference source="platform">
              <ReferenceInput source="platform" resource="platforms">
                <RestSelect
                  disabled={user?.permission?.orders?.indexOf('update') === -1}
                  source="platform"
                  valueProp="id"
                  titleProp="name"
                  header="orders.platform"
                  isAutoUpdate
                />
              </ReferenceInput>
            </RestInputItem>
          </Col>
          <Col span={24}>
            <RestFormDateTimePicker
              disabled={user?.permission?.orders?.indexOf('update') === -1}
              source="finishedDate"
              header="orders.finishedDate"
            />
          </Col>
          <Col span={12}>
            <RestInputItem isReference source="packageId">
              <ReferenceInput source="packageId" resource="packages">
                <RestSelect
                  disabled={user?.permission?.orders?.indexOf('update') === -1}
                  source="packageId"
                  valueProp="id"
                  titleProp="name"
                  header="orders.packageId"
                  isAutoUpdate
                />
              </ReferenceInput>
            </RestInputItem>
          </Col>
          <Col span={12}>
            <RestInputItem
              disabled={user?.permission?.orders?.indexOf('priceOrder') === -1}
              source="priceOrder"
              header="orders.priceOrder"
              ruleType="number"
              isAutoUpdate
              customHandleSubmit={handleChangePrice}
            >
              <InputNumber
                step={10000}
                min={0}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                parser={(value) => value.replace(/\\s?|(,*)/g, '')}
              />
            </RestInputItem>
          </Col>

          <Col span={24}>
            <RestTextEditor
              disabled={user?.permission?.orders?.indexOf('update') === -1}
              isAutoUpdate
              ref={editorRef}
              source="customerNote"
              header="orders.customerNote"
            />
          </Col>
        </Row>
      </OrderDetailWrapper>
    </Card>
  );
};

const OutsourceOrderDetail = () => (
  <Card
    className="box"
    title={
      <span className="txtHeader">{i18next.t('customers.detail.about')}</span>
    }
  >
    <Row gutter={16}>
      <Col span={12}>
        <RestInputItem isReference source="productCatalogIds">
          <ReferenceInput
            source="productCatalogIds"
            valueProp="id"
            titleProp="displayName"
            resource="productTypes"
          >
            <RestSelect
              disabled
              selectProps={{ mode: 'multiple' }}
              source="productCatalogIds"
              valueProp="id"
              titleProp="name"
              header="orders.productCatalogId"
            />
          </ReferenceInput>
        </RestInputItem>
        <RestInputItem isReference source="niche">
          <ReferenceInput source="niche" resource="niches">
            <RestSelect
              disabled
              source="niche"
              valueProp="id"
              titleProp="name"
              header="orders.niche"
              isAutoUpdate
            />
          </ReferenceInput>
        </RestInputItem>
      </Col>
      <Col span={12}>
        <RestInputItem isReference source="platform">
          <ReferenceInput source="platform" resource="platforms">
            <RestSelect
              disabled
              source="platform"
              valueProp="id"
              titleProp="name"
              header="orders.platform"
            />
          </ReferenceInput>
        </RestInputItem>
      </Col>
      <Col span={24}>
        <RestInputItem isReference source="orderRequirementId">
          <ReferenceInput
            source="orderRequirementId"
            resource="orderRequirements"
          >
            <RestSelect
              disabled
              source="orderRequirementId"
              valueProp="id"
              ruleType="number"
              titleProp="name"
              header="orders.orderRequirementId"
              isAutoUpdate
            />
          </ReferenceInput>
        </RestInputItem>
        <RestFormDateTimePicker
          disabled
          source="finishedDate"
          header="orders.finishedDate"
        />
      </Col>
    </Row>
  </Card>
);

OrderDetail.propTypes = {
  user: PropTypes.object,
};

export default connect((state) => ({
  user: state.auth.data,
}))(OrderDetail);
