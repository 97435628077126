import { Button, Col, Input, Modal, notification, Row } from 'antd';
import RestFormDateTimePicker from 'components/RestInput/RestDateTimePicker';
import RestInputItem from 'components/RestInput/RestInputItem';
import RestSelect from 'components/RestInput/RestSelect';
import RestUpload from 'components/RestInput/RestUpload';
import { ORDER_STATUS, PAYMENT_STATUS } from 'configs/localData';
import Create from 'containers/rest/Create';
import ReferenceInput from 'containers/rest/ReferenceInput';
import I18n from 'i18next';
import moment from 'moment';
import React, { useState } from 'react';

const ButtonRow = ({ handleSubmit }) => {
  const [loading, setLoading] = useState(false);
  return (
    <div style={{ textAlign: 'right' }}>
      <Button
        type="primarys"
        onClick={() => {
          setLoading(true);
          handleSubmit().finally(() => {
            setLoading(false);
          });
        }}
        loading={loading}
      >
        {I18n.t('button.save')}
      </Button>
    </div>
  );
};

const formatOnSubmit = (data) => ({
  ...data,
  file: data.file[0],
  deadline: data.finishedDate && moment(data.finishedDate).toISOString(),
  status: ORDER_STATUS[0].value,
});
export default function CustomActions(props) {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <Button
        onClick={() => setVisible(true)}
        type="danger"
        style={{ marginRight: 10 }}
      >
        {I18n.t('orders.importFromTrello')}
      </Button>
      <Modal
        title={I18n.t('orders.importFromTrello')}
        visible={visible}
        footer={null}
        onCancel={() => setVisible(false)}
      >
        <Create
          noCardWrapper
          {...props}
          formatOnSubmit={formatOnSubmit}
          defaultOptions={{ isBack: false, customApi: 'orders/trello-upload' }}
          customSubmitButton={<ButtonRow onSuccess={() => setVisible(false)} />}
          resource="orders"
          callbackSubmit={(res) => {
            res.error?.message?.trim() === 'Wait a min for updating' &&
              setVisible(false);
            res.error?.message &&
              notification.success({
                message: res.error?.message,
              });
          }}
        >
          <RestUpload multiple={false} source="file" />
          <RestInputItem header="Cookie" source="cookie">
            <Input.TextArea />
          </RestInputItem>
          <div>
            <div style={{ display: 'none' }}>
              <RestInputItem source="channelId">
                <input style={{ display: 'none' }} />
              </RestInputItem>
            </div>
            <Row gutter={16} {...props}>
              <Col md={12}>
                <RestInputItem isReference source="customerId">
                  <ReferenceInput
                    source="customerId"
                    resource="customers"
                    searchKey="displayName"
                    initialFilter={{ includes: 'channel' }}
                  >
                    <RestSelect
                      source="customerId"
                      valueProp="id"
                      onChange={(form) => (e) => {
                        form.setFieldsValue({
                          channelId: e && e.channelId,
                        });
                      }}
                      titleProp="displayName"
                      header="orders.customer"
                      formatText={(data, record) =>
                        `${record.displayName} - channel ${
                          record.channel && record.channel.name
                        }`
                      }
                    />
                  </ReferenceInput>
                </RestInputItem>
                <RestInputItem isReference source="saleId">
                  <ReferenceInput source="saleId" resource="salers">
                    <RestSelect
                      source="saleId"
                      valueProp="id"
                      titleProp="displayName"
                      header="orders.saleId"
                    />
                  </ReferenceInput>
                </RestInputItem>
              </Col>
              <Col md={12}>
                <RestFormDateTimePicker
                  defaultValue={moment().add(24, 'hour').toISOString()}
                  source="finishedDate"
                  header="orders.finishedDate"
                  required
                />
                <RestInputItem isReference source="saleId">
                  <ReferenceInput source="dealerId" resource="dealers">
                    <RestSelect
                      source="dealerId"
                      valueProp="id"
                      titleProp="displayName"
                      header="orders.dealerId"
                    />
                  </ReferenceInput>
                </RestInputItem>
                <RestSelect
                  ruleType="string"
                  resourceData={PAYMENT_STATUS}
                  defaultValue={PAYMENT_STATUS[1].value}
                  source="paymentStatus"
                  header="orders.paymentStatus"
                />
              </Col>
            </Row>
          </div>
        </Create>
      </Modal>
    </>
  );
}
