import React from 'react';
import List from '../../../containers/rest/List';
import Reference from '../../../containers/rest/Reference';
import RestFieldItem from '../../../components/RestField/RestFieldItem';
import ActionGroup from '../../../components/RestActions/ActionGroup';
import EditButton from '../../../components/RestActions/EditButton';
// import DeleteButton from '../../../components/RestActions/DeleteButton';

const UserTypesList = props => (
  <List {...props} redirects={{ edit: 'screen', create: 'modal' }} resource="userTypes">
    <RestFieldItem source="name" header="userTypes.name" />
    <RestFieldItem source="description" header="userTypes.description" />
    <Reference source="departmentId" resource="departments" header="userTypes.department">
      <RestFieldItem source="name" />
    </Reference>
    <ActionGroup>
      <EditButton />
      {/* <DeleteButton /> */}
    </ActionGroup>
  </List>
);

UserTypesList.propTypes = {};

export default UserTypesList;
