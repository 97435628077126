import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';

export const usePermissionAction = (resource, action) => {
  const user = useSelector((state) => state.auth.data);
  const [isActive, setActive] = useState(false);
  useEffect(() => {
    if (user.permission) {
      setActive(user.permission[resource]?.indexOf(action) > -1);
    }
    // eslint-disable-next-line
  }, [user.permission]);
  if (user.scope === 'manager') return true;
  return isActive;
};
