import React from 'react';
import i18next from 'i18next';
import { connect } from 'react-redux';
import DashboardWrapper from './styles';
import PageTitle from '../../components/common/PageTitle';
import OrdersList from '../Orders/List';
import Summaries from './Summaries';
import OrderHistoriesList from '../OrderHistories/List';

const Dashboard = ({ ...props }) =>
  props.user && props.user.isOutsource ? (
    <OutsourceDashboard {...props} />
  ) : (
    <DashboardWrapper>
      {props.user && props.user.scope === 'manager' && <Summaries />}
      <div
        className={
          props.user && props.user.isOutsource
            ? 'txtOutsourceTitle'
            : 'txtTitle'
        }
      >
        <PageTitle>{i18next.t('orders.newOrder')}</PageTitle>
      </div>
      <OrdersList {...props} hasSearch={false} hasExport={false} />
    </DashboardWrapper>
  );

const OutsourceDashboard = ({ ...props }) => (
  <DashboardWrapper>
    <div className="txtOutsourceTitle">
      <PageTitle>
        {i18next.t('customers.detail.activityLog.orderRequested')}
      </PageTitle>
    </div>
    <OrderHistoriesList
      isOutsource
      isUpdateRoute={false}
      initialFilter={{
        includes: 'order',
        filter: { isCurrent: true, assignedUser: { $in: [props.user.id] } },
      }}
      hasSearch={false}
      hasExport={false}
    />
  </DashboardWrapper>
);

export default connect((state) => ({
  user: state.auth.data,
}))(Dashboard);
