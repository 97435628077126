import React from 'react';
import Edit from '../../../containers/rest/Edit';
import Form from '../components/Form';

const CustomerSegmentationsEdit = props => (
  <Edit {...props} resource="customerSegmentations">
    <Form />
  </Edit>
);

CustomerSegmentationsEdit.propTypes = {};

export default CustomerSegmentationsEdit;
