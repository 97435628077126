import React from 'react';
import RestShow from '../../rest/Show';
import RestFieldItem from '../../../components/RestField/RestFieldItem';

const MistakeTypesShow = props => (
  <RestShow {...props} hasEdit resource="mistakeTypes">
    <RestFieldItem source="name" header="mistakeTypes.name" />
    <RestFieldItem source="level" header="mistakeTypes.level" />
    <RestFieldItem source="description" header="mistakeTypes.description" />
  </RestShow>
);

export default MistakeTypesShow;
