import React, { useLayoutEffect, useState } from 'react';
import { Calendar, Tag } from 'antd';
import moment from 'moment';
import Box from 'components/common/Box';
import PageTitle from 'components/common/PageTitle';
import I18n from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import workingRegistersActions from 'redux/workingRegisters/actions';
import crudSelectors from 'redux/crudSelectors';
import { WORKING_TIME_OF_DAY } from 'configs/localData';
import ListStyle from './styles';

const MySchedule = () => {
  const dispatch = useDispatch();
  const [currentMonth, setCurrentMonth] = useState(moment());
  const workingRegisters = useSelector(
    crudSelectors.workingRegisters.getDataArr,
  );

  function monthCellRender(value) {
    return (
      <div className="notes-month">
        <span>list gio lam</span>
      </div>
    );
  }

  const dateCellRender = (value) => {
    const listData = workingRegisters.filter(
      (e) =>
        moment(e.date).format('DD/MM') ===
          moment(value.format()).format('DD/MM') &&
        Object.keys(e.workingTimes).length > 0,
    );
    return (
      <ul className="events">
        {listData.map((item) => (
          <li style={{ position: 'relative' }} key={item.id}>
            {WORKING_TIME_OF_DAY.filter((e) =>
              e.hours.find((key) => item.workingTimes[key]),
            ).map((e) => (
              <>
                <Tag size="large" color={e.color}>
                  {`${e.phaseNumber}: ${e.hours
                    .filter((key) => item.workingTimes[key])
                    .join(',')}`}
                </Tag>
                <br />
              </>
            ))}
          </li>
        ))}
      </ul>
    );
  };

  const onChange = (e) => {
    setCurrentMonth(e);
  };

  useLayoutEffect(() => {
    dispatch(
      workingRegistersActions.getAllWorkingRegisters(
        {
          limit: 99,
          filter: {
            date: {
              $lte: currentMonth.endOf('month').format(),
              $gte: currentMonth.startOf('month').format(),
            },
          },
        },
        {
          isRefresh: true,
          customApi: 'workingRegisters/me',
        },
      ),
    );
    // eslint-disable-next-line
  }, [currentMonth]);
  return (
    <>
      <PageTitle>
        {I18n.t('workingRegisters.title', {
          start: currentMonth.startOf('month').format('DD/MM/YY'),
          end: currentMonth.endOf('month').format('DD/MM/YY'),
        })}
      </PageTitle>
      <Box>
        <ListStyle>
          <Calendar
            onChange={onChange}
            dateCellRender={dateCellRender}
            monthCellRender={monthCellRender}
          />
        </ListStyle>
      </Box>
    </>
  );
};

export default MySchedule;
