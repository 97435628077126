import {
  makeCRUDConstantCreator,
  makeCRUDActionsCreator,
} from '../crudCreator/actions';
import { makeConstantCreator, makeActionCreator } from '../../utils/reduxUtils';

export const PRIMARY_KEY = 'id';
export const MODEL = 'designers';
export const IGNORE_ACTIONS = [];
export const DesignersTypes = {
  ...makeCRUDConstantCreator(MODEL, IGNORE_ACTIONS),
  ...makeConstantCreator(
    'GET_DESIGNER_ANALYSIS',
    'GET_DESIGNER_ANALYSIS_SUCCESS',
    'GET_DESIGNER_ANALYSIS_FAILURE',
    'GET_DESIGNER_ANALYSIS_BASE_ON_ORDER_STATUS',
    'GET_DESIGNER_ANALYSIS_BASE_ON_ORDER_STATUS_SUCCESS',
    'GET_DESIGNER_ANALYSIS_BASE_ON_ORDER_STATUS_FAILURE'
  ),
};
const CRUDDesignersActions = makeCRUDActionsCreator(MODEL, IGNORE_ACTIONS);

export const getDesignersAnalysis = data =>
  makeActionCreator(DesignersTypes.GET_DESIGNER_ANALYSIS, { data });
export const getDesignersAnalysisSuccessAction = data =>
  makeActionCreator(DesignersTypes.GET_DESIGNER_ANALYSIS_SUCCESS, { data });
export const getDesignersAnalysisFailureAction = error =>
  makeActionCreator(DesignersTypes.GET_DESIGNER_ANALYSIS_FAILURE, { error });

export const getDesignersAnalysisBaseOnStatus = (status, data) =>
  makeActionCreator(DesignersTypes.GET_DESIGNER_ANALYSIS_BASE_ON_ORDER_STATUS, {
    status,
    data,
  });
export const getDesignersAnalysisBaseOnStatusSuccessAction = data =>
  makeActionCreator(
    DesignersTypes.GET_DESIGNER_ANALYSIS_BASE_ON_ORDER_STATUS_SUCCESS,
    { data }
  );
export const getDesignersAnalysisBaseOnStatusFailureAction = error =>
  makeActionCreator(
    DesignersTypes.GET_DESIGNER_ANALYSIS_BASE_ON_ORDER_STATUS_FAILURE,
    { error }
  );

/**
 * getAllCaseTypes({pageSize, page })
 * getByIdCaseTypes(data)
 * createCaseTypes(data)
 * deleteCaseTypes()
 * editCaseTypes(data)
 */
export default { ...CRUDDesignersActions };
