import React from 'react';
import { Link } from 'react-router-dom';
import List from '../../../containers/rest/List';
import Reference from '../../../containers/rest/Reference';
import RestFieldItem from '../../../components/RestField/RestFieldItem';
import ActionGroup from '../../../components/RestActions/ActionGroup';
import EditButton from '../../../components/RestActions/EditButton';
import DeleteButton from '../../../components/RestActions/DeleteButton';
import { formatMoney, formatDateTime } from '../../../utils/textUtils';
import { PACKAGE_STATUS } from 'configs/localData';
import { PreviewTextEditor } from 'components/RestInput/RestTextEditor';

const PackagesList = props => (
  <List {...props} resource="packages">
    <RestFieldItem
      format={(data, record) => (
        <Link to={`packages/${record && record.id}/edit`}>{data}</Link>
      )}
      source="name"
      header="packages.name"
    />
    {!props.isCustomerDetail && (
      <Reference
        source="customerId"
        header="packages.customerId"
        resource="customers"
      >
        <RestFieldItem source="displayName" />
      </Reference>
    )}
    {!props.isCustomerDetail && (
      <Reference
        source="channelId"
        header="packages.channelId"
        resource="channels"
      >
        <RestFieldItem source="name" />
      </Reference>
    )}
    <RestFieldItem
      sorter
      source="orderCount"
      header="packages.orderCount"
    />
    <RestFieldItem
      sorter
      source="orderUsed"
      header="packages.orderUsed"
    />
    <RestFieldItem
      sorter
      format={data => formatMoney(data)}
      source="price"
      header="packages.price"
    />
    <RestFieldItem
      format={data => formatDateTime(data)}
      source="finishDate"
      header="packages.finishDate"
    />
    <RestFieldItem format={data => <div style={{width: 200}}><PreviewTextEditor html={data} /></div>} source="note" header="packages.note" />
    <RestFieldItem filters={PACKAGE_STATUS} format={data => PACKAGE_STATUS.find(e => e.value === data)?.text} source="status" header="packages.status" />
    <ActionGroup>
      <EditButton />
      <DeleteButton isSortDelete />
    </ActionGroup>
  </List>
);

PackagesList.propTypes = {};

export default PackagesList;
