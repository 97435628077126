import { makeConstantCreator, makeActionCreator } from '../../utils/reduxUtils';
import {
  makeCRUDConstantCreator,
  makeCRUDActionsCreator,
} from '../crudCreator/actions';

export const PRIMARY_KEY = 'id';
export const MODEL = 'workingRegisters';
export const IGNORE_ACTIONS = [];
export const WorkingTimesTypes = {
  ...makeCRUDConstantCreator(MODEL, IGNORE_ACTIONS),
  ...makeConstantCreator(
    'GET_CURRENT_WORKING_USERS',
    'GET_CURRENT_WORKING_USERS_SUCCESS',
    'GET_CURRENT_WORKING_USERS_FAILURE',
  ),
};
const CRUDWorkingTimesActions = makeCRUDActionsCreator(MODEL, IGNORE_ACTIONS);

export const getCurrentWorkingUsersAction = (params) =>
  makeActionCreator(WorkingTimesTypes.GET_CURRENT_WORKING_USERS, { params });
export const getCurrentWorkingUsersSuccessAction = (params) =>
  makeActionCreator(WorkingTimesTypes.GET_CURRENT_WORKING_USERS_SUCCESS, {
    params,
  });
export const getCurrentWorkingUsersFailureAction = (data) =>
  makeActionCreator(WorkingTimesTypes.GET_CURRENT_WORKING_USERS_FAILURE, {
    data,
  });
/**
 * getAllCaseTypes({pageSize, page })
 * getByIdCaseTypes(data)
 * createCaseTypes(data)
 * deleteCaseTypes()
 * editCaseTypes(data)
 */
export default { ...CRUDWorkingTimesActions };
