import styled from 'styled-components';
// import loginBackground from '../../assets/images/login_background.jpg';

const PublicLayoutWrapper = styled.div`
  background-color: transparent;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  .layout {
    height: 100vh;
    display: flex;
    flex-direction: row;
  }

  .main-img {
    background-image: url(https://res.cloudinary.com/csmenouvo/image/upload/v1566972527/background/background-lucas.png);
    background-color: transparent;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    height: 100vh;
    flex: 1;
    ${'' /* background-color: ${({ theme }) => theme.background.container}; */}
    @media only screen and (max-width: 768px) {
      display: none;
    }
  }

  .main-content {
    background-color: ${({ theme }) => theme.background.container};
    padding: 70px 50px;
    text-align: center;
    height: 100vh;
    max-width: 450px;
    min-width: 450px;
    width: auto;
    @media only screen and (max-width: 768px) {
      flex: 1;
      max-width: 100%;
    }
  }
`;

export default PublicLayoutWrapper;
