import React from 'react';
import Edit from '../../rest/Edit';
import Form from '../components/Form';

const MistakeRecordsEdit = props => (
  <Edit {...props} resource="mistakeRecords">
    <Form />
  </Edit>
);

MistakeRecordsEdit.propTypes = {};

export default MistakeRecordsEdit;
