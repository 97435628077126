/* eslint-disable */
import React from 'react';
import { SVGIconWrapper } from './styles';
import { ReactComponent as CPO } from '../../../assets/icons/chef-production-officers.svg';
import { ReactComponent as CAO } from '../../../assets/icons/chief-action-officer.svg';
import { ReactComponent as CBO } from '../../../assets/icons/chief-business-officer.svg';
import { ReactComponent as CEO } from '../../../assets/icons/chief-executive-officer.svg';
import { ReactComponent as CES } from '../../../assets/icons/customer-experience-staff.svg';
import { ReactComponent as Delivery } from '../../../assets/icons/delivery.svg';
import { ReactComponent as Finance } from '../../../assets/icons/finance-department.svg';
import { ReactComponent as GUA } from '../../../assets/icons/gua.svg';
import { ReactComponent as PD } from '../../../assets/icons/product-designer.svg';
import { ReactComponent as SaleB } from '../../../assets/icons/sale-b.svg';
import { ReactComponent as LS } from '../../../assets/icons/ls.svg';
import { ReactComponent as SaleManagement } from '../../../assets/icons/sale-management.svg';

const SVG_ICONS = {
  CPO,
  CAO,
  CBO,
  CEO,
  CES,
  Delivery,
  Finance,
  GUA,
  LS,
  PD,
  SaleB,
  SaleManagement,
};

function SVGIcon(
  { name = 'saleManagement', size = 17, margin = '0 0 0 0', className, ...props },
  ref,
) {
  const Icon = SVG_ICONS[name] || saleManagement;
  return (
    <SVGIconWrapper
      {...props}
      size={size}
      margin={margin}
      ref={ref}
      className={`svgIcon ${className || ''}`}
    >
      <Icon />
    </SVGIconWrapper>
  );
}

// SVGIcon.propTypes = {
//   name: PropTypes.string,
//   size: PropTypes.number,
//   className: PropTypes.string,
// };

// SVGIcon.defaultProps = {
//   name: 'logo',
//   size: 20,
// };

export default React.forwardRef(SVGIcon);
