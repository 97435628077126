import React from 'react';
import { Tag } from 'antd';
import List from '../../../containers/rest/List';
import RestFieldItem from '../../../components/RestField/RestFieldItem';
import ActionGroup from '../../../components/RestActions/ActionGroup';
import EditButton from '../../../components/RestActions/EditButton';
import DeleteButton from '../../../components/RestActions/DeleteButton';

const DealpipelinesList = props => (
  <List {...props} isScroll={false} hasAnalysis resource="dealpipelines">
    <RestFieldItem source="name" header="dealpipelines.name" />
    <RestFieldItem source="description" header="dealpipelines.description" />
    <RestFieldItem
      format={data => <Tag color={data}>{data}</Tag>}
      source="color"
      header="dealpipelines.color"
    />
    <ActionGroup>
      <EditButton />
      <DeleteButton />
    </ActionGroup>
  </List>
);

DealpipelinesList.propTypes = {};

export default DealpipelinesList;
