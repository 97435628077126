import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card } from 'antd';
import i18next from 'i18next';
import AnalysisLineChart from 'components/RestLayout/AnalysisLineChart';
import AnalysisPieChart from '../../components/RestLayout/AnalysisPieChart';
import DesignerNumOrder from '../Users/UserAnalysis/DesignerNumOrder';

const NumOrder = ({ user, filter }) => (
  <Row gutter={16}>
    <Col span={24}>
      <Card title={i18next.t('orderHistories.employee.designer')}>
        {(user.scope === 'manager' ||
          user?.permission?.users?.indexOf('designersAnalysis') > -1) && (
          <DesignerNumOrder filter={filter} />
        )}
      </Card>
      <Card title={i18next.t('channels.title')}>
        {(user.scope === 'manager' ||
          user?.permission?.channels?.indexOf('analysis') > -1) && (
          <AnalysisLineChart
            filter={filter}
            hasRequestData
            resource="channels"
          />
        )}
      </Card>
    </Col>
    {(user.scope === 'manager' ||
      user?.permission?.niches?.indexOf('analysis') > -1) && (
      <Col md={12} sm={24}>
        <Card title={i18next.t('niches.title')}>
          <AnalysisPieChart
            hasRequestData
            filter={filter}
            resource="niches"
            chartName="numOrderPieChar"
          />
        </Card>
      </Col>
    )}
    {(user.scope === 'manager' ||
      user?.permission?.customers?.indexOf('analysis') > -1) && (
      <Col md={12} sm={24}>
        <Card title={i18next.t('customers.title')}>
          <AnalysisPieChart
            hasRequestData
            redirectWhenClick="screen"
            filter={filter}
            resource="customers"
            chartName="numOrderPieChar"
          />
        </Card>
      </Col>
    )}
    {(user.scope === 'manager' ||
      user?.permission?.dealPipelines?.indexOf('analysis') > -1) && (
      <Col md={12} sm={24}>
        <Card title={i18next.t('dealpipelines.title')}>
          <AnalysisPieChart
            hasRequestData
            filter={filter}
            resource="dealpipelines"
            chartName="numOrderPieChar"
          />
        </Card>
      </Col>
    )}
  </Row>
);
NumOrder.propTypes = {
  user: PropTypes.object,
  filter: PropTypes.object,
};

export default NumOrder;
