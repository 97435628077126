import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Input, Col, Button, Row, message } from 'antd';
import { getGoogleRenderLink, getCreateLink } from 'api/order';
import i18next from 'i18next';

const RenderUploadLink = ({ orderId, onAfterRender }) => {
  const [googleLink, setGoogleLink] = useState('');
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  const getLink = () => {
    setLoading(true);
    getCreateLink({
      email: email?.trim(),
      orderId,
    })
      .then((e) => {
        setLoading(false);
        setGoogleLink(e.url);
        onAfterRender(e.url);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onCopy = () => {
    const textField = document.createElement('textarea');
    textField.innerText = googleLink;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    message.info(i18next.t('common.copySuccess'));
    textField.remove();
  };
  return (
    <div>
      <Row gutter={16}>
        <Col md={20}>
          <Input
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            onPressEnter={getLink}
            placeholder={i18next.t('orders.yourEmail')}
          />
        </Col>
        <Col md={4}>
          <Button loading={loading} onClick={getLink} type="primary">
            {i18next.t('button.createLink')}
          </Button>
        </Col>
      </Row>
      <br />
      {googleLink ? (
        <div className="copy" role="presentation" onClick={onCopy}>
          <b>{i18next.t('orders.uploadLink')}</b>
          {googleLink}
        </div>
      ) : null}
      <br />
    </div>
  );
};

export const RenderUploadLinkForVerify = ({ orderId, onAfterRender }) => {
  const [googleLink, setGoogleLink] = useState('');
  const [link, setLink] = useState('');
  const [loading, setLoading] = useState(false);
  const getNewGoogleLink = () => {
    setLoading(true);
    getGoogleRenderLink({
      link: link?.trim(),
      orderId,
    })
      .then((e) => {
        setLoading(false);
        setGoogleLink(e.url);
        onAfterRender(e.url);
        message.info(i18next.t('common.copySuccess'));
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onCopy = () => {
    const textField = document.createElement('textarea');
    textField.innerText = googleLink;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
  };

  return (
    <div>
      <Row gutter={16}>
        <Col md={20}>
          <Input
            onChange={(e) => {
              setLink(e.target.value);
            }}
            onPressEnter={getNewGoogleLink}
            placeholder={i18next.t('orders.previousLink')}
          />
        </Col>
        <Col md={4}>
          <Button loading={loading} onClick={getNewGoogleLink} type="primary">
            {i18next.t('button.render')}
          </Button>
        </Col>
      </Row>
      <br />
      {googleLink ? (
        <div className="copy" role="presentation" onClick={onCopy}>
          <b>{i18next.t('orders.clientLink')}</b>
          {googleLink}
        </div>
      ) : null}
      <br />
    </div>
  );
};

RenderUploadLink.propTypes = {
  orderId: PropTypes.string,
  onAfterRender: PropTypes.func,
};

RenderUploadLinkForVerify.propTypes = RenderUploadLink.propTypes;

export default RenderUploadLink;
