import React from 'react';
import RestShow from '../../../containers/rest/Show';
import RestFieldItem from '../../../components/RestField/RestFieldItem';

const CommentsShow = props => (
  <RestShow {...props} hasEdit resource="comments">
    <RestFieldItem source="userId" header="comments.userId" />
    <RestFieldItem source="content" header="comments.content" />
    <RestFieldItem source="orderId" header="comments.orderId" />
  </RestShow>
);

export default CommentsShow;
