import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { ConnectedRouter } from 'connected-react-router';
import { ConfigProvider, Empty } from 'antd';
import moment from 'moment';
import store, { history } from './redux/store';
import theme from './configs/theme';
import Routes from './routes';
import './configs/language';
import * as serviceWorker from './serviceWorker';
import AppWrapper from './appStyle';
import ReactQueryProvider from './ReactQueryProvider';

moment.lang('en', {
  week: {
    dow: 1, // Monday is the first day of the week
  },
});
ReactDOM.render(
  <Provider store={store}>
    <ReactQueryProvider>
      <ThemeProvider theme={theme}>
        <ConnectedRouter history={history}>
          <ConfigProvider renderEmpty={() => <Empty />}>
            <Routes />
            <AppWrapper />
          </ConfigProvider>
        </ConnectedRouter>
      </ThemeProvider>
    </ReactQueryProvider>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
