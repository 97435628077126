import { makeReducerCreator } from '../../utils/reduxUtils';
import {
  makeCRUDReducerCreator,
  INITIAL_CRUD_STATE,
} from '../crudCreator/reducer';
import { MODEL, IGNORE_ACTIONS, OrderHistoriesTypes } from './actions';

export const INITIAL_STATE = {
  ...INITIAL_CRUD_STATE,
};

export const cleanIsCurrentRequest = (state, { id }) => ({
  ...state,
  error: null,
  itemLoadings: { ...state.itemLoadings, [id]: true },
});

export const cleanIsCurrentRequestSuccess = (state, { id }) => ({
  ...state,
  data: {
    ...state.data,
    [id]: {},
  },
  ids: state.ids.filter((e) => e !== id),
  itemLoadings: { ...state.itemLoadings, [id]: false },
  error: null,
});

const reducer = makeReducerCreator(INITIAL_STATE, {
  ...makeCRUDReducerCreator(MODEL, IGNORE_ACTIONS),
  [OrderHistoriesTypes.CLEAN_IS_CURRENT_REQUEST]: cleanIsCurrentRequest,
  [OrderHistoriesTypes.CLEAN_IS_CURRENT_REQUEST_SUCCESS]: cleanIsCurrentRequestSuccess,
});

export default reducer;
