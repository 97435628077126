import React from 'react';
import PropTypes from 'prop-types';
import RestInputItem from '../../../../components/RestInput/RestInputItem';

const RolesForm = ({ isEdit }) => (
  <>
    <RestInputItem disabled={isEdit} source="name" header="roles.name" />
    <RestInputItem source="description" header="roles.description" />
  </>
);

RolesForm.propTypes = {
  isEdit: PropTypes.bool,
};

export default RolesForm;
