import React from 'react';
import RestInputItem from '../../../../components/RestInput/RestInputItem';

const UserRequestsForm = (props) => (
  <div {...props}>
    <RestInputItem source="title" header="userRequests.title" />
    <RestInputItem source="content" header="userRequests.content" />
    <RestInputItem
      className="d-none"
      defaultValue="PAYMENT_REQUEST"
      source="typeRequest"
      header="userRequests.typeRequest"
    />
  </div>
);

UserRequestsForm.propTypes = {};

export default UserRequestsForm;
