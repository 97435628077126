import styled from 'styled-components';

export default styled.div`
  .editable-row .ant-form-explain {
    position: absolute;
    font-size: 12px;
    margin-top: -4px;
  }
  .extra-action {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 35px;
    .ant-btn {
      width: 124px;
      height: 40px;
      border-radius: 2px;
    }
  }
  .pagination-section {
    display: flex;
    justify-content: flex-end;
    .ant-pagination {
      margin-top: 24px;
    }
  }
  thead {
    tr {
      font-weight: 500;
      font-size: 16px;
      &:nth-child(2) {
        th {
          font-size: 14px;
          font-weight: 400;
          color: ${({ theme }) => theme.text.tabTitle};
        }
      }
    }
  }
  th {
  }

  .ant-table-thead > tr {
    &:first-child {
      & > th:nth-child(2n + 3) {
        background: #d1d1d1;
      }
    }
  }
`;
