import theme from '../theme/index';

export const FORMAT_DATE = 'MMM DD, YYYY';

export const FULL_ROLES = ['manager', 'cpo', 'cso_and_ceo'];

export const GENDER = [
  {
    value: 'male',
    text: 'gender.male',
  },
  {
    value: 'female',
    text: 'gender.female',
  },
];

export const STATUS = [
  {
    value: 'true',
    text: 'status.active',
    color: theme.color.green,
  },
  {
    value: 'false',
    text: 'status.inactive',
    color: theme.color.red,
  },
];

export const PRODUCT_STATUS = [
  {
    value: 'inprogress',
    text: 'status.inprogress',
    color: theme.color.green,
  },
  {
    value: 'pending',
    text: 'status.pending',
    color: theme.color.orange,
  },
  {
    value: 'completed',
    text: 'status.completed',
    color: theme.color.blue,
  },
  {
    value: 'developing',
    text: 'status.developing',
    color: theme.color.green,
  },
];

export const ORDER_STATUS = [
  {
    value: 'new_deal',
    nextActionPermissionKey: 'nextNewDeal',
    text: 'New Deal',
    background: '#ea3223',
    color: '#fff',
    description: 'Đơn hàng mới nhập và cần phân phối',
    order: 0,
    acceptStatus: 'designer_confirm',
    rejectStatus: 'canceled',
    assignResource: 'designers',
    isShowOutsource: true,
    isHasDeadLine: true,
    outsourceLimitFilter: ['pd'],
    // editNextActive: [
    //   'cpo',
    //   'cao',
    //   'manager',
    //   'cso_and_ceo',
    //   'leader_Supporter',
    //   'cpo',
    //   'odm',
    //   'ods',
    //   'ocdm',
    // ],
  },
  {
    value: 'designer_confirm',
    forceUpdate: 'new_deal',
    nextActionPermissionKey: 'nextDesignerConfirm',
    forceUpdateAssignResource: 'designers',
    text: 'Designer confirm',
    background: '#75f94c',
    color: '#000',
    order: 1,
    description:
      'Bộ phận sản xuất xác nhận đã tiếp nhận đơn hàng và tiến hành làm',
    acceptStatus: 'design_in_progress',
    rejectStatus: 'new_deal',
    assignResource: 'designers',
    isShowOutsource: true,
    outsourceLimitFilter: ['pd'],
    // editNextActive: ['manager'],
  },
  {
    value: 'design_in_progress',
    nextActionPermissionKey: 'nextDesignInProgress',
    forceUpdate: 'designer_confirm',
    text: 'Design In Progress',
    background: '#75f94c',
    color: '#000',
    order: 2,
    userType: 'ON/OFF-PD Tạo',
    acceptStatus: 'verified',
    rejectStatus: 'new_deal',
    assignResource: 'delivers',
    isShowOutsource: true,
    outsourceLimitFilter: ['pd'],
    // editNextActive: [
    //   'cpo',
    //   'cao',
    //   'manager',
    //   'cso_and_ceo',
    //   'leader_Supporter',
    //   'cpo',
    //   'ocdm',
    // ],
  },
  {
    value: 'verified',
    nextActionPermissionKey: 'nextVerify',
    forceUpdateAssignResource: 'delivers',
    forceUpdate: 'design_in_progress',
    text: 'Verify Product',
    background: '#127abd',
    color: 'white',
    order: 3,
    description: 'Đơn đã được duyệt và cần giao demo cho khách',
    acceptStatus: 'delivery',
    rejectStatus: 'design_in_progress',
    assignResource: 'delivers',
  },
  {
    value: 'delivery',
    nextActionPermissionKey: 'nextDelivery',
    forceUpdateAssignResource: 'delivers',
    text: 'Delivery',
    background: '#dce9d5',
    color: '#000',
    order: 4,
    acceptStatus: 'waiting_approved',
    rejectStatus: 'feedback',
    assignResource: 'delivers',
  },
  {
    value: 'feedback',
    forceUpdate: 'feedback',
    nextActionPermissionKey: 'nextFeedback',
    text: 'Feedback & Update',
    background: '#59e69a',
    color: '#000',
    order: 6,
    acceptStatus: 'design_in_progress',
    extraAcceptStatus: 'gua_pd',
    rejectStatus: 'canceled',
    assignResource: 'designers',
    isShowOutsource: true,
    isHasDeadLine: true,
    outsourceLimitFilter: ['pd', 'gua_pd'],
  },
  {
    value: 'waiting_approved',
    forceUpdate: 'delivery',
    nextActionPermissionKey: 'nextWaitingApproved',
    text: 'Waiting customer approved',
    background: '#c27ade',
    color: '#fff',
    order: 5,
    acceptStatus: 'done',
    rejectStatus: 'feedback',
    assignResource: 'delivers',
    isShowOutsource: true,
    outsourceLimitFilter: ['pd'],
  },
  {
    value: 'waiting_for_file',
    forceUpdate: 'waiting_for_file',
    text: 'Waiting for file',
    background: '#8a2df5',
    color: '#fff',
    order: 7,
    acceptStatus: 'done',
    rejectStatus: 'feedback',
    assignResource: 'delivers',
    editNextActive: [
      'sale_B_dealer',
      'manager',
      'cso_and_ceo',
      'cao',
      'delivery_GD',
      'cbo',
    ],
  },
  {
    value: 'done',
    nextActionPermissionKey: 'nextDone',
    forceUpdate: 'waiting_for_file',
    text: 'Done!',
    background: '#20c2de',
    color: 'white',
    order: 8,
    rejectStatus: 'feedback',
    isShowOutsource: true,
    outsourceLimitFilter: ['pd', 'gua_pd'],
  },
  {
    value: 'canceled',
    forceUpdate: 'canceled',
    nextActionPermissionKey: 'nextCancel',
    text: 'Cancel Order',
    background: '#354662',
    color: 'white',
    order: 9,
  },
  {
    value: 'gua_pd',
    nextActionPermissionKey: 'nextGuaranteeProduct',
    forceUpdateAssignResource: 'guarantees',
    text: 'Guarantee Product',
    background: '#59e69a',
    color: '#000',
    order: 10,
    acceptStatus: 'verified',
    rejectStatus: 'feedback',
    assignResource: 'delivers',
  },
  {
    value: 'pre_order',
    nextActionPermissionKey: 'nextPreOrder',
    text: 'Pre Order',
    background: '#ea3223',
    color: '#fff',
    description: 'Đơn hàng mới nhập và cần phân phối',
    order: 0,
    acceptStatus: 'new_deal',
    rejectStatus: 'canceled',
    isShowOutsource: true,
    isHasDeadLine: true,
  },
];

export const PACKAGE_STATUS = [
  { value: 'inprogress', text: 'In progress' },
  { value: 'done', text: 'Done' },
];

export const PAYMENT_STATUS = [
  { value: 'TT', text: 'Đã Thanh Toán' },
  { value: 'CTT', text: 'Chưa Thanh Toán' },
];

export const DESIGNER_TYPE = [
  { value: 'online', text: 'Online' },
  { value: 'offline', text: 'Offline' },
];
// statusEnum: ['ACTIVATED', 'DEACTIVATED', 'DELETED', 'WAITING_APPROVE', 'NONE'],
// userTypeList : ['Admin', 'Salesman', 'Delivery Staff', 'Designer'],

export const ROLES = [
  {
    value: 1,
    text: 'Admin',
  },
  {
    value: 2,
    text: 'Member',
  },
];

export const ORDER_FEATURE = [
  { value: 'prioritize', text: 'Prioritize', color: '#ff2911' },
  { value: 'information', text: 'Information', color: '#38393a' },
  { value: 'basic', text: 'Basic', color: '#64bb55' },
  { value: 'medium', text: 'Medium', color: '#f1d42f' },
  { value: 'highClass', text: 'High-class', color: '#fd9e31' },
  { value: 'customerCare', text: 'Customer Care', color: '#59e69a' },
  { value: 'pr', text: 'PR', color: '#fd7bca' },
  { value: 'test', text: 'Test', color: '#d08cb5' },
];

export const USER_TYPES = [
  {
    value: 'true',
    text: 'outsource',
  },
  {
    value: 'false',
    text: 'member',
  },
];

export const EmployeeKeys = {
  7: 'designerId',
  9: 'qualityAssuranceId',
  3: 'saleId',
  1: 'qualityAssuranceId',
  2: 'qualityAssuranceId',
  11: 'qualityAssuranceId',
  13: 'deliverId',
  14: 'guaId',
  22: 'customerId',
};

export const MIN_PRICE = 10000;

export const CHANNEL_TYPES = [
  {
    value: 'facebook',
    text: 'Facebook',
  },
  {
    value: 'skype',
    text: 'Skype',
  },
  {
    value: 'instagram',
    text: 'Instagram',
  },
  {
    value: 'fiverr',
    text: 'Fiverr',
  },
  {
    value: 'gmail',
    text: 'Gmail',
  },
];

export const WORKING_TIME_OF_DAY = [
  {
    title: 'phase.phase1',
    phaseNumber: 'C1',
    color: 'blue',
    hours: [8, 9, 10, 11, 12],
  },
  {
    title: 'phase.phase2',
    phaseNumber: 'C2',
    color: 'green',
    hours: [13, 14, 15, 16, 17, 18],
  },
  {
    title: 'phase.phase3',
    phaseNumber: 'C3',
    color: 'red',
    hours: [19, 20, 21, 22, 23, 24],
  },
];

export const WORKING_TYPES = [
  { value: 'vp', text: 'VP' },
  { value: 'tn', text: 'TN' },
];

export const BONUS_LEVELS = ['A1', 'A2', 'B1', 'B2', 'C1', 'C2', 'D', 'E'];

export const BONUS_SUB_LEVELS = ['A0', 'A1', 'A2', 'A3'];
