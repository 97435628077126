import React from 'react';
import Create from '../../rest/Create';
import Form from '../components/Form';

const PermissionsCreate = props => (
  <Create {...props} resource="permissions">
    <Form />
  </Create>
);

PermissionsCreate.propTypes = {};

export default PermissionsCreate;
