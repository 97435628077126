import React, { useState } from 'react';
// import PropTypes from 'prop-types';
import { Editor } from '@tinymce/tinymce-react';
import { imageUploadHandler } from 'utils/tools';

const TextEditor = React.forwardRef((props, ref = {}) => {
  const [value, setValue] = useState(props.defaultValue || '');
  const onClear = () => {
    setValue('');
  };

  if (ref) {
    ref.current = {
      onClear,
      setValue,
    };
  }

  return (
    <Editor
      {...props}
      value={value}
      // ref={ref}
      onEditorChange={(e) => {
        setValue(e);
        props.onEditorChange && props.onEditorChange(e);
      }}
      // bounds=".app"
      // modules={modules}s
      toolbar="undo redo | forecolor backcolor | styleselect | bold italic | alignleft aligncenter alignright alignjustify | outdent indent | link media image"
      init={{
        images_upload_handler: imageUploadHandler,
      }}
      plugins="code link checklist autolink image imagetools media"
    />
  );
});

TextEditor.propTypes = {};

export default TextEditor;
