import { makeActionCreator, makeConstantCreator } from '../../utils/reduxUtils';

export const SummariesTypes = {
  ...makeConstantCreator(
    'SUMMARIES_DASHBOARD',
    'SUMMARIES_DASHBOARD_SUCCESS',
    'SUMMARIES_DASHBOARD_FAILURE'
  ),
};
export const getSummariesDashboard = (data, options) =>
  makeActionCreator(SummariesTypes.SUMMARIES_DASHBOARD, { data, options });
export const getSummariesDashboardSuccess = (data, options) =>
  makeActionCreator(SummariesTypes.SUMMARIES_DASHBOARD_SUCCESS, {
    data,
    options,
  });
export const getSummariesDashboardFailure = error =>
  makeActionCreator(SummariesTypes.SUMMARIES_DASHBOARD_FAILURE, { error });
