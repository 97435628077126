import React, { useLayoutEffect } from 'react';
import { Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  watchNotification,
  closeNotification,
} from 'redux/notifications/actions';
import PrivateRoutes from './PrivateRoutes';
import PublicRoutes from './PublicRoutes';
import RoutesWrapper from './styles';
import ModalRoute from './ModalRoute';
import NotFoundPage from '../containers/404Page';

const Routes = (props) => {
  const dispatch = useDispatch();
  useLayoutEffect(() => {
    const ele = document.getElementById('ipl-progress-indicator');
    if (ele) {
      setTimeout(() => {
        // fade out
        ele.classList.add('available');
      }, 500);
      setTimeout(() => {
        // remove from DOM
        ele.outerHTML = '';
      }, 1500);
    }
  }, []);

  useLayoutEffect(() => {
    dispatch(watchNotification());
    return () => {
      dispatch(closeNotification());
    };
    // eslint-disable-next-line
  }, []);

  return (
    <RoutesWrapper>
      <PrivateRoutes />
      <PublicRoutes />
      <ModalRoute {...props} />
      <Route component={NotFoundPage} />
    </RoutesWrapper>
  );
};

export default Routes;
