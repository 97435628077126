import React, { useState, Fragment } from 'react';
import i18next from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Tag, Modal, Select, Form } from 'antd';
import TextEditor from 'components/common/TextEditor';
import { goBack, push } from 'connected-react-router';
import { Link } from 'react-router-dom';
import { RestInputContext } from '../../../../components/RestInput/RestInputContext';
import CustomBreadcrumb from '../../../../components/common/Breadcrumb';
import PageTitle from '../../../../components/common/PageTitle';
import OrderActions from '../../../../redux/orders/actions';
import OrderHistoryActions from '../../../../redux/orderHistories/actions';
import { ORDER_STATUS } from '../../../../configs/localData';
import { DesignInprogressAction } from '../OrderStatus/DesignInprogress';
import ReferenceInput from '../../../../containers/rest/ReferenceInput';
import SelectUser from '../../../../components/common/SelectUser/index';
import { uploadImages } from '../../../../api/uploadMedia';

const OrderBreadcrumb = ({ match }) => {
  const dispatch = useDispatch();
  const [deadLine, setDeadline] = useState();
  const [visible, setVisible] = useState(false);
  const [report] = useState({ text: '' });
  const [isUpload, setUpload] = useState(false);
  const [selectedUser, setSelectedUser] = useState([]);
  const [products, onChangeProducts] = useState([]);
  const [forceUpdateStatus, setForceUpdateStatus] = useState(
    ORDER_STATUS[0].value,
  );
  const user = useSelector((state) => state.auth.data);
  const orderLoading = useSelector((state) => state.orders.loading);
  // const orderLoading = useSelector(state => state.orders.loading);
  const orderHistoriesLoading = useSelector(
    (state) => state.orderHistories.loading,
  );

  const onRefresh = (data) => {
    dispatch(OrderActions.getByIdOrders(data));
    dispatch(
      OrderHistoryActions.getAllOrderHistories(
        { limit: 100, filter: { orderId: data.id } },
        { isRefresh: true },
      ),
    );
  };

  const onChangeStatus = (value) => {
    setForceUpdateStatus(value);
  };

  const goCreateMistakeRecord = (order, requestPayload) => {
    if (requestPayload.status === 'feedback') {
      Modal.confirm({
        title: i18next.t('orders.makeToMistake'),
        onOk: () => {
          dispatch(
            push(
              `#mistakeRecords/create?orderId=${order?.id}&note=${
                requestPayload.feedback || requestPayload.note
              }`,
            ),
          );
        },
      });
    }
  };

  const onOk = (order) => async () => {
    const requestPayload = {
      assignedUser: selectedUser,
      status: forceUpdateStatus,
      orderId: order.id,
      products,
      note: report.text,
      deadLine: deadLine && deadLine.toISOString(),
    };
    if (products.length > 0) {
      const formData = new FormData();
      products.forEach((e) => {
        formData.append('images', e.originFileObj);
      });
      const tags = [];
      formData.append('tags', tags);
      setUpload(true);
      formData.append('type', 'product');
      const responseImages = await uploadImages(formData);
      setUpload(false);
      requestPayload.products = responseImages;
    }
    dispatch(
      OrderHistoryActions.forceUpdate(requestPayload, {
        isBack: false,
      }),
    ).then(() => {
      setSelectedUser([]);
      onChangeProducts([]);
      goCreateMistakeRecord(order, requestPayload);
      setForceUpdateStatus(ORDER_STATUS[0].value);
      report.text = '';
      dispatch(
        OrderHistoryActions.getAllOrderHistories(
          {
            includes: 'assignedUsers,assigner',
            limit: 5,
            filter: { orderId: order.id },
          },
          { isRefresh: true },
        ),
      );
      dispatch(OrderActions.getByIdOrders(order));
    });
    setVisible(false);
  };

  const forceUpdate = () => {
    setVisible(true);
  };

  return (
    <RestInputContext.Consumer>
      {({ record = {} }) => {
        const BREADCRUMB_LIST = [
          {
            title: `Order - #${match.params.id}`,
            path: `/orders/${match.params.id}/edit`,
          },
        ];
        const status = user.isOutsource
          ? ORDER_STATUS.filter((e) => e.isShowOutsource).find(
              (e) => e.value === record.status,
            )
          : ORDER_STATUS.find((e) => e.value === record.status);
        const extraAction = (
          <Fragment>
            <Link to={`#mistakeRecords/create?orderId=${record?.id}`}>
              <Button icon="bug" type="danger">
                {i18next.t('button.reportMistake')}
              </Button>
            </Link>
            {user?.permission?.orderFlow?.indexOf('forceCreateOne') > -1 ? (
              <Button
                loading={orderLoading || orderHistoriesLoading}
                icon="edit"
                type="danger"
                style={{ marginLeft: 10 }}
                onClick={() => forceUpdate(record)}
              >
                {i18next.t('button.forceUpdate')}
              </Button>
            ) : null}
            <Button
              loading={orderLoading || orderHistoriesLoading}
              icon="sync"
              type="primary"
              onClick={() => onRefresh(record)}
              style={{ marginLeft: 10 }}
            >
              {i18next.t('button.refresh')}
            </Button>
          </Fragment>
        );
        return (
          <PageTitle extraAction={extraAction}>
            <Button
              onClick={() => dispatch(goBack())}
              size="large"
              type="link"
              icon="arrow-left"
            />
            <CustomBreadcrumb data={BREADCRUMB_LIST} />
            {status && (
              <Tag
                style={{
                  marginLeft: 20,
                  height: 30,
                  paddingTop: 5,
                  color: ORDER_STATUS.find((e) => e.value === record.status)
                    .color,
                }}
                color={
                  ORDER_STATUS.find((e) => e.value === record.status).background
                }
              >
                {i18next.t(
                  `orderStatuses.${
                    ORDER_STATUS.find((e) => e.value === record.status).value
                  }`,
                )}
              </Tag>
            )}
            <Modal
              destroyOnClose
              onOk={onOk(record)}
              onCancel={() => setVisible(false)}
              visible={visible}
              confirmLoading={isUpload}
            >
              <div>
                <Form.Item
                  label={i18next.t('orderHistories.modal.selectOrderStatus')}
                >
                  <Select
                    value={forceUpdateStatus}
                    onChange={onChangeStatus}
                    style={{ width: '100%' }}
                    allowClear={false}
                    placeholder={i18next.t(
                      'orderHistories.modal.selectOrderStatus',
                    )}
                  >
                    {ORDER_STATUS.map((e) => (
                      <Select.Option key={e.value} value={e.value}>
                        {i18next.t(e.text)}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <StatusContent
                  setNote={(e) => {
                    report.text = e;
                  }}
                  deadLine={deadLine}
                  setDeadline={setDeadline}
                  onChangeProducts={onChangeProducts}
                  setSelectedUser={setSelectedUser}
                  status={forceUpdateStatus}
                />
              </div>
            </Modal>
          </PageTitle>
        );
      }}
    </RestInputContext.Consumer>
  );
};

const StatusContent = ({
  status,
  onChangeProducts,
  setNote,
  setSelectedUser,
  item = {},
}) => {
  const currentStatus = ORDER_STATUS.find((e) => e.value === status);
  switch (status) {
    case 'verified':
      return (
        <DesignInprogressAction
          acceptEdit
          onChangeProducts={onChangeProducts}
          setNote={setNote}
          setSelectedUser={setSelectedUser}
          item={item}
        />
      );
    case 'designer_confirm':
    case 'gua_pd':
      return (
        <div>
          {currentStatus.forceUpdateAssignResource && (
            <ReferenceInput
              searchKey="displayName"
              resource={currentStatus.forceUpdateAssignResource}
            >
              <SelectUser onChange={setSelectedUser} />
            </ReferenceInput>
          )}
          <TextEditor
            onEditorChange={(e) => {
              setNote(e);
            }}
            placeholder={i18next.t('orderHistories.modal.reopenReason')}
          />
        </div>
      );
    case 'new_deal':
    case 'design_in_progress':
    case 'feedback':
    case 'canceled':
    case 'done':
    case 'delivery':
    case 'waiting_approved':
    default:
      return (
        <div>
          {currentStatus.forceUpdateAssignResource && (
            <ReferenceInput
              searchKey="displayName"
              resource={currentStatus.forceUpdateAssignResource}
            >
              <SelectUser onChange={setSelectedUser} />
            </ReferenceInput>
          )}
          <TextEditor
            onEditorChange={(e) => {
              setNote(e);
            }}
            placeholder={i18next.t('orderHistories.modal.reopenReason')}
          />
        </div>
      );
  }
};

OrderBreadcrumb.propTypes = {};

export default OrderBreadcrumb;
