import React from 'react';
import PropTypes from 'prop-types';
import { Tabs } from 'antd';
import i18next from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ClientActivityWrapper } from './styles';
import ListOrders from '../../../Orders/List';
import ListPackages from '../../../Packages/List';
import CommentActions from '../../../../redux/comments/actions';
import CustomersActions from '../../../../redux/customers/actions';
import CommentsList from '../../../Comments/List';
// import EmailLogs from '../EmailLogs';
// import CallLogs from '../CallLogs';

const { TabPane } = Tabs;

const ACTIVITY_FORMS = [
  {
    text: 'customers.detail.activityLog.orders',
    key: 'activityLog',
    component: ListOrders,
  },
  {
    text: 'customers.detail.activityLog.package',
    key: 'package',
    component: ListPackages,
  },
  // {
  //   text: 'customers.detail.activityLog.email',
  //   key: 'emailLog',
  //   component: EmailLogs,
  // },
  // {
  //   text: 'customers.detail.activityLog.call',
  //   key: 'callLog',
  //   component: CallLogs,
  // },
];
const ClientActivity = ({ match }) => {
  const dispatch = useDispatch();
  const currentCustomer = useSelector(state => state.customers.currentData);

  const getCustomer = () => {
    dispatch(CustomersActions.getByIdCustomers({ ...currentCustomer }));
  };

  const createComments = (content, rate) =>
    new Promise((resolve, reject) => {
      dispatch(
        CommentActions.createComments(
          {
            content,
            rate,
            customerId: match.params.id,
          },
          { isBack: false, customApi: 'customerComments' }
        )
      )
        .then(e => {
          resolve(e);
          getCustomer();
        })
        .catch(e => {
          reject(e);
        });
    });

  return (
    <ClientActivityWrapper>
      <Tabs>
        {ACTIVITY_FORMS.map(contactForm => (
          <TabPane
            tab={<span>{i18next.t(contactForm.text)}</span>}
            key={contactForm.key}
          >
            {
              <contactForm.component
                noCardWrapper
                isUpdateRoute={false}
                initialFilter={{
                  filter: {
                    customerId: match.params.id,
                  },
                }}
                isCustomerDetail
              />
            }
          </TabPane>
        ))}
        <TabPane
          key="comments"
          tab={i18next.t('customers.detail.activityLog.comments')}
        >
          <CommentsList
            match={match}
            createComments={createComments}
            hasRate
            initialQuery={{
              filter: { customerId: match.params.id },
              includes: 'reviewer',
            }}
            initialOptions={{ customApi: 'customerComments' }}
            reviewerIdKey="reviewerId"
            editCallback={getCustomer}
          />
        </TabPane>
      </Tabs>
    </ClientActivityWrapper>
  );
};
ClientActivity.propTypes = {
  match: PropTypes.object,
};

export default ClientActivity;
