import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import i18next from 'i18next';
import { Waypoint } from 'react-waypoint';
import LoadingComponent from '../Loading/index';

const { Option } = Select;

const SelectUser = ({
  onSearch,
  loading,
  disabled,
  resourceData,
  onChange,
  onEnter,
}) => {
  return (
  <Select
    filterOption={ (inputValue, option) => option.props.value}
    disabled={disabled}
    mode="multiple"
    showSearch
    onChange={onChange}
    style={{ width: '100%' }}
    onSearch={onSearch}
    placeholder={i18next.t('placeholder.undefined')}
  >
    {resourceData.map(data => (
      <Option key={data.id} value={data.id}>
        {data.displayName}
      </Option>
    ))}

    <Option key="loading" disabled value="loadingTracking">
      {loading && <LoadingComponent />}
      <Waypoint onEnter={onEnter} />
    </Option>
  </Select>
)};

SelectUser.propTypes = {
  resourceData: PropTypes.array,
  disabled: PropTypes.func,
  onChange: PropTypes.func,
  onSearch: PropTypes.func,
  loading: PropTypes.bool,
};

export default SelectUser;
