import React from 'react';
import RestShow from '../../rest/Show';
import RestFieldItem from '../../../components/RestField/RestFieldItem';

const LogsShow = props => (
  <RestShow {...props} hasEdit resource="logs">
    <RestFieldItem source="createdAt" header="logs.createdAt" />
    <RestFieldItem source="name" header="logs.name" />
  </RestShow>
);

export default LogsShow;
