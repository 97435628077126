import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { auth } from './auth/reducer';
import modal from './modal/reducer';
// import here
import payments from './payments/reducer';
import userRequests from './userRequests/reducer';
import logs from './logs/reducer';
import mistakeTypes from './mistakeTypes/reducer';
import mistakeRecords from './mistakeRecords/reducer';
import salaryHistories from './salaryHistories/reducer';
import salaryInfos from './salaryInfos/reducer';
import salaries from './salaries/reducer';
import workingRegisters from './workingRegisters/reducer';
import orderRequirements from './orderRequirements/reducer';
import dealpipelines from './dealpipelines/reducer';
import comments from './comments/reducer';
import guarantees from './guarantees/reducer';
import orderRequesteds from './orderRequesteds/reducer';
import roles from './roles/reducer';
import leaders from './leaders/reducer';
import delivers from './delivers/reducer';
import orderHistories from './orderHistories/reducer';
import designers from './designers/reducer';
import dealers from './dealers/reducer';
import salers from './salers/reducer';
import niches from './niches/reducer';
import brands from './brands/reducer';
import products from './products/reducer';
import packages from './packages/reducer';
import serviceTypes from './serviceTypes/reducer';
import orderStatuses from './orderStatuses/reducer';
import customerSegmentations from './customerSegmentations/reducer';
import productTypes from './productTypes/reducer';
import platforms from './platforms/reducer';
import departments from './departments/reducer';
import orders from './orders/reducer';
import channels from './channels/reducer';
import userTypes from './userTypes/reducer';
import customers from './customers/reducer';
import users from './users/reducer';
import reference from './referenceData/reducer';
import config from './config/reducer';
import summaries from './summaries/reducer';
import notifications from './notifications/reducer';
import tempPermissions from './tempPermissions/reducer';

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    summaries,
    auth,
    modal,
    config,
    // add reducer here
    payments,
    userRequests,
    logs,
    mistakeTypes,
    mistakeRecords,
    tempPermissions,
    salaryHistories,
    salaryInfos,
    salaries,
    workingRegisters,
    orderRequirements,
    dealpipelines,
    comments,
    guarantees,
    orderRequesteds,
    roles,
    leaders,
    delivers,
    orderHistories,
    designers,
    dealers,
    salers,
    niches,
    brands,
    products,
    packages,
    serviceTypes,
    orderStatuses,
    customerSegmentations,
    productTypes,
    platforms,
    departments,
    orders,
    channels,
    userTypes,
    customers,
    users,
    reference,
    notifications,
  });
