import styled from 'styled-components';

export default styled.div`
  .txtTitle {
    height: 0px;
    transform: translateY(0px);
  }
  .txtOutsourceTitle {
    height: 0px;
    transform: translateY(0px);
  }
`;
