import { makeReducerCreator } from '../../utils/reduxUtils';
import {
  makeCRUDReducerCreator,
  INITIAL_CRUD_STATE,
} from '../crudCreator/reducer';
import { MODEL, IGNORE_ACTIONS, UsersTypes } from './actions';

export const INITIAL_STATE = {
  ...INITIAL_CRUD_STATE,
};

const updateUser = (state, { data }) => ({
  ...state,
  data: {
    ...state.data,
    [data.userId]: { ...state.data[data.userId], permission: data.permission },
  },
});

const reducer = makeReducerCreator(INITIAL_STATE, {
  ...makeCRUDReducerCreator(MODEL, IGNORE_ACTIONS),
  [UsersTypes.ADD_PERMISSION_FOR_USER]: updateUser,
});

export default reducer;
