import { takeEvery, put, call } from 'redux-saga/effects';
import { apiWrapper } from 'utils/reduxUtils';
import {
  addPermissionForUserApi,
  setDefaultPermissionForUserApi,
} from 'api/user';
import UsersActions, {
  MODEL,
  IGNORE_ACTIONS,
  PRIMARY_KEY,
  UsersTypes,
  addPermissionForUserSuccess,
  addPermissionForUserFailure,
} from './actions';
import rootCRUDSaga from '../crudCreator/saga';

// use IGNORE_SAGAS to replace "saga" or ignore "saga"
// IGNORE_SAGAS = ['GET_ALL', 'GET_BY_ID', 'DELETE', 'EDIT', 'CREATE'];

const IGNORE_SAGAS = IGNORE_ACTIONS;

export function* addPermissionForUserSaga({ data }) {
  try {
    const response = yield call(
      apiWrapper,
      { isShowProgress: true, isShowSuccessNoti: true },
      addPermissionForUserApi,
      data
    );
    // TODO: Set token in cookies
    if (!response) {
      throw response;
    }
    yield put(addPermissionForUserSuccess(data));
    // yield put(UsersActions.getAllUsers({ filter, orderBy, limit, offset }));
  } catch (err) {
    yield put(addPermissionForUserFailure(err));
  }
}

export function* setDefaultPermissionForUserSaga({ data }) {
  try {
    const response = yield call(
      apiWrapper,
      { isShowProgress: true, isShowSuccessNoti: true },
      setDefaultPermissionForUserApi,
      data
    );
    // TODO: Set token in cookies
    if (!response) {
      throw response;
    }
    yield put(UsersActions.getByIdUsers({ id: data.userId }));
    // yield put(UsersActions.getAllUsers({ filter, orderBy, limit, offset }));
  } catch (err) {
    yield put(addPermissionForUserFailure(err));
  }
}

export default [
  ...rootCRUDSaga(MODEL, IGNORE_SAGAS, UsersActions, PRIMARY_KEY),
  takeEvery(UsersTypes.ADD_PERMISSION_FOR_USER, addPermissionForUserSaga),
  takeEvery(
    UsersTypes.SET_DEFAULT_PERMISSION_FOR_USER,
    setDefaultPermissionForUserSaga
  ),
];
