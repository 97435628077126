import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'antd';
import TextEditor from 'components/common/TextEditor';
import i18next from 'i18next';
import Text from '../../../../components/common/Text';
import OrderStatusTitle from './OrderStatusTitle';
import { UploadUI } from '../../../../components/RestInput/RestUpload';
import { PreviewTextEditor } from '../../../../components/RestInput/RestTextEditor/index';
import RenderUploadLink from '../RederUploadLink';

const DesignInprogress = ({ item }) => (
  <Card title={<OrderStatusTitle item={item} />} className="timelineItem">
    <Text type="body">
      {i18next.t(
        `orderHistories.status.description.${item.status}.${item.accept}`,
      )}
    </Text>
    <br />
    <PreviewTextEditor html={item.note} />
  </Card>
);

export const DesignInprogressAction = ({
  acceptEdit,
  setNote,
  setSelectedUser,
  onChangeProducts,
  item,
  note,
}) => {
  const editorRef = useRef(null);
  return (
    <div>
      {/* <ReferenceInput className="itemRow" resource="leaders">
      <SelectUser disabled={!acceptEdit} onChange={setSelectedUser} />
    </ReferenceInput> */}
      <RenderUploadLink
        orderId={item.orderId}
        onAfterRender={(link) => {
          editorRef.current &&
            editorRef.current.setValue(
              `${
                note?.text || ''
              } <br/>Upload Link: <a target="__blank" href=${link}>${link}</a>`,
            );
        }}
      />
      <TextEditor
        disabled={!acceptEdit}
        className="itemRow"
        style={{ marginTop: 20 }}
        placeholder={i18next.t('note')}
        onEditorChange={(e) => {
          setNote(`${e}`);
        }}
        ref={editorRef}
      />
      <br />
      <UploadUI
        useFormData
        multiple
        disabled={!acceptEdit}
        className="itemRow"
        record={{}}
        onChange={onChangeProducts}
      />
    </div>
  );
};

DesignInprogressAction.propTypes = {
  setNote: PropTypes.func,
  setSelectedUser: PropTypes.func,
  onChangeProducts: PropTypes.func,
  acceptEdit: PropTypes.bool,
};

DesignInprogress.propTypes = {
  item: PropTypes.object,
};

export default DesignInprogress;
