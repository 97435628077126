import React from 'react';
import { Icon } from 'antd';
import I18n from 'i18next';
import PropTypes from 'prop-types';
import Text from '../Text';
import { SummaryCardWrapper } from './styles';

const SummaryCard = ({ children, value, header, icon, color }) => (
  <SummaryCardWrapper style={{ background: color }}>
    <div>
      <Icon type={icon} theme="outlined" className="icon" />
    </div>
    <div className="vInfo">
      <div>
        <Text type="h1SemiBold" className="value">
          {value}
        </Text>
        <Text type="h5" className="title">
          {typeof header === 'string' ? I18n.t(header) : header}
        </Text>
      </div>
      {children}
    </div>
  </SummaryCardWrapper>
);
SummaryCard.propTypes = {
  color: PropTypes.string,
  value: PropTypes.any,
  header: PropTypes.any,
  icon: PropTypes.string,
  children: PropTypes.any,
};

export default SummaryCard;
