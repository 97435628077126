import React from 'react';
import RestShow from '../../../containers/rest/Show';
import RestFieldItem from '../../../components/RestField/RestFieldItem';

const OrderStatusesShow = props => (
  <RestShow {...props} hasEdit resource="orderStatuses">
    <RestFieldItem source="name" header="orderStatuses.name" />
    <RestFieldItem source="description" header="orderStatuses.description" />
    <RestFieldItem source="color" header="orderStatuses.color" />
    <RestFieldItem source="isDelete" header="orderStatuses.isDelete" />
  </RestShow>
);

export default OrderStatusesShow;
