import React from 'react';
import List from '../../../containers/rest/List';
import RestFieldItem from '../../../components/RestField/RestFieldItem';
import ActionGroup from '../../../components/RestActions/ActionGroup';
import EditButton from '../../../components/RestActions/EditButton';
import DeleteButton from '../../../components/RestActions/DeleteButton';

const BrandsList = props => (
  <List {...props} isScroll={false} resource="brands">
    <RestFieldItem source="name" header="brands.name" />
    <ActionGroup>
      <EditButton />
      <DeleteButton isSortDelete />
    </ActionGroup>
  </List>
);

BrandsList.propTypes = {};

export default BrandsList;
