import styled from 'styled-components';

export const ActionForNextStepWrapper = styled.div`
  text-align: center;
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px;
  }
  .h4 {
    color: ${({ theme }) => theme.palette.primary};
  }
  .contentAction {
    margin: 15px 0px;
    color: #000;
  }

  .highlight {
    color: red;
  }
  .selectDeadline {
    margin-left: 10px;
  }
`;
