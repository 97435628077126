import { makeReducerCreator } from '../../utils/reduxUtils';
import {
  makeCRUDReducerCreator,
  INITIAL_CRUD_STATE,
} from '../crudCreator/reducer';
import { MODEL, IGNORE_ACTIONS, NotificationsTypes } from './actions';

export const INITIAL_STATE = {
  ...INITIAL_CRUD_STATE,
};

const createInstallationsSuccess = (state, { data }) => ({
  ...state,
  ...data,
});

const reducer = makeReducerCreator(INITIAL_STATE, {
  ...makeCRUDReducerCreator(MODEL, IGNORE_ACTIONS),
  [NotificationsTypes.CREATE_INSTALLATIONS_SUCCESS]: createInstallationsSuccess,
});

export default reducer;
