import React from 'react';
import RestShow from '../../../containers/rest/Show';
import RestFieldItem from '../../../components/RestField/RestFieldItem';

const ProductCatalogsShow = props => (
  <RestShow {...props} hasEdit resource="productTypes">
    <RestFieldItem source="name" header="productTypes.name" />
    <RestFieldItem source="description" header="productTypes.description" />
    <RestFieldItem source="isDelete" header="productTypes.isDelete" />
  </RestShow>
);

export default ProductCatalogsShow;
