import styled from 'styled-components';

export const DeadlineContentWrapper = styled.div`
  cursor: pointer;
  .ant-input-number {
    width: 100%;
  }
  .ant-input-number-handler-wrap {
    display: none;
    pointer-event: none;
  }
  .row {
    display: flex;
    margin-top: 10px;
    .ant-btn {
      flex: 1;
      &:first-child {
        margin-right: 5px;
      }
    }
  }
`;

export default styled.div`
  padding: 5px 10px;
  text-align: center;
  background: ${({ theme }) => theme.palette.primary};
  border-radius: 10px;
  color: white;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
  &.warning {
    background: ${({ theme }) => theme.alert.warning};
    color: #000;
  }
  &.error {
    background: ${({ theme }) => theme.alert.lightError};
    color: white;
    animation: mymove 1s infinite;
  }
  @keyframes mymove {
    0% {
      background: ${({ theme }) => theme.alert.lightError};
      transform: scale(1);
    }
    30% {
      background: ${({ theme }) => theme.alert.error};
      transform: scale(1.1);
    }

    100% {
      background: ${({ theme }) => theme.alert.lightError};
      transform: scale(1);
    }
  }
`;
