import { makeReducerCreator } from '../../utils/reduxUtils';
import {
  makeCRUDReducerCreator,
  INITIAL_CRUD_STATE,
} from '../crudCreator/reducer';
import { MODEL, IGNORE_ACTIONS, DesignersTypes } from './actions';

export const INITIAL_STATE = {
  ...INITIAL_CRUD_STATE,
  analysis: {},
};

const getDesignerAnalysis = state => ({ ...state });
const getDesignerAnalysisSuccess = (state, { data }) => ({
  ...state,
  analysis: data,
});

const getDesignerAnalysisBaseOnOrderStatus = state => ({ ...state });
const getDesignerAnalysisBaseOnOrderStatusSuccess = (state, { data }) => ({
  ...state,
  analysisByOrderStatus: data,
});
const reducer = makeReducerCreator(INITIAL_STATE, {
  ...makeCRUDReducerCreator(MODEL, IGNORE_ACTIONS),
  [DesignersTypes.GET_DESIGNER_ANALYSIS]: getDesignerAnalysis,
  [DesignersTypes.GET_DESIGNER_ANALYSIS_SUCCESS]: getDesignerAnalysisSuccess,
  [DesignersTypes.GET_DESIGNER_ANALYSIS_BASE_ON_ORDER_STATUS]: getDesignerAnalysisBaseOnOrderStatus,
  [DesignersTypes.GET_DESIGNER_ANALYSIS_BASE_ON_ORDER_STATUS_SUCCESS]: getDesignerAnalysisBaseOnOrderStatusSuccess,
});

export default reducer;
