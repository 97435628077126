import React, { useLayoutEffect, useState } from 'react';
import { Calendar, Badge, Select } from 'antd';
import moment from 'moment';
import Box from 'components/common/Box';
import PageTitle from 'components/common/PageTitle';
import { useDispatch, useSelector } from 'react-redux';
import workingRegistersActions from 'redux/workingRegisters/actions';
import crudSelectors from 'redux/crudSelectors';
import { WORKING_TIME_OF_DAY } from 'configs/localData';
import i18next from 'i18next';
import { debounce } from 'lodash';
import ListStyle from './styles';
import CurrentDate from './CurrentDate';

const WorkingTimeList = () => {
  const dispatch = useDispatch();
  const roles = useSelector((state) => state.config.roles);
  const [selectDay, setSelectDate] = useState(
    moment().startOf('week').format('YYYY-MM-DD'),
  );
  const [currentMonth, setCurrentMonth] = useState(moment());
  const [currentRole, setCurrentRole] = useState(undefined);
  const workingRegisters = useSelector(
    crudSelectors.workingRegisters.getDataArr,
  );

  function monthCellRender(value) {
    return (
      <div className="notes-month">
        <span>list gio lam</span>
      </div>
    );
  }

  const dateCellRender = (value) => {
    const listData = workingRegisters.filter(
      (e) =>
        moment(e.date).format('DD/MM') ===
          moment(value.format()).format('DD/MM') &&
        Object.keys(e.workingTimes).length > 0,
    );

    return (
      <ul className="events">
        {listData.map((item) => (
          <li style={{ position: 'relative' }} key={item.user?.displayName}>
            {item.user?.displayName}
            {WORKING_TIME_OF_DAY.filter((e) =>
              e.hours.find((key) => item.workingTimes[key]),
            ).map((e) => (
              <Badge
                key={`${e.phaseNumber}.${e.hours}`}
                count={`${e.phaseNumber}: ${e.hours
                  .filter((key) => item.workingTimes[key])
                  .join(',')}`}
              />
            ))}
          </li>
        ))}
      </ul>
    );
  };

  const onChange = (e) => {
    setCurrentMonth(e);
  };

  const onSelect = (e) => {
    setSelectDate(moment(e).startOf('week').format('YYYY-MM-DD'));
    const containerElm = document.getElementById('container');
    containerElm.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  const onChangeRole = debounce((e) => {
    setCurrentRole(e);
  }, 100);

  useLayoutEffect(() => {
    dispatch(
      workingRegistersActions.getAllWorkingRegisters(
        {
          includes: 'user',
          limit: 99,
          filter: {
            roleId: { $in: currentRole },
            date: {
              $lte: moment(moment(currentMonth).add(1, 'month').endOf('month'))
                .endOf('week')
                .format('YYYY-MM-DD'),
              $gte: moment(
                moment(currentMonth).subtract(1, 'month').startOf('month'),
              )
                .startOf('week')
                .format('YYYY-MM-DD'),
            },
          },
        },
        {
          isRefresh: true,
        },
      ),
    );
    // eslint-disable-next-line
  }, [currentRole, currentMonth]);

  return (
    <>
      <PageTitle>
        {i18next.t('workingRegisters.title', {
          start: currentMonth
            .startOf('month')
            .startOf('week')
            .format('DD/MM/YY'),
          end: currentMonth.endOf('month').endOf('week').format('DD/MM/YY'),
        })}
      </PageTitle>
      <CurrentDate selectDay={selectDay} />
      <br />
      <Box>
        <div>
          <Select
            mode="multiple"
            style={{ minWidth: 200 }}
            onChange={onChangeRole}
          >
            {roles.map((e) => (
              <Select.Option key={e.id} value={e.id}>
                {e.name}
              </Select.Option>
            ))}
          </Select>
        </div>
        <ListStyle>
          <Calendar
            onChange={onChange}
            onSelect={onSelect}
            dateCellRender={dateCellRender}
            monthCellRender={monthCellRender}
          />
        </ListStyle>
      </Box>
    </>
  );
};

export default WorkingTimeList;
