import React from 'react';

import UsersEdit from '../Users/Edit';

const Profile = (props) => (
  <UsersEdit {...props} isProfile showBreadcrumb={false} customId="me" />
);

Profile.propTypes = {};

export default Profile;
