import React from 'react';
import RestShow from '../../../containers/rest/Show';
import RestFieldItem from '../../../components/RestField/RestFieldItem';

const GuaranteesShow = props => (
  <RestShow {...props} hasEdit resource="guarantees">
    <RestFieldItem source="a" header="guarantees.a" />
  </RestShow>
);

export default GuaranteesShow;
