const theme = {
  palette: {
    primary: '#127abd',
    lightPrimary: '#4cb1e8',
    secondary: 'rgba(45, 48, 71, 1)',
    loadingBackgroundColor: '#2c3e51cc',
    color: ['#788195', '#E4E6E9'],
  },
  fonts: {
    primary: 'Georgia, serif',
  },
  fontWeight: {
    thin: 100, // Thin
    utraLight: 200, // Ultra Light
    light: 300, // Light
    regular: 400, // Regular
    medium: 500, // Medium
    semibold: 600, // Semibold
    bold: 700, // Bold
    heavy: 800, // Heavy
    black: 900, // Black
  },
  background: {
    sidebar: '#001529',
    container: '#f0f3fa',
    content: '#fff',
    input: '#efeff0',
    disabled: '#969696',
    header: '#e9f7fd',
    gradient: 'linear-gradient( to right,  #EB2A29, #F89821)',
  },
  text: {
    primary: '#000',
    text: '#1f2933',
    lightPrimary: '#3e4c59',
    secondary: '#7b8794',
    tabTitle: '#262626',
    empty: '#969696',
    highlight: '#F06A2A',
    disabled: '#969696',
    formLabel: '#757575',
    headerTable: '#9aa5b1',
    sidebar: 'hsla(0,0%,100%,.65)',
  },
  border: {
    default: '#d4d2f450',
    light: '#e4e7eb',
  },
  scrollbar: {
    thumb: '#b7b6c2',
    track: '#f0f3fa',
  },
  color: {
    gray: '#a3a3a3',
    green: '#27ae61',
    red: '#e64c38',
    blue: '#0992d0',
  },
  alert: {
    error: '#e64c38',
    lightError: '#FF9966',
    warning: '#FFCC00',
  },
  card: {
    header: '#e9f7fd',
  },
};

module.exports = theme;
