import React from 'react';
import RestFormDateTimePicker from 'components/RestInput/RestDateTimePicker';
import RestSelect from 'components/RestInput/RestSelect';
import { BONUS_LEVELS, BONUS_SUB_LEVELS } from 'configs/localData';
import { InputNumber } from 'antd';
import RestInputItem from '../../../../components/RestInput/RestInputItem';

const SalaryHistoriesForm = (props) => (
  <div {...props}>
    <RestFormDateTimePicker
      pickerType="month"
      source="dateMonth"
      ruleType="object"
      header="salaryInfos.dateMonth"
      disabled
    />
    <RestFormDateTimePicker
      source="startDate"
      showTime={false}
      formatDate="DD/MM/YYYY"
      header="salaryInfos.startDate"
      disabled
    />
    <RestFormDateTimePicker
      showTime={false}
      source="endDate"
      formatDate="DD/MM/YYYY"
      header="salaryInfos.endDate"
      disabled
    />
    <RestSelect
      resourceData={BONUS_LEVELS.map((e) => ({ value: e }))}
      titleProp="value"
      valueProp="value"
      source="salaryScheme"
      header="salaryHistories.salaryScheme"
    />
    <RestSelect
      resourceData={BONUS_SUB_LEVELS.map((e) => ({ value: e }))}
      source="kpi"
      titleProp="value"
      valueProp="value"
      header="salaryHistories.kpi"
    />
    <RestInputItem
      ruleType="number"
      source="coef"
      header="salaryHistories.coef"
    >
      <InputNumber />
    </RestInputItem>
    <RestInputItem
      ruleType="number"
      source="coefOT"
      header="salaryHistories.coefOT"
    >
      <InputNumber />
    </RestInputItem>
  </div>
);

SalaryHistoriesForm.propTypes = {};

export default SalaryHistoriesForm;
