import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'antd';
import i18next from 'i18next';
import Text from '../../../../components/common/Text';
import OrderStatusTitle from './OrderStatusTitle';
import { PreviewTextEditor } from '../../../../components/RestInput/RestTextEditor/index';

const Deliveried = ({ item }) => (
  <Card title={<OrderStatusTitle item={item} />} className="timelineItem">
    <Text type="body">
      {i18next.t(`orderHistories.status.description.${item.status}.${item.accept}`)}
    </Text>
    <br />
    {item.note && <PreviewTextEditor html={item.note} />}
    {item.report && (
      <Text color="red" type="bodyTitle">
        {i18next.t('orderHistories.feedback')}
        :
        <br />
        <PreviewTextEditor html={item.report} />
      </Text>
    )}
  </Card>
);

Deliveried.propTypes = {
  item: PropTypes.object,
};

export default Deliveried;
