import React from 'react';
import RestInputItem from '../../../../components/RestInput/RestInputItem';

const GuaranteesForm = props => (
  <div {...props}>
    <RestInputItem source="a" header="guarantees.a" />
  </div>
);

GuaranteesForm.propTypes = {};

export default GuaranteesForm;
