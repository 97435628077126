import React from 'react';
import { formatChannelData } from 'utils/tools';
import Create from '../../../containers/rest/Create';
import Form from '../components/Form';

const ChannelsCreate = props => (
  <Create {...props} formatOnSubmit={formatChannelData} resource="channels">
    <Form />
  </Create>
);

ChannelsCreate.propTypes = {};

export default ChannelsCreate;
