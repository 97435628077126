import { makeReducerCreator } from '../../utils/reduxUtils';
import { SummariesTypes } from './actions';

export const INITIAL_STATE = {};

const summariesDashboardSuccess = (state, { data }) => ({
  ...state,
  boards: data,
  prevOrder: {},
});

const summariesDashboardFailure = (state, { data }) => ({
  ...state,
  boards: {},
  error: data,
});

const reducer = makeReducerCreator(INITIAL_STATE, {
  [SummariesTypes.SUMMARIES_DASHBOARD_SUCCESS]: summariesDashboardSuccess,
  [SummariesTypes.SUMMARIES_DASHBOARD_FAILURE]: summariesDashboardFailure,
});

export default reducer;
