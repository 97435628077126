import React from 'react';
import RestShow from '../../rest/Show';
import RestFieldItem from '../../../components/RestField/RestFieldItem';

const PaymentsShow = props => (
  <RestShow {...props} hasEdit resource="payments">
    <RestFieldItem source="createdAt" header="payments.createdAt" />
  </RestShow>
);

export default PaymentsShow;
