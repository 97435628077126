// import crud action
import payments from './payments/actions';
import userRequests from './userRequests/actions';
import logs from './logs/actions';
import mistakeTypes from './mistakeTypes/actions';
import mistakeRecords from './mistakeRecords/actions';
import salaryHistories from './salaryHistories/actions';
import salaryInfos from './salaryInfos/actions';
import salaries from './salaries/actions';
import workingRegisters from './workingRegisters/actions';
import orderRequirements from './orderRequirements/actions';
import comments from './comments/actions';
import guarantees from './guarantees/actions';
import orderRequesteds from './orderRequesteds/actions';
import roles from './roles/actions';
import leaders from './leaders/actions';
import delivers from './delivers/actions';
import orderHistories from './orderHistories/actions';
import designers from './designers/actions';
import dealers from './dealers/actions';
import salers from './salers/actions';
import niches from './niches/actions';
import brands from './brands/actions';
import products from './products/actions';
import packages from './packages/actions';
import serviceTypes from './serviceTypes/actions';
import orderStatuses from './orderStatuses/actions';
import customerSegmentations from './customerSegmentations/actions';
import productTypes from './productTypes/actions';
import platforms from './platforms/actions';
import departments from './departments/actions';
import orders from './orders/actions';
import channels from './channels/actions';
import userTypes from './userTypes/actions';
import users from './users/actions';
import customers from './customers/actions';
import dealpipelines from './dealpipelines/actions';

export default {
  // actions here
  payments,
  userRequests,
  logs,
  mistakeTypes,
  mistakeRecords,
  salaryHistories,
  salaryInfos,
  salaries,
  workingRegisters,
  orderRequirements,
  dealpipelines,
  comments,
  guarantees,
  orderRequesteds,
  roles,
  leaders,
  delivers,
  orderHistories,
  designers,
  dealers,
  salers,
  niches,
  brands,
  products,
  packages,
  serviceTypes,
  orderStatuses,
  customerSegmentations,
  productTypes,
  platforms,
  departments,
  orders,
  channels,
  userTypes,
  customers,
  users,
};