import React, { useEffect, useState } from 'react';
import RestTextEditor from 'components/RestInput/RestTextEditor';
import { InputNumber } from 'antd';
import { uniqBy } from 'lodash';
import RestSelect from 'components/RestInput/RestSelect';
import { useSelector } from 'react-redux';
import ReferenceInput from 'containers/rest/ReferenceInput';
import RestInputItem from '../../../../components/RestInput/RestInputItem';

const MistakeRecordsForm = (props) => {
  const order = useSelector((state) => state.orders.currentData);
  const [employees, setEmployees] = useState([]);
  useEffect(() => {
    setEmployees(
      uniqBy(
        [
          order.designer,
          order.sale,
          order.deliver,
          order.qualityAssurance,
          order.dealer,
        ].filter((e) => e),
        'id'
      )
    );
  }, [order]);

  return (
    <div {...props}>
      <RestInputItem
        ruleType="number"
        disabled
        source="orderId"
        header="mistakeRecords.orderId"
      />

      <RestSelect
        resourceData={employees}
        valueProp="id"
        titleProp="displayName"
        source="victimId"
        header="mistakeRecords.victimId"
      />
      <RestInputItem source="link" header="mistakeRecords.link" />
      <RestInputItem isReference source="mistakeTypeId">
        <ReferenceInput
          source="mistakeTypeId"
          valueProp="id"
          titleProp="name"
          resource="mistakeTypes"
        >
          <RestSelect
            valueProp="id"
            titleProp="name"
            source="mistakeTypeId"
            header="mistakeRecords.mistakeTypeId"
          />
        </ReferenceInput>
      </RestInputItem>
      <RestInputItem
        ruleType="number"
        source="monetaryFine"
        header="mistakeRecords.monetaryFine"
      >
        <InputNumber />
      </RestInputItem>
      <RestTextEditor isCreate source="note" header="mistakeRecords.note" />
    </div>
  );
};

MistakeRecordsForm.propTypes = {};

export default MistakeRecordsForm;
