import { takeEvery, put, call } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { push } from 'connected-react-router';
import { notification } from 'antd';
import i18next from 'i18next';
import { omit } from 'lodash';
import { apiWrapper } from '../../utils/reduxUtils';
import {
  AuthTypes,
  loginSuccessAction,
  getCurentUser,
  loginFailureAction,
  updateUserSuccess,
  updateUserFailure,
  getCurentUserFailure,
  getCurentUserSuccess,
  forgotPasswordFailure,
  forgotPasswordSuccess,
  resetPasswordSuccess,
  resetPasswordFailure,
  registerSuccessAction,
  registerFailureAction,
  changePasswordSuccess,
  changePasswordFailure,
  getPermissionsSuccess,
  getPermissionsFailure,
} from './actions';
import { watchNotification, closeNotification } from '../notifications/actions';
import {
  loginApi,
  deleteInstallationApi,
  logoutApi,
  updateCurrentUserApi,
  getCurrentUserApi,
  resetPasswordApi,
  forgotPasswordApi,
  registerApi,
  registerWithTokenApi,
  changePasswordApi,
  getPermissionApi,
} from '../../api/user';

function* loginSaga({ params }) {
  try {
    const response = yield call(
      apiWrapper,
      {
        isShowLoading: true,
        isShowSuccessNoti: false,
      },
      loginApi,
      params
    );
    if (response.token) {
      localStorage.setItem('sessionToken', response.token);
      localStorage.setItem('userId', response.id);
      yield put(loginSuccessAction(response));
      yield put(getCurentUser());
      yield put(watchNotification());

      delay(100);

      window.location.reload();
    } else {
      yield put(loginFailureAction(response));
    }
  } catch (error) {
    yield put(loginFailureAction(error));
  }
}

function* logoutSaga() {
  try {
    localStorage.removeItem('sessionToken');
    localStorage.removeItem('fullName');
    localStorage.removeItem('id');
    localStorage.setItem('scope', '');
    yield put(push('/'));
    const installationId = localStorage.getItem('installationId');
    localStorage.removeItem('installationId');
    yield call(
      apiWrapper,
      {
        isShowLoading: false,
        isShowSuccessNoti: false,
      },
      logoutApi
    );
    if (installationId) {
      yield call(
        apiWrapper,
        {
          isShowLoading: false,
          isShowSuccessNoti: false,
        },
        deleteInstallationApi,
        installationId
      );
    }
    yield put(closeNotification());
  } catch (error) {
    // /logic here
    console.log(error);
  }
}

function* getCurrentUserSaga() {
  try {
    const response = yield call(
      apiWrapper,
      {
        isShowLoading: false,
        isShowSuccessNoti: false,
      },
      getCurrentUserApi,
      {
        includes: ['role'],
      }
    );
    if (response.id) {
      localStorage.setItem('fullName', response.fullName);
      localStorage.setItem('id', response.id);
      localStorage.setItem('scope', response.scope);
      yield put(getCurentUserSuccess(response));
    } else {
      yield put(getCurentUserFailure(response));
    }
  } catch (error) {
    yield put(getCurentUserFailure(error));
  }
}

function* updateUserSaga({ params }) {
  try {
    const response = yield call(
      apiWrapper,
      {
        isShowLoading: true,
        isShowSuccessNoti: true,
      },
      updateCurrentUserApi,
      params
    );
    if (response.id) {
      yield put(updateUserSuccess(response));
    } else {
      yield put(updateUserFailure(response));
    }
  } catch (error) {
    yield put(updateUserFailure(error));
  }
}

function* forgotPasswordSaga({ email }) {
  try {
    const response = yield call(
      apiWrapper,
      {
        isShowLoading: true,
        isShowSuccessNoti: true,
      },
      forgotPasswordApi,
      { email }
    );
    yield put(forgotPasswordSuccess(response));
  } catch (error) {
    yield put(forgotPasswordFailure(error));
  }
}

function* resetPasswordSaga({ password, resetPasswordToken }) {
  try {
    const response = yield call(
      apiWrapper,
      {
        isShowLoading: true,
        isShowSuccessNoti: true,
      },
      resetPasswordApi,
      {
        password,
        resetPasswordToken,
      }
    );
    yield put(resetPasswordSuccess(response));
    yield put(push('/login'));
  } catch (error) {
    yield put(resetPasswordFailure(error));
  }
}

function* registerSaga({ params }) {
  try {
    const response = yield call(
      apiWrapper,
      {
        isShowLoading: true,
        isShowSuccessNoti: false,
      },
      registerApi,
      params
    );
    if (response.token) {
      localStorage.setItem('sessionToken', response.token);
      yield put(registerSuccessAction(response));
      yield put(getCurentUser());
    } else {
      yield put(registerFailureAction(response));
    }
  } catch (error) {
    yield put(registerFailureAction(error));
  }
}

function* registerWithTokenSaga({ params }) {
  try {
    const response = yield call(
      apiWrapper,
      {
        isShowLoading: true,
        isShowSuccessNoti: false,
      },
      registerWithTokenApi,
      params
    );
    if (response.token) {
      localStorage.setItem('sessionToken', response.token);
      yield put(registerSuccessAction(response));
      yield put(getCurentUser());
    } else {
      yield put(registerFailureAction(response));
    }
  } catch (error) {
    yield put(registerFailureAction(error));
  }
}

export function* changePasswordSaga({ password }) {
  try {
    const response = yield call(
      apiWrapper,
      { isShowProgress: true },
      changePasswordApi,
      password
    );
    // TODO: Set token in cookies
    if (!response) {
      throw response;
    }
    notification.success({
      message: i18next.t('notification.success'),
      description: i18next.t('users.changePassword'),
    });
    yield put(changePasswordSuccess(response));
  } catch (err) {
    yield put(changePasswordFailure(err));
  }
}

export function* getPermissionSaga() {
  try {
    const response = yield call(
      apiWrapper,
      { isShowProgress: true },
      getPermissionApi
    );
    // TODO: Set token in cookies
    if (!response) {
      throw response;
    }
    yield put(
      getPermissionsSuccess({
        stringtoWord: response.stringtoWord,
        permissions: Object.keys(omit(response, 'stringtoWord')).map((key) => ({
          permissions: response[key],
          key,
          title: response.stringtoWord[key],
        })),
      })
    );
  } catch (err) {
    yield put(getPermissionsFailure(err));
  }
}

export default [
  takeEvery(AuthTypes.LOGIN, loginSaga),
  takeEvery(AuthTypes.LOGOUT, logoutSaga),
  takeEvery(AuthTypes.GET_CURRENT_USER, getCurrentUserSaga),
  takeEvery(AuthTypes.UPDATE_USER, updateUserSaga),
  takeEvery(AuthTypes.FORGOT_PASSWORD, forgotPasswordSaga),
  takeEvery(AuthTypes.RESET_PASSWORD, resetPasswordSaga),
  takeEvery(AuthTypes.REGISTER, registerSaga),
  takeEvery(AuthTypes.REGISTER_WITH_TOKEN, registerWithTokenSaga),
  takeEvery(AuthTypes.CHANGE_PASSWORD, changePasswordSaga),
  takeEvery(AuthTypes.GET_PERMISSIONS, getPermissionSaga),
];
