import React from 'react';
import RestShow from '../../../containers/rest/Show';
import RestFieldItem from '../../../components/RestField/RestFieldItem';

const OrderRequestedsShow = props => (
  <RestShow {...props} hasEdit resource="orderRequesteds">
    <RestFieldItem source="a" header="orderRequesteds.a" />
  </RestShow>
);

export default OrderRequestedsShow;
