import React from 'react';
import Edit from '../../../containers/rest/Edit';
import Form from '../components/Form';

const DesignersEdit = props => (
  <Edit {...props} resource="designers">
    <Form />
  </Edit>
);

DesignersEdit.propTypes = {};

export default DesignersEdit;
