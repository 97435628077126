import React from 'react';
// import PropTypes from 'prop-types';
import { Row, Col, Card } from 'antd';
import i18next from 'i18next';
import AnalysisLineChart from 'components/RestLayout/AnalysisLineChart';
import AnalysisPieChart from '../../components/RestLayout/AnalysisPieChart';
import DesignerRevenue from '../Users/UserAnalysis/DesignerRevenue';

const Revenue = props => (
  <Row gutter={16}>
    <Col span={24}>
      <Card title={i18next.t('orderHistories.employee.designer')}>
        <DesignerRevenue />
      </Card>
      <Card title={i18next.t('channels.title')}>
        <AnalysisLineChart resource="channels" />
      </Card>
    </Col>
    <Col md={12} sm={24}>
      <Card title={i18next.t('niches.title')}>
        <AnalysisPieChart resource="niches" chartName="revenuePieChar" />
      </Card>
    </Col>
    <Col md={12} sm={24}>
      <Card title={i18next.t('customers.title')}>
        <AnalysisPieChart
          redirectWhenClick="screen"
          resource="customers"
          chartName="revenuePieChar"
        />
      </Card>
    </Col>
    <Col md={12} sm={24}>
      <Card title={i18next.t('dealpipelines.title')}>
        <AnalysisPieChart resource="dealpipelines" chartName="revenuePieChar" />
      </Card>
    </Col>
  </Row>
);
Revenue.propTypes = {};

export default Revenue;
