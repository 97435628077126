import React from "react";
import { connect } from "react-redux";
import Create from "../../../containers/rest/Create";
import Form from "../components/Form";
import { uploadImages } from "../../../api/uploadMedia";

const ProductsCreate = ({ productTypes, ...props }) => (
  <Create
    {...props}
    formatOnSubmit={async values => {
      const formData = new FormData();
      values.productImages.forEach(e => {
        formData.append("images", e.originFileObj);
      });
      const tags = values.productCatalogIds
        ? productTypes
            .filter(e => values.productCatalogIds.indexOf(e.id) > -1)
            .map(e => e.name)
        : "";
      formData.append("tags", tags);
      formData.append('type', 'product');
      const images = await uploadImages(formData);
      values.productImages = images;
      return values;
    }}
    resource="products"
  >
    <Form />
  </Create>
);

ProductsCreate.propTypes = {};

const mapStateToProps = (state, props) => ({
  productTypes: state.config.productTypes,
});

const mapDispatchToProps = (dispatch, props) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(ProductsCreate);
