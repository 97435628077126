import React from 'react';
import RestShow from '../../../containers/rest/Show';
import RestFieldItem from '../../../components/RestField/RestFieldItem';

const DeliversShow = props => (
  <RestShow {...props} hasEdit resource="delivers">
    <RestFieldItem source="name" header="delivers.name" />
  </RestShow>
);

export default DeliversShow;
