import React from 'react';
import RestShow from '../../../containers/rest/Show';
import RestFieldItem from '../../../components/RestField/RestFieldItem';

const DealpipelinesShow = props => (
  <RestShow {...props} hasEdit resource="dealpipelines">
    <RestFieldItem source="name" header="dealpipelines.name" />
    <RestFieldItem source="description" header="dealpipelines.description" />
  </RestShow>
);

export default DealpipelinesShow;
