import React from 'react';
import RestTextEditor from 'components/RestInput/RestTextEditor';
import { Rate } from 'antd';
import RestInputItem from '../../../../components/RestInput/RestInputItem';

const MistakeTypesForm = (props) => (
  <div {...props}>
    <RestInputItem source="name" header="mistakeTypes.name" />
    <RestInputItem ruleType="number" source="level" header="mistakeTypes.level">
      <Rate />
    </RestInputItem>
    <RestTextEditor isCreate source="description" header="mistakeTypes.description" />
  </div>
);

MistakeTypesForm.propTypes = {};

export default MistakeTypesForm;
