import styled from 'styled-components';

export const DealPriceContentWrapper = styled.div`
  cursor: pointer;
  .ant-input-number {
    width: 100%;
  }
  .ant-input-number-handler-wrap {
    display: none;
    pointer-event: none;
  }
  .row {
    display: flex;
    margin-top: 10px;
    .ant-btn {
      flex: 1;
      &:first-child {
        margin-right: 5px;
      }
    }
  }
`;

export default styled.div`
  padding: 5px;
  background: #50b760;
  border-radius: 10px;
  color: white;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
`;
