import { Button, Tag } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { formatMoney } from 'utils/textUtils';
import RestFieldItem from '../../../components/RestField/RestFieldItem';
import List from '../../rest/List';

const PaymentsList = (props) => (
  <List {...props} initialFilter={{ includes: 'orders' }} resource="payments">
    <RestFieldItem source="createdAt" header="payments.createdAt" />
    <RestFieldItem
      format={(data) => formatMoney(data)}
      source="bonusAmount"
      header="payments.bonusAmount"
    />
    <RestFieldItem
      format={(data) => formatMoney(data)}
      source="discountAmount"
      header="payments.discountAmount"
    />
    <RestFieldItem
      format={(data) => formatMoney(data)}
      source="totalAmount"
      header="payments.totalAmount"
    />
    <RestFieldItem source="note" header="payments.note" />
    <RestFieldItem
      format={(data, record) =>
        data ? (
          <Tag>Confirmed</Tag>
        ) : (
          <Link to={`#payments/${record?.id}/edit`}>
            <Button type="primary">Review</Button>
          </Link>
        )
      }
      source="isConfirm"
      header="payments.isConfirm"
    />
  </List>
);

PaymentsList.propTypes = {};

export default PaymentsList;
