import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Table, Spin, Select, Row, Col, Form, Input, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
// import Table from '../components/Table';
import { getPermissions } from 'redux/auth/actions';
import UserActions from 'redux/users/actions';
import crudSelectors from 'redux/crudSelectors';
import Box from 'components/common/Box';
import { getValidData, getSearch, getFilterFromUrl } from 'utils/tools';
import i18next from 'i18next';
import PageTitle from 'components/common/PageTitle';
import { push } from 'connected-react-router';
import { Link } from 'react-router-dom';
import { debounce } from 'lodash';
import PermissionsListStyles from './styles';
import Actions from '../components/Actions';
import SelectionPermissionModal from '../components/SelectionPermissionModal';

const PermissionsList = ({ location }) => {
  const dispatch = useDispatch();
  const permissionConfigs = useSelector((state) => state.auth.permissions);
  const roles = useSelector((state) => state.config.roles);
  const users = useSelector(crudSelectors.users.getDataArr);
  const loading = useSelector(crudSelectors.users.getLoading);
  const filter = useSelector(crudSelectors.users.getFilters);
  const [selected, setSelected] = useState(null);
  const [filterPermissionName, setFilterPermissionName] = useState('');
  const debounceSetFilterPermissionName = debounce(
    setFilterPermissionName,
    300
  );

  const onSelectPermission = (record, data, i, config) => {
    setSelected({ record, data, index: i, config });
  };

  const onSearchPermissionName = (text) => {
    debounceSetFilterPermissionName(text);
  };

  const columns = permissionConfigs
    ? [
        {
          key: 'displayName',
          fixed: 'left',
          width: 200,
          title: i18next.t('users.displayName'),
          dataIndex: `displayName`,
          render: (data, record) => (
            <div style={{ width: 200 }}>
              <Link to={`/users/${record.id}/edit`}>{data}</Link>
            </div>
          ),
        },
        ...permissionConfigs
          .filter(
            (e) =>
              e.title
                .toLocaleLowerCase()
                .indexOf(filterPermissionName.toLocaleLowerCase()) > -1
          )
          .map((config, i) => ({
            ...config,
            dataIndex: `permission.${config.key}`,
            key: config?.title + i,
            checkbox: true,
            align: 'center',
            render: (data, record) => (
              <Button
                onClick={() => onSelectPermission(record, data, i, config)}
              >
                {`${data?.length || 0}/${config?.permissions?.length}`}
              </Button>
            ),
            // children: config?.permissions?.map((e, index) => ({
            //   title: stringtoWord[e],
            //   align: 'center',
            //   key: `${i}-${config?.title}.${e}-${index}`,
            //   dataIndex: `permissions`,
            //   render: (data, record) => (
            //     <PermissionCheckbox
            //       index={i}
            //       roleIndex={
            //         data && findIndex(data, (e) => e.title === config?.title)
            //       }
            //       config={config}
            //       rootPermission={config.key}
            //       key={e}
            //       permissionKey={e}
            //       data={data}
            //       record={record}
            //     />
            //   ),
            // })),
          })),
        {
          key: 'id',
          fixed: 'right',
          width: 80,
          title: i18next.t('users.actions'),
          dataIndex: `id`,
          render: (data, record) => (
            <div style={{ width: 80 }}>
              <Actions record={record} />
            </div>
          ),
        },
      ]
    : [];

  const onChangePagination = (e, filters, sorter) => {
    const formatSort =
      sorter && sorter.field && sorter.order
        ? `${sorter.order === 'descend' ? '-' : ''}${sorter.field}`
        : null;
    dispatch(
      UserActions.getAllUsers(
        {
          page: e.current,
          limit: e.pageSize,
          filter: {
            ...getValidData({ ...filter.filter, isDelete: false }, true),
          },
          orderBy: formatSort,
        },
        { isRefresh: true }
      )
    );

    dispatch(
      push(
        `/permissions?${getSearch({
          page: e.current,
          limit: e.pageSize,
          filter: {
            ...getValidData({ ...filter.filter }, true),
          },
          orderBy: formatSort,
        })}`
      )
    );
  };

  const onChangeRole = (item) => {
    dispatch(
      UserActions.getAllUsers(
        {
          ...filter,
          filter: {
            ...getValidData(
              {
                ...filter.filter,
                roleId: {
                  $in: item,
                },
                isDelete: false,
              },
              true
            ),
          },
        },
        { isRefresh: true }
      )
    );
    dispatch(
      push(
        `/permissions?${getSearch({
          ...filter,
          filter: {
            ...getValidData(
              {
                ...filter.filter,
                roleId: {
                  $in: item,
                },
              },
              true
            ),
          },
        })}`
      )
    );
  };

  const onSearch = (e) => {
    dispatch(
      UserActions.getAllUsers(
        {
          ...filter,
          filter: {
            ...filter?.filter,
            isDelete: false,
          },
          offset: 0,
          q: e,
        },
        { isRefresh: true }
      )
    );

    dispatch(
      push(
        `/permissions?${getSearch({
          ...filter,
          q: e,
        })}`
      )
    );
  };

  useEffect(() => {
    dispatch(getPermissions());
    dispatch(
      UserActions.getAllUsers(
        getFilterFromUrl(location.search) || {
          limit: 5,
          orderBy: 'displayName',
          filter: {
            isDelete: false,
          },
        },
        { isRefresh: true }
      )
    );
    // eslint-disable-next-line
  }, []);
  return (
    <PermissionsListStyles>
      <PageTitle>{i18next.t('permissions.header')}</PageTitle>
      <Box>
        <Row gutter={20}>
          <Col md={12} sm={24}>
            <Form.Item label={i18next.t('permissions.filterRole')}>
              <Select
                mode="multiple"
                style={{ minWidth: '100%' }}
                onBlur={(e) => {
                  onChangeRole(e);
                }}
                defaultValue={
                  filter?.filter?.roleId?.$in ||
                  getFilterFromUrl(location.search)?.filter?.roleId?.$in
                }
              >
                {roles.map((e) => (
                  <Select.Option key={e.id} value={e.id}>
                    {e.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col md={12} sm={24}>
            <Form.Item label={i18next.t('permissions.searchName')}>
              <div>
                <Input.Search
                  defaultValue={
                    filter?.q || getFilterFromUrl(location.search)?.q
                  }
                  onSearch={onSearch}
                />
              </div>
            </Form.Item>
          </Col>
          <Col md={12} sm={24}>
            <Form.Item label={i18next.t('permissions.filterPermissionName')}>
              <div>
                <Input.Search onSearch={onSearchPermissionName} />
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Box>
      {permissionConfigs ? (
        <Box>
          <Table
            rowKey="id"
            loading={loading}
            scroll={{ x: '1200px' }}
            tableLayout="auto"
            dataSource={users}
            columns={columns}
            bordered
            onChange={onChangePagination}
            pagination={{
              total: filter.count,
              current: filter.page,
              pageSize: filter.limit,
              showQuickJumper: true,
              showSizeChanger: true,
            }}
          />
        </Box>
      ) : (
        <Spin />
      )}
      <SelectionPermissionModal
        onCancel={() => setSelected(null)}
        {...selected}
      />
    </PermissionsListStyles>
  );
};

PermissionsList.propTypes = {
  location: PropTypes.object,
};

PermissionsList.defaultProps = {
  resource: 'auth/permission',
  rootPath: '',
  redirects: {
    edit: 'modal',
    create: 'modal',
  },
  initCreateData: {},
};

export default PermissionsList;
