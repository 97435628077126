import { takeEvery, put, call } from 'redux-saga/effects';
import { forceUpdate } from 'api/order';
import { DEFERRED } from 'redux/ExposedPromiseMiddleware';
// import { postApi } from 'api/crud';
import OrderHistoriesActions, {
  OrderHistoriesTypes,
  MODEL,
  IGNORE_ACTIONS,
  PRIMARY_KEY,
  cleanIsCurrentRequestSuccess,
  forceUpdateSuccess,
  forceUpdateFailure,
} from './actions';
import rootCRUDSaga from '../crudCreator/saga';
import { apiWrapper } from '../../utils/reduxUtils';
import { putApi } from '../../api/crud';
// use IGNORE_SAGAS to replace "saga" or ignore "saga"
// IGNORE_SAGAS = ['GET_ALL', 'GET_BY_ID', 'DELETE', 'EDIT', 'CREATE'];

const IGNORE_SAGAS = IGNORE_ACTIONS;

function* cleanIsCurrentRequestSaga({ id }) {
  try {
    const response = yield call(
      apiWrapper,
      { isShowProgress: true },
      putApi,
      'orderHistories',
      id,
      {
        isCurrent: false,
        accept: false,
      },
    );

    if (response) {
      yield put(cleanIsCurrentRequestSuccess(id));
    }
  } catch (error) {
    // yield put((error));
  }
}

function* forceUpdateSaga({ data, [DEFERRED]: deferred }) {
  try {
    // const response = yield call(
    //   apiWrapper,
    //   { isShowProgress: true },
    //   postApi,
    //   'orderHistories',
    //   data,
    // );
    const response = yield call(
      apiWrapper,
      { isShowProgress: true },
      forceUpdate,
      data,
    );

    if (response) {
      yield put(forceUpdateSuccess(response));
    }
    deferred.resolve();
  } catch (error) {
    deferred.resolve(error);
    // yield put((error));
    yield put(forceUpdateFailure(error));
  }
}

export default [
  ...rootCRUDSaga(MODEL, IGNORE_SAGAS, OrderHistoriesActions, PRIMARY_KEY),
  takeEvery(
    OrderHistoriesTypes.CLEAN_IS_CURRENT_REQUEST,
    cleanIsCurrentRequestSaga,
  ),
  takeEvery(OrderHistoriesTypes.FORCE_UPDATE, forceUpdateSaga),
];
