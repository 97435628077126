import React, { useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { Upload, Modal, Icon } from 'antd';
import FormItem from '../../form/FormItem';
import { getRecordData, getImageUrl } from '../../../utils/tools';
import { RestInputContext } from '../RestInputContext';
import { uploadMedia } from '../../../api/uploadMedia';

const RestUpload = (props) => (
  <RestInputContext.Consumer>
    {({ record, form, handleSubmit }) => (
      <UploadUI
        {...props}
        record={record}
        form={form}
        handleSubmit={handleSubmit}
      />
    )}
  </RestInputContext.Consumer>
);

export const UploadUI = (props) => {
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [fileList, setFileList] = useState([]);

  const handleCancel = () => {
    setPreviewVisible(false);
  };

  const handlePreview = (file) => {
    setPreviewVisible(true);
    setPreviewImage(file.url || file.thumbUrl);
  };

  const handleChange = ({ fileList }) => setFileList(fileList);
  const uploadButton = (
    <div>
      <Icon type="plus" />
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  useLayoutEffect(() => {
    setFileList(
      props.defaultValue || getRecordData(props.record, props.source)
        ? makeFileList(
            props.defaultValue || getRecordData(props.record, props.source),
          )
        : [],
    );
    setPreviewImage(
      makeFileList(
        props.defaultValue || getRecordData(props.record, props.source),
      ),
    );
    // eslint-disable-next-line
  }, [props.record.id, props.record[props.source]]);

  const customRequest = async ({ onSuccess, file }) => {
    console.log('asasdasdasd')
    if (props.useFormData) {
      onSuccess('done', file);
    } else {
      const response = await uploadMedia(file);
      if (response) {
        onSuccess(response, file);
      }
    }
  };

  return (
    <div style={{ textAlign: 'left' }}>
      {props.form && (
        <div style={{ display: 'none' }}>
        <FormItem
          {...props}
          ruleType="array"
          noStyle
          defaultValue={
            props.defaultValue || getRecordData(props.record, props.source)
          }
        >
          <input style={{ display: 'none' }} />
        </FormItem>
        </div>
      )}
      <Upload
        customRequest={customRequest}
        // action={`${process.env.REACT_APP_UPLOAD_PHOTO_URL}`}
        // headers={{
        //   'x-requested-with': undefined,
        //   Authorization: `Client-ID ${process.env.REACT_APP_UPLOAD_PHOTO_KEY}`,
        // }}
        multiple={props.multiple}
        beforeUpload={() => true}
        disabled={props.disabled}
        listType="picture-card"
        fileList={fileList}
        onPreview={handlePreview}
        accept="*"
        onChange={({ fileList }) => {
          fileList = props.useFormData
            ? fileList.map((e) => ({ ...e, status: 'done' }))
            : fileList;
          handleChange({ fileList });
          const formattedData = fileList.map(
            (e) =>
              (e && e.response && e.response) || {
                previewImage: e.previewImage,
                image: e.image,
              },
          );
          setPreviewImage(formattedData);
          props.onChange &&
            props.onChange(props.useFormData ? fileList : formattedData);
          props.form &&
            props.form.setFieldsValue({
              [props.source]: props.useFormData
                ? fileList
                : fileList.map(
                    (e) =>
                      (e && e.response && e.response) || {
                        previewImage: e.previewImage,
                        image: e.image,
                      },
                  ),
            });
        }}
        style={{ width: '100%' }}
      >
        {(props.multiple || !fileList?.length) && uploadButton}
      </Upload>
      <Modal visible={previewVisible} footer={null} onCancel={handleCancel}>
        <img alt="example" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </div>
  );
};

const makeFileList = (values) =>
  Array.isArray(values)
    ? values.map((value) =>
        value && value.url
          ? value
          : {
              name: value.previewImage || value.thumbUrl || value,
              uid: value.previewImage || value.thumbUrl || value,
              status: 'done',
              ...value,
              url: value.thumbUrl
                ? value.thumbUrl
                : getImageUrl(value.previewImage ? value.previewImage : value),
            },
      )
    : [];

RestUpload.propTypes = {
  source: PropTypes.string,
  record: PropTypes.object,
  defaultValue: PropTypes.string,
};

RestUpload.defaultProps = {};

export default RestUpload;
