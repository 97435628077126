import React from 'react';
// import PropTypes from 'prop-types';
import i18next from 'i18next';
// import RestInputItem from '../../../../components/RestInput/RestInputItem';
import Text from '../../../../components/common/Text';

const Permission = () => (
  <div>
    <Text type="h1">{i18next.t('userTypes.permission')}</Text>
    <Text type="h4">{i18next.t('userTypes.customerInfo')}</Text>
  </div>
);
Permission.propTypes = {};

export default Permission;
