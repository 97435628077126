import React from 'react';
import { Tabs } from 'antd';
import i18next from 'i18next';
import Edit from '../../../containers/rest/Edit';
import ClientDetail from '../components/ClientDetail';
import { EditClientsWrapper } from './styles';
import ClientAvatar from '../components/ClientAvatar';
import ClientActivity from '../components/ClientActivity';
import Summaries from '../components/Summaries';
import { getIdByUrl } from '../../../utils/tools';

const CustomerEditModal = props => {
  const id = getIdByUrl(props);
  return (
    <EditClientsWrapper>
      <Edit
        {...props}
        noCardWrapper
        formatOnSubmit={formatOnSubmit}
        customEditButton={null}
        initOptions={{ isBack: false }}
        resource="customers"
      >
        <Summaries />
        <Tabs>
          <Tabs.TabPane tab={i18next.t('customers.info')} key="info">
            <ClientAvatar />
            <ClientDetail />
          </Tabs.TabPane>
          <Tabs.TabPane tab={i18next.t('customers.info')} key="order">
            <ClientAvatar />
            <ClientDetail />
          </Tabs.TabPane>
        </Tabs>
        <ClientActivity {...props} id={id} />
      </Edit>
    </EditClientsWrapper>
  );
};

const formatOnSubmit = data => {
  const {
    address,
    avatar,
    channelId,
    note,
    facebookLink,
    totalSpent,
    timeWorking,
    fullName,
    isActive,
    email,
    serviceTypeId,
    feedback,
    customerSegmentation,
    contactOwner,
    phoneNumber,
    displayName,
    dealPipelineId,
  } = data;
  return {
    address,
    avatar,
    channelId,
    note,
    facebookLink,
    totalSpent,
    timeWorking,
    fullName,
    isActive,
    email,
    serviceTypeId,
    feedback,
    customerSegmentation,
    contactOwner,
    phoneNumber,
    displayName,
    dealPipelineId,
  };
};

CustomerEditModal.propTypes = {};

export default CustomerEditModal;
