import styled from 'styled-components';

export const EmployeeWrapper = styled.div`
  .itemRow {
    margin-top: 10px;
    color: #000;
  }
  .employeeRow {
    display: flex;
    flex-wrap: wrap;
  }
  .employeeInfo {
    height: 100%;
  }
  .employeeView {
    margin-top: 20px;
  }
`;
