import React from 'react';
import Create from '../../../containers/rest/Create';
import Form from '../components/Form';

const OrderStatusesCreate = props => (
  <Create {...props} resource="orderStatuses">
    <Form />
  </Create>
);

OrderStatusesCreate.propTypes = {};

export default OrderStatusesCreate;
