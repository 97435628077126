import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { replace } from 'connected-react-router';
import { Modal } from 'antd';
import OrderRequirements from 'pages/OrderRequirements';
import WorkingTimes from 'pages/WorkingRegisters';
import Permissions from 'pages/Permissions';
import UserRequests from 'pages/UserRequests';
import Drawer from '../../components/common/Modal';
import Guarantees from '../../pages/Guarantees';
import OrderRequesteds from '../../pages/OrderRequesteds';
import Roles from '../../pages/Roles';
import Leaders from '../../pages/Leaders';
import Delivers from '../../pages/Delivers';
import OrderHistories from '../../pages/OrderHistories';
import Designers from '../../pages/Designers';
import Dealers from '../../pages/Dealers';
import salers from '../../pages/Salers';
import Niches from '../../pages/Niches';
import Brands from '../../pages/Brands';
import Products from '../../pages/Products';
import Packages from '../../pages/Packages';
import ServiceTypes from '../../pages/ServiceTypes';
import OrderStatuses from '../../pages/OrderStatuses';
import CustomerSegmentations from '../../pages/CustomerSegmentations';
import productTypes from '../../pages/productTypes';
import Platforms from '../../pages/Platforms';
import Departments from '../../pages/Departments';
import Orders from '../../pages/Orders';
import Channels from '../../pages/Channels';
import UserTypes from '../../pages/UserTypes';
import Customers from '../../pages/Customers';
import Users from '../../pages/Users';
import { closeModal as closeModalAction } from '../../redux/modal/actions';
import Comments from '../../pages/Comments';
import Dealpipelines from '../../pages/Dealpipelines';
import SalaryInfos from '../../pages/SalaryInfos';
import SalaryHistories from '../../pages/SalaryHistories';
import MistakeRecords from '../../pages/MistakeRecords';
import MistakeTypes from '../../pages/MistakeTypes';
import CustomerEditModal from '../../pages/Customers/EditModal';
import Payments from '../../pages/Payments';

const modalRoutes = [
  {
    path: '/payments',
    routes: [
      {
        path: '/create',
        component: Payments.Create,
      },
      {
        path: '/edit',
        component: Payments.Edit,
        modalOptions: {
          width: 800,
        },
      },
    ],
  },
  {
    path: '/userRequests',
    routes: [
      {
        path: '/create',
        component: UserRequests.Create,
      },
      {
        path: '/edit',
        component: UserRequests.Edit,
      },
    ],
  },
  {
    path: '/mistakeTypes',
    routes: [
      {
        path: '/create',
        component: MistakeTypes.Create,
      },
      {
        path: '/edit',
        component: MistakeTypes.Edit,
      },
    ],
  },

  {
    path: '/mistakeRecords',
    routes: [
      {
        path: '/create',
        component: MistakeRecords.Create,
      },
      {
        path: '/edit',
        component: MistakeRecords.Edit,
      },
    ],
  },
  {
    path: '/permissions',
    routes: [
      {
        path: '/create',
        component: Permissions.Create,
      },
      {
        path: '/edit',
        component: Permissions.Edit,
      },
    ],
  },
  {
    path: '/salaryHistories',
    routes: [
      {
        path: '/create',
        component: SalaryHistories.Create,
      },
      {
        path: '/edit',
        component: SalaryHistories.Edit,
      },
    ],
  },
  {
    path: '/salaryHistories',
    routes: [
      {
        path: '/create',
        component: SalaryHistories.Create,
      },
      {
        path: '/edit',
        component: SalaryHistories.Edit,
      },
    ],
  },

  {
    path: '/salaryInfos',
    routes: [
      {
        path: '/create',
        component: SalaryInfos.Create,
      },
      {
        path: '/edit',
        component: SalaryInfos.Edit,
      },
    ],
  },
  {
    path: '/workingRegisters',
    routes: [
      {
        path: '/create',
        component: WorkingTimes.Create,
      },
      {
        path: '/edit',
        component: WorkingTimes.Edit,
      },
    ],
  },

  {
    path: '/orderRequirements',
    routes: [
      {
        path: '/create',
        component: OrderRequirements.Create,
      },
      {
        path: '/edit',
        component: OrderRequirements.Edit,
      },
    ],
  },
  {
    path: '/dealpipelines',
    routes: [
      {
        path: '/create',
        component: Dealpipelines.Create,
      },
      {
        path: '/edit',
        component: Dealpipelines.Edit,
      },
    ],
  },
  {
    path: '/comments',
    routes: [
      {
        path: '/create',
        component: Comments.Create,
      },
      {
        path: '/edit',
        component: Comments.Edit,
      },
    ],
  },
  {
    path: '/guarantees',
    routes: [
      {
        path: '/create',
        component: Guarantees.Create,
      },
      {
        path: '/edit',
        component: Guarantees.Edit,
      },
    ],
  },
  {
    path: '/orderRequesteds',
    routes: [
      {
        path: '/create',
        component: OrderRequesteds.Create,
      },
      {
        path: '/edit',
        component: OrderRequesteds.Edit,
      },
    ],
  },
  {
    path: '/roles',
    routes: [
      {
        path: '/create',
        component: Roles.Create,
      },
      {
        path: '/edit',
        component: Roles.Edit,
      },
    ],
  },

  {
    path: '/leaders',
    routes: [
      {
        path: '/create',
        component: Leaders.Create,
      },
      {
        path: '/edit',
        component: Leaders.Edit,
      },
    ],
  },

  {
    path: '/delivers',
    routes: [
      {
        path: '/create',
        component: Delivers.Create,
      },
      {
        path: '/edit',
        component: Delivers.Edit,
      },
    ],
  },

  {
    path: '/orderHistories',
    routes: [
      {
        path: '/create',
        component: OrderHistories.Create,
      },
      {
        path: '/edit',
        component: OrderHistories.Edit,
      },
    ],
  },

  {
    path: '/designers',
    routes: [
      {
        path: '/create',
        component: Designers.Create,
      },
      {
        path: '/edit',
        component: Designers.Edit,
      },
    ],
  },

  {
    path: '/dealers',
    routes: [
      {
        path: '/create',
        component: Dealers.Create,
      },
      {
        path: '/edit',
        component: Dealers.Edit,
      },
    ],
  },

  {
    path: '/salers',
    routes: [
      {
        path: '/create',
        component: salers.Create,
      },
      {
        path: '/edit',
        component: salers.Edit,
      },
    ],
  },

  {
    path: '/niches',
    routes: [
      {
        path: '/create',
        component: Niches.Create,
      },
      {
        path: '/edit',
        component: Niches.Edit,
        modalOptions: { width: 600 },
      },
    ],
  },

  {
    path: '/brands',
    routes: [
      {
        path: '/create',
        component: Brands.Create,
      },
      {
        path: '/edit',
        component: Brands.Edit,
      },
    ],
  },

  {
    path: '/products',
    routes: [
      {
        path: '/create',
        component: Products.Create,
      },
      {
        path: '/edit',
        component: Products.Edit,
      },
    ],
  },

  {
    path: '/packages',
    routes: [
      {
        path: '/create',
        component: Packages.Create,
      },
      {
        path: '/edit',
        component: Packages.Edit,
      },
    ],
  },

  {
    path: '/serviceTypes',
    routes: [
      {
        path: '/create',
        component: ServiceTypes.Create,
      },
      {
        path: '/edit',
        component: ServiceTypes.Edit,
      },
    ],
  },

  {
    path: '/orderStatuses',
    routes: [
      {
        path: '/create',
        component: OrderStatuses.Create,
      },
      {
        path: '/edit',
        component: OrderStatuses.Edit,
      },
    ],
  },
  {
    path: '/customerSegmentations',
    routes: [
      {
        path: '/create',
        component: CustomerSegmentations.Create,
      },
      {
        path: '/edit',
        component: CustomerSegmentations.Edit,
      },
    ],
  },

  {
    path: '/productTypes',
    routes: [
      {
        path: '/create',
        component: productTypes.Create,
      },
      {
        path: '/edit',
        component: productTypes.Edit,
      },
    ],
  },

  {
    path: '/platforms',
    routes: [
      {
        path: '/create',
        component: Platforms.Create,
      },
      {
        path: '/edit',
        component: Platforms.Edit,
      },
    ],
  },

  {
    path: '/departments',
    routes: [
      {
        path: '/create',
        component: Departments.Create,
      },
      {
        path: '/edit',
        component: Departments.Edit,
      },
    ],
  },

  {
    path: '/orders',
    routes: [
      {
        path: '/create',
        component: Orders.Create,
      },
      {
        path: '/edit',
        component: Orders.Edit,
      },
    ],
  },

  {
    path: '/channels',
    routes: [
      {
        path: '/create',
        component: Channels.Create,
      },
      {
        path: '/edit',
        component: Channels.Edit,
      },
    ],
  },

  {
    path: '/userTypes',
    routes: [
      {
        path: '/create',
        component: UserTypes.Create,
      },
      {
        path: '/edit',
        component: UserTypes.Edit,
      },
    ],
  },
  {
    path: '/customers',
    routes: [
      {
        path: '/create',
        component: Customers.Create,
      },
      {
        path: '/edit',
        component: CustomerEditModal,
      },
    ],
  },
  {
    path: '/users',
    routes: [
      {
        path: '/create',
        component: Users.Create,
      },
      {
        path: '/edit',
        component: Users.Edit,
      },
    ],
  },
];

const getModalRoute = (currentModal) => {
  const modalRoute =
    currentModal &&
    modalRoutes.find((route) => currentModal.search(route.path) > -1);
  if (modalRoute) {
    return modalRoute.routes.find(
      (route) => currentModal.indexOf(route.path) > -1,
    );
  }
  return modalRoute;
};

class ModalRoute extends Component {
  componentDidMount() {
    const { location } = this.props;
    if (location.hash && location.hash !== '#') {
      const modelRoute = location.hash.replace('#', '/');
      this.modal = getModalRoute(modelRoute);
    }
  }

  closeModal = () => {
    const { replaceRoute, location } = this.props;
    replaceRoute(`${location.pathname}${location.search}`);
  };

  render() {
    const { location, previewVisible, handleCancel, previewImage } = this.props;
    const modelRoute = location.hash.replace('#', '/');
    this.modal = getModalRoute(modelRoute) || this.modal;
    const modalOptions =
      this.modal && this.modal.modalOptions ? this.modal.modalOptions : {};
    return (
      <Fragment>
        <Drawer
          {...modalOptions}
          visible={!!(location.hash && location.hash !== '#')}
          footer={null}
          onCancel={this.closeModal}
          onClose={this.closeModal}
        >
          {this.modal && this.modal.component && (
            <this.modal.component
              showModal
              visibleModal={!!(location.hash && location.hash !== '#')}
              location={location}
            />
          )}
        </Drawer>
        <Modal visible={previewVisible} footer={null} onCancel={handleCancel}>
          <img alt="example" style={{ width: '100%' }} src={previewImage} />
        </Modal>
      </Fragment>
    );
  }
}

ModalRoute.propTypes = {
  location: PropTypes.object,
  currentModal: PropTypes.string,
  closeModal: PropTypes.func,
  showModal: PropTypes.func,
  replaceRoute: PropTypes.func,
};

const mapStateToProps = (state) => ({
  location: state.router.location,
  previewVisible: state.modal.previewVisible,
  previewImage: state.modal.previewImage,
});

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(closeModalAction()),
  handleCancel: () => dispatch(closeModalAction()),
  replaceRoute: (data) => dispatch(replace(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalRoute);
