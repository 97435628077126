import React from 'react';
import _ from 'lodash';
import { Row, Col } from 'antd';
import Edit from '../../../containers/rest/Edit';
import ClientDetail from '../components/ClientDetail';
import { EditClientsWrapper } from './styles';
import ClientAvatar from '../components/ClientAvatar';
import ClientActivity from '../components/ClientActivity';
import ClientBreadcrumb from '../components/ClientBreadcrumb';
import Summaries from '../components/Summaries';

const ClientsEdit = props => (
  <EditClientsWrapper>
    <Edit
      {...props}
      noCardWrapper
      formatOnSubmit={formatOnSubmit}
      customEditButton={null}
      initOptions={{ isBack: false }}
      resource="customers"
    >
      <ClientBreadcrumb />
      <Summaries />
      <Row gutter={16}>
        <Col md={8}>
          <ClientAvatar />
          <ClientDetail />
        </Col>
        <Col md={16}>
          <ClientActivity {...props} />
        </Col>
      </Row>
    </Edit>
  </EditClientsWrapper>
);

const formatOnSubmit = data => {
  return _.omit(data, ['updatedAt','createdAt','isDelete','happy' ]);
};

ClientsEdit.propTypes = {};

export default ClientsEdit;
