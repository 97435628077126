import React from 'react';
import RestShow from '../../../containers/rest/Show';
import RestFieldItem from '../../../components/RestField/RestFieldItem';

const DepartmentsShow = props => (
  <RestShow {...props} hasEdit resource="departments">
    <RestFieldItem source="name" header="departments.name" />
    <RestFieldItem source="description" header="departments.description" />
    <RestFieldItem source="isDelete" header="departments.isDelete" />
  </RestShow>
);

export default DepartmentsShow;
