import styled from 'styled-components';

export const CommentItemWrapper = styled.div`
  position: relative;
  .ant-rate {
    padding: 10px;
  }
  .ant-comment-content-detail {
    background-color: white;
    border-radius: 10px;
    > div {
      background-color: white;
    }
  }
  &.firstItem {
    .ant-comment-content-detail {
      border: 2px solid white;
      -webkit-animation: flash 2s 5; /* Safari 4.0 - 8.0 */
      animation: flash 2s 5;
    }

    @-webkit-keyframes flash {
      0% {
        border-color: white;
        border-width: 2px;
      }
      50% {
        border-color: red;
        border-width: 2px;
      }
      100% {
        border-color: white;
        border-width: 2px;
      }
    }

    @keyframes flash {
      0% {
        border-color: white;
        border-width: 2px;
      }
      50% {
        border-color: red;
        border-width: 2px;
      }
      100% {
        border-color: white;
        border-width: 2px;
      }
    }
  }
`;
