import styled from 'styled-components';

export const BarChartWrapper = styled.div`
  display: flex;
  overflow-x: scroll;
  flex-direction: column;
  tspan {
    font-size: 10px;
    font-weight: 500;
  }

  .custom-tooltip {
    background-color: #ffffff;
    box-shadow: 0 1px 10px rgba(10, 10, 10, 0.15);
    padding: 10px;
    border-radius: 5px;
    .revenue {
      color: #8884d8;
    }
    .numOrder {
      color: #82ca9d;
    }
  }
`;
