import React from 'react';
import RestShow from '../../../containers/rest/Show';
import RestFieldItem from '../../../components/RestField/RestFieldItem';

const SalerBSShow = props => (
  <RestShow {...props} hasEdit resource="salers">
    <RestFieldItem source="name" header="salers.name" />
  </RestShow>
);

export default SalerBSShow;
