import React, { useState } from 'react';
// import PropTypes from 'prop-types';
import { Modal, Button } from 'antd';
import { useDispatch } from 'react-redux';
import i18next from 'i18next';
import { ORDER_STATUS } from 'configs/localData';
import OrderHistories from 'redux/orderHistories/actions';
import Orders from 'redux/orders/actions';
import { getSuggestDesigner } from 'api/order';

const { confirm, info } = Modal;

const GetSuggestionDesigner = ({ order }) => {
  const [isLoading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const getSuggestion = () => {
    setLoading(true);
    getSuggestDesigner(order)
      .then((res) => {
        setLoading(false);
        res?.designId
          ? confirm({
              title: i18next.t('orders.suggestionDesigner', {
                displayName: res?.displayName,
              }),
              content: i18next.t('orders.assignForSuggestionDesigner'),
              onOk() {
                dispatch(
                  OrderHistories.createOrderHistories({
                    assignedUser: [res?.designId],
                    status: ORDER_STATUS[1].value,
                    orderId: order.id,
                  })
                ).then((e) => {
                  dispatch(Orders.getByIdOrders(order));
                  dispatch(
                    OrderHistories.getAllOrderHistories(
                      {
                        includes: 'assignedUsers,assigner',
                        limit: 10,
                        filter: { orderId: order.id },
                      },
                      { isRefresh: true }
                    )
                  );
                });
              },
              onCancel() {},
            })
          : info({
              title: i18next.t('orders.suggestionFail'),
              onOk: () => {},
            });
      })
      .catch((err) => {
        setLoading(false);
        info({
          title: i18next.t('orders.suggestionFail'),
          onOk: () => {},
        });
      });
  };
  return (
    <Button
      style={{ marginTop: 20 }}
      loading={isLoading}
      onClick={getSuggestion}
    >
      {i18next.t('button.getSuggestion')}
    </Button>
  );
};
GetSuggestionDesigner.propTypes = {};

export default GetSuggestionDesigner;
