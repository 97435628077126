import React from 'react';
import RestInputItem from '../../../../components/RestInput/RestInputItem';
import RestTextEditor from '../../../../components/RestInput/RestTextEditor';
import RestColorPicker from '../../../../components/RestInput/RestColorPicker';

const DealpipelinesForm = props => (
  <div {...props}>
    <RestInputItem source="name" header="dealpipelines.name" />
    <RestTextEditor
      isCustomUpdate={false}
      source="description"
      header="dealpipelines.description"
    />
    <RestColorPicker source="color" />
  </div>
);

DealpipelinesForm.propTypes = {};

export default DealpipelinesForm;
