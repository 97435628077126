import React from 'react';
import RestShow from '../../../containers/rest/Show';
import RestFieldItem from '../../../components/RestField/RestFieldItem';

const BrandsShow = props => (
  <RestShow {...props} hasEdit resource="brands">
    <RestFieldItem source="name" header="brands.name" />
  </RestShow>
);

export default BrandsShow;
