import React from 'react';
import moment from 'moment';
import Create from '../../rest/Create';
import Form from '../components/Form';

const SalaryInfosCreate = (props) => (
  <Create
    {...props}
    formatOnSubmit={(values) => ({
      ...values,
      dateMonth: moment(values.dateMonth).startOf('month').format('YYYY-MM-DD'),
      startDate: moment(values.startDate).format('YYYY-MM-DD'),
      endDate: moment(values.endDate).format('YYYY-MM-DD'),
    })}
    resource="salaryInfos"
  >
    <Form />
  </Create>
);

SalaryInfosCreate.propTypes = {};

export default SalaryInfosCreate;
