import React from 'react';
import Create from '../../../containers/rest/Create';
import Form from '../components/Form';

const CustomerSegmentationsCreate = props => (
  <Create {...props} resource="customerSegmentations">
    <Form />
  </Create>
);

CustomerSegmentationsCreate.propTypes = {};

export default CustomerSegmentationsCreate;
