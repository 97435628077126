import React from 'react';
import List from '../List';

const ListDeleted = props => (
  <List
    {...props}
    defaultOptions={{ customApi: 'orders/getDelete', isRefresh: true }}
    isDeleted
    header="orders.listDeleted"
    isSortDelete={false}
  />
);

ListDeleted.propTypes = {};

export default ListDeleted;
