import { post, get } from './utils';

export async function getTrelloData(data) {
  return post(`/orders/trelloContent`, data);
}

export async function getCommentByOrder(orderId) {
  return get(`/customerComments/order/${orderId}`);
}

export async function getSuggestDesigner(data) {
  return post(`/orders/recommendDesigner`, data);
}

export async function forceUpdate(data) {
  return post(`/orderHistories/force`, data);
}

export async function getGoogleRenderLink(data) {
  return post(`/file/render`, data);
}

export async function getCreateLink(data) {
  return post(`/file/createLink`, data);
}
