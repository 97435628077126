import { createGlobalStyle } from 'styled-components';
import { CLASS_UTILITY } from 'utils/css';

const AppWrapper = createGlobalStyle`
${CLASS_UTILITY}
.bg-gray {
    background: #d0d0d0;
    padding: 20px;
    border-radius: 20px;

}
.slick-dots {
  li {
    background: #d0d0d0;
  }
  .slick-active {
    background: blue;
  }
}
.ant-carousel .slick-dots li.slick-active button {
  background: #4cd00c !important;
}
.tox-tinymce {
  min-height: 500px;
}
  .ql-editor {
    min-height: 150px;
    img {
      height: 100px;
      width: 100px;
      border: 0.5px solid #f9f9f9;
    }
  }
  .anticon:before {
    display: block;
    font-family: 'anticon', 'TCRM' !important;
  }
  .anticon:after {
    display: block;
    font-family: 'anticon', 'TCRM' !important;
  }
  .gradientBackground {
    background-image: ${({ theme }) =>
      `linear-gradient(90deg, ${theme.palette.lightPrimary}, ${theme.palette.primary})`};
  }
  .ant-btn-primary {
    background-image: ${({ theme }) => theme.background.header};
    border: none;
  }
  .ant-select-selection {
    background: #fff;
  }
  .ant-tabs {
    color: ${({ theme }) => theme.text.primary};
  }
  .ant-card {
    color: ${({ theme }) => theme.text.lightPrimary};
  }
  .ant-input-number-handler-wrap {
    display: none;
    pointer-event: none;
  }

  .copy {
    cursor: pointer;
  }
  .text-right {
    text-align: right;
  }
  .text-center {
    text-align: center;
  }
`;

export default AppWrapper;
