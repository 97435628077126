import React from 'react';
import RestInputItem from '../../../../components/RestInput/RestInputItem';

const SalariesForm = props => (
  <div {...props}>
    <RestInputItem source="name" header="salaries.name" />
  </div>
);

SalariesForm.propTypes = {};

export default SalariesForm;
