import styled from 'styled-components';

export const UserTagWrapper = styled.div`
  padding: 5px;
  border-radius: 20px;
  height: 40px;
  display: flex;
  align-items: center;
  background: #d1d1d180;
  justify-content: flex-start;
  cursor: pointer;
  margin: 10px 10px 10px 0px;
  .ant-avatar {
    margin-right: 10px;
  }
  .icon {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: ${({ theme }) => theme.palette.lightPrimary};
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
