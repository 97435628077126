import React from 'react';
import {
  BarChart as ReBarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';
import { formatShortNumber } from '../../utils/textUtils';
import { BarChartWrapper } from './styles';

const CustomTooltip = ({ active, payload, label }) => {
  if (active) {
    return (
      <div className="custom-tooltip">
        <p>
          <b className="label">{label}</b>
        </p>
        <div className="revenue">
          {`${formatShortNumber(payload && payload[0].value)}`}
        </div>
      </div>
    );
  }

  return null;
};

const BarChart = ({ resource = [] }) => (
  <BarChartWrapper>
    <div>
      <ReBarChart
        width={500}
        height={300}
        data={resource}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis tickFormatter={e => formatShortNumber(e)} />
        <Tooltip content={<CustomTooltip />} />
        <Legend />
        <Bar dataKey="Revenue" fill="#8884d8" />
      </ReBarChart>
    </div>
    <div>
      <ReBarChart
        width={500}
        height={300}
        data={resource}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis tickFormatter={e => formatShortNumber(e)} />
        <Tooltip content={<CustomTooltip />} />
        <Legend />
        <Bar dataKey="Total Order" fill="#82ca9d" />
      </ReBarChart>
    </div>
  </BarChartWrapper>
);
export default BarChart;
