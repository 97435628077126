import React from 'react';
import { PreviewTextEditor } from 'components/RestInput/RestTextEditor';
import List from '../../../containers/rest/List';
import RestFieldItem from '../../../components/RestField/RestFieldItem';
import ActionGroup from '../../../components/RestActions/ActionGroup';
import EditButton from '../../../components/RestActions/EditButton';
import DeleteButton from '../../../components/RestActions/DeleteButton';
import PreviewImage from '../../../components/common/PreviewImage';

const PlatformsList = (props) => (
  <List {...props} isScroll={false} resource="platforms">
    <RestFieldItem source="name" header="platforms.name" />
    <RestFieldItem
      format={(data) => <PreviewTextEditor html={data} />}
      source="description"
      header="platforms.description"
    />
    <RestFieldItem
      source="productDemoFile"
      format={(data) => <PreviewImage image={data} />}
      header="platforms.productDemoFile"
    />
    <ActionGroup>
      <EditButton />
      <DeleteButton isSortDelete />
    </ActionGroup>
  </List>
);

PlatformsList.propTypes = {};

export default PlatformsList;
