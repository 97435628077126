import React, { useState, useRef } from 'react';
import { Carousel, Modal, Icon } from 'antd';
import PropTypes from 'prop-types';
import { getImageUrl } from 'utils/tools';
import GridPhotosWrapper from './styles';

const GridPhotos = ({ images }) => {
  const carouselRef = useRef(null);
  // const subImages = images ? [...images].splice(0, 0) : [];
  const [previewVisible, setPreviewVisible] = useState(null);

  const handleCancel = () => {
    setPreviewVisible(null);
  };

  const onPreviewUI = index => {
    setPreviewVisible(true);
    setTimeout(() => {
      carouselRef.current && carouselRef.current.goTo(index);
    }, 10);
  };
  return (
    <GridPhotosWrapper>
      {/* {subImages.map((data, index) => (
        <PreviewImage
          onPreview={() => onPreviewUI(index)}
          key={String(index)}
          src={
            typeof data === 'string'
              ? getImageUrl(data)
              : getImageUrl(data && data.previewImage)
          }
        />
      ))} */}
      {images && images.length > 0 ? (
        <div className="imageCount">
          <img
            alt=""
            src={
              typeof images[0] === 'string'
                ? getImageUrl(images[0])
                : getImageUrl(images[0] && images[0].previewImage)
            }
            className="image"
          />
          {images.length - 1 ? (
            <div className="overlay">
              <span>+{images.length - 1}</span>
            </div>
          ) : null}
          <div className="overlay2">
            <Icon type="eye" onClick={() => onPreviewUI(2)} />
          </div>
        </div>
      ) : null}

      <Modal visible={previewVisible} footer={null} onCancel={handleCancel}>
        <Carousel draggable ref={carouselRef}>
          {images &&
            images.map((data, index) => (
              <div key={String(index)}>
                <img
                  alt="example"
                  style={{ width: '100%', height: '100%' }}
                  // src={data}
                  src={
                    typeof data === 'string'
                      ? getImageUrl(data)
                      : getImageUrl(data && data.previewImage)
                  }
                />
              </div>
            ))}
        </Carousel>
      </Modal>
    </GridPhotosWrapper>
  );
};

GridPhotos.propTypes = {
  images: PropTypes.array,
};
GridPhotos.defaultProps = {
  images: [],
};

export default GridPhotos;
