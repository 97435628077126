import React from 'react';
import RestInputItem from '../../../../components/RestInput/RestInputItem';

const OrderHistoriesForm = props => (
  <div {...props}>
    <RestInputItem source="assigner" header="orderHistories.assigner" />
    <RestInputItem source="assignedUser" header="orderHistories.assignedUser" />
    <RestInputItem source="status" header="orderHistories.status" />
    <RestInputItem source="orderId" header="orderHistories.orderId" />
  </div>
);

OrderHistoriesForm.propTypes = {};

export default OrderHistoriesForm;
