import React from 'react';
import Edit from '../../rest/Edit';
import Form from '../components/Form';

const MistakeTypesEdit = props => (
  <Edit {...props} resource="mistakeTypes">
    <Form />
  </Edit>
);

MistakeTypesEdit.propTypes = {};

export default MistakeTypesEdit;
