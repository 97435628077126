import React from 'react';
// import PropTypes from 'prop-types';
import { Card } from 'antd';
import i18next from 'i18next';
import { useSelector } from 'react-redux';
import SaveButton from '../../../../components/RestActions/SaveButton';
import UsersForm from '../Form';
import UserOutsourceInfo from '../UserOutsourceInfo';

const ClientDetail = () => {
  const user = useSelector((state) => state.auth.data);

  return (
    <Card
      className="box"
      title={
        <span className="txtHeader">{i18next.t('customers.detail.about')}</span>
      }
      extra={
        user && user.isOutsource ? null : (
          <SaveButton className="icEdit">{i18next.t('button.save')}</SaveButton>
        )
      }
    >
      {user.isOutsource ? (
        <UserOutsourceInfo user={user} />
      ) : (
        <UsersForm isEdit />
      )}
    </Card>
  );
};

ClientDetail.propTypes = {};

export default ClientDetail;
