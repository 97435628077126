import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Icon } from 'antd';
import { PreviewWrapper } from './styles';
import { showPreviewImage } from '../../../redux/modal/actions';
import { getImageUrl } from '../../../utils/tools';

const PreviewImage = ({ image, src, onPreview }) => {
  const dispatch = useDispatch();

  const showPreview = item => {
    onPreview && onPreview(item);
    dispatch(
      showPreviewImage({
        previewImage: getImageUrl(item),
      }),
    );
  };
  return (
    <PreviewWrapper>
      <img
        className="image"
        src={src ? getImageUrl(src) : getImageUrl(image && image.previewImage)}
        alt={image && image.preview}
      />
      <div className="overlay">
        <Icon type="eye" onClick={() => showPreview(src || (image && image.image))} />
      </div>
    </PreviewWrapper>
  );
};
PreviewImage.propTypes = {
  image: PropTypes.object,
  onPreview: PropTypes.func,
};

export default PreviewImage;
