import React from 'react';
import RestShow from '../../../containers/rest/Show';
import RestFieldItem from '../../../components/RestField/RestFieldItem';

const PlatformsShow = props => (
  <RestShow {...props} hasEdit resource="platforms">
    <RestFieldItem source="name" header="platforms.name" />
    <RestFieldItem source="description" header="platforms.description" />
    <RestFieldItem source="isDelete" header="platforms.isDelete" />
  </RestShow>
);

export default PlatformsShow;
