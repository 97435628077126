import React from 'react';
import moment from 'moment';
import Create from '../../../containers/rest/Create';
import Form from '../components/Form';
import { ORDER_STATUS } from '../../../configs/localData';

const OrdersCreate = (props) => (
  <Create {...props} formatOnSubmit={formatOnSubmit} resource="orders">
    <Form />
  </Create>
);

const formatOnSubmit = (data) => ({
  ...data,
  thumbnail: data.thumbnail.previewImage,
  deadline: data.finishedDate && moment(data.finishedDate).toISOString(),
  status: ORDER_STATUS[0].value,
});
OrdersCreate.propTypes = {};

export default OrdersCreate;
