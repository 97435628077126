import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Editor } from '@tinymce/tinymce-react';
import { Button } from 'antd';
import i18next from 'i18next';
import FormItem from '../../form/FormItem';
import { getRecordData, imageUploadHandler } from '../../../utils/tools';
import { RestInputContext } from '../RestInputContext';
import RestTextEditorWrapper, { PreviewTextEditorWrapper } from './styles';

const RestTextEditor = React.forwardRef((props, ref) => {
  const [trick] = useState({});
  const [isFocus, setFocus] = useState(false);
  const editorRef = useRef(null);

  const onSetValueToForm = (e) => {
    trick.setFieldsValue && trick.setFieldsValue({ [props.source]: e });
    // props.isAutoUpdate && trick.handleSubmit(e);
  };

  const onUpdateText = (e) => {
    onSetValueToForm(e);
  };

  if (ref) {
    ref.current = {
      onChange: onUpdateText,
    };
  }

  return (
    <RestTextEditorWrapper
      onMouseEnter={() => {
        trick.isEnter = true;
      }}
      onMouseLeave={() => {
        trick.isEnter = false;
      }}
      onClick={(e) => {
        e.stopPropagation();
        setFocus(true);
      }}
      style={{ position: 'relative' }}
    >
      <RestInputContext.Consumer>
        {({ record, form, handleSubmit }) => {
          trick.setFieldsValue = form.setFieldsValue;
          trick.getFieldsValue = form.getFieldsValue;
          trick.handleSubmit = handleSubmit;
          trick.record = record;
          const defaultValue =
            props.defaultValue || getRecordData(record, props.source);
          return (
            <div>
              <div
                style={{
                  display:
                    (isFocus && props.isCustomUpdate) || props.isCreate
                      ? 'block'
                      : 'none',
                }}
              >
                <FormItem
                  {...props}
                  record={record}
                  formOptions={{ trigger: 'onEditorChange' }}
                  form={form}
                  defaultValue={defaultValue}
                >
                  <Editor
                    {...props}
                    ref={editorRef}
                    // bounds=".app"
                    // modules={modules}
                    toolbar="undo redo | forecolor backcolor | styleselect | bold italic | alignleft aligncenter alignright alignjustify | outdent indent | link media image"
                    init={{
                      images_upload_handler: imageUploadHandler,
                    }}
                    plugins="code link checklist autolink image imagetools media"
                    handleSubmit={handleSubmit}
                    disabled={!(isFocus && props.isCustomUpdate)}
                  />
                  {/* <input style={{ display: 'none' }} /> */}
                </FormItem>
              </div>
              {isFocus || !defaultValue || props.isCreate ? null : (
                <PreviewTextEditor html={defaultValue} />
              )}
            </div>
          );
        }}
      </RestInputContext.Consumer>
      {isFocus && props.isCustomUpdate && !props.isCreate ? (
        <Button
          onClick={(e) => {
            e.stopPropagation();
            setTimeout(() => {
              if (props.isAutoUpdate) {
                props.customHandleSubmit
                  ? props.customHandleSubmit(trick.getFieldsValue())
                  : trick.handleSubmit();
              }
            }, 20);
            setFocus(false);
          }}
          type="primary"
        >
          {i18next.t('button.save')}
        </Button>
      ) : null}
      &nbsp;&nbsp;
      {isFocus && props.isCustomUpdate && !props.isCreate ? (
        <Button
          onClick={(e) => {
            e.stopPropagation();
            // editorRef.current.blur();
            setFocus(false);
          }}
        >
          {i18next.t('button.cancel')}
        </Button>
      ) : null}
    </RestTextEditorWrapper>
  );
});

export const PreviewTextEditor = ({ html }) => (
  <PreviewTextEditorWrapper dangerouslySetInnerHTML={{ __html: html }} />
);

RestTextEditor.propTypes = {
  source: PropTypes.string,
  record: PropTypes.object,
  defaultValue: PropTypes.string,
  isCustomUpdate: PropTypes.bool,
};

RestTextEditor.defaultProps = {
  isCustomUpdate: true,
};
export default RestTextEditor;
