import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Button, Modal } from 'antd';
import i18next from 'i18next';
import TextEditor from 'components/common/TextEditor';
import Text from '../../../../components/common/Text';
import OrderStatusTitle from './OrderStatusTitle';
import { PreviewTextEditor } from '../../../../components/RestInput/RestTextEditor/index';

const Canceled = ({ item }) => (
  <Card title={<OrderStatusTitle item={item} />} className="timelineItem">
    <div>
      <br />
      <Text type="body">
        {i18next.t(
          `orderHistories.status.description.${item.status}.${item.accept}`
        )}
      </Text>
      <br />
      {item.note && (
        <Text type="body">
          <Text type="bodyTitle" color="red">
            NOTE:
          </Text>
          {item.note && <PreviewTextEditor html={item.note} />}
          {item.report && <PreviewTextEditor html={item.report} />}
        </Text>
      )}
    </div>
  </Card>
);

export const CancelAction = ({ item, onReopen }) => {
  const [visible, setVisible] = useState(false);
  const [report] = useState({ text: '' });
  const onOk = () => {
    onReopen(report.text);
    setVisible(false);
  };
  return (
    <div>
      {/* <Text>{i18next.t('')}</Text> */}
      <br />
      <Button onClick={() => setVisible(true)} type="primary">
        {i18next.t('orderHistories.button.reopen')}
      </Button>
      <Modal
        title={i18next.t('orderHistories.modal.reopen')}
        visible={visible}
        onCancel={() => setVisible(false)}
        onOk={onOk}
      >
        <TextEditor
          onEditorChange={(e) => {
            report.text = e;
          }}
          placeholder={i18next.t('orderHistories.modal.reopenReason')}
        />
      </Modal>
    </div>
  );
};

CancelAction.propTypes = {
  onReopen: PropTypes.func,
  item: PropTypes.object,
};

Canceled.propTypes = {
  item: PropTypes.object,
};

Canceled.defaultProps = {
  item: {},
};

export default Canceled;
