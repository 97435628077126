import React from 'react';
import { formatChannelData } from 'utils/tools';
import Edit from '../../../containers/rest/Edit';
import Form from '../components/Form';

const ChannelsEdit = props => (
  <Edit {...props} formatOnSubmit={formatChannelData} resource="channels">
    <Form />
  </Edit>
);

ChannelsEdit.propTypes = {};

export default ChannelsEdit;
