import { all } from 'redux-saga/effects';
import authSaga from './auth/sagas';
import customersSagas from './customers/sagas';
import usersSagas from './users/sagas';
import referenceSagas from './referenceData/sagas';
import configSagas from './config/sagas';
import userTypesSagas from './userTypes/sagas';
import channelsSagas from './channels/sagas';
import ordersSagas from './orders/sagas';
import departmentsSagas from './departments/sagas';
import platformsSagas from './platforms/sagas';
import productCatalogsSagas from './productTypes/sagas';
import customerSegmentationsSagas from './customerSegmentations/sagas';
import orderStatusesSagas from './orderStatuses/sagas';
import serviceTypesSagas from './serviceTypes/sagas';
import packagesSagas from './packages/sagas';
import productsSagas from './products/sagas';
import brandsSagas from './brands/sagas';
import nichesSagas from './niches/sagas';
import salerBSSagas from './salers/sagas';
import dealersSagas from './dealers/sagas';
import designersSagas from './designers/sagas';
import orderHistoriesSagas from './orderHistories/sagas';
import deliversSagas from './delivers/sagas';
import leadersSagas from './leaders/sagas';
import rolesSagas from './roles/sagas';
import orderRequestedsSagas from './orderRequesteds/sagas';
import guaranteesSagas from './guarantees/sagas';
import summariesSagas from './summaries/sagas';
import commentsSagas from './comments/sagas';
import dealpipelinesSagas from './dealpipelines/sagas';
import modalSagas from './modal/sagas';
import orderRequirementsSagas from './orderRequirements/sagas';
import notificationsSagas from './notifications/sagas';
import workingTimesSagas from './workingRegisters/sagas';
import salariesSagas from './salaries/sagas';
import salaryInfosSagas from './salaryInfos/sagas';
import salaryHistoriesSagas from './salaryHistories/sagas';
import mistakeRecordsSagas from './mistakeRecords/sagas';
import mistakeTypesSagas from './mistakeTypes/sagas';
import logsSagas from './logs/sagas';
import userRequestsSagas from './userRequests/sagas';
import paymentsSagas from './payments/sagas';

export default function* root() {
  yield all([
    ...paymentsSagas,
    ...userRequestsSagas,
    ...logsSagas,
    ...mistakeTypesSagas,
    ...mistakeRecordsSagas,
    ...salaryHistoriesSagas,
    ...salaryInfosSagas,
    ...salariesSagas,
    ...workingTimesSagas,
    ...notificationsSagas,
    ...orderRequirementsSagas,
    ...modalSagas,
    ...dealpipelinesSagas,
    ...commentsSagas,
    ...summariesSagas,
    ...guaranteesSagas,
    ...orderRequestedsSagas,
    ...rolesSagas,
    ...leadersSagas,
    ...deliversSagas,
    ...orderHistoriesSagas,
    ...designersSagas,
    ...dealersSagas,
    ...salerBSSagas,
    ...nichesSagas,
    ...brandsSagas,
    ...productsSagas,
    ...packagesSagas,
    ...serviceTypesSagas,
    ...orderStatusesSagas,
    ...customerSegmentationsSagas,
    ...productCatalogsSagas,
    ...platformsSagas,
    ...departmentsSagas,
    ...ordersSagas,
    ...channelsSagas,
    ...userTypesSagas,
    ...customersSagas,
    ...configSagas,
    ...usersSagas,
    ...authSaga,
    ...referenceSagas,
  ]);
}
