import React from 'react';
import RestShow from '../../rest/Show';
import RestFieldItem from '../../../components/RestField/RestFieldItem';

const SalariesShow = props => (
  <RestShow {...props} hasEdit resource="salaries">
    <RestFieldItem source="name" header="salaries.name" />
  </RestShow>
);

export default SalariesShow;
