import React, { useContext, useState, useLayoutEffect } from 'react';
import moment from 'moment';
import { Table, Typography, Tag, DatePicker } from 'antd';
import I18n from 'i18next';
import RestInputItem from 'components/RestInput/RestInputItem';
import RestSelect from 'components/RestInput/RestSelect';
import workingRegistersActions from 'redux/workingRegisters/actions';
import { RestInputContext } from 'components/RestInput/RestInputContext';
import { flat } from 'utils/tools';
import { getAllApi } from 'api/crud';
import { WORKING_TIME_OF_DAY, WORKING_TYPES } from 'configs/localData';
import { formatDate } from 'utils/textUtils';
import { useSelector, useDispatch } from 'react-redux';
import CurrentDate from 'containers/WorkingRegisters/List/CurrentDate';
import WorkingTimeStyles from './styles';

const WEEKS = [
  'date.t2',
  'date.t3',
  'date.t4',
  'date.t5',
  'date.t6',
  'date.t7',
  'date.cn',
];
const WorkingTimesForm = (props) => {
  // const monday = moment().startOf('week');
  const [startDate, setStateDate] = useState(moment().startOf('w'));
  const dispatch = useDispatch();
  const roles = useSelector((state) => state.config.roles);
  const authUser = useSelector((state) => state.auth.data);
  const currentUser = useSelector((state) => state.users.currentData);
  const { form } = useContext(RestInputContext);
  const currentRole = roles.find((e) =>
    props.userId ? e.id === currentUser.roleId : e.id === authUser.roleId,
  );

  const datesOfWeek = [];
  for (let i = 0; i < 7; i += 1) {
    datesOfWeek.push({
      title: WEEKS[i],
      date: moment(startDate.format()).add(i, 'd').format('YYYY-MM-DD'),
    });
  }

  const onChangeDate = (date) => {
    form.resetFields();
    setStateDate(moment(date.format()).startOf('week'));
    dispatch(
      workingRegistersActions.getAllWorkingRegisters(
        {
          includes: 'user',
          limit: 99,
          filter: {
            roleId: currentRole?.id,
            date: {
              $lte: moment(date.format()).endOf('w').format('YYYY-MM-DD'),
              $gte: moment(date.format()).startOf('w').format('YYYY-MM-DD'),
            },
          },
        },
        {
          isRefresh: true,
        },
      ),
    );
    getAllApi(props.userId ? 'workingRegisters' : 'workingRegisters/me', {
      filter: JSON.stringify({
        userId: props.userId,
        date: {
          $lte: moment(date.format()).endOf('w').format('YYYY-MM-DD'),
          $gte: moment(date.format()).startOf('w').format('YYYY-MM-DD'),
        },
      }),
    }).then((e) => {
      const tempData = { total: 0 };
      e.results.forEach((element, index) => {
        Object.keys(element.workingTimes).forEach((hour) => {
          tempData[`values.${6 - index}.workingTimes.${hour}`] =
            element.workingTimes[hour];
          tempData.total += element.workingTimes[hour] ? 1 : 0;
        });
      });

      tempData.total = Object.keys(tempData).filter(
        (key) =>
          ['date', 'total', 'normal', 'overtime'].indexOf(key) === -1 &&
          key.indexOf('date') === -1 &&
          tempData[key],
      ).length;
      tempData.overtime = Object.keys(tempData).filter((key) => {
        const isOT =
          Number(key.substring(key.lastIndexOf('.') + 1, key.length)) >= 19 ||
          key.substring(key.indexOf('.') + 1, key.indexOf('.') + 2) === '6';
        return (
          ['date', 'total', 'normal', 'overtime'].indexOf(key) === -1 &&
          key.indexOf('date') === -1 &&
          tempData[key] &&
          isOT
        );
      }).length;
      tempData.normal = tempData.total - tempData.overtime;
      form.setFieldsValue(tempData);
    });
  };

  useLayoutEffect(() => {
    currentRole?.id && onChangeDate(startDate);
    // eslint-disable-next-line
  }, [currentRole?.id]);

  const onSelectTime = (i, phaseIndex, hour) => (e) => {
    let newData = {};
    if (e) {
      newData = {
        [`values.${i}.workingTimes.${hour}`]: e,
        [`values.${i}.workingTimes.${hour + 1}`]: e,
        [`values.${i}.workingTimes.${hour + 2}`]: e,
        [`values.${i}.workingTimes.${hour + 3}`]: e,
      };
    } else {
      newData = {
        [`values.${i}.workingTimes.${hour}`]: e,
      };
    }

    // if (!e) {
    //   WORKING_TIME_OF_DAY[phaseIndex].hours.forEach((h) => {
    //     newData[[`values.${i}.workingTimes.${h}`]] = false;
    //   });
    // }

    const values = {
      ...flat(form.getFieldsValue()),
      ...newData,
    };
    newData.total = Object.keys(values).filter(
      (key) =>
        ['date', 'total', 'normal', 'overtime'].indexOf(key) === -1 &&
        key.indexOf('date') === -1 &&
        values[key],
    ).length;
    newData.overtime = Object.keys(values).filter((key) => {
      const isOT =
        Number(key.substring(key.lastIndexOf('.') + 1, key.length)) >= 19 ||
        key.substring(key.indexOf('.') + 1, key.indexOf('.') + 2) === '6';
      return (
        ['date', 'total', 'normal', 'overtime'].indexOf(key) === -1 &&
        key.indexOf('date') === -1 &&
        values[key] &&
        isOT
      );
    }).length;
    newData.normal = newData.total - newData.overtime;
    form.setFieldsValue(newData);
    return e;
  };

  const columns = [
    {
      title: I18n.t('workingRegisters.summary'),
      dataIndex: 'summary',
      fixed: 'left',
      children: [
        {
          title: I18n.t('workingRegisters.total'),
          dataIndex: 'total',
        },
        {
          title: I18n.t('workingRegisters.normal'),
          dataIndex: 'normal',
        },
        {
          title: I18n.t('workingRegisters.overtime'),
          dataIndex: 'overtime',
        },
      ],
    },
    {
      title: <div style={{ visibility: 'hidden', width: 10 }}>fake</div>,
      dataIndex: 'fake',
    },
    ...datesOfWeek.map((date, i) => ({
      ...date,
      title: formatDate(date.date, 'dddd (DD/MM/YYYY)'),
      dataIndex: date.title,
      key: date.title + i,
      checkbox: true,
      align: 'center',
      children: WORKING_TIME_OF_DAY.map((e, index) => ({
        title: I18n.t(e.title),
        align: 'center',
        key: `${i}.${e.title}.hours`,
        dataIndex: `hours`,
        children: e.hours.map((hour, hourIndex) => ({
          title: hour,
          align: 'center',
          key: `${i}.hours.${hour}`,
          dataIndex: `hour`,
          render: (data, record) => (
            <RestSelect
              ruleType="string"
              selectProps={{ style: { width: 60 } }}
              source={`values.${i}.workingTimes.${hour}`}
              valueProp="value"
              titleProps="text"
              resourceData={WORKING_TYPES}
              formOptions={{
                getValueFromEvent: onSelectTime(
                  i,
                  index,
                  hour,
                  e.hours.length - 4 < hourIndex,
                ),
              }}
              // disabled={
              //   e.hours.length - 4 < hourIndex ||
              //   moment()
              //     .endOf('w')
              //     .diff(startDate) >= 0
              // }
            />
          ),
        })),
      })),
    })),
  ];

  return (
    <WorkingTimeStyles>
      <div className="row">
        <Typography.Title level={2}>
          {I18n.t('workingRegisters.title', {
            start: startDate.format('DD/MM'),
            end: moment(startDate.format()).endOf('w').format('DD/MM'),
          })}
        </Typography.Title>
        <div>
          <DatePicker.WeekPicker value={startDate} onChange={onChangeDate} />
          <Tag style={{ marginLeft: 20 }} color="blue">
            {`Team ${currentRole?.name}`}
          </Tag>
        </div>
      </div>
      <Table
        scroll={{ x: '1200px' }}
        tableLayout="auto"
        dataSource={[form.getFieldsValue()]}
        columns={columns}
        bordered
        pagination={false}
        rowKey="total"
      />
      <div style={{ display: 'none' }}>
        {datesOfWeek.map((e, index) => (
          <RestInputItem
            defaultValue={e.date}
            required={false}
            key={`values.${String(index)}.date`}
            source={`values.${index}.date`}
          />
        ))}
        <RestInputItem ruleType="number" source="total" />
        <RestInputItem ruleType="number" source="normal" />
        <RestInputItem ruleType="number" source="overtime" />
      </div>
      <CurrentDate selectDay={startDate.format('YYYY-MM-DD')} />
    </WorkingTimeStyles>
  );
};

WorkingTimesForm.propTypes = {};

export default WorkingTimesForm;
