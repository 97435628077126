import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'antd';
import { findIndex } from 'lodash';
import { addPermissionForUser } from 'redux/users/actions';
import { setPermission } from 'redux/tempPermissions/actions';
import PermissionCheckbox from '../PermissonCheckbox';

const SelectionPermissionModal = ({
  onCancel,
  record,
  index: i,
  data,
  config,
}) => {
  const dispatch = useDispatch();
  const stringtoWord = useSelector((state) => state.auth.stringtoWord);
  const tempPermissions = useSelector(
    (state) => state.tempPermissions[record?.id]
  );

  const onOk = () => {
    dispatch(addPermissionForUser(tempPermissions));
    onCancel();
  };

  const onClose = () => {
    dispatch(
      setPermission(record.id, {
        userId: record.id,
        permission: record.permission,
      })
    );
    onCancel();
  };

  return (
    <Modal
      onOk={onOk}
      onCancel={onClose}
      title={config?.title}
      visible={!!config}
    >
      {config?.permissions?.map((e, index) => (
        <div>
          <PermissionCheckbox
            index={i}
            roleIndex={
              data && findIndex(data, (e) => e.title === config?.title)
            }
            config={config}
            rootPermission={config.key}
            key={e}
            permissionKey={e}
            data={data}
            record={record}
          />
          {` ${stringtoWord[e]}`}
        </div>
      ))}
    </Modal>
  );
};

SelectionPermissionModal.propTypes = {
  onCancel: PropTypes.func,
  record: PropTypes.object,
  index: PropTypes.number,
  data: PropTypes.object,
  config: PropTypes.object,
};

export default SelectionPermissionModal;
