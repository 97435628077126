import React from 'react';
import RestInputItem from '../../../../components/RestInput/RestInputItem';

const DesignersForm = props => (
  <div {...props}>
    <RestInputItem source="displayName" header="designers.displayName" />
  </div>
);

DesignersForm.propTypes = {};

export default DesignersForm;
