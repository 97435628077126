import React from 'react';
import RestShow from '../../../containers/rest/Show';
import RestFieldItem from '../../../components/RestField/RestFieldItem';

const RolesShow = props => (
  <RestShow {...props} hasEdit resource="roles">
    <RestFieldItem source="name" header="roles.name" />
    <RestFieldItem source="description" header="roles.description" />
  </RestShow>
);

export default RolesShow;
