import { ModalTypes } from './actions';
import { makeReducerCreator } from '../../utils/reduxUtils';

export const initialState = {
  current: null,
};

const showModal = (state, { data }) => ({
  current: data,
});

const closeModal = () => ({
  current: null,
});
const showPreviewImage = (state, { data }) => ({
  ...state,
  ...data,
  previewVisible: true,
});

const cancelPreviewImage = state => ({
  ...state,
  previewVisible: false,
});

export default makeReducerCreator(initialState, {
  [ModalTypes.SHOW_MODAL]: showModal,
  [ModalTypes.CLOSE_MODAL]: closeModal,
  [ModalTypes.SHOW_PREVIEW]: showPreviewImage,
  [ModalTypes.CANCEL_PREVIEW]: cancelPreviewImage,
});
