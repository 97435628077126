import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Rate, notification } from 'antd';
import i18next from 'i18next';
import TextEditor from 'components/common/TextEditor';

const desc = ['terrible', 'bad', 'normal', 'good', 'wonderful'];

const Editor = ({ hasRate, submitting, onSubmit, value, initRate }) => {
  const ref = useRef(null);
  const [note] = useState({ text: value || ' ' });
  const [rate, setRate] = useState(initRate || 0);

  const onSubmitComment = () => {
    if (rate < 1 && hasRate) {
      notification.error({
        description: i18next.t('comments.requireRate'),
      });
      return;
    }
    onSubmit(note.text, rate).then(res => {
      if (!res.error) {
        note.text = '';
        setRate(0);
        ref.current && ref.current.onClear();
      }
    });
  };

  return (
    <div>
      <div style={{ background: 'white' }}>
        {hasRate && (
          <div style={{ margin: 10 }}>
            <Rate tooltips={desc} onChange={e => setRate(e)} value={rate} />
            {rate ? (
              <span className="ant-rate-text">{desc[rate - 1]}</span>
            ) : (
              ''
            )}
            <br />
          </div>
        )}
        <TextEditor
          ref={ref}
          onEditorChange={e => {
            note.text = e;
          }}
          placeholder={i18next.t('orderHistories.modal.reopenReason')}
          defaultValue={value}
        />
      </div>
      <Form.Item>
        <Button
          htmlType="submit"
          loading={submitting}
          onClick={onSubmitComment}
          type="primary"
        >
          {i18next.t('button.comment')}
        </Button>
      </Form.Item>
    </div>
  );
};

Editor.propTypes = {
  submitting: PropTypes.bool,
  onSubmit: PropTypes.func,
  value: PropTypes.string,
  hasRate: PropTypes.bool,
  initRate: PropTypes.number,
};

export default Editor;
