import { Avatar, Button, Checkbox, Tabs } from "antd";
import { getAllApi } from "api/crud";
import {
  findProductWithImage,
  uploadMedia,
  uploadMediaWithImgBB,
} from "api/uploadMedia";
import RestUpload from "components/RestInput/RestUpload";
import React, { useEffect } from "react";
import { useState } from "react";
import { convertRequestParams } from "redux/crudCreator/dataProvider";

export default function SearchByImageContent({ form, initImage, onLink }) {
  const [loading, setLoading] = useState(false);
  const [suggestionOrders, setOrders] = useState([]);
  const [selected, setSelected] = useState([]);
  const onSearch = () => {
    form.validateFields(async (err, values) => {
      if (!err) {
        setLoading(true);
        console.log(values);
        // const submitData = values;values.link
        const image = await uploadMediaWithImgBB(
          values?.link?.[0]?.originFileObj || values?.link?.[0]
        );
        // call api here
        const _searchOrders = await findProductWithImage(image?.data?.url);
        console.log();
        const orders = await getAllApi(
          "orders",
          convertRequestParams("GET_ALL", {
            filter: {
              id: {
                $in: _searchOrders.result
                  .filter((e) => e.id)
                  .map((order) => order.id),
              },
            },
          })
        );
        setOrders(orders.results || orders.result);
        setLoading(false);
      } else {
      }
    });
  };

  const toggleSelect = (value, order) => {
    setSelected((old) => {
      if (value) {
        return [...old, order];
      }
      return old.filter((item) => item.id !== order.id);
    });
  };

  const handleLink = () => {
    onLink && onLink(selected.map((item) => item.id));
  };

  useEffect(() => {
    if (initImage) {
      onSearch();
    }
  }, [initImage]);
  return (
    <div>
      <RestUpload
        defaultValue={initImage ? [initImage] : []}
        useFormData
        source="link"
      />
      <Button loading={loading} onClick={onSearch}>
        Search
      </Button>
      {suggestionOrders.length > 0 && (
        <div>
          <div className="d-flex w-100 justify-between">
            <h2>Results</h2>
            {onLink && (
              <Button onClick={handleLink} type="danger">
                Link
              </Button>
            )}
          </div>
          {suggestionOrders.map((order, index) => (
            <a href={`/orders/${order.id}/edit`}>
              <div key={index} className="d-flex mt-16">
                <Avatar
                  shape="square"
                  style={{ width: 100, height: 100 }}
                  src={order?.thumbnail}
                />
                <div className="flex-1 ml-12">
                  <h3>
                    #{order.id} - {order?.name}
                  </h3>
                  <p>Score: {order.score}</p>
                </div>
                {onLink && (
                  <Checkbox
                    onChange={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      toggleSelect(e.target.checked, order);
                    }}
                  />
                )}
              </div>
            </a>
          ))}
        </div>
      )}
    </div>
  );
}
