/* eslint-disable */
import React, { useState } from 'react';
import { Select, Input, InputNumber, Popconfirm, Form } from 'antd';
import RestFieldItem from 'components/RestField/RestFieldItem';
import { BONUS_SUB_LEVELS, BONUS_LEVELS } from 'configs/localData';
import { values } from 'lodash';
import i18next from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import Reference from 'containers/rest/Reference';
import { Link } from 'react-router-dom';
import { formatInputMoney } from 'utils/tools';
import List from '../../rest/List';
import crudActions from '../../../redux/crudActions';
import { formatMoney } from 'utils/textUtils';

const data = [];
for (let i = 0; i < 100; i += 1) {
  data.push({
    key: i.toString(),
    name: `Edrward ${i}`,
    age: 32,
    address: `London Park no. ${i}`,
  });
}
const EditableContext = React.createContext();

class EditableCell extends React.Component {
  getInput = () => {
    if (this.props.inputType === 'salaryScheme') {
      return (
        <Select allowClear style={{ width: 150 }}>
          {BONUS_LEVELS.map((e) => (
            <Select.Option key={e} value={e}>
              {e}
            </Select.Option>
          ))}
        </Select>
      );
    }
    if (this.props.inputType === 'kpi') {
      return (
        <Select allowClear style={{ width: 150 }}>
          {BONUS_SUB_LEVELS.map((e) => (
            <Select.Option key={e} value={e}>
              {e}
            </Select.Option>
          ))}
        </Select>
      );
    }
    if (this.props.inputType === 'number') {
      return <InputNumber {...formatInputMoney} />;
    }
    return <Input />;
  };

  renderCell = ({ getFieldDecorator }) => {
    const {
      editing,
      dataIndex,
      title,
      inputType,
      record,
      index,
      children,
      defaultValue,
      ...restProps
    } = this.props;
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item style={{ margin: 0 }}>
            {getFieldDecorator(dataIndex, {
              initialValue: record[dataIndex] || defaultValue,
            })(this.getInput())}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  render() {
    return (
      <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
    );
  }
}

const EditableTable = ({ form, filterData, setFilter, location, ...props }) => {
  const [editingKey, setEditKey] = useState('');
  const [currentRow, setCurrentRow] = useState(null);
  const roles = useSelector((state) => state.config.roles);
  const salaryHistories = useSelector(
    (state) => state.reference.salaryHistories,
  );

  const isEditing = (record) => record.id === editingKey;
  const dispatch = useDispatch();
  const cancel = () => {
    setEditKey('');
    setCurrentRow(null);
  };

  const save = (form, key) => {
    form.validateFields((error, row) => {
      if (error) {
        return;
      }
      // const newData = [...this.state.data];
      // const index = newData.findIndex(item => key === item.key);
      // if (index > -1) {
      //   const item = newData[index];
      //   newData.splice(index, 1, {
      //     ...item,
      //     ...row,
      //   });
      // } else {
      //   newData.push(row);
      // }

      const currentSalaryHistory = values(salaryHistories?.data || []).find(
        (e) => e.userId === editingKey,
      );
      if (currentSalaryHistory) {
        dispatch(
          crudActions.salaryHistories.editSalaryHistories(
            {
              id: currentSalaryHistory.id,
              dateMonth: filterData.dateMonth,
              startDate: filterData.startDate,
              endDate: filterData.endDate,
              userId: editingKey,
              salaryScheme: currentRow.salaryScheme || 'E',
              coef: currentRow.coef || 0,
              coefOT: currentRow.coefOT || 0,
              ...row,
            },
            { isBack: false },
          ),
        ).then((e) => {
          console.log('filterData', filterData);
          setFilter({
            ...filterData,
            initialFilter: { ...filterData.initialFilter },
          });
        });
        setEditKey('');
      } else {
        dispatch(
          crudActions.salaryHistories.createSalaryHistories(
            {
              dateMonth: filterData.dateMonth,
              startDate: filterData.startDate,
              endDate: filterData.endDate,
              userId: editingKey,
              salaryScheme: currentRow.salaryScheme || 'E',
              ...row,
              coef: currentRow.coef || 0,
              coefOT: currentRow.coefOT || 0,
            },
            { isBack: false },
          ),
        ).then((e) => {
          setFilter({
            ...filterData,
            initialFilter: { ...filterData.initialFilter },
          });
        });
        setEditKey('');
      }
    });
  };

  const edit = (key, record) => {
    setEditKey(key);
    setCurrentRow(record);
  };

  const components = {
    body: {
      cell: EditableCell,
    },
  };

  return (
    <EditableContext.Provider value={form}>
      <List
        location={location}
        components={components}
        rootPath="/salaryHistories"
        bordered
        resource="users"
        ignoreFilters={['dateMonth']}
      >
        <RestFieldItem
          format={(data, record) => (
            <Link to={`/users/${record.id}/edit`}>{data}</Link>
          )}
          source="displayName"
          header="users.displayName"
        />
        <RestFieldItem
          filters={roles.map((e) => ({
            value: e.id,
            text: e.description,
          }))}
          format={(data) =>
            roles.find((e) => e.id === data) &&
            roles.find((e) => e.id === data).description
          }
          source="roleId"
          header="Role"
        />
        <RestFieldItem
          onCell={(record) => ({
            record,
            inputType: 'salaryScheme',
            dataIndex: 'salaryScheme',
            defaultValue:
              values(salaryHistories?.data || []).find(
                (e) => e.userId === record.id,
              )?.salaryScheme || record.salaryScheme,
            editing: isEditing(record),
          })}
          format={(data, record) =>
            values(salaryHistories?.data || []).find(
              (e) => e.userId === record.id,
            )?.salaryScheme || data
          }
          source="salaryScheme"
          header="salaryHistories.salaryScheme"
        />
        <RestFieldItem
          onCell={(record) => ({
            record,
            inputType: 'number',
            dataIndex: 'coef',
            defaultValue:
              values(salaryHistories?.data || []).find(
                (e) => e.userId === record.id,
              )?.coef || record.coef,
            editing: isEditing(record),
          })}
          format={(data, record) =>
            values(salaryHistories?.data || []).find(
              (e) => e.userId === record.id,
            )?.coef || data
          }
          source="coef"
          header="salaryHistories.coef"
        />
        <RestFieldItem
          onCell={(record) => ({
            record,
            inputType: 'number',
            dataIndex: 'coefOT',
            defaultValue:
              values(salaryHistories?.data || []).find(
                (e) => e.userId === record.id,
              )?.coefOT || record.coefOT,
            editing: isEditing(record),
          })}
          format={(data, record) =>
            values(salaryHistories?.data || []).find(
              (e) => e.userId === record.id,
            )?.coefOT || data
          }
          source="coefOT"
          header="salaryHistories.coefOT"
        />
        <RestFieldItem
          onCell={(record) => ({
            record,
            inputType: 'kpi',
            dataIndex: 'kpi',
            defaultValue:
              values(salaryHistories?.data || []).find(
                (e) => e.userId === record.id,
              )?.kpi || BONUS_SUB_LEVELS[0],
            editing: isEditing(record),
          })}
          format={(data, record) =>
            values(salaryHistories?.data || []).find(
              (e) => e.userId === record.id,
            )?.kpi
          }
          source="kpi"
          header="salaryHistories.kpi"
        />
        <Reference
          source="id"
          mappedBy="userId"
          resource="salaryHistories"
          header="salaryHistories.normalTime"
          initialFilter={filterData.initialFilter}
        >
          <RestFieldItem
            format={(data, record) => {
              return (
                <div>
                  {`${i18next.t('salaryHistories.time')}: `}
                  {`${record?.workingHours || 0} hours`}
                  <br />
                  {`${i18next.t('salaryHistories.bonus')}: `}
                  <b>{`${formatMoney(record?.salaryInBonus || 0)}`}</b>
                  <br />
                  {`${i18next.t('salaryHistories.bonusForKPI')}: `}
                  <b>{`${formatMoney(record?.salaryInKpi || 0)}`}</b>
                  <br />
                  {`${i18next.t('salaryHistories.baseSalary')}: `}
                  <b>{`${formatMoney(record?.salaryInBasic || 0)}`}</b>
                  <br />
                  {`${i18next.t('salaryHistories.salary')}: `}
                  <b>{`${formatMoney(record?.salary || 0)}`}</b>
                </div>
              );
            }}
            source="salary"
          />
        </Reference>
        <Reference
          source="id"
          mappedBy="userId"
          resource="salaryHistories"
          header="salaryHistories.overtime"
          initialFilter={filterData.initialFilter}
        >
          <RestFieldItem
            format={(data, record) => {
              return (
                <div>
                  {`${i18next.t('salaryHistories.time')} OT: `}
                  {`${record?.workingHoursOT || 0} hours`}
                  <br />
                  {`${i18next.t('salaryHistories.salary')} OT: `}
                  <b>{`${formatMoney(record?.salaryInOT || 0)}`}</b>
                </div>
              );
            }}
            source="workingHours"
          />
        </Reference>
        <RestFieldItem
          format={(text, record) => {
            const editable = isEditing(record);
            return editable ? (
              <span>
                <EditableContext.Consumer>
                  {(form) => (
                    <a
                      onClick={() => save(form, record.id)}
                      style={{ marginRight: 8 }}
                    >
                      Save
                    </a>
                  )}
                </EditableContext.Consumer>
                <Popconfirm
                  title="Sure to cancel?"
                  onConfirm={() => cancel(record.id)}
                >
                  <a>Cancel</a>
                </Popconfirm>
              </span>
            ) : (
              <a
                disabled={editingKey !== ''}
                onClick={() => edit(record.id, record)}
              >
                Edit
              </a>
            );
          }}
          source="action"
          header="Action"
        />
      </List>
    </EditableContext.Provider>
  );
};

const EditableFormTable = Form.create()(EditableTable);
export default EditableFormTable;
