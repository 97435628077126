import React from 'react';
// import PropTypes from 'prop-types';
import { Card } from 'antd';
import i18next from 'i18next';
import ReferenceInput from '../../../../containers/rest/ReferenceInput';
import MaterialInput from '../../../../components/common/MaterialInput';
import RestInputItem from '../../../../components/RestInput/RestInputItem';
import RestDateTimeInput from '../../../../components/RestInput/RestDateTimeInput';
import RestSelect from '../../../../components/RestInput/RestSelect';
import SaveButton from '../../../../components/RestActions/SaveButton';
import RestTextEditor from '../../../../components/RestInput/RestTextEditor';

const ClientDetail = () => (
  <Card
    className="box"
    title={
      <span className="txtHeader">{i18next.t('customers.detail.about')}</span>
    }
    extra={<SaveButton className="icEdit" />}
  >
    <RestInputItem isAutoUpdate source="code" header="Order Code" />
    <RestInputItem isReference source="contactOwner">
      <ReferenceInput
        source="contactOwner"
        valueProp="id"
        titleProp="name"
        resource="users"
        searchKey="q"
      >
        <RestSelect
          source="contactOwner"
          valueProp="id"
          titleProp="displayName"
          header="customers.contactOwner"
        />
      </ReferenceInput>
    </RestInputItem>
    <RestInputItem isReference source="customerSegmentationId">
      <ReferenceInput
        source="customerSegmentationId"
        resource="customerSegmentations"
      >
        <RestSelect
          isAutoUpdate
          source="customerSegmentationId"
          valueProp="id"
          titleProp="name"
          header="customers.customerSegmentation"
        />
      </ReferenceInput>
    </RestInputItem>
    <RestInputItem source="email">
      <MaterialInput isAutoUpdate placeholder={i18next.t('customers.email')} />
    </RestInputItem>

    <RestInputItem source="phoneNumber">
      <MaterialInput placeholder={i18next.t('customers.phoneNumber')} />
    </RestInputItem>

    <RestInputItem source="facebookLink">
      <MaterialInput
        isAutoUpdate
        placeholder={i18next.t('customers.facebookLink')}
      />
    </RestInputItem>
    <RestInputItem source="fbId">
      <MaterialInput isAutoUpdate placeholder="Facebook ID" />
    </RestInputItem>
    <RestInputItem source="address">
      <MaterialInput
        isAutoUpdate
        placeholder={i18next.t('customers.address')}
      />
    </RestInputItem>
    <RestDateTimeInput
      disabled
      required
      placeholder={i18next.t('customers.createdAt')}
      source="createdAt"
    />
    <RestInputItem isReference source="channelId">
      <ReferenceInput source="channelId" resource="channels">
        <RestSelect
          isAutoUpdate
          source="channelId"
          valueProp="id"
          titleProp="name"
          header="customers.channel"
        />
      </ReferenceInput>
    </RestInputItem>
    <RestInputItem isReference source="serviceTypeId">
      <ReferenceInput source="serviceTypeId" resource="serviceTypes">
        <RestSelect
          isAutoUpdate
          source="serviceTypeId"
          valueProp="id"
          titleProp="name"
          header="customers.serviceType"
        />
      </ReferenceInput>
    </RestInputItem>
    <RestInputItem isReference source="dealPipelineId">
      <ReferenceInput source="dealPipelineId" resource="dealpipelines">
        <RestSelect
          ruleType="number"
          source="dealPipelineId"
          valueProp="id"
          titleProp="name"
          isAutoUpdate
          header="customers.dealPipeline"
        />
      </ReferenceInput>
    </RestInputItem>
    <RestTextEditor isAutoUpdate source="note" header="customers.note" />
    <RestTextEditor
      isAutoUpdate
      source="feedback"
      header="customers.feedback"
    />
  </Card>
);

ClientDetail.propTypes = {};

export default ClientDetail;
