import React from 'react';
import RestShow from '../../../containers/rest/Show';
import RestFieldItem from '../../../components/RestField/RestFieldItem';
import { formatMoney } from '../../../utils/textUtils';

const ProductsShow = props => (
  <RestShow {...props} hasEdit resource="products">
    <RestFieldItem source="name" header="products.name" />
    <RestFieldItem source="productCatalogId" header="products.productCatalogId" />
    <RestFieldItem source="images" header="products.images" />
    <RestFieldItem format={data => formatMoney(data)} source="price" header="products.price" />
  </RestShow>
);

export default ProductsShow;
