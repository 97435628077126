import { Button, Form, Modal } from "antd";
import React, { useState } from "react";

import SearchByImageContent from "./SearchByImageContent";
import { SearchByImageButtonStyle } from "./style";
import { RestInputContext } from "components/RestInput/RestInputContext";

function SearchByImageButton({ form, onLink, initImage }) {
  const [visible, setVisible] = useState(false);
  const _onLink = (e) => {
    onLink(e);
    setVisible(false);
  };
  console.log(initImage);
  return (
    <>
      <SearchByImageButtonStyle
        type="primary"
        onClick={() => {
          setVisible(true);
          if (initImage) {
            form.setFieldsValue({ link: [initImage] });
          }
        }}
      >
        <img src="/images/scan-image.gif" /> Search By Image
      </SearchByImageButtonStyle>
      <Modal
        title="Search With Image"
        footer={null}
        visible={visible}
        onCancel={() => setVisible(false)}
      >
        <Form form={form}>
          <RestInputContext.Provider
            value={{ form, record: initImage ? { link: [initImage] } : {} }}
          >
            <SearchByImageContent
              initImage={initImage}
              onLink={_onLink}
              form={form}
            />
          </RestInputContext.Provider>
        </Form>
      </Modal>
    </>
  );
}

const SearchByImageButtonForm = Form.create()(SearchByImageButton);

export default (props) => <SearchByImageButtonForm {...props} />;
