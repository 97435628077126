import React from 'react';
import RestShow from '../../../containers/rest/Show';
import RestFieldItem from '../../../components/RestField/RestFieldItem';

const LeadersShow = props => (
  <RestShow {...props} hasEdit resource="leaders">
    <RestFieldItem source="displayName" header="leaders.displayName" />
  </RestShow>
);

export default LeadersShow;
