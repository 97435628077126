import React from 'react';
import i18next from 'i18next';
import { RestInputContext } from '../../../../components/RestInput/RestInputContext';
import CustomBreadcrumb from '../../../../components/common/Breadcrumb';
import PageTitle from '../../../../components/common/PageTitle';

const ClientBreadcrumb = () => (
  <RestInputContext.Consumer>
    {({ record = {} }) => {
      const BREADCRUMB_LIST = [
        {
          title: i18next.t('customers.title'),
          path: '/customers',
        },
        {
          title: record.displayName,
          path: `/customers/${record.id}/edit`,
        },
      ];
      return (
        <PageTitle>
          <CustomBreadcrumb data={BREADCRUMB_LIST} />
        </PageTitle>
      );
    }}
  </RestInputContext.Consumer>
);

ClientBreadcrumb.propTypes = {};

export default ClientBreadcrumb;
