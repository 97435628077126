import React from 'react';
import { Tag, Switch, Rate } from 'antd';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import i18next from 'i18next';
import List from '../../../containers/rest/List';
import RestFieldItem from '../../../components/RestField/RestFieldItem';
import ActionGroup from '../../../components/RestActions/ActionGroup';
import EditButton from '../../../components/RestActions/EditButton';
import { formatDateTime } from '../../../utils/textUtils';
import { USER_TYPES, STATUS } from '../../../configs/localData';

const UsersList = (props) => (
  <List
    {...props}
    redirects={{ edit: 'screen', create: 'modal' }}
    resource="users"
  >
    <RestFieldItem
      format={(data, record) => (
        <Link to={`/users/${record.id}/edit`}>{data}</Link>
      )}
      source="displayName"
      header="users.displayName"
    />
    <RestFieldItem
      filters={props.roles.map((e) => ({ value: e.id, text: e.description }))}
      format={(data) =>
        props.roles.find((e) => e.id === data) &&
        props.roles.find((e) => e.id === data).description
      }
      source="roleId"
      header="roleId"
    />
    <RestFieldItem
      sorter
      width={150}
      source="rate"
      header="Rate"
      format={(data, record) =>
        record ? (
          <div style={{ width: 170 }}>
            <Rate value={record.rate || 0} />({record.totalRate || 0})
          </div>
        ) : null
      }
    />
    <RestFieldItem
      filters={USER_TYPES}
      format={(data) => <Tag>{data ? 'Outsource' : 'member'}</Tag>}
      source="isOutsource"
      header="isOutsource"
    />
    <RestFieldItem source="email" header="email" />
    <RestFieldItem source="phone" header="users.phone" />
    <RestFieldItem source="linkFB" header="users.linkFB" />
    <RestFieldItem
      format={(data) => formatDateTime(data)}
      source="birthday"
      header="users.birthday"
    />
    <RestFieldItem
      fixed="right"
      filters={STATUS.map((e) => ({ ...e, text: i18next.t(e.text) }))}
      component={<Switch />}
      type="switch"
      valueProp="checked"
      source="isDelete"
      hasDebounce={false}
      header="users.isDelete"
    />
    <ActionGroup>
      <EditButton />
    </ActionGroup>
  </List>
);

UsersList.propTypes = {};

export default connect((state) => ({
  roles: state.config.roles,
}))(UsersList);
